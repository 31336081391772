import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SettelementService } from '../services/settelement.service';
import * as XLSX from 'xlsx';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

type AOA = any[][];

@Component({
  selector: 'app-settelement-add',
  templateUrl: './settelement-add.component.html',
  styleUrls: ['./settelement-add.component.scss']
})
export class SettelementAddComponent implements OnInit {
  private gridApi;

  model: any = {};
  reconModel : any = {};
  submitted = false;
  public rowData:any;
  public columnDefs:any;
  public rowSelection: any;
  private gridColumnApi: any;

  settelementNames:any[];
  settelementName:any;
  reconNames:any[] = [];
  reconName:any;
  reconModuleName: string;

  settelementNewName:any; 
  settlevalidationb:boolean=true;
  //Import Functionality Start
  dataImport: AOA;
  finalData = [];
 
  settelementAddForm:any;
  fileName:string;
  fileData:string;

  constructor( public dialog: MatDialog,public settelementService : SettelementService,private formBuilder: FormBuilder){
    this.settelementAddForm =this.formBuilder.group({
      settelementName:new FormControl('',Validators.required),
      settelementNewName:new FormControl('',Validators.required),
      import :new FormControl({ value: "", disabled: true }),

   });
    this.rowSelection="single";
  }

  ngOnInit() {
     this.reconModuleName = localStorage.getItem('reconModuleName');
    // this.getReconReportInfoName();
    this. loadReportInfo();
    //this.getReconNames();
    console.log(this.settelementNames);
    console.log(this.settelementName);
    this.settelementAddForm.controls['import'].disable();
  }

  onSubmit() {
    this.submitted = true;    
  }
  get fval() {
    return this.settelementAddForm.controls;
  }
  button(value){
    console.log("button value",value);
    if (value ==undefined || value=="select"){
    this.settlevalidationb=true;
    this.settelementAddForm.get('import').disable();
  }
    else
    this.settlevalidationb=false;
    this.settelementAddForm.get('import').enable();
    
  }
  getReconReportInfoName(){
    this.settelementService.getReconReportInfoName().subscribe(
      (data) => {
        this.settelementNames=data.object;
      },
      error => {
        console.log(error);
      }
    );
  }

  // getReconNames() {
  //   this.settelementService.getReconNames().subscribe(
  //     data => {
  //       console.log("Result Data --> ", data);
  //       for (const [key, value] of Object.entries(data)) {
  //           console.log(value.id , "  ===  " , value.moduleName)
  //           this.reconNames.push({ name: value.id, value: value.moduleName });
  //       }
  //       console.log("Recon Names --> ", this.reconNames);
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }

  addEditSettelement(){
    this.submitted = true;
    if (this.settelementAddForm.invalid) {
      return;
     }
     else{
    console.log("Add Event");
    console.log("1",this.model);
    console.log("2",this.finalData);
    console.log("Lastest Data --> ", this.fileData);
    console.log("Lastest Data 1 --> ", this.fileName);
    var reportName;
    if(this.model.settelementName === 'new'){
      reportName = this.model.settelementNewName;
    } else {
      reportName = this.model.settelementName;
    }
    var data = {
      "reportName": reportName,
      "uploadFileName": this.fileData,
      //"reconName": this.model.reconName
      "reconName": localStorage.getItem('reconModuleName'),
      "fileName":this.fileName,
      "updatedBy":JSON.parse(localStorage.getItem('user')).userId
    }
    console.log("Final Data Save --> ", data);
    this.settelementService.saveReconReportInfo(data).subscribe(
      data => {

        alert(data.message);
        console.log("Result Data --> ", data);
        
        this.getReconReportInfoName();
        //location.reload();
        this.settelementAddForm.reset();
        this.submitted=false;
        this.model.settelementName="select";
        this. loadReportInfo();
       

      },
      error => {
        console.log(error);
      }
    );
  }}

  onFileChange1(event) {  
    alert("Hi");
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.close.fileName = file.name;
      this.fileName = file.name;
      let reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        // this.close.fileData = btoa(reader.result.toString());
        this.fileData = btoa(reader.result.toString());
      }
      console.log("Data --> ", this.fileName, this.fileData);
    }
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    let validExts = new Array(".properties");
    console.log("File Name ", target.files[0].name);
    this.fileName=target.files[0].name;
    let fileExt = target.files[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (false) {
    // if (validExts.indexOf(fileExt) < 0 && fileExt != "") {
      alert("Invalid file selected, valid files are of " + validExts.toString() + " types.");
    }
    else {
      / wire up file reader /
      //const target: DataTransfer = <DataTransfer>(evt.target);
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        / read workbook /
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        / grab first sheet /
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        / save data /
        this.dataImport = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

        for (let index = 0; index < this.dataImport.length; index++) {
          var objData = {};
          for (let index1 = 0; index1 < this.dataImport[0].length; index1++) {
            objData[this.dataImport[0][index1]] = this.dataImport[index][index1];
          }
          if (index !== 0) {
            this.finalData[index - 1] = objData;
          };
        }
        console.log("Final Data --> ", this.finalData);
        // for (let index = 0; index < this.finalData.length; index++) {
        //   // this.gridApi.updateRowData({ add: [this.finalData[index]] });
        //   this.rowData.push(this.finalData[index]);
        // }
        // console.log("Final Data --> ", this.rowData);
      };
      reader.readAsBinaryString(target.files[0]);
      reader.onload = () => {
        this.fileData = btoa(reader.result.toString());
        console.log(this.fileData);
      }
    }
  }
   

  loadReportInfo(){

    this.settelementService.getReconReportInfo(this.reconModuleName).subscribe(
      (reportinfoList) => {
        this.rowData = reportinfoList;
        this.columnDefs = this.columnDefsReportInfodata;
      },
      error => {
        console.log(error);
      }
    );
  }
  private columnDefsReportInfodata = [
    { headerName: 'Report Id', field: 'reportId', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Report Name', field: 'reportName', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'CreationTime', field: 'creationTime', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'UpdateTime', field: 'updateTime', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'UpdateBy', field: 'updatedBy', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    // { headerName: 'OrganizationId', field: 'organizationid', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    //{ headerName: 'OpSource Id', field: 'opSourceId', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    //{ headerName: 'OpIpAddress', field: 'opIpAddress', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    //{ headerName: 'Upload File Name', field: 'uploadFileName', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Recon Name', field: 'reconName', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'File Name', field: 'fileName', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true }   
  ];
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  //Delete ReconInfo
  deleteReconInfoData(){
    var selectedData = this.gridApi.getSelectedRows();
    console.log("selected data",selectedData);
    if (selectedData == "") {
      alert("please select a record to delete");
     }else if(selectedData !== "")
     {
      if (confirm("Do you want to delete this record")) {
        console.log("selected data",selectedData[0].reportId)
        var reportId = selectedData[0].reportId;
        this.settelementService.deleteReconRecportInfo(reportId)  
        .subscribe( (serverResponse:any)=>{
          alert(serverResponse.message);
        },
        error => {
          console.log(error);
        });
        this.gridApi.updateRowData({ remove: selectedData });
      }
      
    }
    
  }
}