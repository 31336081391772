import { Component, OnInit, Inject } from '@angular/core';
import { ClosedCasesService } from '../../../services/closed-cases.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICaseManagement } from '../../../models/case-management.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-re-open',
  templateUrl: './re-open.component.html',
  styleUrls: ['./re-open.component.scss']
})
export class ReOpenComponent implements OnInit {

  caseManagement: ICaseManagement;
  reOpenForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(public closedCasesService: ClosedCasesService,
    public dialogRef: MatDialogRef<ICaseManagement>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.caseManagement = data.selectedCase;
  }

  ngOnInit() {
    this.reOpenForm = this.formBuilder.group({
      comments: ['', Validators.required]
    });
  }

  get f() { return this.reOpenForm.controls; }

  reOpen() {

    this.submitted = true;
    if (this.reOpenForm.invalid) {
      return;
    }
    this.loading = true;

    this.closedCasesService.reOpen(this.caseManagement, this.reOpenForm.value.comments).subscribe(
      caseResponse => {
        alert(caseResponse.message);
        this.loading = false;
        this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }
    );
  }

}
