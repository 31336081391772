import {Grid, GridOptions, Module, AllCommunityModules} from "@ag-grid-community/all-modules";

export class IReconDepartMent
{
  public id:number;
  public departmentName:string; 
}
export class IEscalationMatrixData
{
  public id:number;
  public departMent:string; 
  public escalatedPerson:string;
  public mailid:string;
  public status:string;
  public activeIndex:string;
  public version:string;
}
export class DialogData1{
  public id:number;
  public departMent:string; 
  public escalatedPerson:string;
  public mailid:string;
  public status:string;
  public activeIndex:string;
  public version:string;
}