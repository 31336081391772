import { Component, OnInit, Inject } from '@angular/core';

import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EtlService } from '../../../services/etl.service';
//for Cleaning
@Component({
  selector: 'AddMetaDataAdd',
  templateUrl: 'add-meta-data-add.html'
})

export class AddMetaDataAdd {
  selectOperationValue: string;

  metaDataFileName: any;
  metaDataTempletName: any;
  metaDataTempGroupId: any;
  fieldName: any;
  fieldType: any;
  fieldFormat: any;
  fieldSize: any;
  mandatory: any;
  description: any;
  AddmetadataAdd:any;
  submitted = false;
  selected: any[];
  rowData:any;
  dataTypeFormats = {
    "VARCHAR": ['VARCHAR'],
    "NUMBER": ['123', '-123', '123.00', '-123.00', '123.000', '123.000000000'],
    "DATE": ['dd/mm/yy', 'dd/mm/yyyy', 'mm/dd/yy', 'mm/dd/yyyy', 'yy/mm/dd', 'yyyy/mm/dd'],
    "TIMESTAMP": ["TIMESTAMP"],
    "LOOKUP": [],
    "DATETIME": ['DATETIME']
  };

 

  constructor(public etlService: EtlService, @Inject(MAT_DIALOG_DATA) public data: any, 
  public dialogRef:MatDialogRef<AddMetaDataAdd>) {
console.log("Add meta data ",data);
this.metaDataTempletName=data.tempId;
this.metaDataTempGroupId=data.groupId;

this.rowData=data.row;
this.AddmetadataAdd = new FormGroup ({
  FieldName:new FormControl('', Validators.required),
  FieldType:new FormControl('', Validators.required),
  Format:new FormControl('', Validators.required),
  FieldSize:new FormControl('', Validators.required),
  Mandatory:new FormControl('', Validators.required),
  Description:new FormControl('', Validators.required),
  
  
 });
 
}
 
    changeSelectOperation(event)
    {
        this.selectOperationValue = event.target.value;
        
    }
 
    get fval() {
      return this.AddmetadataAdd.controls;
      }
      signup(){
        this.submitted = true;
        if (this.AddmetadataAdd.invalid) {
        
          return;
        }
      
      
        }
 
    onSelect(value){
      console.log("Data --> ", value);
      if(value === "VARCHAR"){
        this.selected = this.dataTypeFormats.VARCHAR;
      } else if(value === "NUMBER") {
        this.selected = this.dataTypeFormats.NUMBER;
      } else if(value === "DATE") {
        this.selected = this.dataTypeFormats.DATE;
      } else if(value === "TIMESTAMP") {
        this.selected = this.dataTypeFormats.TIMESTAMP;
      } else if(value === "LOOKUP") {
        this.selected = this.dataTypeFormats.LOOKUP;
      } else if(value === "DATETIME") {
        this.selected = this.dataTypeFormats.DATETIME;
      } else {
        console.log("Wrong Selection --> ", value);
      }
    }
 
  
  saveMetaDataFormConfig() {
  
    if (this.AddmetadataAdd.invalid) {
       
      return;
    }
    let flag: boolean = false;


  for (let data of this.rowData) {
     if (data.FIELD_NAME==this.fieldName) {
         flag = true; 
      }   
  }

  if (flag) {
    alert("column is duplicated");
  }else{
    this.dialogRef.close(
    this.etlService.saveMetaDataFormConfig({cleaningSelectOperationCategory:"AddMetaData",
    metaDataTempletName:this.metaDataTempletName,
    metaDataTempGroupId:this.metaDataTempGroupId,
    fieldName:this.fieldName,
    fieldType:this.fieldType,
    fieldFormat:this.fieldFormat,
    fieldSize:this.fieldSize,
    mandatory:this.mandatory,
    description:this.description 
    }).subscribe((data:any)=>{
      if(data.message =="Record sucessfully inserted "){
        alert("Operation Added Successfully");
      }
      else
      alert(data.message);
      // alert("Add meta configuration successfully saved");
 
    }))
  }
  }

}