import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-select-cycle',
  templateUrl: './select-cycle.component.html',
  styleUrls: ['./select-cycle.component.scss']
})
export class SelectCycleComponent implements OnInit {

  roles: any = {}  

  model: any = {};
 
  submitted = false;
  constructor(public dialogRef: MatDialogRef<SelectCycleComponent>) { }

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;   
    console.log('Submitted'); 
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
