import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { IGroupName, IGroupUser } from '../../user/models/users.model';
import { GroupService } from '../service/group.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-group-users',
  templateUrl: './add-group-users.component.html',
  styleUrls: ['./add-group-users.component.scss']
})
export class AddGroupUsersComponent implements OnInit {

  roles: any = {}
  //userRoles: string[] = ['User Role 1', 'User Role 2'];
  groupNameList: IGroupName[]=[];
  userNameList: IGroupUser[]=[];
  name:any;
  user_name:any;
  model: any = {};
 
  submitted = false;
  users: any;
  addGroupForm: any;
 
  constructor(public dialogRef: MatDialogRef<AddGroupUsersComponent>, public groupService:GroupService,private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.addGroupForm =this.formBuilder.group({
        name:new FormControl('',Validators.required),
       users:new FormControl('',Validators.required),
       
     });
     }

  ngOnInit() {
    this.loadGroupName();
    this.loadUserGroup();
  }

  onSubmit() {
    this.submitted = true;   
    console.log('Submitted'); 
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  get fval() {
    return this.addGroupForm.controls;
    }
   //load group name for Group User
   loadGroupName(){
    this.groupService.loadGroupName().subscribe(
      (groupNameList : IGroupName[]) => {
        this.groupNameList = groupNameList;
     console.log("groupNameList",this.groupNameList)
      },
      error => {
        console.log(error);
      }
    );
  }
  //load Group User for asignGroupsTo users
  loadUserGroup(){
    this.groupService.loadGroupUser().subscribe(
      (userNameList : IGroupUser[]) => {
        this.userNameList = userNameList;
     console.log("userNameList",this.userNameList)
      },
      error => {
        console.log(error);
      }
    );
  }

  addAsignUsersGroup(){
    this.submitted = true;
    if (this.addGroupForm.invalid) {
    
    return;
    }
    else{
    let newColumnData = {
      "userId": this.users,
      "groupIds": this.name,
    }
    console.log("hello add user",newColumnData);
    this.groupService.addAsignGroupDetails(newColumnData).subscribe(
      screenResponse => {
        alert(screenResponse.message);
        this.dialogRef.close("true");

      },
      error => {
        console.log(error);
      }

    );


  }
  }
}
