import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ILoginResponse } from './login.model';
import { MatDialog } from '@angular/material';
import { ForgetPasswordDialog } from './forget-password/forget-password.componet';
import { HeaderService } from 'src/app/core/components/header/header.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  errorFlag: boolean = false;
  errorMessage: string;
  submitted = false;
  hide = true;
  flag = false;
  url: string;
  existingUserName = '';
  existingModuleName = '';

  constructor(public router: Router,
    private formBuilder: FormBuilder,
    public loginService: LoginService,
    public headerService: HeaderService,
    public dialog: MatDialog,
    ) { 
  }

  ngOnInit() {
    this.existingUserName = localStorage.getItem('existingUserName');
    this.existingModuleName = localStorage.getItem('existingModuleName');
    console.log("User-->", this.existingUserName);
    console.log("Module-->", this.existingModuleName);
    if (!(this.existingUserName === null || this.existingModuleName === null)) {
      if (!(this.existingUserName === null)) {
        this.flag = true;
        this.logoutDashboardAndReports();
      }
    }
    this.loginForm = this.formBuilder.group({
      userId: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  login() {    
    this.errorFlag = false;
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loginService.authenticateUser(this.loginForm.value).subscribe(
      (loginResponse: ILoginResponse) => {
        if (loginResponse.status) {
          if (loginResponse.message.startsWith("WARNING")) alert(loginResponse.message);
          localStorage.setItem('user', JSON.stringify(loginResponse));
          this.headerService.setUserLoggedIn(true);
          this.router.navigate(['/home']);
        }
        else {
          this.errorFlag = true;
          this.errorMessage = loginResponse.message;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  openForgetPasswordDialog(): void {
    console.log("Inside Forget Password");
    const dialogRef = this.dialog.open(ForgetPasswordDialog, {
      width: '350px',
      panelClass: 'mat-modal'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  logoutDashboardAndReports() {
    this.loginService.authenticateUserLogout().subscribe(
      (data) => {
        for (const [key, value] of Object.entries(data)) {
          this.url = value;
        }
        localStorage.clear();
      },
      error => {
        console.log(error);
        localStorage.clear();
      }
    );
  }
}