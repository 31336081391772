import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ParentComponent } from './parent.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FileUploadComponent } from '../parent/etl/components/file-upload/file-upload.component';
import { FileExtractionComponent } from '../parent/etl/components/file-extraction/file-extraction.component'
import { FieldConfigurationComponent } from './etl/components/field-configuration/field-configuration.component';
import { FileTransformationComponent } from './etl/components/file-transformation/file-transformation.component';
import { StagingDataComponent } from './staging/components/staging-data/staging-data.component';
import { ExceptionDataComponent } from './staging/components/exception-data/exception-data.component';
import { ProcessStatusComponent } from './etl/components/process-status/process-status.component';
import { AddRuleComponent } from './recon/components/add-rule/add-rule.component';
import { AddRuleDetailsComponent } from './recon/components/add-rule-details/add-rule-details.component';
import { UnreconciledComponent } from './recon/components/unreconciled/unreconciled.component';
import { ReconciledComponent } from './recon/components/reconciled/reconciled.component';
import { AddReconconfigComponent } from './recon/components/add-reconconfig/add-reconconfig.component';
import { ReportsComponent } from './reports/reports.component';
import { ActivityManagerComponent } from './activity-manager/components/activity-manager/activity-manager.component';
import { RunReconComponent } from './recon/components/run-recon/run-recon.component';
import { EscMatrixComponent } from './escalation-matrix/components/escalation-matrix/esc-matrix.component';
import { AgingComponent } from './aging/aging.component';
import { CreatedCasesComponent } from './case-management/components/created-cases/created-cases.component';
import { ClosedCasesComponent } from './case-management/components/closed-cases/closed-cases.component';
import { MasterSetupComponent } from './master-setup/master-setup.component';
import { ScreenSetupComponent } from './master-setup/screen-setup/screen-setup.component';
import { MasterDataComponent } from './master-setup/master-data/master-data.component';
import { OrgStructureComponent } from './master-setup/org-structure/org-structure.component';
import { SettelementAddComponent } from './settelement/settelement-add/settelement-add.component';
import { SettelementListComponent } from './settelement/settelement-list/settelement-list.component';
import { SameSideReversalComponent } from './recon/components/same-side-reversal/same-side-reversal.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserComponent } from './user-management/user/user.component';
import { RolesComponent } from './user-management/roles/roles.component';
import { PermissionComponent } from './user-management/permission/permission.component';
import { GroupsComponent } from './user-management/groups/groups.component';
import { UserRolesComponent } from './user-management/user-roles/user-roles.component';
import { FrameworkComponent } from './framework-landing-page/framework.component';
import { AuthGuard } from 'src/app/core/components/helpers/auth.guard';
import { ViewReconComponent } from './recon/view-recon/view-recon.component';
import { ViewEtlComponent } from './etl/components/view-etl/view-etl.component';
import { AdministrationComponent } from './reports/administration/administration.component';
import { ViewComponent } from './reports/view/view.component';
import { ForgetPasswordPageComponent } from '../login/forget-password/forget-password-page/forget-password-page.component';
import { LoginComponent } from '../login/login.component';
import { PasswordPolicyComponent } from './user-management/password-policy/password-policy.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { ConfigurationComponent } from './scheduler/components/configuration/configuration.component';
import { DependencyComponent } from './scheduler/components/dependency/dependency.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { GlBalancingComponent } from './recon/components/gl-balancing/gl-balancing.component';


const routes: Routes = [
	{
		path: 'home',
		component: FrameworkComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'forget-password-page',
		component: ForgetPasswordPageComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'access-denied',
		component: AccessDeniedComponent,
		canActivate: [AuthGuard]
	},
	{
		path: '',
		component: LoginComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'sample-recon', component: ParentComponent,
		children: [
			{
				path: 'dashboard',
				component: DashboardComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'etl', component: FileUploadComponent,
				children: [{
					path: 'file-upload',
					component: FileUploadComponent,
					canActivate: [AuthGuard]
				},
				]
			},
			{
				path: 'etl', component: ProcessStatusComponent,
				children: [{
					path: 'file-process-status',
					component: ProcessStatusComponent,
					canActivate: [AuthGuard]
				},
				]
			},
			{
				path: 'etl', component: FileExtractionComponent,
				children: [{
					path: 'file-extraction',
					component: FileExtractionComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'etl', component: ViewEtlComponent,
				children: [{
					path: 'view-etl',
					component: ViewEtlComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'etl', component: FileTransformationComponent,
				children: [{
					path: 'file-transformation',
					component: FileTransformationComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'staging', component: StagingDataComponent,
				children: [{
					path: 'staging-data',
					component: StagingDataComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'staging', component: ExceptionDataComponent,
				children: [{
					path: 'exception-data',
					component: ExceptionDataComponent,
					canActivate: [AuthGuard]
				}]
			},

			{
				path: 'recon', component: AddReconconfigComponent,
				children: [{
					path: 'add-recon-config',
					component: AddReconconfigComponent,
					canActivate: [AuthGuard]
				}]
			},

			{
				path: 'recon', component: AddRuleComponent,
				children: [{
					path: 'add-rule',
					component: AddRuleComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'recon', component: AddRuleDetailsComponent,
				children: [{
					path: 'add-rule-details',
					component: AddRuleDetailsComponent,
					canActivate: [AuthGuard]
				}]
			},

			{
				path: 'recon', component: ReconciledComponent,
				children: [{
					path: 'reconciled',
					component: ReconciledComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'recon', component: SameSideReversalComponent,
				children: [{
					path: 'same-side-reversal',
					component: SameSideReversalComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'recon', component: UnreconciledComponent,
				children: [{
					path: 'unreconciled',
					component: UnreconciledComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'recon', component: GlBalancingComponent,
				children: [{
					path: 'gl-balancing',
					component: GlBalancingComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'recon', component: RunReconComponent,
				children: [{
					path: 'run-recon',
					component: RunReconComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'recon', component: ViewReconComponent,
				children: [{
					path: 'view-recon',
					component: ViewReconComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'etl', component: FieldConfigurationComponent,
				children: [{
					path: 'field-configuration',
					component: FieldConfigurationComponent,
					children: [{ path: ':fileExtension/:fileId/:fileName/:templateName/:templateId', component: FieldConfigurationComponent }]
				}]
			},
			{
				path: 'reports', component: AdministrationComponent,
				children: [{
					path: 'administration',
					component: AdministrationComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'reports', component: ViewComponent,
				children: [{
					path: 'view',
					component: ViewComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'dashboard', component: DashboardComponent,
				children: [{
					path: '',
					component: DashboardComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'activity-manager', component: ActivityManagerComponent,
				children: [{
					path: 'activity-manager',
					component: ActivityManagerComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'escalation-matrix',
				component: EscMatrixComponent,
				canActivate: [AuthGuard]
			},

			{
				path: 'aging',
				component: AgingComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'aging',
				component: AgingComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'master-setup', component: MasterSetupComponent,
				children: [
					{
						path: 'screen-setup',
						component: ScreenSetupComponent,
						canActivate: [AuthGuard]
					},
					{
						path: 'master-data',
						component: MasterDataComponent,
						canActivate: [AuthGuard]
					},
					{
						path: 'org-structure',
						component: OrgStructureComponent,
						canActivate: [AuthGuard]
					}
				]
			},
			{
				path: 'user-management', component: UserManagementComponent,
				children: [
					{
						path: 'user',
						component: UserComponent,
						canActivate: [AuthGuard]
					},
					{
						path: 'roles',
						component: RolesComponent,
						canActivate: [AuthGuard]
					},
					{
						path: 'user-roles',
						component: UserRolesComponent,
						canActivate: [AuthGuard]
					},
					{
						path: 'permissions',
						component: PermissionComponent,
						canActivate: [AuthGuard]
					},
					{
						path: 'groups',
						component: GroupsComponent,
						canActivate: [AuthGuard]
					},
					{
						path: 'password-policy',
						component: PasswordPolicyComponent,
						canActivate: [AuthGuard]
					}
				]
			},
			{
				path: 'settlement', component: MasterSetupComponent,
				children: [
					{
						path: 'add',
						component: SettelementAddComponent,
						canActivate: [AuthGuard]
					},
					{
						path: 'list',
						component: SettelementListComponent,
						canActivate: [AuthGuard]
					}
				]
			},
			{
				path: 'case-management', component: CreatedCasesComponent,
				children: [{
					path: 'created-cases',
					component: CreatedCasesComponent,
					canActivate: [AuthGuard]
				}]
			},
			{
				path: 'case-management', component: ClosedCasesComponent,
				children: [{
					path: 'closed-cases',
					component: ClosedCasesComponent,

				}]
			},
			{
				path: 'scheduler', component: SchedulerComponent,
				children: [
					{
						path: 'configuration',
						component: ConfigurationComponent,
						canActivate: [AuthGuard]
					},
					{
						path: 'dependency',
						component: DependencyComponent,

					}
				]
			},
			

		],
	},
	{
		path: 'sample-recon/etl/field-configuration/:fileExtension/:fileId/:fileName/:templateName/:templateId',
		component: FieldConfigurationComponent
	},

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ParentRoutingModule { }