import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserManagementRoutingModule } from './user-management-routing.module';
import { UserManagementComponent } from './user-management.component';
import { UserComponent } from './user/user.component';
import { GroupsComponent } from './groups/groups.component';
import { PermissionComponent } from './permission/permission.component';
import { RolesComponent } from './roles/roles.component';
import { MaterialModule } from 'src/app/shared/modules/material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditUserComponent } from './user/edit-user/edit-user.component';
import { AddUserComponent } from './user/add-user/add-user.component';
import { AgGridModule } from 'ag-grid-angular';
import { AddRolesComponent } from './roles/add-roles/add-roles.component';
import { EditRolesComponent } from './roles/edit-roles/edit-roles.component';
import { UserRolesComponent } from './user-roles/user-roles.component';
import { AddUserRolesComponent } from './user-roles/add-user-roles/add-user-roles.component';
import { EditUserRolesComponent } from './user-roles/edit-user-roles/edit-user-roles.component';
import { AddGroupsComponent } from './groups/add-groups/add-groups.component';
import { EditGroupsComponent } from './groups/edit-groups/edit-groups.component';
import { AddGroupUsersComponent } from './groups/add-group-users/add-group-users.component';
import { EditGroupUsersComponent } from './groups/edit-group-users/edit-group-users.component';

@NgModule({
  declarations: [UserManagementComponent, UserComponent, GroupsComponent, PermissionComponent, RolesComponent, AddUserComponent,
    EditUserComponent,
    AddRolesComponent,
    EditRolesComponent,
    UserRolesComponent,
    AddUserRolesComponent,
    EditUserRolesComponent,
    AddGroupsComponent,
    EditGroupsComponent,
    AddGroupUsersComponent,
    EditGroupUsersComponent
    ],
  imports: [
    CommonModule,
    UserManagementRoutingModule,
    MaterialModule,
    FormsModule,
    AgGridModule.withComponents([]),
    ReactiveFormsModule
  ],
  entryComponents: [
    AddUserComponent,
    EditUserComponent,
    AddRolesComponent,
    EditRolesComponent,
    AddUserRolesComponent,
    EditUserRolesComponent,
    AddGroupsComponent,
    EditGroupsComponent,
    AddGroupUsersComponent,
    EditGroupUsersComponent,    
  ]
})
export class UserManagementModule { }
