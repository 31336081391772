import { Component, OnInit } from '@angular/core';
import { FileType } from '../../models/etl.model';
import { EtlService } from '../../services/etl.service';
import { MatDialog } from '@angular/material';
import { RollBackComponent } from './roll-back/roll-back.component';

@Component({
  selector: 'app-process-status',
  templateUrl: './process-status.component.html',
  styleUrls: ['./process-status.component.scss']
})
export class ProcessStatusComponent implements OnInit {
  fileType:FileType[];
  selectedFileType:string;
  rowData: any;
  reconModuleId: number;
  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;
  gridApi: any;

  columnDefs = [
    {headerName: 'ID', field: 'ID', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    {headerName: 'TEMPLATE_NAME', field: 'TEMPLATE_NAME', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    {headerName: 'FILE_TYPE', field: 'FILE_TYPE', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    {headerName: 'FILE_NAME', field: 'FILE_NAME', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    {headerName: 'FILE_PATH', field: 'FILE_PATH', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    {headerName: 'STATUS', field: 'STATUS', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    //{headerName: 'REASON_EX', field: 'REASON_EX', sortable: true, filter: true, editable: true },
    {headerName: 'TOTAL_RECORDS', field: 'TOTAL_RECORDS', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    {headerName: 'SUCCESS_RECORDS', field: 'SUCCESS_RECORDS', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    {headerName: 'EXCEPTION_RECORDS', field: 'EXCEPTION_RECORDS', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    {headerName: 'CREATIONTIME', field: 'CREATIONTIME', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    {headerName: 'UPDATETIME', field: 'UPDATETIME', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    {headerName: 'USER_NAME', field: 'USER_NAME', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    {headerName: 'IP', field: 'IP', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    {headerName: 'SCHEDULER_STATUS', field: 'SCHEDULER_STATUS', sortable: true, filter: true, editable: false,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true }
  ];

  constructor(public etlService: EtlService,
    public dialog: MatDialog) { 


    this.overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>'
  this.overlayNoRowsTemplate =
  '<span class="ag-overlay-loading-center">No Rows To Show</span>';
  }

  ngOnInit() {
    this.reconModuleId = parseInt(localStorage.getItem('reconModuleId'));
    this.fileType=[
      {name:"Delimiter", value:"TEXT"},
      {name:"Excel", value:"excel"},
      {name:"Fixed Length", value:"FixLength"},
      {name:"Swift MT103", value:"MT103"},
      {name:"Swift MT202", value:"MT202"},
      {name:"Swift MT940", value:"MT940"},
      {name:"Swift MT950", value:"MT950"},
      {name:"Visa Acquire", value:"VisaAcquire"},
      {name:"Rupay 01", value:"Rupay01"},
      {name:"NTSL", value:"NTSL"},
      {name:"TLF", value:"TLF"},
      {name:"SCHEDULER", value:"Scheduler"}
    ];
  }

  loadTableData(){
    this.etlService.getETLProcessStatusData(this.selectedFileType,this.reconModuleId).subscribe(data => {
      this.rowData = data
    }
    );
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  constructRollBack() {
    if (this.gridApi.getSelectedRows().length == 0) {
      alert("Please Select Record");
    }
    else if(this.gridApi.getSelectedRows()[0].STATUS != "Transformation - Completed"){
      alert("File status is "+this.gridApi.getSelectedRows()[0].STATUS+". Please select successfully uploaded file");
    }
    else {
      const dialogRef = this.dialog.open(RollBackComponent,
        {
          data: {
            etlProcessId: this.gridApi.getSelectedRows()[0].ID,
            templateName: this.gridApi.getSelectedRows()[0].TEMPLATE_NAME,
            fileName: this.gridApi.getSelectedRows()[0].FILE_NAME
          },
          panelClass: 'mat-modal',
          width: '500px',         
          disableClose: true
        }
      )
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loadTableData();
        }
      });
    }
  }
  onPageSizeChanged(newPageSize) {
   
    var value =((document.getElementById("page-size"))as HTMLInputElement).value;
    this.gridApi.paginationSetPageSize(Number(value));
  
  }

}