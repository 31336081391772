import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReconService } from '../../../services/recon.service';
import { AgGridColumnsService } from '../../../services/ag-grid-columns.service';
import { IActivityData, IActivityInput, IExternalSource } from '../../../models/recon.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'force-match',
  templateUrl: './force-match.html',
})
export class ForceMatch {

  reconName: string;
  activityData: IActivityData;
  comments: string;
  submitted: boolean = false;
  baseTable: string;
  baseColumnDefs: any;
  baseRowData: any = [];
  externalData: IExternalSource[] = [];
  forceMatchForm: any;

  constructor(public dialogRef: MatDialogRef<ForceMatch>,
    public reconService: ReconService,
    private formBuilder: FormBuilder,
    public agGridColumns: AgGridColumnsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.reconName = data.reconName;
    this.activityData = data.activityData;

    this.baseTable = this.activityData.base.tableName;
    this.baseColumnDefs = this.agGridColumns.generateColumns(this.activityData.base.data);
    this.baseRowData = this.activityData.base.data;

    this.activityData.external.forEach((source) => {
      this.externalData.push({
        externalSourceName: source.tableName,
        externalSourceColumnDefs: this.agGridColumns.generateColumns(source.data),
        externalSourceRowData: source.data
      });
    });
    this.forceMatchForm = this.formBuilder.group({
      comments: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
  }

  get fval() { return this.forceMatchForm.controls; }

  forceMatch() {
    this.submitted = true;
    if (this.forceMatchForm.invalid) {
      return;
    }

    let activityInput: IActivityInput;
    activityInput = {
      reconName: this.reconName,
      comments: this.comments,
      activityType: "FORCE_MATCH",
      activityData: this.activityData
    }

    this.reconService.processActivity(activityInput).subscribe(
      activityResponse => {
        alert(activityResponse.message);
        this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }
    );
  }
}
