import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IClose } from '../../../models/case-management.model';
import { CreatedCasesService } from '../../../services/created-cases.service';

@Component({
  selector: 'app-close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.scss']
})
export class CloseComponent implements OnInit {

  closeForm: FormGroup;
  submitted = false;
  loading = false;
  close = new IClose();

  constructor(public createdCasesService: CreatedCasesService,
    public dialogRef: MatDialogRef<CloseComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.close.caseManagement = data.selectedCase;
  }

  ngOnInit() {
    this.closeForm = this.formBuilder.group({
      comments: ['', Validators.required]
    });
  }

  get f() { return this.closeForm.controls; }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.close.fileName = file.name;
      let reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        this.close.fileData = btoa(reader.result.toString());
      }
    }
  }

  submitCloseCase() {

    this.submitted = true;
    if (this.closeForm.invalid) {
      return;
    }
    this.loading = true;

    this.close.comments = this.closeForm.value.comments;
    this.createdCasesService.close(this.close).subscribe(
      caseResponse => {
        alert(caseResponse.message);
        this.loading = false;
        if(caseResponse.message.includes("SUCCESS")) this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }
    );

  }

}
