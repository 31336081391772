
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EtlService } from '../../../services/etl.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
    selector: 'editMetaData',
    templateUrl: 'add-meta-data-edit.html'  
  })
 
  export class editMetaData implements OnInit {
    config_data:any;  
    local_data : any;
    AddmetadataEdit:any;
    submitted = false;
    selected: any[];
  dataTypeFormats = {
    "VARCHAR": ['VARCHAR'],
    "NUMBER": ['123', '-123', '123.00', '-123.00', '123.000', '123.000000000'],
    "DATE": ['dd/mm/yy', 'dd/mm/yyyy', 'mm/dd/yy', 'mm/dd/yyyy', 'yy/mm/dd', 'yyyy/mm/dd'],
    "TIMESTAMP": ["TIMESTAMP"],
    "LOOKUP": [],
    "DATETIME": ['DATETIME']
  };
    ngOnInit(): void {
      this.local_data=[];
      this.loadTempColumns();
      this.local_data=this.data.editSelectedData;
      var value=this.data.editSelectedData.FIELD_TYPE;
      this.selected=this.dataTypeFormats[value];
      console.log("data selected",this.selected)
      console.log("local_data",this.local_data);
      console.log("file id",this.data.fileId);
      this.selectOperationValue=this.data.editSelectedData.OPERATION_NAME;
      this.config_data=this.data;
      console.log("config_data",this.config_data);
      
    }
    public templetColumnNames:any=[];
    selectOperationValue:string;
    constructor(@Inject(MAT_DIALOG_DATA) public data:any,public etlService:EtlService,
    public dialogRef:MatDialogRef<editMetaData>) {
      this.AddmetadataEdit = new FormGroup ({
        FieldName:new FormControl('', Validators.required),
        FieldType:new FormControl('', Validators.required),
        Format:new FormControl('', Validators.required),
        FieldSize:new FormControl('', Validators.required),
        Mandatory:new FormControl('', Validators.required),
        Description:new FormControl('', Validators.required),
        
        
       });
    }

    get fval() {
      return this.AddmetadataEdit.controls;
      }
      signup(){
        this.submitted = true;
        if (this.AddmetadataEdit.invalid) {
        
          return;
        }
      
      
        }

        onSelect(value){
          console.log("Data --> ", value);
          if(value === "VARCHAR"){
            this.selected = this.dataTypeFormats.VARCHAR;
          } else if(value === "NUMBER") {
            this.selected = this.dataTypeFormats.NUMBER;
          } else if(value === "DATE") {
            this.selected = this.dataTypeFormats.DATE;
          } else if(value === "TIMESTAMP") {
            this.selected = this.dataTypeFormats.TIMESTAMP;
          } else if(value === "LOOKUP") {
            this.selected = this.dataTypeFormats.LOOKUP;
          } else if(value === "DATETIME") {
            this.selected = this.dataTypeFormats.DATETIME;
          } else {
            console.log("Wrong Selection --> ", value);
          }
        }

    changeSelectOperation(event)
    {
        this.selectOperationValue = event.target.value;
        
    }
    
    loadTempColumns()
    {
      this.etlService.getTemplateColumnNameData(this.data.editSelectedData.TEMPLET_ID).subscribe(
        data=>{
            for (const [key, value] of Object.entries(data)) {
            
                this.templetColumnNames.push({name:key,value:value});
                }
          
        }
      )
    }

    saveMetaDataConfig()
    {
      if (this.AddmetadataEdit.invalid) {
       
        return;
      }
   
     this.etlService.getEditMetaDataConfig(this.config_data).subscribe((data:any)=>{
       
      this.dialogRef.close(data);
     }) 
    }
   

  
  }