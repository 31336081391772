import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupService } from '../service/group.service';
import { IGroupData } from '../models/group.model';
import { IGroupName } from '../../user/models/users.model';

@Component({
  selector: 'app-add-groups',
  templateUrl: './add-groups.component.html',
  styleUrls: ['./add-groups.component.scss']
})
export class AddGroupsComponent implements OnInit {

  submitted = false;
  groupName: any;

  constructor(public dialogRef: MatDialogRef<AddGroupsComponent>,
    public groupService: GroupService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() { }

  addGrouDetails() {
    console.log('lll');
    this.submitted = true;
    let group = {
      "name": this.groupName,
      "description": this.groupName,
      "organisationId": "myorg",
      "status": "active"
    };
    this.groupService.addGroupDetails(group).subscribe(
      (group: IGroupName) => {
        alert("Group Added Successfully");
        this.dialogRef.close(group);
     },
      error => {
        console.log(error);
        alert(error.error.details[0]);
      }
    );
  }

}
