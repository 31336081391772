import { Component, OnInit, Inject } from '@angular/core';
import { NgModel, FormBuilder } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AddScreenComponent } from './add-screen/add-screen.component';
import { MasterTable } from '../models/master-setup.model';
import { MasterTableService } from '../services/master-setup.service';
import { EditScreenComponent } from './edit-screen/edit-screen.component';

@Component({
  selector: 'app-screen-setup',
  templateUrl: './screen-setup.component.html',
  styleUrls: ['./screen-setup.component.scss']
})
export class ScreenSetupComponent implements OnInit {
  gridApi:any;
  gridColumnApi:any;
  rowSelection :any;
  selected = 'choose';
  model: any = {};
  submitted = false;
  screenName:any;
  ButtonShow:boolean=true;
  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;
  public rowData:any;
  public columnDefs:any;
  masterTableList: MasterTable[] = [];

  constructor(public dialog: MatDialog,public masterTableService: MasterTableService,
    
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">No Rows To Show</span>'
    this.overlayNoRowsTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>';
    this.rowSelection = "single";
    }

  ngOnInit() {
    this.loadAllMasterTable();
  }
  loadAllMasterTable() {
    
    this.masterTableService.loadAllMasterTableToSetup().subscribe(
      (masterTableList: MasterTable[]) => {
        this.masterTableList = masterTableList;
      },
      error => {
        console.log(error);
      }
      
    );
    console.log("Master data list",this.masterTableList);
  }

  

  addNewfield(tableName){
    
    const dialogRef = this.dialog.open(AddScreenComponent, {data: {tableName},
      width: '600px',
      panelClass: 'mat-modal'
   
    });
   
   dialogRef.afterClosed().subscribe(result => {
      this.loadMasterTableNames(tableName);
    });
  }

  // EditScreen(){
  //   const dialog = this.dialog.open(EditScreenComponent);
  //   dialog.afterClosed().subscribe(result =>{});
  // }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  //for Update ScreenSetUp
  openEditDialog(): void {
    var editSelectedData = this.gridApi.getSelectedRows();
    console.log("Edit Selected Row --> ", editSelectedData);
    if (editSelectedData == "") {
      alert("please select a record");
     }
     else{

     
    const dialogRef = this.dialog.open(EditScreenComponent, {
      data: editSelectedData,
      panelClass : 'mat-modal',
      width : '500px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog edit was closed', result);
      if (result !== undefined) {
        this.gridApi.updateRowData({ update: [result] });     
      }
    });
  }
  }
  //Delete Master ScreenRow
  deleteMasterScreenData(){
    var selectedData = this.gridApi.getSelectedRows();
    console.log("selected data",selectedData);
    if (selectedData == "") {
      alert("please select a record to delete");
     }else if(selectedData !== "")
     {
      if (confirm("Do you want to delete this record")) {
        console.log("selected data",selectedData[0].id)
        var id = selectedData[0].id;
        this.masterTableService.deleteMasterData(id)  
        .subscribe( (masterScreenResponse:any)=>{
          alert(masterScreenResponse.message);
        },
        error => {
          console.log(error);
        });
        this.gridApi.updateRowData({ remove: selectedData });
      }
      
    }
    
  }

  saveScreenSetup()
  {
    this.submitted = true;
    let screenData = {
      "screenName": this.screenName
      };

    this.masterTableService.saveScreenSetup(screenData).subscribe(
      screenResponse => {
        alert(screenResponse.message);
        this.submitted=false;
        // this.screenName=[];
      
        //window.location.reload();
        this.selected='choose';
        this.loadAllMasterTable();
      },
      error => {
        console.log(error);
      }
     
    );
    

  }

  loadMasterTableNames(tableName)
  {
    if(tableName=="choose" || tableName=="new"){
      this.ButtonShow=true;
      this.rowData=[];
    }
    else{
  
this.masterTableService.loadMasterTableNames(tableName).subscribe(data => {
  console.log("Load Master table fields:",data)
  this.ButtonShow=false;

  this.rowData = data
  if (this.rowData) {
    this.columnDefs = this.generateColumns(this.rowData);
  }

}
);
    }

  }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            filter: true,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }


  createMasterTable(masterTableName)
  {


    this.masterTableService.createMasterTable(masterTableName).subscribe(
      screenResponse => {
        if(screenResponse==false){
          alert("fail to create table");
          return;
        }else{
        alert("Master Table Created Successfully");
        }
        //this.submitted=false;
        // this.screenName=[];
      
        //window.location.reload();
        this.loadAllMasterTable();
      },
      error => {
        console.log(error);
      }
     
    );
    

  }

}
