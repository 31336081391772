import { Component, OnInit } from '@angular/core';
import { StagingService } from '../../services/staging.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-exception-data',
  templateUrl: './exception-data.component.html',
  styleUrls: ['./exception-data.component.scss']
})
export class ExceptionDataComponent implements OnInit {
  moduleNames: any[] = [];
  fileTypes: any[] = [];
  columnDefs: any;
  rowData: any;
  gridApi: any;
  gridColumnApi: any;
  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;

  constructor(public stagingService: StagingService) {

    this.overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>'
  this.overlayNoRowsTemplate =
  '<span class="ag-overlay-loading-center">Please wait while your data is loading</span>';
  
  }
  
  ngOnInit() {
    this.loadTableNames();
    
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onPageSizeChanged(newPageSize) {
   
    var value =(document.getElementById("page-size"))as HTMLInputElement;
    var value1 = value.value
    this.gridApi.paginationSetPageSize(Number(value1));
  
  }
  loadTableNames(){

    this.fileTypes=[];

    this.stagingService.getStagingModelStagingTables(localStorage.getItem('reconModuleId')).subscribe(
      data =>{
            for (const [key, value] of Object.entries(data)) {
              this.fileTypes.push({name:key,value:value + '_EX'});
              }
      },
      error=>{
        console.log(error);
      }
    );
  }

  loadTableData(tableName) {
    this.stagingService.getTablesData(tableName).subscribe(data => {
      if(data.length > 0) {
        this.rowData = data
        if (this.rowData) {
          this.columnDefs = this.generateColumns(this.rowData);
        }
      } else {
        this.rowData = [];
        this.overlayLoadingTemplate =
        '<span class="ag-overlay-loading-center">No Row Data into Exception Table</span>'
        this.overlayNoRowsTemplate =
        '<span class="ag-overlay-loading-center">No Row Data into Exception Table</span>';
      }
    }
    );
  }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            filter: true,
            suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }
  
  fileNameExcel: string = 'ExceptionData.xlsx';
  excelExport(): void {
    var exportData = [];
    var tempColumns = [];
    var rowColArray = [];
    let rowIndex = 0;
    for (let index = 0; index < this.columnDefs.length; index++) {
      tempColumns[index] = this.columnDefs[index].field;
      rowColArray[index] = this.columnDefs[index].field;
    }
    exportData[rowIndex] = rowColArray;
    rowIndex = rowIndex + 1;
    this.rowData.forEach((k, v) => {
      let colIndex = 0;
      var rowArray = [];
      for (let key in k) {
        if (tempColumns.indexOf(key) > -1) {
          rowArray[colIndex] = k[key];
          colIndex = colIndex + 1;
        }
      }
      exportData[rowIndex] = rowArray;
      rowIndex = rowIndex + 1;
    })

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, this.fileNameExcel);
  }
  formula: string = "Formula 1";
  //Export Csv
  csvExport() {
    var params = {     
      fileName: 'Exception Data.csv',      
    };
    this.gridApi.exportDataAsCsv(params);
  //new Angular2Csv(this.rowData, this.formula, { headers: Object.keys(this.rowData[0]) });
  }

}