import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrameworkComponent } from './modules/parent/framework-landing-page/framework.component';
import { LoginComponent } from './modules/login/login.component';

const routes: Routes = [ 
  { path: '', component : LoginComponent},   
  { path: 'master-setup', loadChildren: () => import('./modules/parent/master-setup/master-setup.module').then(m => m.MasterSetupModule) },
  { path: 'settelement', loadChildren: () => import('./modules/parent/settelement/settelement.module').then(m => m.SettelementModule) },  
  { path: 'user-management', loadChildren: () => import('./modules/parent/user-management/user-management.module').then(m => m.UserManagementModule) }, 
  
  // { path: 'file-extraction', component : FileExtractionComponent },
  //{ path: 'etl', loadChildren: () => import('./modules/etl/etl.module').then(m => m.EtlModule), data: { preload: true } },
  //{ path: 'parent', loadChildren: () => import('./modules/parent/parent.module').then(m => m.ParentModule), data: { preload: true } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ enableTracing: false})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
