import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { IReconModule, IExternalSource, IActivity, ISelectedActivityTxns } from '../../models/recon.model';
import { ReconService } from '../../services/recon.service';
import { AgGridColumnsService } from '../../services/ag-grid-columns.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { UserManagementService } from '../../../user-management/services/user-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';



@Component({
  selector: 'app-reconciled',
  templateUrl: './reconciled.component.html',
  styleUrls: ['./reconciled.component.scss']
})

export class ReconciledComponent implements OnInit {

  @ViewChild('agGrid', { static: false }) agGrid: AgGridAngular;

  gridApi: any;
  gridColumnApi: any;
  content: any;
  submitted: boolean = false;
  reconModuleId: number;
  fromDate: any;
  toDate: any;
  rowClassRules: any;
  reconciledData:any;
  currentPage:any;
  filterCriteria:any=[];

  reconModuleList: IReconModule[] = [];
  baseSourceName: string;
  baseSourceColumnDefs: any;
  baseSourceRowData: any;
  selectedBaseSourceRecord: any;
  totalRecords:any;
  reconciled: any;
  permissionList: string[] = [];
  pageSize:any;
  page:any;
  pgno:boolean=false;
  myTempRowData: any[] = [];
  rowData: any;
  reconcileData:any;
  stagingData
  x:any;
  y:any

  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;

  constructor(public reconService: ReconService,
    public agGridColumns: AgGridColumnsService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute) {
    this.reconciled = this.formBuilder.group({
      fromDate: new FormControl('', Validators.required),
      toDate: new FormControl('', Validators.required)
    });
    this.overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>'
    this.overlayNoRowsTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>';
  }

  ngOnInit() {
    this.page=0;
    this.reconModuleId = parseInt(localStorage.getItem('reconModuleId'));
    this.loadChildPermissionsByUser();
    this.reconService.checkReconciledPermission().subscribe();
  }

  loadChildPermissionsByUser() {
     this.userManagementService.loadChildPermissionsByUser(this.route.firstChild.routeConfig.path.replace(/-/g,' ')).subscribe(
      (list: string[]) => {
        this.permissionList = list;
      },
      error => {
        console.log(error);
      }
    );
  }


  get fval() {
    return this.reconciled.controls;
  }

  //Load base source Data
  loadReconciledBaseSourceData() {

    
    this.pageSize=10;
    this.loaddata()
  }

  onPageSizeChanged(newPageSize) {
    const element = document.getElementById('page-size') as HTMLInputElement
    const value = element.value
    this.page=0;
    this.gridApi.paginationSetPageSize(Number(value));
    this.pageSize=value;
    this.loaddata()
  }


  onPaginationChanged(event){

    if(this.toDate.year)
    {

  console.log('onPagination this.toDate.year',this.toDate.year);

        this.setText('#lbCurrentPage', this.page+1);
        this.setText('#lbTotalPages',  Math.ceil(this.totalRecords/this.pageSize));
        this.setText('#startRecordCount', ((this.page)*this.pageSize)+1);
         if(this.totalRecords<((this.page+1)*this.pageSize))
         {
           this.setText('#endRecordCount',   this.totalRecords);
         }
         else
          this.setText('#endRecordCount',   (this.page+1)*this.pageSize);

       this.setText('#totalRecordsCount', this.totalRecords);

     }
  }


  onBtFirst() {
    this.gridApi.paginationGoToFirstPage();
  }

  onBtLast() {
    this.gridApi.paginationGoToLastPage();
  }
  onBtNext() {
    if(this.page==0){
      this.pgno=!this.pgno


    }
  if(Math.ceil(this.totalRecords/this.pageSize)>this.page+1)
  {
     this.page=this.page+1;
     console.log('this.currentPage',this.page);



    this.loaddata()
  }
  }

  onBtPrevious() {
  console.log("page no :",this.page);

  if(this.page==1){
    this.pgno=false;
  }
   if(this.page>=0)
   {
  this.page=this.page-1;

  this.loaddata()
   }
  }


  onBtPageFive() {
  this.gridApi.paginationGoToPage(4);
  }

  onBtPageFifty() {
  this.gridApi.paginationGoToPage(49);
  }


  setText(selector, text) {


  document.querySelector(selector).innerHTML = text;
  }

  loaddata(){

    this.submitted = true;
    if (this.reconciled.invalid) {
      return;
    }
     else {
       if (new Date(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day) >
        new Date(this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day)) {
        alert("From date should be less than To date");
    }

    else {
       this.baseSourceRowData = [];
    this.reconService.loadReconciledBaseSourceDataByPagination(this.reconModuleId,
      (this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day),
      (this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day),this.page,this.pageSize,this.filterCriteria).subscribe(
        data => {
  console.log('data---',data);

  this.reconciledData=data;

  this.totalRecords=this.reconciledData.totalElements;

  this.baseSourceName =this.reconciledData.baseSourceName

  if (this.reconciledData.content == null) alert("Recon not configured Properly")

  else if (this.reconciledData.content.length == 0) alert("No Data Available");


  else{
    var dummyData: any = [];

    dummyData = this.reconciledData.content;

    let tempObj = {};

    dummyData.forEach(element => {

      let map = new Map(Object.entries(element));



      for (let [key, value] of map) {

        if (value === null || value === "null") {

          tempObj[key] = "NA";

        } else {

          tempObj[key] = value;

        }

      }

      this.myTempRowData.push(tempObj);

      tempObj = {};

    })
    this.baseSourceRowData =[...this.myTempRowData];
   
  }

  this.baseSourceColumnDefs = this.agGridColumns.generateReconColumns(this.baseSourceRowData);

              this.setText('#lbCurrentPage', this.currentPage);

              this.setText('#lbTotalPages',  this.totalRecords/this.gridApi.paginationGetPageSize());
     },
     error=>{
console.log(error);
})

          

      }
     }

  }




  // Matched transactions popup
  onRowClicked(event: any) {
    let baseSourceList: any[] = [];
    this.baseSourceRowData.forEach(row => {
      if (row.RECON_ID == event.data.RECON_ID) {
        baseSourceList.push(row);
      }
    });
    this.dialog.open(ReconciledModal,
      {
        data: {
          reconModuleId: this.reconModuleId,
          baseSourceName: this.baseSourceName,
          selectedBaseSource: baseSourceList
        },
        panelClass: 'mat-modal',
        width: '998px',
      }
    );
  }

  // Force unmatch popup
  constructForceUnmatch() {
    this.selectedBaseSourceRecord = this.agGrid.api.getSelectedNodes().map(node => node.data);
    if (this.selectedBaseSourceRecord[0]) { }
    else {
      alert("Please select record")
    }
    if (this.selectedBaseSourceRecord[0].WORKFLOW_STATUS == 'N') {

      let baseSourceList: any[] = [];
      this.baseSourceRowData.forEach(row => {
        if (row.RECON_ID == this.selectedBaseSourceRecord[0].RECON_ID) {
          baseSourceList.push(row);
        }
      });

      const dialogRef = this.dialog.open(ForceUnMatchModal, {
        panelClass: 'mat-modal',
        width: '998px',
        data: {
          baseSourceName: this.baseSourceName,
          selectedBaseSource: baseSourceList,
          reconModuleId: this.reconModuleId
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    else {
      alert("The Selected Record Under Workflow")
    }
  }

  //Export Functionality Start
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  fileNameExcel: string = 'ReconcileData.xlsx';
  excelExport(): void {
    var exportData = [];
    var tempColumns = [];
    var rowColArray = [];
    let rowIndex = 0;
    for (let index = 0; index < this.baseSourceColumnDefs.length; index++) {
      tempColumns[index] = this.baseSourceColumnDefs[index].field;
      rowColArray[index] = this.baseSourceColumnDefs[index].field;
    }
    exportData[rowIndex] = rowColArray;
    rowIndex = rowIndex + 1;
    this.baseSourceRowData.forEach((k) => {
      let colIndex = 0;
      var rowArray = [];
      for (let key in k) {
        if (tempColumns.indexOf(key) > -1) {
          rowArray[colIndex] = k[key];
          colIndex = colIndex + 1;
        }
      }
      exportData[rowIndex] = rowArray;
      rowIndex = rowIndex + 1;
    })

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, this.fileNameExcel);
  }

  CustomLoadingCellRenderer() {
    this.CustomLoadingCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.innerHTML =
        '<div class="ag-custom-loading-cell" style="padding-left: 10px; line-height: 25px;">' +
        '    <span>' + params.loadingMessage + ' </span>' +
        '</div>';

      this.CustomLoadingCellRenderer.prototype.getGui = function () {
        return this.eGui;
      };
    };

  }

  //Export Csv
  csvExport() {
    var params = {
      fileName: 'Reconciled Data.csv',
    };
    this.gridApi.exportDataAsCsv(params);
  //new Angular2Csv(this.baseSourceRowData, this.formula, { headers: Object.keys(this.baseSourceRowData[0]) });
  }
  // onPageSizeChanged(newPageSize) {
  //
  //   var value =((document.getElementById("page-size"))as HTMLInputElement).value;
  //   this.gridApi.paginationSetPageSize(Number(value));
  //
  // }
}


// Reconciled Modal Component Start
@Component({
  selector: 'reconciled-modal',
  templateUrl: './reconciled-modal/reconciled.modal.html',
  styleUrls: ['./reconciled.component.scss']
})
export class ReconciledModal {

  reconModuleId: number;
  sourceReconId: string;
  baseSourceName: string;
  baseSourceColumnDefs: any;
  baseSourceRowData: any = [];
  public externalSourceData: IExternalSource[] = [];
  private domLayout;

  ngOnInit() {
    this.loadReconciledExternalSourceData();
  }

  constructor(public reconService: ReconService,
    public agGridColumns: AgGridColumnsService,
    public dialogRef: MatDialogRef<ReconciledModal>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.domLayout = 'autoHeight';
    this.reconModuleId = data.reconModuleId;
    this.baseSourceName = data.baseSourceName;
    this.sourceReconId = data.selectedBaseSource[0].RECON_ID;
    this.baseSourceRowData = data.selectedBaseSource;
    if (this.baseSourceRowData) {
      this.baseSourceColumnDefs = this.agGridColumns.generateReconColumns(this.baseSourceRowData);
    }
  }

  loadReconciledExternalSourceData() {
    this.reconService.loadReconciledExternalSourceData(this.reconModuleId, this.sourceReconId).subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          if (value) {
            this.externalSourceData.push({
              externalSourceName: key,
              externalSourceColumnDefs: this.agGridColumns.generateReconColumns(value),
              externalSourceRowData: value
            });
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }
}
// Reconciled Modal Component End

// Force UnMatch Modal Component Start
@Component({
  selector: 'force-unmatch-modal',
  templateUrl: './force-unmatch-modal/force-unmatch.modal.html',
})
export class ForceUnMatchModal {

  reconId: number;
  reconModuleName: string;
  baseSourceName: string;
  sourceReconId: string;
  baseSourceColumnDefs: any;
  baseSourceRowData: any = [];
  externalSource: any;
  externalSourceList: IExternalSource[] = [];
  externalSourceRowData: any = [];
  comments: string;
  activityData: IActivity;
  submitted: boolean = false;
  forceUnMatch1: any;
  ngOnInit() {
    this.reconModuleName = localStorage.getItem('reconModuleName');
    this.loadReconciledExternalSourceData();
  }

  constructor(public reconService: ReconService,
    public agGridColumns: AgGridColumnsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ReconciledModal>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.reconId = data.reconModuleId;
    this.baseSourceName = data.baseSourceName;
    this.sourceReconId = data.selectedBaseSource[0].RECON_ID;
    this.baseSourceRowData = data.selectedBaseSource;
    if (this.baseSourceRowData) {
      this.baseSourceColumnDefs = this.agGridColumns.generateReconColumns(this.baseSourceRowData);
    }
    this.forceUnMatch1 = this.formBuilder.group({
      comments: new FormControl('', Validators.required),
    });
  }
  get fval() {
    return this.forceUnMatch1.controls;
  }
  signup() {
    this.submitted = true;
    if (this.forceUnMatch1.invalid) {

      return;
    }
  }

  loadReconciledExternalSourceData() {
    this.reconService.loadReconciledExternalSourceData(this.reconId, this.sourceReconId).subscribe(
      data => {
        this.externalSourceRowData = data;
        for (const [key, value] of Object.entries(data)) {
          if (value) {
            this.externalSourceList.push({
              externalSourceName: key,
              externalSourceColumnDefs: this.agGridColumns.generateReconColumns(value),
              externalSourceRowData: value
            });
          }
        }

      },
      error => {
        console.log(error);
      }
    );

  }

  forceUnMatch() {
    this.submitted = true;
    if (this.forceUnMatch1.invalid) {
      return;
    }
    else {

      let baseSelectedActivityTxns: ISelectedActivityTxns;
      let externalSelectedActivityTxns: ISelectedActivityTxns[] = [];
      let externalData: any = [];
      let activity: IActivity;

      baseSelectedActivityTxns = {
        tableName: this.baseSourceName,
        data: this.baseSourceRowData
      }

      for (const [key, value] of Object.entries(this.externalSourceRowData)) {
        externalData = [];
        externalData = value;
        externalSelectedActivityTxns.push({
          tableName: key,
          data: externalData
        })
      }

      activity = {
        reconName: this.reconModuleName,
        comments: this.comments,
        activityType: "FORCE_UNMATCH",
        activityData: {
          base: baseSelectedActivityTxns,
          external: externalSelectedActivityTxns
        }
      }

      console.log(activity)
      this.reconService.forceUnMatch(activity).subscribe(
        activityResponse => {
          alert(activityResponse.message);
          this.dialogRef.close();
        },
        error => {
          console.log(error);
        }
      );

    }
  }
  // Force UnMatch Modal Component Close

}
