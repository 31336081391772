
import { Component, OnInit, Inject } from '@angular/core';

import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EtlService } from '../../../services/etl.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'addValidation',
  templateUrl: 'ft-valid-add.html'
})


export class addValidation implements OnInit {

  templetColumnNames: any = [];

  validationSelectColumnName: any;
  validationSelectOperationCategory: any;
  validationSelectOperation: any;
  startsWith: any;
  endsWith: any;
  containsConstants: any;
  patternCheck: any;
  lengthCheck: any;
  lengthType: any;
  validationSelectTempletid1:0;
  validationSelectTempletGroupId:"";
  ValidationAdd:FormGroup;
  columnName:any;
  COLUMN_DATA_TYPE:string;
  rowData:any;
  ngOnInit(): void {
    this.loadTempColumns();
  }
  selectOperationValue: string;
 
submitted = false;
  constructor(public etlService: EtlService, @Inject(MAT_DIALOG_DATA) public data: any, 
  public dialogRef:MatDialogRef<addValidation>) {
    console.log("VV Validation Temp ID:", data);
    this.rowData=data.row;
  //   this.configModal.validationSelectTempletid1= parseInt(data.tempId);
  // this.configModal.validationSelectTempletGroupId=data.groupId;
  this.ValidationAdd = new FormGroup ({
    validationSelectColumnName:new FormControl('', Validators.required),
    validationSelectOperationCategory:new FormControl('', Validators.required),
    validationSelectOperation:new FormControl('', Validators.required),
     startsWith:new FormControl(''),
     endsWith:new FormControl(''),
     containsConstants:new FormControl(''),
    patternCheck:new FormControl(''),
     lengthCheck:new FormControl(''),
     lengthType:new FormControl(''),
     dataType:new FormControl('')
  });
  this.validationSelectTempletid1=data.tempId;
  this.validationSelectTempletGroupId=data.groupId;

    this.validationSelectOperationCategory="Validation";
  

  }
  get fval() {
    return this.ValidationAdd.controls;
    }
    signup(){
      this.submitted = true;
      if (this.ValidationAdd.invalid) {
        return;
     
      }
    
     
      }

  changeSelectOperation(value) {
    this.selectOperationValue = value;
    this.ValidationAdd.get('startsWith').setValidators(null);
    this.ValidationAdd.get('endsWith').setValidators(null);
    this.ValidationAdd.get('containsConstants').setValidators(null);
    this.ValidationAdd.get('patternCheck').setValidators(null);
    this.ValidationAdd.get('lengthCheck').setValidators(null);
    this.ValidationAdd.get('lengthType').setValidators(null);
    this.ValidationAdd.patchValue({
      startsWith: undefined,
      endsWith: undefined,
      containsConstants: undefined,
      patternCheck: undefined,
      lengthCheck: undefined,
      lengthType: undefined,
    })
    if(this.selectOperationValue==='Starts With')
        {
          const STARTS_WITH = <FormControl>this.ValidationAdd.get('startsWith');
          STARTS_WITH.setValidators([Validators.required, ])
       }else if(this.selectOperationValue==='Ends With')
       {
        const ENDS_WITH = <FormControl>this.ValidationAdd.get('endsWith');
        ENDS_WITH.setValidators([Validators.required, ]);
       }else if(this.selectOperationValue==='Contains Data')
       {
        const CONTAINS_CONSTANTS = <FormControl>this.ValidationAdd.get('containsConstants');
        CONTAINS_CONSTANTS.setValidators([Validators.required, ]);
       }else if(this.selectOperationValue==='Pattern Check')
       {
        const PATTERN_CHECK = <FormControl>this.ValidationAdd.get('patternCheck');
        PATTERN_CHECK.setValidators([Validators.required, ]);
       }else if(this.selectOperationValue==='Valid Length')
       {
        const LENGTH_CHECK1 = <FormControl>this.ValidationAdd.get('lengthCheck');
        LENGTH_CHECK1.setValidators([Validators.required, ]);

        const LENGTH_TYPE1 = <FormControl>this.ValidationAdd.get('lengthType');
        LENGTH_TYPE1.setValidators([Validators.required, ]);
       }
  }

  loadTempColumns() {


    this.etlService.getTemplateColumnNameData(this.data.tempId).subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {

          this.templetColumnNames.push({ name: key, value: value });
        }
        this.ValidationAdd.patchValue({
          validationSelectColumnName:"",
          validationSelectOperation:""
        })

      }
    )
  }
  //Save validation configurations

  saveValidationConfig() {
    if (this.ValidationAdd.invalid) {
      return;
   
    }
    let flag: boolean = false;


    for (let data of this.rowData) {
       if (this.validationSelectOperation == "Starts With") {
          if (data.OPERATION_NAME == this.validationSelectOperation && data.COLUMN_NAME==this.validationSelectColumnName && data.STARTS_WITH==this.startsWith) {
            flag = true;
            break;
          }
        }
          if (this.validationSelectOperation == "Ends With") {
            if (data.OPERATION_NAME == this.validationSelectOperation && data.COLUMN_NAME==this.validationSelectColumnName && data.ENDS_WITH==this.endsWith) {

              flag = true;
              break;

            }
          }
          if (this.validationSelectOperation == "Contains Data") {
            if (data.OPERATION_NAME == this.validationSelectOperation && data.COLUMN_NAME==this.validationSelectColumnName && data.CONTAINS_CONSTANTS==this.containsConstants) {

              flag = true;
              break;

            }
          }
          if (this.validationSelectOperation == "Valid Length") {
            if (data.OPERATION_NAME == this.validationSelectOperation && data.COLUMN_NAME==this.validationSelectColumnName && data.LENGTH_CHECK==this.lengthCheck && data.LENGTH_TYPE==this.lengthType) {

              flag = true;
              break;

            }
          }
          if (this.validationSelectOperation == "Mandatory Field"){
        if (data.OPERATION_NAME == this.validationSelectOperation  && data.COLUMN_NAME == this.validationSelectColumnName ) {
        flag = true;
        break;
      }
    }
    }

    if (flag) {
      alert(" Record already exists");
    }
    else {
    this.dialogRef.close(
    this.etlService.saveValidationConfig({cleaningSelectOperationCategory:"validation",
    validationSelectColumnName:this.validationSelectColumnName,validationSelectOperationCategory:this.validationSelectOperationCategory,
    validationSelectOperation:this.validationSelectOperation,
    startsWith:this.startsWith,
    endsWith:this.endsWith,
    containsConstants:this.containsConstants,
    patternCheck:this.patternCheck,
    lengthCheck:this.lengthCheck,
    lengthType:this.lengthType,
    validationSelectTempletGroupId: this.validationSelectTempletGroupId,
    validationSelectTempletid1:this.validationSelectTempletid1

    }).subscribe((data:any)=>{
      if(data.message =="Record sucessfully inserted "){
        alert("Operation added successfully");
      }
      else
      alert(data.message);
 
    }))
    }
  }
  changeColumnName(value){
    var str=value.split(" ");
    this.validationSelectColumnName=str[0];
    this.COLUMN_DATA_TYPE=str[1];
    this.ValidationAdd.patchValue({
      validationSelectOperation:""
    })
    this.changeSelectOperation(this.validationSelectOperation);
  }
  omit_special_char(e) {
    var k;
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
}
   
}