import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../../models/etl.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'add-dialog',
  templateUrl: 'add.html'
})

export class Adddialog implements OnInit{
  submitted = false;
  dataType: any;
  FIELD_NAME:any;
  FIELD_SHORT_NAME:any;
  FIELD_TYPE:any;
  FIELD_FORMAT:any;
  FIELD_LENGTH:any;
  FIELD_FROM_POS:any;
  FIELD_TO_POS:any;
  MANDATORY:any;
  COL_POS:any;
  selected: any[];
  fileType: any;
  fileConfigurationadd: FormGroup;
  dataTypeFormats = {
    "VARCHAR": ['VARCHAR'],
    "NUMBER": ['123', '-123', '123.00', '-123.00', '123.000', '123.000000000', '-123.000'],
    "DATE": ['dd/mm/yy', 'dd/mm/yyyy', 'mm/dd/yy', 'mm/dd/yyyy', 'yy/mm/dd', 'yyyy/mm/dd'],
    "TIMESTAMP": ["TIMESTAMP"],
    "LOOKUP": [],
    "DATETIME": ['DATETIME']
  };

  constructor(
    public dialogRef: MatDialogRef<Adddialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      console.log("data", data);
    var dummyData: any;
    dummyData = data
    this.fileType = dummyData.FILE_TYPE;
    console.log("file_type :", this.fileType)
    this.fileConfigurationadd = new FormGroup({
      Name: new FormControl('', Validators.required),
      shortName: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      format: new FormControl('', Validators.required),
      Size: new FormControl('', Validators.required),
      fromPosition: new FormControl('', Validators.required),
      toPosition: new FormControl('', Validators.required),
      columnPosition: new FormControl('', Validators.required),
      Mandatory: new FormControl('', Validators.required),
    });
this.COL_POS = dummyData.RowData.length;


  }
  ngOnInit(): void {
    this.COL_POS++;
    console.log("dummyData.RowData.length",this.COL_POS);
    if(this.fileType!='FixLength')
    {
      this.fileConfigurationadd.get('fromPosition').setValidators(null);
      this.fileConfigurationadd.get('toPosition').setValidators(null);
    }
  }
  get fval() {
    return this.fileConfigurationadd.controls;
  }
  size(value){
    console.log("this.FIELD_FROM_POS",this.FIELD_FROM_POS);
    console.log("this.FIELD_TO_POS",value);
  
    var num= (value -this.FIELD_FROM_POS  );
console.log("this.FIELD_FROM_POS - this.FIELD_TO_POS)",num)
this.FIELD_LENGTH=num+1;
    
  }
  signup() {
    
    this.submitted = true;
    if (this.fileConfigurationadd.invalid) {

      return;
    }
    if(this.FIELD_FROM_POS > this.FIELD_TO_POS){
      alert("from position is always small than to position");
      this.FIELD_TO_POS=""
      this.FIELD_LENGTH=""
      return;
          }
    else{
    let newColumnData = {
      "FIELD_NAME": this.FIELD_NAME,
      "FIELD_SHORT_NAME": this.FIELD_SHORT_NAME,
      "FIELD_TYPE": this.FIELD_TYPE,
      "FIELD_FORMAT": this.FIELD_FORMAT,
      "FIELD_LENGTH": this.FIELD_LENGTH,
      "FIELD_FROM_POS": this.FIELD_FROM_POS,
      "FIELD_TO_POS": this.FIELD_TO_POS,
      "COL_POS": this.COL_POS,
      "MANDATORY": this.MANDATORY,
    
    };
   
    this.dialogRef.close(newColumnData);
  }}





  onNoClick(): void {
    this.dialogRef.close();
  }

  onSelect(value) {
    console.log("Data --> ", value);
    if (value === "VARCHAR") {
      this.selected = this.dataTypeFormats.VARCHAR;
    } else if (value === "NUMBER") {
      this.selected = this.dataTypeFormats.NUMBER;
    } else if (value === "DATE") {
      this.selected = this.dataTypeFormats.DATE;
    } else if (value === "TIMESTAMP") {
      this.selected = this.dataTypeFormats.TIMESTAMP;
    } else if (value === "LOOKUP") {
      this.selected = this.dataTypeFormats.LOOKUP;
    } else if (value === "DATETIME") {
      this.selected = this.dataTypeFormats.DATETIME;
    } else {
      console.log("Wrong Selection --> ", value);
    }
  }
}


@Component({
  selector: 'edit-dialog',
  templateUrl: 'edit.html'
})
export class Editdialog implements OnInit{
  submitted = false;
  dataType: any;
  selected: any[];
  local_data: any;
  FILE_TYPE:any;
  dataForm: FormGroup;
  fileConfigurationEdit:FormGroup;
  dataTypeFormats = {
    "VARCHAR": ['VARCHAR'],
    "NUMBER": ['123', '-123', '123.00', '-123.00', '123.000', '123.000000000'],
    "DATE": ['dd/mm/yy', 'dd/mm/yyyy', 'mm/dd/yy', 'mm/dd/yyyy', 'yy/mm/dd', 'yyyy/mm/dd'],
    "TIMESTAMP": ["TIMESTAMP"],
    "LOOKUP": [],
    "DATETIME": ['DATETIME']
  };

  constructor(
    public dialogRef: MatDialogRef<Editdialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    console.log("Edit Constructor --> ", data);
    var dummyData: any;
    dummyData=data;
    var value=dummyData.selectedData[0].FIELD_TYPE;
    this.selected=this.dataTypeFormats[value];
    this.local_data = dummyData.selectedData[0];
    this.FILE_TYPE=dummyData.FILE_TYPE;

    this.fileConfigurationEdit = new FormGroup({
      Name: new FormControl('', Validators.required),
      shortName: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      format: new FormControl('', Validators.required),
      Size: new FormControl('', Validators.required),
      fromPosition: new FormControl('', Validators.required),
      toPosition: new FormControl('', Validators.required),
      columnPosition: new FormControl('', Validators.required),
      Mandatory: new FormControl('', Validators.required),
    });
  }
  ngOnInit(): void {
    if(this.FILE_TYPE!='FixLength')
    {
      this.fileConfigurationEdit.get('fromPosition').setValidators(null);
      this.fileConfigurationEdit.get('toPosition').setValidators(null);
    }
  }

  get fval() {
    return this.fileConfigurationEdit.controls;
  }
  signup() {
  
    this.submitted = true;
    if (this.fileConfigurationEdit.invalid) {

      return;
    }
    if(this.local_data.FIELD_FROM_POS > this.local_data.FIELD_TO_POS){
      alert("from position is always small than to position");
      this.local_data.FIELD_TO_POS=""
      this.local_data.FIELD_LENGTH=""
          }
          else{
    this.dialogRef.close(this.local_data);
          }
  }
  size(value){
    console.log("this.FIELD_FROM_POS",this.local_data.FIELD_FROM_POS);
    console.log("this.FIELD_TO_POS",value);
 
    var num= (value -this.local_data.FIELD_FROM_POS  );
console.log("this.FIELD_FROM_POS - this.FIELD_TO_POS)",num)
this.local_data.FIELD_LENGTH=num+1;
    
  }
  onNoClick(): void {
    this.dialogRef.close();
  }



  onSelect(value) {
    console.log("Data --> ", value);
    if (value === "VARCHAR") {
      this.selected = this.dataTypeFormats.VARCHAR;
    } else if (value === "NUMBER") {
      this.selected = this.dataTypeFormats.NUMBER;
    } else if (value === "DATE") {
      this.selected = this.dataTypeFormats.DATE;
    } else if (value === "TIMESTAMP") {
      this.selected = this.dataTypeFormats.TIMESTAMP;
    } else if (value === "LOOKUP") {
      this.selected = this.dataTypeFormats.LOOKUP;
    } else if (value === "DATETIME") {
      this.selected = this.dataTypeFormats.DATETIME;
    } else {
      console.log("Wrong Selection --> ", value);
    }
  }
}