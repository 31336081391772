import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { IReconModule, ISource, ISchedulerResponse, IJobScheduler, IViewName } from '../../../models/scheduler.model';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-add-configuration',
  templateUrl: './add-configuration.component.html',
  styleUrls: ['./add-configuration.component.scss']
})
export class AddConfigurationComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddConfigurationComponent>,
    private configurationService: ConfigurationService) { }

  addConfigurationForm: FormGroup;
  submitted = false;
  reconModuleList: IReconModule[];
  sourceList: ISource[];
  jobScheduler: IJobScheduler;
  public viewNameObjs: IViewName[] = [];
  viewShow:boolean=false;

  totalWeekDays = [
    { name: 'Monday' },
    { name: 'Tuesday' },
    { name: 'Wednesday' },
    { name: 'Thursday' },
    { name: 'Friday' },
    { name: 'Saturday' },
    { name: 'Sunday' }
  ];

  ngOnInit() {

    this.loadReconModules();

    this.addConfigurationForm = this.formBuilder.group({
      jobName: ['', Validators.required],
      jobType: ['', Validators.required],
      reconName: ['', Validators.required],
      // sourceId: [Number],
      retryCount: ['', Validators.required],
      interval: ['', Validators.required],
      occurance: ['ONETIME'],
      startingDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endDate: ['', Validators.required],
      EndTime: ['', Validators.required],
      weekDaysList: this.createWeekDays(this.totalWeekDays),
      onSuccess: ['', Validators.required],
      onFailure: ['', Validators.required],
      status: ['', Validators.required],
      entityName: ['']
    });
  }

  createWeekDays(totalWeekDays) {
    const arr = totalWeekDays.map(day => {
      return new FormControl(day.selected || false);
    });
    return new FormArray(arr);
  }

  showWeekly: boolean = false;

  date = new FormControl(new Date());

  get f() { return this.addConfigurationForm.controls; }

  showBlockOneTime() {
    this.showWeekly = false;
  }
  showBlockDaily() {
    this.showWeekly = false;
  }
  showBlockWeekly() {
    this.showWeekly = true;
  }

  loadReconModules() {
    this.configurationService.loadReconModules().subscribe(
      (reconModuleList: IReconModule[]) => {
        this.reconModuleList = reconModuleList;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadsourcesByModule() {
    this.sourceList = [];
    // this.configurationService.loadSourcesByReconModules(this.addConfigurationForm.value.reconModuleId).subscribe(
    //   data => {
    //     for (const [key, value] of Object.entries(data)) {
    //       this.sourceList.push({ id: key, sourceName: value });
    //     }
    //   },
    //   error => {
    //     console.log(error);
    //   }
    // );
  }



  onJobTypeChange(data){
    this.viewShow=false;
    if(data=="VIEW"){
      this.viewShow=true;
      this.viewNameObjs = [];
        var currentPage = "File Upload";
        this.configurationService.getViewData(currentPage).subscribe(
          data => {
            for (const [key, value] of Object.entries(data)) {
              this.viewNameObjs.push({ name: key, value: value });
            }
          },
          error => {
            console.log(error);
          }
        );
      
      
    }
    
  }


  saveSchedulerConfig() {
    this.submitted = true;
    if (this.addConfigurationForm.invalid) {
      return;
    }

    this.jobScheduler = this.addConfigurationForm.value;
    let startDateTime: any;
    let startDate: string;
    let startTime: string;
    let endDateTime: any;
    let endDate: string;
    let endTime: string;
    startDate = this.addConfigurationForm.get('startingDate').value
    startTime = this.addConfigurationForm.get('startTime').value
    endDate = this.addConfigurationForm.get('endDate').value
    endTime = this.addConfigurationForm.get('EndTime').value
    startDateTime = startDate.toString().substring(0, 15) + ' ' + startTime;
    endDateTime = endDate.toString().substring(0, 15) + ' ' + endTime;
    let newDate = new Date(startDateTime);
    let newDate1 = new Date(endDateTime);
    this.jobScheduler.startingDate = newDate;
    this.jobScheduler.endDate = newDate1;
    this.jobScheduler = this.addConfigurationForm.value;
    if (this.addConfigurationForm.value.triggerType == "weekly") {
      let selectedWeekDays: string[] = [];
      this.addConfigurationForm.value.weekDaysList.forEach((day, i) => {
        if (day) selectedWeekDays.push(this.totalWeekDays[i].name);
      });
      this.jobScheduler.weekDays = selectedWeekDays.join(',');
    }
    this.configurationService.saveSchedulerConfig(this.jobScheduler).subscribe(
      (schedulerResponse: ISchedulerResponse) => {
        //alert(schedulerResponse.message);
        this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }
    );
  }

}