import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MasterTableService } from '../../services/master-setup.service';
import { DilogOpenData } from'../../../master-setup/models/master-setup.model';



@Component({
  selector: 'app-edit-screen',
  templateUrl: './edit-screen.component.html',
  styleUrls: ['./edit-screen.component.scss']
})
export class EditScreenComponent implements OnInit {

  local_data: any;
  model: any = {};
  submitted = false;
  mandatory:false;
  selected: any[];
  colType:any;
  dataTypeFormats = {
    "VARCHAR": ['VARCHAR'],
    "number": ['number'],
    "Date": ['Date'],
  
    "Identity": ['Identity']
  };
  constructor(public dialogRef: MatDialogRef<EditScreenComponent>,public masterTableService: MasterTableService,
    
    @Inject(MAT_DIALOG_DATA) public data: DilogOpenData) {
      this.local_data = data[0];
      if(this.local_data.mandatory=="false"){
        this.local_data.mandatory=false;
      }if(this.local_data.isUnique=="false"){
        this.local_data.isUnique=false;
      }
      console.log("Edit Constructor --> ", data);
     
      var value=data[0].colType;
      this.selected=this.dataTypeFormats[value];
      console.log("this.selected",this.selected)
      this.local_data =data[0];
     
     }
 
  ngOnInit() {
  }
 
  saveMasterScreen()
  {
    this.submitted = true;
      var saveData = this.data;
      console.log("savedata",saveData);
      this.masterTableService.updateMasterScreeen(this.local_data).subscribe(
        (masterScreenResponse:any)=>{
 
          alert(masterScreenResponse.message);
          this.dialogRef.close("true");
        },
        error => {
          console.log(error);
        }  )   
  }
  
  addNewColumn()
  {
    

  }
  onSelect(value) {
    console.log("Data --> ", value);
    if (value === "VARCHAR") {
      this.selected = this.dataTypeFormats.VARCHAR;
    } else if (value === "number") {
      this.selected = this.dataTypeFormats.number;
    } else if (value === "Date") {
      this.selected = this.dataTypeFormats.Date;
    } 
    else if (value === "Identity") {
      this.selected = this.dataTypeFormats.Identity;
    }  else {
      console.log("Wrong Selection --> ", value);
    }
  }

}
