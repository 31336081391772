
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DialogData } from 'src/app/modules/parent/recon/models/recon.model';
import { RuleService } from '../../../services/rule.service';


@Component({
    selector: 'edit-dialog',
    templateUrl: 'edit.html'
  })
  export class EditdialogAddRule {
    local_data: any;
    editAddRule:any;
    submitted:boolean=false;
    rulePriority:any;
    constructor(
        public dialogRef: MatDialogRef<EditdialogAddRule> ,public ruleService: RuleService,private formBuilder: FormBuilder,
          @Inject(MAT_DIALOG_DATA) public data: DialogData)
         {
          this.local_data = data[0];
    console.log("Edit Constructor --> ", data);

    this.editAddRule = this.formBuilder.group({
      ruleId:new FormControl('',Validators.required),
      moduleId:new FormControl('', Validators.required),
      ruleName:new FormControl('',Validators.required),
      ruleStatus:new FormControl('',Validators.required),
      rulePriority:new FormControl('',Validators.required),
      ruleType:new FormControl('',Validators.required),
      ruleDescription:new FormControl('',Validators.required)


   });
   }
   get fval() {
    return this.editAddRule.controls;
  }
   saveReconConfig(){
     this.submitted=true;
     if(this.editAddRule.invalid){
       return;
     }
     else if( this.local_data.rulePriority>20 ||  this.local_data.rulePriority <=0 ){
      alert("rule priority should be between 1 to 20");
    }
    else{
      var saveData=this.data;
      this.ruleService.updateReconRule(saveData[0]).subscribe((data:any)=>{
        alert("Edited Successfully");
        this.dialogRef.close(this.local_data);
       })
    }
  }
   
  }