import { Component, OnInit, Inject } from '@angular/core';
import { ISourceConfig } from '../../../models/recon.model';
import * as FileSaver from 'file-saver';
import { ReconService } from '../../../services/recon.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DownloadTableFileComponent } from '../../unreconciled/download-table-file/download-table-file.component';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {

  sourceList: ISourceConfig[] = [];
  fileType: string;
  fromDate: string;
  toDate: string;
  
  constructor(public reconService: ReconService,public dialogRef: MatDialogRef<DownloadTableFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.sourceList = data.sourceList;
    this.fileType = data.fileType;
    this.fromDate = data.fromDate;
    this.toDate = data.toDate;
  }

  ngOnInit() {
  }

  download(source: ISourceConfig, fileType: string) {
    source.gridOptions = undefined;
    if (fileType == "Excel") {
      this.reconService.exportSameSideDataBySourceToExcel(source, this.fromDate, this.toDate).subscribe(
        response => {
          const blob = new Blob([response.body], { type: 'application/vnd.ms.excel' });
          FileSaver.saveAs(blob, source.tableName + '.xlsx');
        },
        error => {
          console.log(error);
        }
      );
    }
    else if (fileType == "CSV") {
      this.reconService.exportSameSideDataBySourceToCSV(source, this.fromDate, this.toDate).subscribe(
        response => {
          const blob = new Blob([response.body], { type: 'text/csv' });
          FileSaver.saveAs(blob, source.tableName + '.csv');
        },
        error => {
          console.log(error);
        }
      );
    }

  }

}
