import { Component, OnInit, Inject } from '@angular/core';
import { ForceMatch } from '../force-match/force-match.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReconService } from '../../../services/recon.service';
import { AgGridColumnsService } from '../../../services/ag-grid-columns.service';
import { IActivityData, IExternalSource, IActivityInput } from '../../../models/recon.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-suppress',
  templateUrl: './suppress.component.html',
  styleUrls: ['./suppress.component.scss']
})
export class SuppressComponent implements OnInit {

  reconName: string;
  activityData: IActivityData;
  action: string;
  comments: string;

  baseTable: string;
  baseColumnDefs: any;
  baseRowData: any = [];
  externalData: IExternalSource[] = [];
  submitted: boolean = false;
  suppressForm: any;

  constructor(public dialogRef: MatDialogRef<ForceMatch>,
    public reconService: ReconService,
    private formBuilder: FormBuilder,
    public agGridColumns: AgGridColumnsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.reconName = data.reconName;
    this.activityData = data.activityData;
    this.action = data.action;

    if (this.activityData.base != null) {
      this.baseTable = this.activityData.base.tableName;
      this.baseColumnDefs = this.agGridColumns.generateColumns(this.activityData.base.data);
      this.baseRowData = this.activityData.base.data;
    }

    this.activityData.external.forEach((source) => {
      this.externalData.push({
        externalSourceName: source.tableName,
        externalSourceColumnDefs: this.agGridColumns.generateColumns(source.data),
        externalSourceRowData: source.data
      });
    });

    this.suppressForm = this.formBuilder.group({
      comments: new FormControl('', Validators.required),
    });

  }

  ngOnInit() {
  }

  get fval() { return this.suppressForm.controls; }

  suppress() {
    this.submitted = true;
    if (this.suppressForm.invalid) {
      return;
    }
    
    let activityInput: IActivityInput;
    activityInput = {
      reconName: this.reconName,
      comments: this.comments,
      activityType: this.action,
      activityData: this.activityData
    }

    this.reconService.processActivity(activityInput).subscribe(
      activityResponse => {
        alert(activityResponse.message);
        this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }
    );

  }

}
