import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NgModel, FormBuilder, FormControl, Validators } from '@angular/forms';
import {UserManagementService} from '../../services/user-management.service';
import {IRoleUser, IRole} from '../../models/user-management.model';
 

@Component({
  selector: 'app-add-user-roles',
  templateUrl: './add-user-roles.component.html',
  styleUrls: ['./add-user-roles.component.scss']
})
export class AddUserRolesComponent implements OnInit {

  roles: any;
  users:any;
  userRoles: IRole[]=[];
  usersRole:any;
   
  userNameListForRole:IRoleUser[]=[];

  model: any = {};
 
  submitted = false;
  constructor(public dialogRef: MatDialogRef<AddUserRolesComponent>,public userManagementService: UserManagementService,private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.usersRole =this.formBuilder.group({
        users:new FormControl('',Validators.required),
        roles:new FormControl('',Validators.required),
     
     });
     }

  ngOnInit() {

    this.loadUserForRoles();
    this.loadRolesForUser();
  }
  get fval() {
    return this.usersRole.controls;
  }
  onSubmit() {
    this.submitted = true;   
    console.log('Submitted'); 
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  loadUserForRoles(){
    this.userManagementService.loadUserForRoles().subscribe(
      (userNameListForRole : IRoleUser[]) => {
        this.userNameListForRole = userNameListForRole;
     console.log("userNameList",this.userNameListForRole)
      },
      error => {
        console.log(error);
      }
    );
  }

  
  loadRolesForUser(){
    this.userManagementService.loadActiveRolesForUser().subscribe(
      (userRoles : IRole[]) => {
        this.userRoles = userRoles;
     console.log("userNameList",this.userRoles)
      },
      error => {
        console.log(error);
      }
    );
  }

  assignRolesToUser(){
    this.submitted = true;
    if (this.usersRole.invalid) {
      return;
     }
     else{
    let newColumnData = {
      "userId": this.users,
      "roleId": this.roles,
      
    }
     
    console.log("hello add user",newColumnData);
    this.dialogRef.close(newColumnData);
 
  }
  }
}
