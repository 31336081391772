import { Component, OnInit, Inject } from '@angular/core';
import { MasterTableService } from '../../services/master-setup.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-add-screen',
  templateUrl: './add-screen.component.html',
  styleUrls: ['./add-screen.component.scss']
})
export class AddScreenComponent implements OnInit {

  model: any = {};
  tableName: "";
  fieldName: any;
  shortName: any;
  colFormat: any;
  colType: any;
  mandatory:any;
  isUnique:any;
  colSize:any;
  submitted = false;
  selected: any[];
  dataTypeFormats = {
    "VARCHAR": ['VARCHAR'],
    "number": ['number'],
    "Date": ['Date'],
  
    "Identity": ['Identity']
  };
  constructor(public dialogRef: MatDialogRef<AddScreenComponent>, public masterTableService: MasterTableService,

    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.tableName = data.tableName;
 

  }

  ngOnInit() {
  }

  onSubmit() {
  
    console.log('Submitted');
  }

  addNewColumnData() {
    this.submitted = true;
    let newColumnData = {
      "tableName": this.tableName,

      "fieldName": this.fieldName,
      "shortName": this.shortName,
      "colFormat": this.colFormat,
      "colType": this.colType,
      "mandatory":this.mandatory,
      "isUnique":this.isUnique,
      "colSize":this.colSize,
    };
   this.masterTableService.addNewColumnData(newColumnData).subscribe(
      screenResponse => {
        alert(screenResponse.message);
        console.log(screenResponse.message);
        //this.screenName=[];
        if(screenResponse.message=="Column name already Exists please add new column")

   {
     
   }
        else{
          this.dialogRef.close("true");
        }

      },
      error => {
        console.log(error);
      }

    );

  }
  onSelect(value) {
    console.log("Data --> ", value);
    if (value === "VARCHAR") {
      this.selected = this.dataTypeFormats.VARCHAR;
    } else if (value === "number") {
      this.selected = this.dataTypeFormats.number;
    } else if (value === "Date") {
      this.selected = this.dataTypeFormats.Date;
    } 
    else if (value === "Identity") {
      this.selected = this.dataTypeFormats.Identity;
    }  else {
      console.log("Wrong Selection --> ", value);
    }
  }
 

}