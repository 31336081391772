import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CaseDepartment, CaseReason, ICaseManagement, ICaseResponse } from '../models/case-management.model';

@Injectable({
  providedIn: 'root'
})
export class CaseCreationService {

  constructor(private http: HttpClient) { }

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  // getCaseID(): Observable<Number> {
  //   return this.http.get<Number>(environment.caseManagementUrl + '/getCaseID.ascent', this.httpOptions);
  // }

  loadAllCaseDepartments(): Observable<CaseDepartment[]> {
    return this.http.get<CaseDepartment[]>(environment.caseManagementUrl + '/loadAllCaseDepartments.ascent', this.httpOptions);
  }

  loadAllCaseReasons(): Observable<CaseReason[]> {
    return this.http.get<CaseReason[]>(environment.caseManagementUrl + '/loadAllCaseReasons.ascent', this.httpOptions);
  }

  checkCase(moduleName:String, sourceName:String, sid:String): Observable<Boolean> {
    return this.http.get<Boolean>(environment.caseManagementUrl + '/checkCase.ascent/'+moduleName+'/'+sourceName+'/'+sid, this.httpOptions);
  }

  saveCase(caseManagement: ICaseManagement): Observable<ICaseResponse> {
    return this.http.post<ICaseResponse>(environment.caseManagementUrl + '/saveCase.ascent', caseManagement, this.httpOptions);
  }

}