import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { IRole, IEntity, IEntityOperation, IPermission, IRoleUser, IUSerRole, IPermissionResponse, IUsersResponse, IUser } from '../models/user-management.model';
import { IServerResponse } from 'src/app/modules/login/login.model';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getReadRoleMyOrg():Observable<IRole[]>{
    return this.http.get<IRole[]>(environment.userManagementUrl+'/v1/role/read/all/myorg', this.httpOptions);
  }

  getActiveRoleMyOrg():Observable<IRole[]>{
    return this.http.get<IRole[]>(environment.userManagementUrl+'/v1/role/read/active/myorg', this.httpOptions);
  }

  getEntityMyOrg():Observable<IEntity[]>{
    return this.http.get<IEntity[]>(environment.userManagementUrl+'/master/entity/read/all/myorg', this.httpOptions);
  }

  getEntityOperationMyOrg():Observable<IEntityOperation[]> {
    return this.http.get<IEntityOperation[]>(environment.userManagementUrl+'/master/operation/read/all/myorg', this.httpOptions);
  }

  getPermissionsMyOrg():Observable<IPermission[]> {
    return this.http.get<IPermission[]>(environment.userManagementUrl+'/v1/permission/read/all/myorg', this.httpOptions);
  }

  getPermissionsMyOrg1():Observable<any> {
    return this.http.get<any>(environment.userManagementUrl+'/v1/permission/read/all1/myorg', this.httpOptions);
  }
  
  getPermissionsByRole(roleId: number):Observable<IPermissionResponse[]> {
    return this.http.get<IPermissionResponse[]>(environment.userManagementUrl+'/v1/role/permissions/read/'+roleId, this.httpOptions);
  }

  savePermission(requestData : any):Observable<any> {
    return this.http.post<any>(environment.userManagementUrl+'/v1/permission/create', JSON.stringify(requestData) , this.httpOptions);
  }   
  
  deletePermission(permissionId:any, roleIds:any):Observable<IUsersResponse> {
    return this.http.delete<IUsersResponse>(environment.userManagementUrl+'/v1/permission/delete/'+permissionId+'/'+roleIds, this.httpOptions);
  }

//Vaiji for Roles
addNewRoles(newAddRoleData: any):Observable<any>
{
  console.log("In Role service :",newAddRoleData);
  return this.http.post<any>(environment.userManagementUrl + '/v1/role/create',JSON.stringify(newAddRoleData), this.httpOptions);
}

getRolesData() {
  return this.http.get<IRole[]>(environment.userManagementUrl+'/v1/role/read/all', this.httpOptions);
}

updateRoles(updateRoles: any):Observable<any>{
  return this.http.put(environment.userManagementUrl +'/v1/role/update',JSON.stringify(updateRoles), this.httpOptions);
}

deleteRole(data:any):Observable<IUsersResponse>{
  return this.http.delete<IUsersResponse>(environment.userManagementUrl+'/v1/role/delete/'+data,this.httpOptions);
}

loadUserForRoles(){
    
  return this.http.get<IRoleUser[]>(environment.userManagementUrl + '/v1/user/read/all', this.httpOptions);

}
loadRolesForUser(){
    
  return this.http.get<IRole[]>(environment.userManagementUrl + '/v1/role/read/all', this.httpOptions);

}

loadActiveRolesForUser(){
  return this.http.get<IRole[]>(environment.userManagementUrl + '/v1/role/active/read', this.httpOptions);
}

assignRolesToUser(newColumnData:any){
   return this.http.put<any>(environment.userManagementUrl +'/v1/user-role/'+newColumnData.userId+'/'+newColumnData.roleId.toString(), this.httpOptions);
  
}

deleteAssignedRolesToUser(userId:any){
  return this.http.put<any>(environment.userManagementUrl +'/v1/user-role/delete/'+userId, this.httpOptions);
 
}

getUserRolesData() : Observable<IUser[]>{
   
      return this.http.get<IUser[]>(environment.userManagementUrl+'/v1/user/read/all/myorg', this.httpOptions);
    }


    loadChildPermissionsByUser(subTabName: string) : Observable<string[]>{
      return this.http.get<string[]>(environment.userManagementUrl+'/v1/user/roles/operation/childs/read/'+
                                        JSON.parse(localStorage.getItem('user')).id+'/'+subTabName,this.httpOptions);
    }

  loadAllPolicy() : Observable<IServerResponse>{
    return this.http.get<IServerResponse>(environment.userManagementUrl+'/password/password-policy', this.httpOptions);
  }

  deletePolicy(id:string) : Observable<IServerResponse>{
    return this.http.delete<IServerResponse>(environment.userManagementUrl+'/password/password-policy/'+id, this.httpOptions);
  }

  savePolicy(data:any) : Observable<IServerResponse>{
    return this.http.post<IServerResponse>(environment.userManagementUrl+'/password/password-policy',JSON.stringify(data), this.httpOptions);
  }

  updatePolicy(data:any) : Observable<IServerResponse>{
    return this.http.put<IServerResponse>(environment.userManagementUrl+'/password/password-policy',JSON.stringify(data), this.httpOptions);
  }
}