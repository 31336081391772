import { Component, OnInit } from '@angular/core';
import { IActivityData, IReconModule, ISelectedActivityTxns, ISourceConfig } from '../../models/recon.model';
import { ReconService } from '../../services/recon.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Grid, GridOptions, ClientSideRowModelModule } from "@ag-grid-community/all-modules";
import { AgGridColumnsService } from '../../services/ag-grid-columns.service';
import { UserManagementService } from '../../../user-management/services/user-management.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ExportComponent } from './export/export.component';
import { SameSideUnmatchComponent } from './same-side-unmatch/same-side-unmatch.component';

@Component({
  selector: 'app-same-side-reversal',
  templateUrl: './same-side-reversal.component.html',
  styleUrls: ['./same-side-reversal.component.scss']
})
export class SameSideReversalComponent implements OnInit {

  reconModuleList: IReconModule[] = [];
  reconModuleId: number;
  reconModuleName: string;
  sameSideForm: FormGroup;
  fromDate: any;
  toDate: any;
  submitted: boolean = false;
  sourceList: ISourceConfig[];
  sourceDataList: ISourceConfig[];
  gridOptions: GridOptions = <GridOptions>{};
  permissionList: string[] = [];
  selectedBaseSourceRecord: any;

  constructor(public reconService: ReconService,
    private formBuilder: FormBuilder,
    public agGridColumns: AgGridColumnsService,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.loadChildPermissionsByUser();
    this.reconService.checkSameSidePermission().subscribe();
    this.reconModuleId = parseInt(localStorage.getItem('reconModuleId'));
    this.reconModuleName = localStorage.getItem('reconModuleName');
    this.sameSideForm = this.formBuilder.group({
      fromDate: new FormControl('', Validators.required),
      toDate: new FormControl('', Validators.required)
    });
  }

  loadChildPermissionsByUser() {
    this.userManagementService.loadChildPermissionsByUser(this.route.firstChild.routeConfig.path.replace(/-/g, ' ')).subscribe(
      (list: string[]) => {
        this.permissionList = list;
      },
      error => {
        console.log(error);
      }
    );
  }

  get fval() {
    return this.sameSideForm.controls;
  }

  loadSameSideReversalData() {

    this.submitted = true;
    if (this.sameSideForm.invalid) {
      return;
    }
    else {
      if (new Date(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day) >
        new Date(this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day)) {
        alert("From date should be less than To date");
      }
      else {
        this.sourceList = [];
        this.sourceDataList = [];
        let checkSourceDataCount: number = 0;
        document.getElementById("myGrid").innerHTML = "";
        this.reconService.loadSourceListByRecon(this.reconModuleId).subscribe(
          (sourceList: ISourceConfig[]) => {
            this.sourceList = sourceList;
            this.sourceList.forEach((source) => {
              this.reconService.loadSameSideReversalDataBySource(source,
                (this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day),
                (this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day)).subscribe(
                  data => {
                    this.createTable(data, source);
                  },
                  error => {
                    console.log(error);
                    checkSourceDataCount++;
                    if (error.status == 404) {
                      if (checkSourceDataCount == this.sourceList.length)
                        alert("No Data Available");
                    }

                  }
                );
            });//for
          },
          error => {
            console.log(error);
            if (error.status == 404) alert("Recon not configured properly");
          }
        );
      }
    }
  }

  createTable(data: any[], source: ISourceConfig) {
    this.gridOptions = {
      columnDefs: this.agGridColumns.generateReconColumns(data),
      rowData: data,
      rowSelection: 'single',
      enableCellTextSelection: true,
      floatingFilter: true,
      animateRows: true,
      pagination: true
    };
    this.sourceDataList.push(source);
    let eGridDiv: HTMLElement = <HTMLElement>document.querySelector('#myGrid');
    new Grid(eGridDiv, this.gridOptions, { modules: [ClientSideRowModelModule] });
    this.gridOptions.columnApi.autoSizeAllColumns();
    source.gridOptions = this.gridOptions;
  }

  onPageSizeChanged(newPageSize) {
    for (let source of this.sourceList) {
      var value = ((document.getElementById("page-size")) as HTMLInputElement).value;
      if (source.gridOptions != undefined)
        source.gridOptions.api.paginationSetPageSize(Number(value));
    }
  }

  export(fileType: string) {
    if (this.sourceDataList != undefined && this.sourceDataList.length > 0) {
      this.dialog.open(ExportComponent, {
        width: '500px',
        panelClass: 'mat-modal',
        data: {
          fromDate: (this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day),
          toDate: (this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day),
          sourceList: this.sourceDataList,
          fileType: fileType
        }
      });
    }
    else {
      alert("No data to export");
    }
  }

  constructSameSideUnmatch() {

    let flag: boolean = true;
    let message: string;
    let selectedRecords: any[] = [];
    let sourceName: string;

    for (let source of this.sourceList) {
      if (source.tableName.includes('A1PT')) {
        selectedRecords = source.gridOptions.api.getSelectedNodes().map(node => node.data);
        if (selectedRecords.length != 0) {
          for (let record of selectedRecords) {
            if (record.WORKFLOW_STATUS == 'Y') {
              flag = false;
              message = "The Selected Records Under Workflow";
              break;
            }
          }
        }
        else {
          flag = false;
          message = "Please Select A1PT Record";
          break;
        }
        sourceName = source.tableName;
      }
    }

    if (flag) {

      let dataList: any[] = [];
      let externalSelectedActivityTxns: ISelectedActivityTxns[] = [];
      let activityData: IActivityData;

      for (let source of this.sourceList) {
        if (source.tableName.includes('A1PT')) {
          for (let row of source.gridOptions.rowData) {
            if (row.RECON_ID == selectedRecords[0].RECON_ID) dataList.push(row);
          }
        }
      }
      externalSelectedActivityTxns.push({
        tableName: sourceName,
        data: dataList
      })
      activityData = {
        base: null,
        external: externalSelectedActivityTxns
      }
      this.reconService.loadSelectedTxnsAllColumns(activityData).subscribe(
        activityDataAllColumns => {
          const dialogRef = this.dialog.open(SameSideUnmatchComponent, {
            panelClass: 'mat-modal',
            width: '998px',
            data: {
              reconName: this.reconModuleName,
              activityData: activityDataAllColumns
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.loadSameSideReversalData();
            console.log(`Dialog result: ${result}`);
          });

        },
        error => {
          console.log(error);
        }
      );
    }
    else {
      alert(message);
    }
  }

}
