import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UserManagementService } from '../services/user-management.service';
import { MatDialog } from '@angular/material';
import { AddPasswordPolicyComponent } from './add-password-policy/add-password-policy.component';
import { EditPasswordPolicyComponent } from './edit-password-policy/edit-password-policy.component';

@Component({
  selector: 'app-password-policy',
  templateUrl: './password-policy.component.html',
  styleUrls: ['./password-policy.component.scss']
})
export class PasswordPolicyComponent implements OnInit {
  @ViewChild('dataTable', { static: true }) table: ElementRef;
  private gridApi;
  private gridColumnApi;
  public rowData: any;
  public rowSelection: any;

  columnDefs = [
    { headerName: 'Title', field: 'title', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Use Policy', field: 'usePolicy', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Minimum Length', field: 'minLength', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Maximum Length', field: 'maxLength', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'PWD MaxAge', field: 'pwdMaxAge', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'PWD Expiry Warning', field: 'pwdExpiryWarning', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'PWD Max Failure', field: 'pwdMaxFailure', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Allow UserToChangeOwnPwd', field: 'allowUserToChangeOwnPwd', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Is Special Chars Allowed', field: 'isSpecialCharsAllowed', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Is Uppercase Allowed', field: 'isUppercaseAllowed', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Is Number Allowed', field: 'isNumberAllowed', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Special Chars', field: 'specialChars', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Created On', field: 'createdOn', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Updated On', field: 'updatedOn', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Comments', field: 'activityComments', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true }
  ];

  constructor(
    public userManagementService: UserManagementService,
    public dialog: MatDialog) {
    this.rowSelection = "single";
  }

  ngOnInit() {
    this.loadAllPolicy();
  }

  loadAllPolicy() {
    this.userManagementService.loadAllPolicy().subscribe(
      (data) => {
        this.rowData = data.object;
      },
      error => {
        console.log(error);
      }
    );
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(AddPasswordPolicyComponent, {
      panelClass: 'mat-modal',
      width: '1050px',
      height: '550px',
      disableClose: true
    });

    console.log('dialogRef --> ', dialogRef);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed --> ', result);
      if (result !== undefined) {
        // this.gridApi.updateRowData({ add: [result] });
        // this.rowData.push(result);
        // console.log('Row Data --> ', this.rowData);
        this.userManagementService.savePolicy(result).subscribe(
          (data) => {
            alert(data.message);
            this.userManagementService.loadAllPolicy().subscribe(
              (data) => {
                this.rowData = data.object;
              },
              error => {
                console.log(error);
              }
            );
          },
          error => {
            console.log(error);
          }
        );

      }
    });
  }
  openEditDialog(): void {
    var editSelectedData = this.gridApi.getSelectedRows();
    console.log("Edit Selected Row --> ", editSelectedData);
    if (editSelectedData == "") {
      alert("please select a record");
    }
    else {
      if (editSelectedData[0].title != "default") {
        const dialogRef = this.dialog.open(EditPasswordPolicyComponent, {
          data: editSelectedData,
          panelClass: 'mat-modal',
          width: '1050px',
          height: '550px',
          disableClose: true
        });
        
        dialogRef.afterClosed().subscribe(result => {
        this.loadAllPolicy();
          console.log('The dialog edit was closed', result);
          if (result !== undefined) {
            this.gridApi.updateRowData({ update: [result] });
          }
        });
      }
      else {
        alert("Can't update default password policy");
      }
    }
  }
  onRemoveSelected() {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData == "") {
      alert("Please select a record to delete");
    }
    else if (selectedData !== "") {
      if (selectedData[0].title != "default") {
        if (confirm("Are you sure you want to do this?")) {
          this.userManagementService.deletePolicy(selectedData[0].id).subscribe(
            (data) => {
              alert(data.message);
              if(selectedData[0].usePolicy == "Yes") this.loadAllPolicy();
            },
            error => {
              console.log(error);
            }
          );
          this.gridApi.updateRowData({ remove: selectedData });
        }
      }
      else {
        alert("Can't delete default password policy");
      }
    }

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.gridApi.sizeColumnsToFit();
  }
  onPageSizeChanged(newPageSize) {
    const element = document.getElementById('page-size') as HTMLInputElement
    const value = element.value
    this.gridApi.paginationSetPageSize(Number(value));
  }
}
