import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AgGridColumnsService {

  constructor() { }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            editable: false,
            resizable: true,
            suppressSizeToFit: false,
            floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: false,
            suppressMenu: true,
            suppressColumnVirtualisation: true
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }

}