import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UserManagementService } from '../../services/user-management.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.scss']
})
export class AddRolesComponent implements OnInit {

  roleName:any;
  status:any;
  description:any;
  responsibility:any;
  rowData:any;

  model: any = {};
  Roles:any;
  submitted = false;
  constructor(public dialogRef: MatDialogRef<AddRolesComponent>,public userManagementService: UserManagementService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.Roles =this.formBuilder.group({
        roleName:new FormControl('',Validators.required),
        status:new FormControl('',Validators.required),
        description :new FormControl('',Validators.required),
        responsibility:new FormControl('',Validators.required),
   
     });
     this.rowData=data;
     }

  ngOnInit() {
  }
  get fval() {
    return this.Roles.controls;
  }
  onSubmit() {
    this.submitted = true;   
    console.log('Submitted'); 
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }


  addRoles()
  {
    this.submitted = true;
    if (this.Roles.invalid) {
      return;
     }
     else{
    let newAddRoleData = {
      "name": this.roleName,
      "status": this.status,
      "description": this.description,
      "responsiblity": this.responsibility,
      "organisationId": "myorg"
    };
    console.log(this.rowData);
    let flag: boolean = false;
    for(let data of this.rowData){
      console.log(data.name);
      console.log(newAddRoleData.name);
      if(data.name == newAddRoleData.name) {
        flag = true;
        break;
      }
    }

    if(flag) {
      alert(" Record already exists");
    }
    else{
    this.dialogRef.close(newAddRoleData);
  
    }
    }
  }

}
