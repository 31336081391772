import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IGroupName } from '../../user/models/users.model';
import { GroupService } from '../service/group.service';


@Component({
  selector: 'app-edit-groups',
  templateUrl: './edit-groups.component.html',
  styleUrls: ['./edit-groups.component.scss']
})
export class EditGroupsComponent implements OnInit {

  submitted = false;

  constructor(public dialogRef: MatDialogRef<EditGroupsComponent>, public groupService: GroupService,
    @Inject(MAT_DIALOG_DATA) public group: IGroupName) { }

  ngOnInit() { }
  cancel(){
    this.dialogRef.close();
  }
  updateGroups() {
    this.submitted = true;
    this.groupService.updateGroups(this.group).subscribe(
      (group: IGroupName) => {
        alert('Group Updated Successfully');
        this.dialogRef.close(group);
      },
      error => {
        console.log(error);
      }
    );
  }

}
