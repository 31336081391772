import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GlBalancingService } from '../../services/gl-balancing.service';
import { GlBalancingInput } from '../../models/recon.model';
import { AgGridColumnsService } from '../../services/ag-grid-columns.service';

@Component({
  selector: 'app-gl-balancing',
  templateUrl: './gl-balancing.component.html',
  styleUrls: ['./gl-balancing.component.scss']
})
export class GlBalancingComponent implements OnInit {

  dateForm: FormGroup;
  submitted = false;
  columnDefs: any;
  glList: any[];

  constructor(private formBuilder: FormBuilder,
    private glBalancingService: GlBalancingService,
    public agGridColumns: AgGridColumnsService,) { }

  ngOnInit() {

    this.dateForm = this.formBuilder.group({
      glDate: [Date, Validators.required],
      type: ['', Validators.required]
    });

  }

  get f() { return this.dateForm.controls; }

  loadGlData() {
    this.submitted = true;
    if (this.dateForm.invalid) {
      return;
    }

    const glBalancingInput = new GlBalancingInput();
    glBalancingInput.reconModuleId = parseInt(localStorage.getItem('reconModuleId'));
    glBalancingInput.type = this.dateForm.value.type;
    glBalancingInput.glDate = (this.dateForm.value.glDate.year + '-' + this.format(this.dateForm.value.glDate.month) + '-' + this.format(this.dateForm.value.glDate.day));

    this.glBalancingService.load(glBalancingInput).subscribe(
        (glList: any[]) => {
          this.columnDefs = this.agGridColumns.generateColumns(glList);
          this.glList = glList;
        },
        error => {
          console.log(error);
        }
      );

  }

  format(value: number): string {
    return value <= 9 ? ('0' + value) : value.toString();
  }

}
