import { Component, OnInit, OnDestroy } from '@angular/core';
import { EtlService } from '../../services/etl.service'
import { IFileName1 } from '../../models/etl.model';
import { ITemplateName } from '../../models/etl.model';
import { MatDialog } from '@angular/material/dialog';
import { cleaningAdd } from './file-transformation-modal/cleaning-modal';
import { addValidation } from './file-transformation-modal/ft-valid-add';
import { formatingAdd } from './file-transformation-modal/formating';
import { splitingAdd } from './file-transformation-modal/spliting';
import { AddMetaDataAdd } from './file-transformation-modal/add-meta-data';
import { editFormating } from './file-transformation-modal/ft-format-edit';
import { editCleaning } from './file-transformation-modal/cleaning-edit';
import { editValidation } from './file-transformation-modal/ft-valid-edit';
import { editJavaSplit } from './file-transformation-modal/spliting-javaSplit-edit';
import { editMetaData } from './file-transformation-modal/add-meta-data-edit';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { element } from 'protractor';


@Component({
  selector: 'app-file-transformation',
  templateUrl: './file-transformation.component.html',
  styleUrls: ['./file-transformation.component.scss']
})
export class FileTransformationComponent implements OnInit {

  itemList = [];
  selectedItems: any = [];
  settings = {};
  dropdownSettings = {};
  dropdownList = [];
  selected = [];
  public FilenameObjs: IFileName1[] = [];
  public FiletempObjs: ITemplateName[] = []
  public templateId: any = [];
  templateGroupId: any = [];
  rowData: any;
  data: any = [];
  columnDefs: any;
  onChangeSelectOperationValue: string;
  templateColumnNames: any = [];
  DuplicateCheckDataElements: any = [];
  arrayLength: any;
  selectedColumns: any = [];
  editSelectedData: any;
  public rowSelection: any;
  public gridApi;
  private gridColumnApi;
  selectedRow: any;
  cleaningbtn: boolean = true;
  //For Splitting
  configModal: any = [];
  configQueryName: any = [];
  configQueryData: any = [];
  sqlSplitFileName: any;
  isSqlAddshow: boolean = true;
  isSqlEditShow: boolean = true;
  isSqlDeleteShow: boolean = true;
  sqlSplitSelectTemplet: any;
  sqlSplitOperationCategory: any;
  queryName: any;
  sqlSplitTempletGroupId: any;
  queryNameText: any;
  sqlSplitOperationCategoryQuery: any;
  sqlSplitDescription: any;
  SQLSPLIT: any;
  submitted = false;
  DuplicateCheck: FormGroup;
  selectTempletid1: any;
  TemplateGroupIdModel: string = "";
  tempSource: any;
  reconModuleId: number;
  FiletempObj:any;
  myTempRowData: any[] = [];
  icons: { filter: string; };
  new: boolean = true;
  TemplateName:any;


  constructor(public etlservice: EtlService, public dialog: MatDialog) {
    this.rowSelection = "single";
    this.sqlSplitOperationCategory = "SqlSplit";
    this.DuplicateCheck = new FormGroup({
      DuplicateCheckFileName: new FormControl('', Validators.required),
      DuplicateCheckTemplateName: new FormControl('', Validators.required),

    });
  }


  ngOnInit() {
    this.reconModuleId = parseInt(localStorage.getItem('reconModuleId'));

    this.icons = {
      filter: ' '
    }
    this.dropdownSettings = {
      text: "Select Columns",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disableDropdown: false // disable dropdown
    };

    this.getFileNameData();
  }
  onItemSelect(item: any) {
    this.selected = [];
    this.selected.push(item);
    console.log("item :", item);
    console.log("selected :", this.selected);

  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }



  get fval() {
    return this.DuplicateCheck.controls;
  }
  signup() {
    this.submitted = true;
    if (this.DuplicateCheck.invalid) {

      return;
    }

  }

  columnDefsCleaning = [
    { headerName: 'Column Name', field: 'COLUMN_NAME', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Operation Category', field: 'OPERATION_CATEGORY', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Operation Name', field: 'OPERATION_NAME', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Replace Null By', field: 'REPLACE_NULL_BY', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Masking Character', field: 'MASKING_CHARACTER', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Masking From Pos', field: 'MASKING_FROMPOS', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Masking To Pos', field: 'MASKING_TOPOS', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Replace Character', field: 'REPLACE_CHARACTER', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Replace Character By', field: 'REPLACE_CHARACTER_BY', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Remove Characters', field: 'REMOVE_CHARACTERS', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true }
  ];

  columnDefsValidation = [
    { headerName: 'Column Name', field: 'COLUMN_NAME', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Operation Category', field: 'OPERATION_CATEGORY', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Operation Name', field: 'OPERATION_NAME', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Starts With', field: 'STARTS_WITH', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Ends With', field: 'ENDS_WITH', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Contains Constants', field: 'CONTAINS_CONSTANTS', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Pattern Check', field: 'PATTERN_CHECK', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Length Check', field: 'LENGTH_CHECK', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Length Type', field: 'LENGTH_TYPE', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true }
  ];

  columnDefsFormatting = [
    { headerName: 'Column Name', field: 'COLUMN_NAME', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Operation Category', field: 'OPERATION_CATEGORY', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Operation Name', field: 'OPERATION_NAME', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Date Format', field: 'DATE_FORMAT', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Date Type', field: 'DATE_TYPE', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Datetime Type', field: 'DATETIME_TYPE', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Time Format', field: 'TIME_FORMAT', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Input Format', field: 'INPUT_FORMAT', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Output Format', field: 'OUTPUT_FORMAT', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true }
  ];

  columnDefsAddMetaData = [
    { headerName: 'Operation Category', field: 'OPERATION_CATEGORY', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Field Name', field: 'FIELD_NAME', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Field Type', field: 'FIELD_TYPE', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Field Format', field: 'FIELD_FORMAT', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Mandatory', field: 'MANDATORY', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Description', field: 'DESCRIPTION', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Field Size', field: 'FIELD_SIZE', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true }
  ];

  Cleaning() {
    this.FilenameObjs = [];
    this.FiletempObjs = [];
    this.templateGroupId = [];
    this.columnDefs = [];
    this.DuplicateCheckDataElements = [];
    this.getFileNameData();
    this.cleaningbtn = true;
    this.templateColumnNames = [];
    this.editSelectedData = "";
    this.TemplateGroupIdModel = "";
  }

  getFileNameData() {

    this.FilenameObjs = []
    var currentPage = "File Transformation";
    this.etlservice.getFileNameDataByModule(this.reconModuleId, currentPage).subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          this.FilenameObjs.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );

  }

  loadTemplateName(id) {
    this.rowData = [];
    this.isSqlAddshow = true;
    this.isSqlEditShow = true;
    this.isSqlDeleteShow = true;
    this.TemplateGroupIdModel = "";
    this.FiletempObjs = []
    this.configQueryName = [];
    this.queryName = undefined;
    this.onChangeSelectOperationValue = null;
    this.queryNameText = [];
    this.sqlSplitOperationCategoryQuery = [];
    this.sqlSplitDescription = [];
    this.templateGroupId = [];
    this.sqlSplitTempletGroupId = undefined;
    this.sqlSplitSelectTemplet = undefined;
    if (this.FiletempObjs = []) {
      this.cleaningbtn = true;
      this.new=true;
    }
    this.etlservice.getTemplateName(id).subscribe(
      data => {

        for (const [key, value] of Object.entries(data)) {
          this.FiletempObjs.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );

  }

  loadMyGroupId(selectTempletid1, source) {

    this.tempSource = source;
    this.selectTempletid1 = selectTempletid1;
    this.TemplateGroupIdModel = "";
    this.sqlSplitTempletGroupId = undefined;
    this.rowData = [];
    this.isSqlAddshow = true;
    this.isSqlEditShow = true;
    this.isSqlDeleteShow = true;
    this.configQueryName = [];
    this.queryName = undefined;
    this.onChangeSelectOperationValue = null;
    this.queryNameText = [];
    this.sqlSplitOperationCategoryQuery = [];
    this.sqlSplitDescription = [];
    this.templateGroupId = [];
    console.log("poonam",selectTempletid1);
    var str = selectTempletid1.split(" ");
    var id = str[0];
  

    console.log("this.templateGroupId", this.templateGroupId);
    if (selectTempletid1 == "Choose...") {
      this.cleaningbtn = true;
      this.new = true;
    }
    this.etlservice.getTemplateGroupID(selectTempletid1, source).subscribe(
      data => {
        this.templateGroupId = data;
        if (this.templateGroupId.length != 0) {
          this.new = true;
          this.TemplateGroupIdModel =this.templateGroupId[0].TEMP_GROUP_ID;
          this.loadTableData(this.TemplateGroupIdModel,source)
        }
        else
          this.new = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  // loadFileTempletesColumns(id) {
  //   this.etlservice.getTemplateColumnNameData(id).subscribe(
  //     data => {
  //       for (const [key, value] of Object.entries(data)) {

  //         this.templateColumnNames.push({itemName:value,id:key});
  //       }
  //       console.log("templateColumnNames   :",this.templateColumnNames);
  //     }

  //   )
  // }


  duplicateCheckDataElements(id) {
    this.DuplicateCheckDataElements = [];
    this.arrayLength = "";
    this.templateColumnNames = [];
    this.etlservice.getDuplicateCheckDataElements(id).subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {

          this.DuplicateCheckDataElements.push({ name: key, value: value });

        }
        console.log("DuplicateCheckDataElements1", this.DuplicateCheckDataElements.length);
        var arrLength = this.DuplicateCheckDataElements.length;
        if (arrLength === 0) {
          this.arrayLength = "Empty";

          this.etlservice.getTemplateColumnNameData(id).subscribe(
            data => {
              for (const [key, value] of Object.entries(data)) {

                this.templateColumnNames.push({ Name: key, value: value });
              }
              console.log("templateColumnNames   :", this.templateColumnNames);
            }

          )
        } else {
          this.arrayLength = "NonEmpty";
        }
      }
    );

    // if(this.DuplicateCheckDataElements.length==='0')
    // {
    //   alert("duplicate data check is empmty")
    // }

  }

  runDuplicateCheck(templetId) {

    console.log("col names ", this.selected)
    // for(let i=0;i<this.selected[0].length;i++)
    // {
    //   this.selectedColumns.push(this.selected[0]);
    // }
    //this.selectedColumns.push(this.selected[0]);
    this.etlservice.getRunDuplicateCheck(this.selected[0], templetId).subscribe(
      data => {
        if (data == 0) {
          alert("Unsuccessful :: \n Table has data OR Duplicate Checks");
        }
        else if (data == null) {
          alert("Successful");
        }
        else {
          alert("select valid input")
        }
      }

    );

  }

  deletedDuplicateCheck(id) {
    this.etlservice.getDeletedDuplicateCheck(id).subscribe(
      data => {
        if (data == "Success") {
          alert("Succussefully Deleted");
          this.duplicateCheckDataElements(id);
        } else {
          alert("Un-Succusseful");
        }
      }
    )
  }



  // openAdd(tempId,groupId): void {

  //   alert("TempId :"+tempId+"groupId :"+groupId);
  //   this.data ={ 'tempId': tempId, 'groupId': groupId}
  //   console.log("for clean this.data",this.data);
  //   const dialogRef = this.dialog.open(cleaningAdd,{data:{tempId,groupId}});

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //   });
  // }

  // openAddValid(tempId,groupId):void{
  //   alert("TempId :"+tempId+"groupId :"+groupId);
  //   this.data ={ 'tempId': tempId, 'groupId': groupId}
  //   console.log("this.data",this.data);
  //   const dialogRef = this.dialog.open(addValidation,{data:{tempId,groupId}});

  //   dialogRef.afterClosed().subscribe(result => {
  //    });
  // }



  //data table

  loadTableData(id, source) {
    this.rowData = [];
    this.myTempRowData = [];
    // if (this.selectTempletid1 == "Choose...") {
    //   console.log(this.selectTempletid1);
    //   this.cleaningbtn = true;
    // }
 if (id === "Choose...") {
      this.cleaningbtn = true;
    }
    if (source === "cleaning") {
      this.etlservice.getCleaningTableData(id).subscribe(data => {
        var dummyData: any = [];
        dummyData = data;
        let tempObj = {};
        dummyData.forEach(element => {

          let map = new Map(Object.entries(element));

          for (let [key, value] of map) {
            if (value === undefined || value === "undefined") {
              tempObj[key] = "NA";
            } else {
              tempObj[key] = value;
            }
          }
          this.myTempRowData.push(tempObj);
          tempObj = {};
        });
        this.rowData = [...this.myTempRowData];
        this.cleaningbtn = false;
        if (this.rowData) {
          this.columnDefs = this.columnDefsCleaning;
        };
        //        this.columnDefs = this.generateColumns(this.rowData);
      }
      );
    } else if (source === "validation") {
      this.etlservice.getValidationTableData(id).subscribe(data => {
        var dummyData: any = [];
        dummyData = data;
        let tempObj = {};
        dummyData.forEach(element => {

          let map = new Map(Object.entries(element));

          for (let [key, value] of map) {
            if (value === undefined || value === "undefined") {
              tempObj[key] = "NA";
            } else {
              tempObj[key] = value;
            }
          }
          this.myTempRowData.push(tempObj);
          tempObj = {};
        });

        this.rowData = [...this.myTempRowData];
        this.cleaningbtn = false;
        if (this.rowData) {
          this.columnDefs = this.columnDefsValidation;
          //        this.columnDefs = this.generateColumns(this.rowData);
        }
      }
      );
    } else if (source === "formatting") {
      this.etlservice.getFormattingTableData(id).subscribe(data => {
        var dummyData: any = [];
        dummyData = data;
        let tempObj = {};
        dummyData.forEach(element => {

          let map = new Map(Object.entries(element));

          for (let [key, value] of map) {
            if (value === undefined || value === "undefined") {
              tempObj[key] = "NA";
            } else {
              tempObj[key] = value;
            }
          }
          this.myTempRowData.push(tempObj);
          tempObj = {};
        });

        this.rowData = [...this.myTempRowData];
        this.cleaningbtn = false;
        if (this.rowData) {
          this.columnDefs = this.columnDefsFormatting;
          //        this.columnDefs = this.generateColumns(this.rowData);
        }
      }
      );
    } else if (source === "javaSplit") {
      this.etlservice.getJavaSplitTableData(id).subscribe(data => {
        this.rowData = data
        if (this.rowData) {
          this.columnDefs = this.generateColumns(this.rowData);
        }
      }
      );
    } else if (source === "AddMetaData") {
      this.etlservice.getAddMetaDataTableData(id).subscribe(data => {
        var dummyData: any = [];
        dummyData = data;
        let tempObj = {};
        dummyData.forEach(element => {

          let map = new Map(Object.entries(element));

          for (let [key, value] of map) {
            if (value === undefined || value === "undefined") {
              tempObj[key] = "NA";
            } else {
              tempObj[key] = value;
            }
          }
          this.myTempRowData.push(tempObj);
          tempObj = {};
        });

        this.rowData = [...this.myTempRowData];
        this.cleaningbtn = false;
        if (this.rowData) {
          this.columnDefs = this.columnDefsAddMetaData;
          this.gridApi.sizeColumnsToFit();
          //        this.columnDefs = this.generateColumns(this.rowData);
        }
      }
      );
    }

  }

  loadConfigData(id) {


    this.configQueryName = [];
    this.queryName = undefined;
    this.onChangeSelectOperationValue = null;
    this.queryNameText = [];
    this.sqlSplitOperationCategoryQuery = [];
    this.sqlSplitDescription = [];

    this.etlservice.getloadConfiguredQueryNames(id).subscribe(data => {
      this.rowData = data;
      //this.rowData.splice(0, 1);
      this.configQueryName = this.rowData;

    });

  }
  loadConfiguredQuerieData(queryName, id) {
    this.rowData = [];
    this.configQueryData = [];
    this.onChangeSelectOperationValue = null;
    this.sqlSplitOperationCategoryQuery = [];
    this.sqlSplitDescription = [];
    this.SQLSPLIT = [];
    this.isSqlAddshow = true;
    this.isSqlEditShow = true;
    this.isSqlDeleteShow = true;
    if (queryName == 'NewQuery') {
      this.onChangeSelectOperationValue = queryName;
      this.isSqlAddshow = false;
      this.isSqlEditShow = true;
      this.isSqlDeleteShow = true;
      return;
    } else {
      this.isSqlEditShow = false;
      this.isSqlDeleteShow = false;
    }

    this.etlservice.getloadConfiguredQuerieData(queryName, id).subscribe(data => {
      console.log("data :", data)
      this.rowData = data;
      this.configQueryData = this.rowData[0];
      this.SQLSPLIT = this.configQueryData.SPLIT_ID;
      this.sqlSplitOperationCategoryQuery = this.configQueryData.SPLIT_QUREY;
      this.sqlSplitDescription = this.configQueryData.SQL_DESCRIPTION;
      console.log("query data :", this.rowData);
      console.log("query data :", this.rowData[0].SPLIT_QUREY)
    })

  }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            filter: true
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }

  // openAddFormat(tempId,groupId):void{
  //   const dialogRef = this.dialog.open(formatingAdd,{data:{tempId,groupId}});

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //   });



  // }

  // openJAddSplit():void{
  //   const dialogRef = this.dialog.open(splitingAdd);

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //   });

  // }

  // openAddMetaDataAdd():void{
  //   const dialogRef = this.dialog.open(AddMetaDataAdd);

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //   });

  // }
NewAdd(id,source){
  this.etlservice.getTemplateGroupID(this.selectTempletid1, this.tempSource).subscribe(
    data => {
      this.templateGroupId = data;
      if (this.templateGroupId.length != 0) {
        this.new = true;
        this.TemplateGroupIdModel =this.templateGroupId[0].TEMP_GROUP_ID;
        this.loadTableData(this.TemplateGroupIdModel,this.tempSource)
      }
      else
        this.new = false;
    },
    error => {
      console.log(error);
    }
  );
}

  openAdd(dialogName, tempId, groupId,id): void {
    let row = this.rowData
    if (dialogName === "addCleaning") {
      const dialogRef = this.dialog.open(cleaningAdd, { data: { row, tempId, groupId }, width: '500px', panelClass: 'mat-modal' });

      dialogRef.afterClosed().subscribe(result => {
        if (groupId == "NewGroup") {
          this.NewAdd(this.selectTempletid1, this.tempSource);
        } else {
          this.loadTableData(groupId, "cleaning");
        }
      });
    }
    else if (dialogName === "addValidation") {
      const dialogRef = this.dialog.open(addValidation, { data: { row,tempId, groupId }, width: '500px', panelClass: 'mat-modal' });
      dialogRef.afterClosed().subscribe(result => {
        if (groupId === "NewGroup") {
          this.NewAdd(this.selectTempletid1, this.tempSource);
        } else {
          this.loadTableData(groupId, "validation");
        }
      });
    }
    else if (dialogName === "addFormatting") {
      const dialogRef = this.dialog.open(formatingAdd, { data: {row, tempId, groupId }, width: '500px', panelClass: 'mat-modal' });
      dialogRef.afterClosed().subscribe(result => {
        if (groupId === "NewGroup") {
          this.NewAdd(this.selectTempletid1, this.tempSource);
        } else {
          this.loadTableData(groupId, "formatting");
        }
      });
    } else if (dialogName === "Java-Split") {
      const dialogRef = this.dialog.open(splitingAdd, { data: { tempId, groupId }, width: '500px', panelClass: 'mat-modal' });
      dialogRef.afterClosed().subscribe(result => {
      });
    } else if (dialogName === "Meta-Data") {
      const dialogRef = this.dialog.open(AddMetaDataAdd, { data: {row, tempId, groupId }, width: '500px', panelClass: 'mat-modal' });
      dialogRef.afterClosed().subscribe(result => {
        if (groupId === "NewGroup") {
          this.NewAdd(this.selectTempletid1, this.tempSource);
        } else {
          this.loadTableData(groupId, "AddMetaData");
        }


      });
    }


  }

  openEdit(dialogName, fileId) {
    console.log("selected data :", this.editSelectedData, "    dialogName :", dialogName, "     fileId :", fileId)
    var data = this.editSelectedData;
    console.log("data :", data)
    if (data === undefined || data === "" || data === "Empty") {
      alert("Please select data ");
      return;
    }
    if (dialogName === "editFormatting") {
      const dialogRef = this.dialog.open(editFormating, { data: { editSelectedData: this.editSelectedData, fileId }, width: '500px', panelClass: 'mat-modal' });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog edit was closed', result);
        if (result !== undefined) {
          //      this.gridApi.updateRowData({ update: [result] });
          //      console.log('Row Data --> ' , this.rowData)
          console.log("test",result.message);
          alert(result.message);
          this.loadTableData(this.editSelectedData.TEMP_GROUP_ID, "formatting");
        }
        this.editSelectedData = "";
      });
    } else if (dialogName === "editCleaning") {
      const dialogRef = this.dialog.open(editCleaning, { data: { editSelectedData: this.editSelectedData, fileId }, width: '500px', panelClass: 'mat-modal' });
      dialogRef.afterClosed().subscribe((result:any) => {
        console.log("test",result);
        if (result !== undefined) {
          this.gridApi.updateRowData({ update: [result] });
          //      console.log('Row Data --> ' , this.rowData)
          //      console.log('Row Data --> ' , this.tempId)
          console.log("test",result);
          alert(result.message);
          this.loadTableData(this.editSelectedData.TEMP_GROUP_ID, "cleaning");
        }
        this.editSelectedData = "";
      });

    } else if (dialogName === "editValidation") {
      const dialogRef = this.dialog.open(editValidation, { data: { editSelectedData: this.editSelectedData, fileId }, width: '500px', panelClass: 'mat-modal' });
      dialogRef.afterClosed().subscribe(result => {

        if (result !== undefined) {
          //       this.gridApi.updateRowData({ update: [result] });
          //       console.log('Row Data --> ' , this.rowData)
          alert(result.message);
          this.loadTableData(this.editSelectedData.TEMP_GROUP_ID, "validation");
        }
        this.editSelectedData = "";
      });
    } else if (dialogName === "Java-Split-Edit") {
      const dialogRef = this.dialog.open(editJavaSplit, { data: { editSelectedData: this.editSelectedData, fileId }, width: '500px', panelClass: 'mat-modal' });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog edit was closed', result);
        if (result !== undefined) {
          this.gridApi.updateRowData({ update: [result] });
          console.log('Row Data --> ', this.rowData)
        }
        this.editSelectedData = "";
      });
    } else if (dialogName === "Meta-Data-Edit") {
      const dialogRef = this.dialog.open(editMetaData, { data: { editSelectedData: this.editSelectedData, fileId }, width: '500px', panelClass: 'mat-modal' });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog edit was closed', result);
        if (result !== undefined) {
          alert(result.message);
          this.gridApi.updateRowData({ update: [result] });
          console.log('Row Data --> ', this.rowData)
        }
        this.editSelectedData = "";
      });
    }

  }
delete(id,source){
  this.etlservice.getTemplateGroupID(id,source).subscribe(
    data => {
      this.templateGroupId = data;
      if (this.templateGroupId.length != 0) {
        this.new = true;
      }
      else
        this.new = false;
        this.cleaningbtn=true;
    },
    error => {
      console.log(error);
    }
  );
}
  deleteRow(dialogName, fileId,id) {
    console.log("Dialog --> ", dialogName);
    console.log("editSelectedData", this.editSelectedData);
    console.log("fileId", fileId);
    let count=this.rowData.length-1;
    var data = this.editSelectedData;
    console.log("count,count",count);
    console.log("data :", data)
    if (data === undefined || data === "" || data === "Empty") {
      alert("Please select data ");
      return;
    }
    if (dialogName === "format-delete") {
      if (confirm("Do you want to delete ?")) {
        console.log("Insdid --> ", this.editSelectedData.FOR_OP_ID);
        this.etlservice.deleteformatConfig(this.editSelectedData.FOR_OP_ID)
          .subscribe((data: any) => {
            
            if(count==0){
             this.delete(this.selectTempletid1,"formatting");
             this.cleaningbtn=true;
             alert(data.message);
            }
            this.loadTableData(this.editSelectedData.TEMP_GROUP_ID, "formatting");
            this.editSelectedData = "";
            alert(data.message);
          });
      }

    } else if (dialogName === "cleaning-delete") {
      if (confirm("Do you want to delete ?")) {
        console.log("Insdid --> ", this.editSelectedData.CL_OP_ID);
        this.etlservice.deleteCleaningConfig(this.editSelectedData.CL_OP_ID)
          .subscribe((data: any) => {
            if(count==0){
              this.delete(this.selectTempletid1,"cleaning");
              this.cleaningbtn=true;
              console.log("test",data.message);
          alert(data.message);
             }
            this.loadTableData(this.editSelectedData.TEMP_GROUP_ID, "cleaning");
            this.editSelectedData = "";
            console.log("test",data.message);
            alert(data.message);
          });
      }
    } else if (dialogName === "valid-delete") {
      console.log("Insdid --> ", this.editSelectedData);
      if (confirm("Do you want to delete ?")) {
        this.etlservice.deleteValidConfig(this.editSelectedData.VAL_OP_ID)
          .subscribe((data: any) => {
            if(count==0){
              this.delete(this.selectTempletid1,"validation");
              alert(data.message);
             }
            this.loadTableData(this.editSelectedData.TEMP_GROUP_ID, "validation");
            this.editSelectedData = "";
            alert(data.message);
          });
      }
    } else if (dialogName === "Java-Split-Delete") {

    } else if (dialogName === "Meta-Data-Delete") {

      console.log("Insdid --> ", this.editSelectedData.CL_MT_ID);
      if (confirm("Do you want to delete ?")) {
        this.etlservice.deleteMetaDataConfig(this.editSelectedData.CL_MT_ID)
          .subscribe((data: any) => {
            if(count==0){
              this.delete(this.selectTempletid1,"AddMetaData");
              alert(data.message);
             }
            this.loadTableData(this.editSelectedData.TEMP_GROUP_ID, "AddMetaData");
            this.editSelectedData = "";
            alert(data.message);
          });
      }
    }
  }

  createdColumn(templateId, templateGroupId) {
    console.log("template id :", templateId, "      group id :", templateGroupId);
    this.etlservice.createMetaDataColumn(templateId, templateGroupId).subscribe(data => {
      var msg: any;
      msg = data;
      alert(msg.message);
    });

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  onRowClicked(event) {
    this.editSelectedData = [];
    console.log("event.data :", event.data)
    this.editSelectedData = event.data;
  }

  onChangeSelectOperation(event) {
    this.onChangeSelectOperationValue = event.target.value;

  }

  //To save the SQL split  configurations  
  saveSqlSplitConfig() {

    if (this.queryNameText === 'null' || this.queryNameText === "" || this.queryNameText === undefined
      || this.sqlSplitOperationCategoryQuery === 'null' || this.sqlSplitOperationCategoryQuery === "" || this.sqlSplitOperationCategoryQuery === undefined ||
      this.sqlSplitOperationCategoryQuery.length === 0) {
      alert("Invalid Input : Please check New Query Name & Split Query");
    } else {
      this.etlservice.saveSqlSplitConfig({
        sqlSplitOperationCategory: "SqlSplit",
        sqlSplitFileName: this.sqlSplitFileName,
        sqlSplitSelectTemplet: this.sqlSplitSelectTemplet,
        sqlSplitTempletGroupId: this.sqlSplitTempletGroupId,
        queryName: this.queryNameText,
        queryNameText: this.queryNameText,
        sqlSplitOperationCategoryQuery: this.sqlSplitOperationCategoryQuery,
        sqlSplitDescription: this.sqlSplitDescription
      }).subscribe((data: any) => {
        alert("Successfully Added SQL Split Configuration");
        this.FiletempObjs = [];
        this.templateGroupId = [];
        this.configQueryName = [];
        this.sqlSplitFileName = "";
        this.sqlSplitSelectTemplet = "";
        this.sqlSplitTempletGroupId = "";
        this.queryName = "";
        this.queryNameText = "";
        this.sqlSplitOperationCategoryQuery = "";
        this.sqlSplitDescription = "";

      })
    }


  }

  //To edit the SQL split configurations  
  editSqlSplitConfig() {
    if (this.queryNameText === 'null' || this.queryNameText === "" || this.queryNameText === undefined
      || this.sqlSplitOperationCategoryQuery === 'null' || this.sqlSplitOperationCategoryQuery === "" || this.sqlSplitOperationCategoryQuery === undefined ||
      this.sqlSplitOperationCategoryQuery.length === 0) {
      alert("Invalid Input : Please check Query Name & Split Query");
    } else {
      this.etlservice.editSqlSplitConfig({
        sqlSplitOperationCategory: "SqlSplit",
        sqlSplitFileName: this.sqlSplitFileName,
        sqlSplitSelectTemplet: this.sqlSplitSelectTemplet,
        sqlSplitTempletGroupId: this.sqlSplitTempletGroupId,
        queryName: this.queryName,
        queryNameText: this.queryNameText,
        sqlSplitOperationCategoryQuery: this.sqlSplitOperationCategoryQuery,
        sqlSplitDescription: this.sqlSplitDescription,
        SPLIT_ID: this.SQLSPLIT
      }).subscribe((data: any) => {
        alert("Successfully Edited SQL Split Configuration");
        this.FiletempObjs = [];
        this.templateGroupId = [];
        this.configQueryName = [];
        this.sqlSplitFileName = "";
        this.sqlSplitSelectTemplet = "";
        this.sqlSplitTempletGroupId = "";
        this.queryName = "";
        this.queryNameText = "";
        this.sqlSplitOperationCategoryQuery = "";
        this.sqlSplitDescription = "";

      })
    }
  }

  deleteSqlSplitConfig() {
    if (confirm("Are you sure you want to delete this item ? ")) {
      this.etlservice.deleteSqlSplitConfig({
        SPLIT_ID: this.SQLSPLIT
      }).subscribe((data: any) => {
        alert("Successfully Deleted SQL Split Configuration");
        this.FiletempObjs = [];
        this.templateGroupId = [];
        this.configQueryName = [];
        this.sqlSplitFileName = "";
        this.sqlSplitSelectTemplet = "";
        this.sqlSplitTempletGroupId = "";
        this.queryName = "";
        this.queryNameText = "";
        this.sqlSplitOperationCategoryQuery = "";
        this.sqlSplitDescription = "";

      })

    }
  }

}