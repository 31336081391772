import { Component, OnInit } from '@angular/core';
import { EtlService } from '../../services/etl.service';
import { IfileExtract, ITemplateName1, IViewName } from '../../models/etl.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  loadData: boolean = false;
  public FilenameObjs: IfileExtract[] = [];
  public FiletempObjs: ITemplateName1[] = [];
  public viewNameObjs: IViewName[] = [];
  FileUpload: FormGroup;
  submitted = false;
  observableArray: any;
  reconModuleId: number;
  moduleName: any;
  transformDatabtn: boolean = true;
  dbDataIntegration :any;
  fromDate: any;
  toDate: any;
  runEtlFlag: boolean = true;
  mergeFlag: boolean = false;
  mergeButtonFlag: boolean = true;

  splitFlag: boolean = false;
  splitButtonFlag: boolean = true;
  constructor(public etlService: EtlService,
    private formBuilder: FormBuilder) {
      this.dbDataIntegration = this.formBuilder.group({
        fromDate: new FormControl('', Validators.required),
        toDate: new FormControl('', Validators.required)
      });
      this.FileUpload = new FormGroup({
        FileName: new FormControl('', Validators.required),
        TemplateName: new FormControl('', Validators.required),
        ViewName: new FormControl('',Validators.required),
        // fileUpload: new FormControl('', Validators.required),//fileUpload functionality
      });
  }
  get fval() {
    return this.FileUpload.controls;
  }
  signup() {
  //   this.submitted = true;
  //   if (this.FileUpload.invalid) {

  //     return;
  //   }

  }
  ngOnInit() {
    this.reconModuleId = parseInt(localStorage.getItem('reconModuleId'));
    this.getFileNameData();
    this.getViewNameData();
    this.reconModuleId = parseInt(localStorage.getItem('reconModuleId'));
    this.moduleName = localStorage.getItem('reconModuleName');
    console.log("Module ID & Module Name --> ", this.reconModuleId, this.moduleName);
  }



  getFileNameData() {
    this.FilenameObjs = [];
    var currentPage = "File Upload";
    this.etlService.getFileNameDataByModule(this.reconModuleId, currentPage).subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          this.FilenameObjs.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );

  }

  loadTemplatedByFile(id) {
    console.log(id);
    this.runEtlFlag = true;
    this.transformDatabtn = true;
    this.mergeButtonChecked(id);
    this.splitButtonChecked(id);
    this.FiletempObjs = [];
    this.etlService.getTemplateData1(id).subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          this.FiletempObjs.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  mergeButtonChecked(id){
    this.mergeFlag = false;
    if(this.moduleName === 'AGENCY' && id === '43'){
      this.mergeFlag = true;
    } else if(this.moduleName === 'COMPANYAGENCY' && id === '302'){
      this.mergeFlag = true;
    } else if(this.moduleName === 'POS' && id === '87'){
      this.mergeFlag = true;
    } else if(this.moduleName === 'OMANTELPORTAL' && id === '417'){
      this.mergeFlag = true;
    }else if(this.moduleName === 'ACQUIRER_ATM_POS' && id === '867'){
      this.mergeFlag = true;
    }
    
  }

  mergeButtonVisibilityChecked(id){
    this.mergeButtonFlag = true;
    if(this.moduleName === 'AGENCY' && id === '43'){
      this.mergeButtonFlag = false;
    } else if(this.moduleName === 'COMPANYAGENCY' && id === '302'){
      this.mergeButtonFlag = false;
    } else if(this.moduleName === 'POS' && id === '87'){
      this.mergeButtonFlag = false;
    } else if(this.moduleName === 'OMANTELPORTAL' && id === '418'){
      this.mergeButtonFlag = false;
    } else if(this.moduleName === 'ACQUIRER_ATM_POS' && id === '867'){
      this.mergeButtonFlag = false;
    } 
     
  }

  splitButtonChecked(id){
    this.splitFlag = false;
    if(this.moduleName === 'MAOP' && id === '412'){
      this.splitFlag = true;
    } else if(this.moduleName === 'MAOP' && id === '413'){
      this.splitFlag = true;
    }
    else if(this.moduleName === 'ATM_ACQUIRER' && id === '821'){
      this.splitFlag = true;
      }
    else if(this.moduleName === 'ISSUER_ATM_POS' && id === '861'){
      this.splitFlag = true;
      }
  }

  splitButtonVisibilityChecked(id){
    this.splitButtonFlag = true;
    if(this.moduleName === 'MAOP' && id === '413'){
      this.splitButtonFlag = false;
    } else if(this.moduleName === 'MAOP' && id === '414'){
      this.splitButtonFlag = false;
    }else if(this.moduleName === 'ATM_ACQUIRER' && id === '821'){
         this.splitButtonFlag = false;
    }else if(this.moduleName === 'ISSUER_ATM_POS' && id === '861'){
      this.splitButtonFlag = false;
 }
    
  }

  selectedTemplateName(id){
    this.mergeButtonVisibilityChecked(id);
    this.splitButtonVisibilityChecked(id);
   // if(id !== "" && id !== "message" && this.mergeButtonFlag === true && this.splitButtonFlag === true){
    if(id !== "" && id !== "message" && this.mergeButtonFlag === true){
      this.runEtlFlag = false;
    } else {
      this.runEtlFlag = true;
    }
  }

  runETL(fileId, tempId, value) {
    console.log(fileId);
    console.log(tempId);
    console.log(value);
    this.submitted = true;
    // if (this.FileUpload.invalid) {

    //   return;
    // }
    this.etlService.getReadConfiguredFile(fileId, tempId, value, this.reconModuleId, this.moduleName).subscribe(data => {
      var response: any;
      response = data;

      if (response.message == "file read is sucessfully") {
        alert("Run ETL is successful");
        this.transformDatabtn = false;
      }
      else
      alert(response.message)
    });
  }

  transformData(fileId, tempId, value) {
    this.submitted = true;
    // if (this.FileUpload.invalid) {

    //   return;
    // }
    this.etlService.transformConfiguredFile(fileId, tempId, value, this.reconModuleId, this.moduleName).subscribe(data => {
      var response: any;
      response = data;
       if(response.STATUS == "success"){
         alert("Data Transformed is successful");
       }
       else
      alert(response.STATUS)
    });
  }

  
mergeData(fileId, tempId) {
  this.submitted=false;
  this.etlService.mergeData(this.reconModuleId, fileId, tempId).subscribe(data => {
  console.log("test merge", data);
       alert(data);
  },
  error => {
    console.log(error.error.text);
    alert(error.error.text);
  });
}

splitData(fileId, tempId) {
  this.submitted=false;
  this.etlService.splitData(this.reconModuleId, fileId, tempId).subscribe(data => {
  console.log("test split", data);
       alert(data);
  },
  error => {
    console.log(error.error.text);
    alert(error.error.text);
  });
}

onFileChange(event, tempId) {
  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    const data: FormData = new FormData();
    data.append('file', file);
    data.append('tempId', tempId);
    this.etlService.fileUpload(data).subscribe(data => {
           alert(data);
      },
      error => {
        alert('File upload failed');
      });
  }
  this.FileUpload.value.fileUpload = '';
}

onSwitchLoad(){
  this.loadData =! this.loadData;
}

getViewNameData() {
  this.viewNameObjs = [];
  var currentPage = "File Upload";
  this.etlService.getViewData(currentPage).subscribe(
    data => {
      for (const [key, value] of Object.entries(data)) {
        this.viewNameObjs.push({ name: key, value: value });
      }
    },
    error => {
      console.log(error);
    }
  );

}

loadDataTransferProcess(value){
  console.log(this.fromDate);
  console.log(this.toDate);
  console.log(value);
  var str = value.split(" ");
  console.log(str[0]);
  console.log(str[1]);
  if (this.dbDataIntegration.invalid) {
    return;
  }
  else {
    if (new Date(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day) >
      new Date(this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day)) {
      alert("From date should be less than To date");
    }
    else {
      this.etlService.loadDataTransferProcess(str[0],str[1],
        (this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day),
        (this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day)).subscribe((data: any) => {
          alert(data.message);
        },
        error => {
          console.log(error);
        });
    }//else
  }//else
  }

}



