import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ICaseManagement, ICaseResponse, IClose } from '../models/case-management.model';

@Injectable({
  providedIn: 'root'
})
export class CreatedCasesService {

  constructor(private http: HttpClient) { }
 
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
 
  loadCreatedCases(reconModuleName: string) : Observable<ICaseManagement[]>{
    return this.http.get<ICaseManagement[]>(environment.caseManagementUrl + '/loadCreatedCases.ascent/'+reconModuleName, this.httpOptions);
  }

  close(close: IClose) : Observable<ICaseResponse>{
    return this.http.post<ICaseResponse>(environment.caseManagementUrl + '/close.ascent', close, this.httpOptions);
  }
  
}
