import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddPasswordPolicyData } from '../../models/user-management.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-password-policy',
  templateUrl: './add-password-policy.component.html',
  styleUrls: ['./add-password-policy.component.scss']
})
export class AddPasswordPolicyComponent implements OnInit {

  passwordPolicy: FormGroup;
  local_data: any;
  title: any;
  submitted = false;
  maxLength: any;
  specialChars: any;
  constructor(public dialogRef: MatDialogRef<AddPasswordPolicyComponent>, private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: AddPasswordPolicyData
  ) {

    this.passwordPolicy = this.formBuilder.group({
      title: new FormControl('', Validators.required),
      minLength: new FormControl('', Validators.required),
      maxLength: new FormControl('', Validators.required),
      pwdMaxAge: new FormControl('', Validators.required),
      pwdExpiryWarning: new FormControl('', Validators.required),
      pwdMaxFailure: new FormControl('', Validators.required),
      allowUserToChangeOwnPwd: new FormControl('', Validators.required),
      isSpecialCharsAllowed: new FormControl('', Validators.required),
      isUppercaseAllowed: new FormControl('', Validators.required),
      isNumberAllowed: new FormControl('', Validators.required),
      specialChars: new FormControl('', Validators.required),
      activityComments: new FormControl('', Validators.required),
      usePolicy: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
  }

  get fval() {
    return this.passwordPolicy.controls;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  isSpecialCharsAllowed(value) {
    console.log(value)
    if (value == "No") {
      this.passwordPolicy.controls['specialChars'].disable();
      this.passwordPolicy.get('specialChars').reset();
    }
    else
      this.passwordPolicy.controls['specialChars'].enable();
  }
  savePasswordPolicy() {
    this.submitted = true;
    if (this.passwordPolicy.invalid) {
      return;
    }
    console.log("Data --> ", this.passwordPolicy.value);
    if(Number(this.passwordPolicy.value.maxLength) > Number(this.passwordPolicy.value.minLength))
    {
      this.dialogRef.close(this.passwordPolicy.value);
    }
    else {
      alert("Maximun Password length should be greater than Minimum password length");
    }

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  checkMinLength() {
    if (this.passwordPolicy.value.minLength == 0) {
      alert(" Minimum password length should not be zero");
      this.passwordPolicy.get('minLength').reset();
    }
  }
  checkMaxLength() {
    if (this.passwordPolicy.value.maxLength == 0) {
      alert("Maximun Password length should not be zero");
      this.passwordPolicy.get('maxLength').reset();
    }

  }
}