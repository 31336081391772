import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment'; 
import { Observable } from 'rxjs';
import { IGroupName, IUsersData } from '../../user/models/users.model';
import { IRole, IUsersResponse } from '../../models/user-management.model';
import { IGroupData } from '../models/group.model';




@Injectable({
  providedIn: 'root'
})
export class GroupService {
    constructor(private http: HttpClient) { }

    httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
   

    addGroupDetails(group: any):Observable<IGroupName>
    {
      return this.http.post<IGroupName>(environment.userManagementUrl + '/v1/group/create', group, this.httpOptions);
     }
 
    loadGroupName(){
      
      return this.http.get<IGroupName[]>(environment.userManagementUrl + '/v1/groupName/read', this.httpOptions);

    }
    loadGroupUser(){
    
      return this.http.get<IGroupName[]>(environment.userManagementUrl + '/v1/user/read/all', this.httpOptions);

    }
    addAsignGroupDetails(newColumnData:any){
      console.log("addAsignGroupDetails:",newColumnData);
       return this.http.put<any>(environment.userManagementUrl +'/v1/user-group/'+newColumnData.userId+'/'+newColumnData.groupIds.toString(), this.httpOptions);  
    }
    loadRolesForUser(){
      return this.http.get<IRole[]>(environment.userManagementUrl + '/v1/role/read/all', this.httpOptions);
    }
    getGroupsData() {
      return this.http.get<IGroupData[]>(environment.userManagementUrl+'/v1/loadgroupData/read', this.httpOptions);
    }
    getAssignGroupToUserData() {
      return this.http.get<IUsersData[]>(environment.userManagementUrl+'/v1/user/read/all/user-groups/myorg', this.httpOptions);
    }

    updateGroups(updateGroups:IGroupName){
     return this.http.put<IGroupName>(environment.userManagementUrl +'/v1/group/update',JSON.stringify(updateGroups), this.httpOptions);
    }


    deleteGroup(groupId: number):Observable<IUsersResponse>{
      return this.http.delete<IUsersResponse>(environment.userManagementUrl +'/v1/group/delete/myorg/'+groupId, this.httpOptions);
    }

    deleteAssignGroupsToUser(userId:any){
      return this.http.put<any>(environment.userManagementUrl +'/v1/groups-user/delete/'+userId, this.httpOptions);
    }
  
   
}