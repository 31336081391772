import { Grid, GridOptions, Module, AllCommunityModules } from "@ag-grid-community/all-modules";

export class IReconModule {
  public id: number;
  public moduleName: string;
}

export class IReconDEModule {
  public id: number;
  public dataElementName: string;
}

export class IReconciled {
  public reconId: number;
  public fromDate: string;
  public toDate: string;
}

export class IExternalSource {
  public externalSourceName: any;
  public externalSourceColumnDefs: any;
  public externalSourceRowData: any;
}

export class IActivity {
  public reconName: string;
  public comments: string;
  public activityType: string;
  public activityData: any;
}

export class IActivityResponse {
  public status: boolean;
  public message: string;
}

//un-reconcile start

export class ISourceConfig {
  public tableName: string;
  public columns: string[];
  public amountColumn: string;
  public flag: boolean;
  public gridOptions: GridOptions = <GridOptions>{};
}

export class ISelectedActivityTxns {
  public tableName: string;
  public data: any[];
}

export class IActivityData {
  public base: ISelectedActivityTxns;
  public external: ISelectedActivityTxns[];
}

export class IActivityInput {
  public reconName: string;
  public comments: string;
  public activityType: string;
  public activityData: IActivityData;
}

export class ISource {
  public sourceColumnDefs: any;
  public sourceRowData: any;
}
//un-reconcile end

export interface DialogData {
  ID: number,
  MODULEID: number,
  MODULENAME: number;
  SOURCEID: number;
  ISCENTRAL: number;
  RECONDESCRIPTION: string;
}

//Master table List
export class IMasterTableList {

  public masTableName: string;
}


export class IReconProcessStatus {
  public id: number;
  public moduleId: number;
  public startTime: Date;
  public endTime: Date;
  public reconStatus: string;
  public reconProcessId: number;
  public updatedBy: string;
  public opIpAddress: string;
  public rollbackStatus: string;
}

export class RollBackResponse {
  public status: boolean;
  public message: string;
}

export class GlBalancingResponse {
  public status: boolean;
  public message: string;
}

export class GlBalancingInput {
  public reconModuleId: number;
  public type: string;
  public glDate: string;
}

export class GlBalancing {
  public field: string;
  public a1pt: string;
  public settlement: string;
}