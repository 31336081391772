import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment'; 
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StagingService {

  constructor(private http: HttpClient) { }

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  getStagingModel(){
    return this.http.get(environment.apiUrl + '/getStagingModel.ascent', this.httpOptions);
  }

  getStagingModelStagingTables(moduleId:string){
    return this.http.get(environment.apiUrl + '/getStagingModelStagTables.ascent?moduleId=' + moduleId, this.httpOptions);
  }

  getTablesData(tableName:string): Observable<any[]>{
    return this.http.get<any[]>(environment.apiUrl + '/getTablesData.ascent?tableName=' + tableName, this.httpOptions);
  }
  getTablesDataByPagination(tableName:string,page:any,pageSize:any): Observable<any[]>{

    return this.http.post<any[]>(environment.apiUrl + '/getStagingOrExceptionDataByPagination.ascent',JSON.stringify({'tableName':tableName,'page':page,'pageSize':pageSize}), this.httpOptions);

    }
}