import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { IReconModule, ISchedulerResponse, IJobScheduler } from '../models/scheduler.model';
import { Observable } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
 
  constructor(private http: HttpClient) { }
 
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
 
  loadAllJobSchedulers(): Observable<IJobScheduler[]>  {
    return this.http.get<IJobScheduler[]>(environment.scheduler + '/recon-schedulers', this.httpOptions);
  }

  loadReconModules(): Observable<IReconModule[]>  {
    return this.http.get<IReconModule[]>(environment.reconUrl + '/loadReconModules.ascent', this.httpOptions);
  }
 
  loadSourcesByReconModules(moduleId:number){
    return this.http.get(environment.reconUrl + '/getTablesBasedonModule.ascent?moduleId=' + moduleId, this.httpOptions);
  }

  saveSchedulerConfig(jobScheduler: IJobScheduler): Observable<ISchedulerResponse> {
    return this.http.post<ISchedulerResponse>(environment.scheduler + '/recon-schedulers/', jobScheduler, this.httpOptions);
  }

  deleteSchedulerConfig(jobSchedulerId: number): Observable<ISchedulerResponse> {
    return this.http.delete<ISchedulerResponse>(environment.scheduler + '/recon-schedulers/'+jobSchedulerId, this.httpOptions);
  }

  updateSchedulerConfig(jobScheduler: IJobScheduler,jobSchedulerId: any): Observable<ISchedulerResponse> {
    return this.http.put<ISchedulerResponse>(environment.scheduler + '/recon-schedulers/'+jobSchedulerId,jobScheduler, this.httpOptions);
  }

  getViewData(currentPage:string){
    return this.http.get(environment.apiUrl+'/clientDbReader/'+currentPage, this.httpOptions);
  }

}