import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IActivityData, IActivityInput } from '../../../models/recon.model';
import { AgGridColumnsService } from '../../../services/ag-grid-columns.service';
import { ReconService } from '../../../services/recon.service';

@Component({
  selector: 'app-same-side-match',
  templateUrl: './same-side-match.component.html',
  styleUrls: ['./same-side-match.component.scss']
})
export class SameSideMatchComponent implements OnInit {

  reconName: string;
  activityData: IActivityData;
  externalTable: string;
  externalColumnDefs: any;
  externalRowData: any = [];
  comments: string;
  submitted: boolean = false;
  sameSideForm: any;

  constructor(public dialogRef: MatDialogRef<SameSideMatchComponent>,
    public reconService: ReconService,
    private formBuilder: FormBuilder,
    public agGridColumns: AgGridColumnsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.reconName = data.reconName;
    this.activityData = data.activityData;

    this.activityData.external.forEach((source) => {
      this.externalTable = source.tableName;
      this.externalColumnDefs = this.agGridColumns.generateColumns(source.data);
      this.externalRowData = source.data;
    });

    this.sameSideForm = this.formBuilder.group({
      comments: new FormControl('', Validators.required),
    });

  }

  ngOnInit() {
  }

  get fval() { return this.sameSideForm.controls; }

  sameSideMatch() {
    this.submitted = true;
    if (this.sameSideForm.invalid) {
      return;
    }
    let activityInput: IActivityInput;
    activityInput = {
      reconName: this.reconName,
      comments: this.comments,
      activityType: "SAME_SIDE_MATCH",
      activityData: this.activityData
    }
    this.reconService.processActivity(activityInput).subscribe(
      activityResponse => {
        alert(activityResponse.message);
        this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }
    );
  }

}
