export interface IGroup {
    id:number;
    active_index:string;
    created_date:string;
    last_modified_date:string;
    op_ip_address:string;
    updated_by:number;
    description:string;
    name:string;
    organisation_id:string;
    status:string;
}

export interface IRole {
    id:number;
    name:string;
    description:string;
    organisationId:string;
    status:string;
    createdDate:string;
    lastModifiedDate:string;
    updatedBy:any;
    permissions:any[];
}

export interface IEntity {
    id:string;
    name:string;
    description:string;
    organisationId:string;
    type:string;
    createdDate:string;
    lastModifiedDate:string;
    updatedBy:any;
    operations:IOperation[];
}

export interface IOperation {
    createdDate:string;
    lastModifiedDate:string;
    updatedBy:number;
    id:number;
    name:string;
    description:string;
    organisationId:string;
    type:string;
    childs:IChild[];
}

export interface IChild {
    createdDate:string;
    lastModifiedDate:string;
    updatedBy:number;
    id: number;
    name: string;
    description:string;
    organisationId:string;
    type:string;
}

export class RolesDilogData
{
  public name  :string; 
  public status:string;
  public description:string;
  public responsiblity:string;
   
}


export interface IEntityOperation {
    updatedBy:string;
    activeIndex:string;
    id:number;
    name:string;
    description:string;
    organisationId:string;
    type:string;
    childs: any;
}

export interface IPermission {
    updatedBy:string;
    opIpAddress:string;
    activeIndex:string;
    id:number;
    name:string;
    description:string;
    organisationId:string;
    entities:IEntity[];
}

export interface IRoleUser
{

   id:number;
   user_id:string;
  //user_name:string;
  // phon_number:string;
  // email_id:string;
  // reporting_user_id:string;
  // organizationid:string;
  // is_ldap_user:string;
  // roles:string;
  // createdDate:Date;
  // lastModifiedDate:Date;
  // updatedBy:string;

}
export interface IUSerRole
{
    user_id:string;
    roles:any;
}

export interface IUser {
    id: number;
    user_id: string;
    user_name: string;
    phon_number: string;
    email_id: string;
    reporting_user_id: string;
    organizationid: string;
    is_ldap_user: string;
    roles: IRole[];
    createdDate: Date;
    lastModifiedDate: Date;
    updatedBy: string;
    groups: IGroup[];
}

export interface IPermissionResponse {
    permissionId: number;
    entityName: string;
    operationName: string;
    childName: string;
}

export interface IUsersResponse {
    status: boolean;
    message: string;
}

export interface AddPasswordPolicyData {
    id: string,
    title: string,
    minLength: string,
    maxLength: string,
    pwdMaxAge: string,
    pwdExpiryWarning: string,
    pwdMaxFailure: string,
    allowUserToChangeOwnPwd: string,
    isSpecialCharsAllowed: string,
    isUppercaseAllowed: string,
    isNumberAllowed: string,
    specialChars: string,
    createdOn: string,
    updatedOn: string,
    version: string,
    activeIndex: string,
    status: string,
    workflowStatus: string,
    activityComments: string,
    usePolicy: string
  }