import { Component, OnInit, Inject } from '@angular/core';
import { IReconProcessStatus, RollBackResponse } from '../../../models/recon.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RuleService } from '../../../services/rule.service';

@Component({
  selector: 'app-recon-roll-back',
  templateUrl: './recon-roll-back.component.html',
  styleUrls: ['./recon-roll-back.component.scss']
})
export class ReconRollBackComponent implements OnInit {

  reconProcessStatus: IReconProcessStatus;
  loading = false;

  constructor(public ruleService: RuleService,
    public dialogRef: MatDialogRef<ReconRollBackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.reconProcessStatus = data.reconProcessStatus;
  }

  ngOnInit() {
  }

  submitRollBack() {
    this.loading = true;
    this.ruleService.reconRollBack(this.reconProcessStatus).subscribe(
      (response: RollBackResponse) => {
        alert(response.message);
        this.loading = false;
        this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }
    );
  }

}
