import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

const TOKEN_HEADER_KEY = 'Authorization';
const TOKEN_PREFIX = 'Bearer ';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let authReq = req;
        const user = JSON.parse(localStorage.getItem('user'));
        if (user != null) {
            authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, TOKEN_PREFIX + user.token) });
        }
        return next.handle(authReq).pipe(catchError(error => {
            if (error.status === 403) {
                this.router.navigate(['/access-denied']);
            }
            else if (error.status === 401) {
                // alert("Your session has expired. Please login again");
                localStorage.clear();
                this.router.navigate(['']);
            }
            return throwError(error);
        }))
    }
}

export const tokenInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
];