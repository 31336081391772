import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-user-roles',
  templateUrl: './edit-user-roles.component.html',
  styleUrls: ['./edit-user-roles.component.scss']
})
export class EditUserRolesComponent implements OnInit {

  roles: any = {}
  userRoles: string[] = ['User Role 1', 'User Role 2'];

  model: any = {};
 
  submitted = false;
  constructor(public dialogRef: MatDialogRef<EditUserRolesComponent>) { }

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;   
    console.log('Submitted'); 
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
