import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { } from '../password-policy.component';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-edit-password-policy',
  templateUrl: './edit-password-policy.component.html',
  styleUrls: ['./edit-password-policy.component.scss']
})
export class EditPasswordPolicyComponent implements OnInit {
  modelData: any;
  EditpasswordPolicy: any;
  submitted = false;
  rowData: any;
  constructor(public dialogRef: MatDialogRef<EditPasswordPolicyComponent>, private formBuilder: FormBuilder,
    public userManagementService: UserManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.modelData = data[0];

    this.EditpasswordPolicy = this.formBuilder.group({
      id: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      minLength: new FormControl('', Validators.required),
      maxLength: new FormControl('', Validators.required),
      pwdMaxAge: new FormControl('', Validators.required),
      pwdExpiryWarning: new FormControl('', Validators.required),
      pwdMaxFailure: new FormControl('', Validators.required),
      allowUserToChangeOwnPwd: new FormControl('', Validators.required),
      isSpecialCharsAllowed: new FormControl('', Validators.required),
      isUppercaseAllowed: new FormControl('', Validators.required),
      isNumberAllowed: new FormControl('', Validators.required),
      specialChars: new FormControl('', Validators.required),
      activityComments: new FormControl('', Validators.required),
      usePolicy: new FormControl('', Validators.required)
    });
    if (this.modelData.isSpecialCharsAllowed == "No")
      this.EditpasswordPolicy.controls['specialChars'].disable();
  }

  ngOnInit() {


  }
  get fval() {
    return this.EditpasswordPolicy.controls;
  }
  isSpecialCharsAllowed(value) {
    console.log(value)
    if (value == "No") {
      this.EditpasswordPolicy.controls['specialChars'].disable();
      this.EditpasswordPolicy.get('specialChars').reset();
    }
    else
      this.EditpasswordPolicy.controls['specialChars'].enable();
  }
  loadAllPolicy() {
    this.userManagementService.loadAllPolicy().subscribe(
      (data) => {
        this.rowData = data.object;
      },
      error => {
        console.log(error);
      }
    );
  }
  savePasswordPolicy() {
    this.submitted = true;
    if (this.EditpasswordPolicy.invalid) {
      return;
    }
    else {
      var saveData = this.data;
      console.log("save data after edit", saveData);
      if (Number(saveData[0].maxLength) > Number(saveData[0].minLength)) {
        this.userManagementService.updatePolicy(saveData[0]).subscribe(
          (data) => {

            alert(data.message);
            this.dialogRef.close("true");
          },
          error => {
            console.log(error);
            alert(error.error.details);
          })
      }
      else {
        alert("Maximun Password length should be greater than Minimum password length");
      }

    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  checkMinLength() {
    if (this.EditpasswordPolicy.value.minLength == 0) {
      alert(" Minimum password length should not be zero");
      this.EditpasswordPolicy.get('minLength').reset();
    }
  }
  checkMaxLength() {
    if (this.EditpasswordPolicy.value.maxLength == 0) {
      alert("Maximun Password length should not be zero");
      this.EditpasswordPolicy.get('maxLength').reset();
    }

  }
}
