// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  debug:true,  
  
  //UAT DETAILS

// apiUrl: "http://wat-reco-app01t-v.otg.om:8080/auto-recon",
// reconUrl: "http://wat-reco-app01t-v.otg.om:8080/auto-recon",
// caseManagementUrl: "http://wat-reco-app01t-v.otg.om:8080/auto-recon",
// userManagementUrl: "http://wat-reco-app01t-v.otg.om:8080/auto-recon",
// reportingAndDashboardUrl: "http://wat-reco-app01t-v.otg.om:9545/intellicus",
// scheduler: "http://wat-reco-app01t-v.otg.om:8080/auto-recon"

    // fIDELITY SIT
    // apiUrl: "https://fedility-sit.ascentbusiness.com/auto-recon",
    // reconUrl: "https://fedility-sit.ascentbusiness.com/auto-recon",
    // caseManagementUrl: "https://fedility-sit.ascentbusiness.com/auto-recon",
    // userManagementUrl: "https://fedility-sit.ascentbusiness.com/auto-recon",
    // reportingAndDashboardUrl: "https://203.92.47.27:3023/intellicus",
    // scheduler: "https://fedility-sit.ascentbusiness.com/auto-recon"

//lOCAL HOST 
// apiUrl:"http://localhost:8080/auto-recon",
// 	reconUrl:"http://localhost:8080/auto-recon",
// 	caseManagementUrl:"http://localhost:8080/auto-recon",
// 	userManagementUrl:"http://localhost:8080/auto-recon",
// 	reportingAndDashboardUrl:"http://203.92.47.27:3023/intellicus",
// 	scheduler:"http://localhost:8080/auto-recon"


apiUrl:"https://ahlibank-sit.ascentbusiness.com/auto-recon",
	reconUrl:"https://ahlibank-sit.ascentbusiness.com/auto-recon",
	caseManagementUrl:"https://ahlibank-sit.ascentbusiness.com/auto-recon",
	userManagementUrl:"https://ahlibank-sit.ascentbusiness.com/auto-recon",
	reportingAndDashboardUrl:"https://203.92.47.27:3023/intellicus",
	scheduler:"https://ahlibank-sit.ascentbusiness.com/auto-recon"
};
