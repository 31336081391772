import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { UpdateCommentService } from '../../services/update-comment.service';

@Component({
  selector: 'child-cell',
  template: `<span><button type="button" style="height: 20px" (click)="invokeParentMethod()" class="btn btn-info">Download</button></span>`,
  styles: [
    `.btn {
          line-height: 0.5
      }`
  ]
})

export class DownloadFileComponent implements ICellRendererAngularComp {

  public params: any;

  constructor(public updateCommentService: UpdateCommentService) { }

  agInit(params: any): void {
    this.params = params;
  }

  public invokeParentMethod() {
    if (this.params.node.data.fileName == null) {
      alert("File has not attached for this closed case");
    }
    else {
      this.updateCommentService.downloadFile(this.params.node.data.sno).subscribe(
        (file: any) => {
          window.open(file.url);
        },
        error => {
          alert("Download failed");
          console.log(error);
        }
      );
    }
  }

  refresh(): boolean {
    return false;
  }

}
