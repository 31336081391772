import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../loader.service';
import { ThemePalette } from '@angular/material';

@Component({
  selector: 'app-mat-loader',
  templateUrl: './mat-loader.component.html',
  styleUrls: ['./mat-loader.component.scss']
})
export class MatLoaderComponent implements OnInit {
  
  color: ThemePalette = 'primary';

  Loader:boolean;

  constructor(private loaderService: LoaderService) {
 
    this.loaderService.isLoading.subscribe((x) => {
      //console.log('loader works', x);
      this.Loader = x;
    });
 
  }

  ngOnInit(): void {
  }

}
