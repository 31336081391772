import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettelementRoutingModule } from './settelement-routing.module';
import { SettelementComponent } from './settelement.component';
import { SettelementAddComponent } from './settelement-add/settelement-add.component';
import { SettelementListComponent } from './settelement-list/settelement-list.component';
import { MaterialModule } from 'src/app/shared/modules/material-module';
import { AgGridModule } from 'ag-grid-angular';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectCycleComponent } from './settelement-list/select-cycle/select-cycle.component';

import { GlBalancingComponent } from './gl-balancing/gl-balancing.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [SettelementComponent, SettelementAddComponent, SettelementListComponent, SelectCycleComponent, GlBalancingComponent],
  imports: [
    CommonModule,
    SettelementRoutingModule,
    MaterialModule,
    FormsModule,
    AgGridModule.withComponents([]),
    NgbModule,
    ReactiveFormsModule
  ],
  entryComponents:[
    SelectCycleComponent
  ]
})
export class SettelementModule { }
