import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { EscalationMatrixService } from '../../../services/escalation-matrix.service';
import { DialogData1 } from '../../../models/escalationmatrix.model';

@Component({
  selector: 'app-edit-esc-matrix',
  templateUrl: './edit-esc-matrix.component.html',
  styleUrls: ['./edit-esc-matrix.component.scss']
})
export class EditEscMatrixComponent implements OnInit {
  local_data: any;
  submitted = false;
  model: any = {};
 
  constructor(public dialogRef: MatDialogRef<EditEscMatrixComponent> ,public escalationServic: EscalationMatrixService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData1) {
      this.local_data = data[0];
      console.log("Edit Constructor --> ", data);
     }

     ngOnInit() {
    }
    //for Update Escalation 
    saveEscMatrixForm(){
      this.submitted = true;
      var saveData = this.data;
      console.log("savedata",saveData);
      this.escalationServic.updateEscalation(saveData[0]).subscribe(
        (activityResponse:any)=>{

          alert(activityResponse.message);
        },
        error => {
          console.log(error);
        }  )   
     
    }
}
