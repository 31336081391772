import { Component, OnInit } from '@angular/core';
import { MasterTableService } from '../master-setup/services/master-setup.service';
import { ILoginResponse } from '../../login/login.model';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  url: string;

  loginResponse:ILoginResponse;
  userName: string;
  moduleId:string;
  moduleName:string;

  constructor(public masterTableService:MasterTableService) { }

  ngOnInit() {
    this.loginResponse = JSON.parse(localStorage.getItem('user'));
    console.log("Login Response UserName--> ", this.loginResponse.userName);
    this.userName = this.loginResponse.userName;

    this.moduleId = localStorage.getItem('reconModuleId');
    this.moduleName= localStorage.getItem('reconModuleName');

    this.launchReport();
  }

  launchReport(){
    console.log("Current UserName & Module Name --> ", this.userName, this.moduleName);
    this.masterTableService.launchReport(this.userName, this.moduleName).subscribe(
      data=>{
        for (const [key, value] of Object.entries(data)) {
          this.url = value;
        }
        console.log(this.url);
    })
}

}
