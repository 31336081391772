import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgGridColumnsService } from '../../services/ag-grid-columns.service';

@Component({
  selector: 'app-case-data',
  templateUrl: './case-data.component.html',
  styleUrls: ['./case-data.component.scss']
})
export class CaseDataComponent implements OnInit {

  sourceName: string;
  columnDefs: any;
  rowData: any = [];

  constructor(public agGridColumns: AgGridColumnsService,
    public dialogRef: MatDialogRef<CaseDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.sourceName = data.caseData.tableName
    this.columnDefs = this.agGridColumns.generateCaseColumns(data.caseData.data);
    this.rowData = data.caseData.data;
  }

  ngOnInit() {
  }

}
