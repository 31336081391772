import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { RollBackResponse } from '../models/etl.model';

@Injectable({
  providedIn: 'root'
})
export class EtlService {
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }
  getTemplateData1(id:number){
    return this.http.post(environment.apiUrl+'/loadAllTemplat.ascent?id='+id, this.httpOptions);
  }
 
  getFileNameData(ext:string,moduleId:number){
      return this.http.post(environment.apiUrl+'/loadAllTemplatByExt.ascent?ext='+ext+"&moduleId="+moduleId, this.httpOptions);

  }
  getFileData(){
    return this.http.post(environment.apiUrl+'/loadAllFiles.ascent', this.httpOptions);
  }
  
getTemplateData(id:number){
  return this.http.post(environment.apiUrl+'/loadAllTemplat.ascent?id='+id, this.httpOptions);
}


getFileNameBasedOnExtension(ext:string){
  return this.http.post(environment.apiUrl+'/loadAllTemplatByExt.ascent?ext='+ext, this.httpOptions);
}

getFileNameData1(){
  return this.http.post(environment.apiUrl+'/loadAllFiles.ascent', this.httpOptions);
}
getTemplateName(id:number):Observable<any[]>{
return this.http.post<any>(environment.apiUrl+'/loadAllTemplat.ascent?id='+id, this.httpOptions);
}
getFieldConfig(id:number){
  return this.http.post(environment.apiUrl+'/loadFieldsMapToDatatable.ascent?id='+id, this.httpOptions);
}

getDeleteFileConfigData(id:number){
  return this.http.post(environment.apiUrl+'/deleteFileConfigData.ascent?id='+id, this.httpOptions);
}

getTemplateGroupID(selectTempletid1:number,source:string)
{
 return this.http.post(environment.apiUrl+'/loadGroupID.ascent?selectTempletid1='+selectTempletid1+'&source='+source, this.httpOptions);
}
getCleaningTableData(id:string)
{

return this.http.post(environment.apiUrl+'/loadCleaningConfigData.ascent?id='+id, this.httpOptions);
}

getValidationTableData(id:string)
{
return this.http.post(environment.apiUrl+'/loadValidationConfigData.ascent?id='+id, this.httpOptions);
}


getFormattingTableData(id:string)
{
return this.http.post(environment.apiUrl+'/loadFormattingConfigData.ascent?id='+id, this.httpOptions);
}

getJavaSplitTableData(id:string)
{ 
return this.http.post(environment.apiUrl+'/loadJavaSplitConfigData.ascent?id='+id, this.httpOptions);
}

getAddMetaDataTableData(id:string)
{ 
return this.http.post(environment.apiUrl+'/loadMetaDataConfigData.ascent?id='+id, this.httpOptions);
}
getTemplateColumnNameData(selectTempletid1:string)
{ 
  
return this.http.post(environment.apiUrl+'/loadAllTemplatColumns.ascent?id='+selectTempletid1, this.httpOptions);
}

getDuplicateCheckDataElements(id:string)
{ 
 return this.http.post(environment.apiUrl+'/duplicateCheckDataElements.ascent?id='+id, this.httpOptions);
}
getDeletedDuplicateCheck(id:string)
 { 
  return this.http.post(environment.apiUrl+'/deletedDuplicateCheck.ascent?id='+id, this.httpOptions);
 }
 getRunDuplicateCheck(selectData:any,templetId:string)
 { 
 return this.http.post(environment.apiUrl+'/runDuplicateCheck.ascent?selectData='+encodeURI(JSON.stringify(selectData))+"&templetId="+templetId, this.httpOptions);
}

//Save file configurations
saveConfigurationDetails(data:any)
{
  return this.http.post(environment.apiUrl+'/saveConfigurationDetails.ascent?action='+data.action+"&fileConfiguration="+encodeURI(JSON.stringify (data.fileConfiguration)),this.httpOptions);
}

//Save  edit file configurations
editConfigurationDetails(data:any)
{
  return this.http.post(environment.apiUrl+'/saveConfigurationDetails.ascent?action='+data.action+"&fileConfiguration="+encodeURI(JSON.stringify (data.fileConfiguration)),this.httpOptions);
}

//Save validation configurations
saveValidationConfig(data:any) 
{
  console.log(" In validation etl service",data);
  
  //return this.http.post(environment.apiUrl+'/saveValidationConfig.ascent?configModal='+encodeURI(JSON.stringify (data.configModal)),this.httpOptions);

  return this.http.post(environment.apiUrl+'/saveValidationConfig.ascent?cleaningSelectOperationCategory='+data.cleaningSelectOperationCategory
  +"&validationSelectColumnName="+data.validationSelectColumnName
  +"&validationSelectOperationCategory="+data.validationSelectOperationCategory
  +"&validationSelectOperation="+data.validationSelectOperation
  +"&startsWith="+data.startsWith
  +"&endsWith="+data.endsWith
  +"&containsConstants="+data.containsConstants
  +"&patternCheck="+data.patternCheck
  +"&lengthCheck="+data.lengthCheck
  +"&lengthType="+data.lengthType
  +"&validationSelectTempletGroupId="+data.validationSelectTempletGroupId
  +"&validationSelectTempletid1="+data.validationSelectTempletid1,  
  this.httpOptions);
}

  // saveFeildsConfig(data: any){
    // return this.http.post(environment.apiUrl+'/fieldSaveaNGNew.ascent', data, this.httpOptions);
  // }

  saveFeildsConfig(data: any){
    return this.http.post(environment.apiUrl+'/fieldSaveaNG.ascent?data='+encodeURI(JSON.stringify(data)), this.httpOptions);
  }
//Save Cleaning configurations
  saveCleaningConfig(data:any)
  {
     
    return this.http.post(environment.apiUrl+'/saveCleaningConfig.ascent?cleaningSelectOperationCategory='+data.cleaningSelectOperationCategory
  +"&cleaningSelectColumnName="+data.cleaningSelectColumnName
  +"&cleaningSelectOperationCategory="+data.cleaningSelectOperationCategory
  +"&selectOperation="+data.selectOperation
  +"&replaceNullBy="+data.replaceNullBy
  +"&maskingCharacter="+encodeURI(data.maskingCharacter)
  +"&maskingFromPos="+data.maskingFromPos
  +"&maskingToPos="+data.maskingToPos
  +"&replaceCharacters="+data.replaceCharacters
  +"&replaceCharactersBy="+data.replaceCharactersBy
  +"&removingCharacters="+data.removingCharacters
  +"&selectTempletGroupId="+data.selectTempletGroupId
  +"&selectTempletid1="+data.selectTempletid1,  
  this.httpOptions);

  }
  saveFormattingConfig(data:any)
  {
 
    return this.http.post(environment.apiUrl+'/saveFormattingConfig.ascent?cleaningSelectOperationCategory='+data.cleaningSelectOperationCategory
  +"&formattingSelectTempletid1="+data.formattingSelectTempletid1
  +"&formattingSelectTempletGroupId="+data.formattingSelectTempletGroupId
  +"&formattingSelectColumnName="+data.formattingSelectColumnName
  +"&formattingSelectOperationCategory="+data.formattingSelectOperationCategory
  +"&formattingSelectOperation="+data.formattingSelectOperation
  +"&formatingDate="+data.formatingDate
  +"&dateTypeSelectOperation="+data.dateTypeSelectOperation
  +"&timeFormat="+data.timeFormat
  +"&inputFormat="+data.inputFormat
  +"&outputFormat="+data.outputFormat
  +"&timeDateTypeSelectOperation="+data.timeDateTypeSelectOperation,  
  this.httpOptions);
  }

  getEditFormattingConfig(configData:any)
{
  
return this.http.post(environment.apiUrl+'/updateFormattingConfig.ascent?FOR_OP_ID='+configData.editSelectedData.FOR_OP_ID
+'&cleaningSelectOperationCategory='+configData.editSelectedData.OPERATION_CATEGORY
+'&formattingSelectColumnName='+configData.editSelectedData.COLUMN_NAME
+'&formattingSelectOperation='+configData.editSelectedData.OPERATION_NAME
+'&formattingSelectOperationCategory='+configData.editSelectedData.OPERATION_CATEGORY
+'&formattingSelectTempletGroupId='+configData.editSelectedData.TEMP_GROUP_ID
+'&formattingSelectTempletid1='+configData.editSelectedData.TEMPLET_ID
+'&formattingSelectTransFileName='+configData.fileId
+'&formatingDate='+configData.editSelectedData.DATE_FORMAT
+'&dateTypeSelectOperation='+configData.editSelectedData.DATE_TYPE
+'&timeFormat='+configData.editSelectedData.TIME_FORMAT
+'&inputFormat='+configData.editSelectedData.INPUT_FORMAT
+'&outputFormat='+configData.editSelectedData.OUTPUT_FORMAT
+'&timeDateTypeSelectOperation='+configData.editSelectedData.DATETIME_TYPE, this.httpOptions)


}

loadTemplateEdit(data: string) {
  return this.http.post(environment.apiUrl+'/loadTemplateEdit.ascent?key='+data, this.httpOptions);
}
getReadConfiguredFile(fileId:any,tempId:any,value:any,moduleId,moduleName){
  return this.http.post(environment.apiUrl+'/readConfiguredFile.ascent?templetId='+fileId+'&testFileName='+fileId+'&testTempletName='+tempId+'&orderByColNames='+value+'&moduleId='+moduleId+'&moduleName='+moduleName, this.httpOptions); 
}
transformConfiguredFile(fileId:any,tempId:any,value:any,moduleId,moduleName){
  return this.http.get(environment.apiUrl+'/runTransOperation.ascent?templetId='+fileId+'&testFileName='+fileId+'&testTempletName='+tempId+'&orderByColNames='+value+'&moduleId='+moduleId+'&moduleName='+moduleName, this.httpOptions); 
}



getEditCleaningConfig(configData:any){
  
return this.http.post(environment.apiUrl+'/updateCleaningConfig.ascent?CL_OP_ID='+configData.editSelectedData.CL_OP_ID
+'&cleaningSelectOperationCategory='+configData.editSelectedData.OPERATION_CATEGORY
+'&cleaningSelectColumnName='+configData.editSelectedData.COLUMN_NAME
+'&selectOperation='+configData.editSelectedData.OPERATION_NAME
+'&selectTempletGroupId='+configData.editSelectedData.TEMP_GROUP_ID
+'&selectTempletid1='+configData.editSelectedData.TEMPLET_ID
+'&replaceNullBy='+configData.editSelectedData.REPLACE_NULL_BY
+'&maskingCharacter='+configData.editSelectedData.MASKING_CHARACTER
+'&maskingFromPos='+configData.editSelectedData.MASKING_FROMPOS
+'&maskingToPos='+configData.editSelectedData.MASKING_TOPOS
+'&replaceCharacters='+configData.editSelectedData.REPLACE_CHARACTER
+'&replaceCharactersBy='+configData.editSelectedData.REPLACE_CHARACTER_BY
+'&removingCharacters='+configData.editSelectedData.REMOVE_CHARACTERS, this.httpOptions)
}

getEditMetaDataConfig(configData:any)
{
  
return this.http.post(environment.apiUrl+'/updateMetaData.ascent?CL_MT_ID='+configData.editSelectedData.CL_MT_ID
+'&fieldName='+configData.editSelectedData.FIELD_NAME
+'&fieldType='+configData.editSelectedData.FIELD_TYPE
+'&fieldFormat='+configData.editSelectedData.FIELD_FORMAT
+'&fieldSize='+configData.editSelectedData.FIELD_SIZE
+'&mandatory='+configData.editSelectedData.MANDATORY
+'&description='+configData.editSelectedData.DESCRIPTION
+'&OPERATION_CATEGORY='+configData.editSelectedData.OPERATION_CATEGORY
+'&metaDataTempletName='+configData.editSelectedData.TEMPLET_ID
+'&metaDataTempGroupId='+configData.editSelectedData.TEMP_GROUP_ID, this.httpOptions)
}

getEditValidationConfig(configData:any)
{
  
return this.http.post(environment.apiUrl+'/updateValidationConfig.ascent?VAL_OP_ID='+configData.editSelectedData.VAL_OP_ID
+'&validationSelectOperationCategory='+configData.editSelectedData.OPERATION_CATEGORY
+'&validationSelectColumnName='+configData.editSelectedData.COLUMN_NAME
+'&validationSelectOperation='+configData.editSelectedData.OPERATION_NAME
+'&validationSelectTempletGroupId='+configData.editSelectedData.TEMP_GROUP_ID
+'&validationSelectTempletid1='+configData.editSelectedData.TEMPLET_ID
+'&startsWith='+configData.editSelectedData.STARTS_WITH
+'&endsWith='+configData.editSelectedData.ENDS_WITH
+'&containsConstants='+configData.editSelectedData.CONTAINS_CONSTANTS
+'&patternCheck='+configData.editSelectedData.PATTERN_CHECK
+'&lengthCheck='+configData.editSelectedData.LENGTH_CHECK
+'&lengthType='+configData.editSelectedData.LENGTH_TYPE, this.httpOptions)
}

//saveSqlSplitConfig

saveSqlSplitConfig(data:any)
{
  console.log("Split data : ",data);
 
  return this.http.post(environment.apiUrl+'/saveSqlSplitConfig.ascent?sqlSplitOperationCategory='+data.sqlSplitOperationCategory
+"&sqlSplitFileName="+data.sqlSplitFileName
+"&sqlSplitSelectTemplet="+data.sqlSplitSelectTemplet
+"&sqlSplitTempletGroupId="+data.sqlSplitTempletGroupId
+"&queryName="+data.queryName
+"&queryNameText="+ data.queryNameText
+"&sqlSplitOperationCategoryQuery="+encodeURI(data.sqlSplitOperationCategoryQuery)
+"&sqlSplitDescription="+data.sqlSplitDescription,  
this.httpOptions);
}

//editSqlSplitConfig

editSqlSplitConfig(data:any)
{
  console.log("Split data : ",data);
 
  return this.http.post(environment.apiUrl+'/updateSqlSplitConfig.ascent?sqlSplitOperationCategory='+data.sqlSplitOperationCategory
+"&sqlSplitFileName="+data.sqlSplitFileName
+"&sqlSplitSelectTemplet="+data.sqlSplitSelectTemplet
+"&sqlSplitTempletGroupId="+data.sqlSplitTempletGroupId
+"&queryName="+data.queryName
+"&queryNameText="+data.queryNameText
+"&sqlSplitOperationCategoryQuery="+data.sqlSplitOperationCategoryQuery
+"&sqlSplitDescription="+data.sqlSplitDescription
+"&SPLIT_ID="+data.SPLIT_ID,  
this.httpOptions);
}


deleteSqlSplitConfig(data:any)
{
  return this.http.post(environment.apiUrl+'/deleteSqlSplitConfig.ascent?SPLIT_ID='+data.SPLIT_ID,  
this.httpOptions);
}



getETLProcessStatusData(ext:string,moduleId:any): Observable<any[]>{
  console.log(ext)
  return this.http.get<any[]>(environment.apiUrl + '/getETLProcessStatusData.ascent?ext=' + ext+'&moduleId='+moduleId, this.httpOptions);
}

//Save Add meta data configurations
saveMetaDataFormConfig(data:any) 
{ 
  return this.http.post(environment.apiUrl+'/saveMetaDataFormConfig.ascent?cleaningSelectOperationCategory='+data.cleaningSelectOperationCategory
  +"&metaDataTempletName="+data.metaDataTempletName
  +"&metaDataTempGroupId="+data.metaDataTempGroupId
  +"&fieldName="+data.fieldName
  +"&fieldType="+data.fieldType
  +"&fieldFormat="+data.fieldFormat
  +"&fieldSize="+data.fieldSize
  +"&mandatory="+data.mandatory
  +"&description="+data.description,  
  this.httpOptions);
}


deleteMetaDataConfig(colId:string){
  console.log(colId);
  return this.http.post(environment.apiUrl + '/deleteMetaDataConfig.ascent?id=' + colId, this.httpOptions);
}

createMetaDataColumn(tempId:any,groupId:any){
  
  console.log("in etl service");
  console.log("template id    :",tempId,"      group id       :",groupId);
  return this.http.post(environment.apiUrl + '/createMetaDataCols.ascent?metaDataTempletName='+tempId+'&metaDataTempGroupId='+groupId,this.httpOptions);
}


deleteCleaningConfig(colId:string){
  console.log(colId);
  return this.http.post(environment.apiUrl + '/deleteCleaningConfig.ascent?CL_OP_ID=' + colId, this.httpOptions);
}
deleteValidConfig(colId:string){
  console.log(colId);
  return this.http.post(environment.apiUrl + '/deleteValidationConfig.ascent?selectTempletid1=' + colId, this.httpOptions)

}
deleteformatConfig(colId:string){
  console.log(colId);
  return this.http.post(environment.apiUrl + '/deleteFormattingConfig.ascent?selectTempletid1=' + colId, this.httpOptions)

}

getloadConfiguredQueryNames(id){
  return this.http.post(environment.apiUrl + '/loadConfiguredQueryNames.ascent?queryId=' + id, this.httpOptions)

}
getloadConfiguredQuerieData(queryName,id){
  return this.http.post(environment.apiUrl + '/loadConfiguredQuerieData.ascent?queryId='+id+"&queryName="+queryName, this.httpOptions)

}
 

checkAging(tempName:any)
{
  return this.http.post(environment.apiUrl+'/checkAging.ascent?tempName='+tempName,this.httpOptions);
}

rollBackByFile(etlProcessId:number,templateName:string,fileName:string): Observable<RollBackResponse>
{
  return this.http.delete<RollBackResponse>(environment.apiUrl+'/rollBackByFile.ascent?etlProcessId='+etlProcessId+'&templateName='+templateName+'&fileName='+fileName,this.httpOptions);
}

checkStagingTable(id:any)
{ 
 return this.http.post(environment.apiUrl+'/checkStagingTable.ascent?id='+id, this.httpOptions);
}

loadETLView(moduleName:any){
  return this.http.get<any>(environment.apiUrl + '/etlview/' + moduleName, this.httpOptions);
}

getFileNameDataByModule(moduleId:any, currentPage:string){
  return this.http.post(environment.apiUrl+'/loadAllFiles.ascent?moduleId='+moduleId+'&currentPage='+currentPage, this.httpOptions);
}

getFileType(currentPage:any){
  return this.http.get(environment.apiUrl+'/fileType/'+currentPage, this.httpOptions);
}

mergeData(reconModuleId:number, fileId:number, tempId:number){
  return this.http.get(environment.apiUrl+'/mergeData.ascent/'+reconModuleId+'/'+fileId+'/'+tempId, this.httpOptions);
}

splitData(reconModuleId:number, fileId:number, tempId:number){
  return this.http.get(environment.apiUrl+'/splitData.ascent/'+reconModuleId+'/'+fileId+'/'+tempId, this.httpOptions);
}

fileUpload(file:any){
  return this.http.post(environment.apiUrl+'/fileUpload/', file, { reportProgress: true, responseType: 'text'});
}

getViewData(currentPage:string){
  return this.http.get(environment.apiUrl+'/clientDbReader/'+currentPage, this.httpOptions);
}

loadDataTransferProcess(viewName: string, dateFeild: string, fromDate: string, toDate: string) {
  return this.http.get(environment.apiUrl + '/clientDbReadernew/'+viewName+'/'+dateFeild+'/'+fromDate+'/'+toDate, this.httpOptions);
}

}