import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { IActivity } from '../models/activity-manager.model';
import { Observable } from 'rxjs';
import { IActivityResponse } from '../../recon/models/recon.model';

@Injectable({
  providedIn: 'root'
})

export class ActivityManagerService {

  constructor(private http: HttpClient) { }

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    loadActivityForUser(moduleName:any) : Observable<IActivity[]> {
      return this.http.get<IActivity[]>(environment.reconUrl+'/loadActivityForUser.ascent/'+moduleName, this.httpOptions);
    }

    persistActivity(activity:IActivity,submitType:string,checkerComments:string) : Observable<IActivityResponse> {
      return this.http.post<IActivityResponse>(environment.reconUrl+'/persistActivity.ascent?submitType='+submitType+'&checkerComments='+checkerComments, activity, this.httpOptions);
    }

}
