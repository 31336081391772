import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IGroup } from 'src/app/modules/parent/user-management/models/user-management.model';
import { environment } from 'src/environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class FrameworkService {
 
  constructor(private http: HttpClient) { }
 
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
 
  loadUserPermissions(): Observable<IGroup[]> {
    return this.http.get<IGroup[]>(environment.userManagementUrl + '/v1/user/groups/read/'+JSON.parse(localStorage.getItem('user')).id, this.httpOptions);
  }

  getIdByReconModuleName(reconModuleName): Observable<any> {
    return this.http.get<any>(environment.reconUrl+ '/getIdByReconModuleName.ascent/'+reconModuleName, this.httpOptions);
  }

}