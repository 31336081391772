import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { FrameworkService } from './framework.service';
import { IUser, IEntity, IOperation, IGroup } from '../user-management/models/user-management.model';


@Component({
  selector: 'app-framework',
  templateUrl: './framework.component.html',
  styleUrls: ['./framework.component.scss'],
  animations: [
    trigger('ngIfAnimation', [
      transition(':enter, :leave', [])
    ]),
    trigger('easeInOut', [
      transition('void => *', [
        style({
          opacity: 0
        }),
        animate("500ms ease-in", style({
          opacity: 1
        }))
      ]),
      transition('* => void', [
        style({
          opacity: 1
        }),
        animate("500ms ease-in", style({
          opacity: 0
        }))
      ])
    ])
  ]
})
export class FrameworkComponent implements OnInit {

  reconModuleList: IGroup[];
  public show: boolean = false;

  toggle() {
    this.show = !this.show;
  }

  constructor(public frameworkService: FrameworkService) { }

  ngOnInit() {
    this.loadUserPermissions();
  }

  loadUserPermissions() {
    this.reconModuleList = [];
    this.frameworkService.loadUserPermissions().subscribe(
      (reconModuleList: IGroup[]) => {
        this.reconModuleList = reconModuleList;
      },
      error => {
        console.log(error);
      }
    );
  }

  onClick(selectedReconModule: IOperation){
    localStorage.setItem('reconModuleName', selectedReconModule.name);
    this.frameworkService.getIdByReconModuleName(selectedReconModule.name).subscribe(
      (reconModuleId: any) => {
        reconModuleId;
        localStorage.setItem('reconModuleId', reconModuleId);
      },
      error => {
        console.log(error);
      }
    );

  }

}
