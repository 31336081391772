import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddUserRolesComponent } from './add-user-roles/add-user-roles.component';
import { EditUserRolesComponent } from './edit-user-roles/edit-user-roles.component';
import { UserManagementService } from '../services/user-management.service';
import { IRole, IUsersResponse } from '../models/user-management.model';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit {
  public rowData: any;
  public columnDefs: any;

  myTempRowData: any[] = [];
  roles: IRole[] = [];

  private gridApi: any;
  public rowSelection: any;

  constructor(public dialog: MatDialog, public userManagementService: UserManagementService) {
    this.rowSelection = "single";
  }

  ngOnInit() {
    this.getUserRolesData();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  addUserDetails() {
    const dialogRef = this.dialog.open(AddUserRolesComponent, {
      width: '500px',
      panelClass: 'mat-modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      // let flag: boolean = false;
    
      // for(let data of this.rowData){
      //   console.log(data.userId );
      //   console.log(result.users );
      //   console.log(data.roleId  );
      //   console.log(result.roles );
      //   if(data.userId == result.users && data.roleId == result.roles) {
      //     flag = true;
      //     break;
      //   }
      // }
  
      // if(flag) {
      //   alert(" Record already exists");
      // }
      // else{
        this.userManagementService.assignRolesToUser(result).subscribe(
          screenResponse => {
            alert(screenResponse.message);
     
          },
          error => {
            console.log(error);
          }
     
        );
      }
    );
  }


  editUserDetails() {
    const dialogRef = this.dialog.open(EditUserRolesComponent, {
      width: '500px',
      panelClass: 'mat-modal'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  deleteUserDetails() {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData == "") {
      alert("Please select a record to delete");
    } else if (selectedData !== "") {
      if (confirm("Do you want to delete this record")) {
        var userId = selectedData[0].id;
        this.userManagementService.deleteAssignedRolesToUser(userId).subscribe(
          (userResponse: IUsersResponse) => {
            alert(userResponse.message);
            this.gridApi.updateRowData({ remove: selectedData });
          },
          error => {
            console.log(error);
          });
      }
    }
  }

  private _columnDefsUserRoledata = [
    { width:200,headerName: 'User Id', field: 'user_id', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { width:200,headerName: 'Roles', field: 'roles', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },

  ];

  public get columnDefsUserRoledata() {
    return this._columnDefsUserRoledata;
  }
  public set columnDefsUserRoledata(value) {
    this._columnDefsUserRoledata = value;
  }



  //getting users records 
  getUserRolesData() {
    this.rowData = [];
    this.myTempRowData = [];
    this.userManagementService.getUserRolesData().subscribe(
      data => {
        // var dummyData: any = [];
        // dummyData = data;
        // let tempObj = {};
        // dummyData.forEach(element => {

        //   let map = new Map(Object.entries(element));

        //   for (let [key, value] of map) {
        //     if (value === undefined || value === "undefined") {
        //       tempObj[key] = "NA";
        //     } else {
        //       if (key == 'roles') {
        //         this.roles = value;
        //         if (this.roles.length == 0) {
        //           tempObj[key] = value;
        //         } else {
        //           this.roles = value;
        //           tempObj[key] = this.roles[0].name;
        //         }
        //       } else {
        //         tempObj[key] = value;
        //       }
        //     }
        //   }
        //   this.myTempRowData.push(tempObj);
        //   tempObj = {};
        // });

        let tempObj = {};
        data.forEach(user => {
          if(user.roles.length != 0){
            tempObj['id'] = user.id;
            tempObj['user_id'] = user.user_id;
            tempObj['roles'] = user.roles[0].name;
            this.myTempRowData.push(tempObj);
            tempObj = {};
          }
        });
        
        this.rowData = [...this.myTempRowData];

        if (this.rowData) {
          this.columnDefs = this.columnDefsUserRoledata;
        };
        //        this.columnDefs = this.generateColumns(this.rowData);
      }
    );
  }
  onPageSizeChanged(newPageSize) {
    const element = document.getElementById('page-size') as HTMLInputElement
    const value = element.value
    this.gridApi.paginationSetPageSize(Number(value));
  }
}
