import { Component, OnInit } from '@angular/core';
import { UserManagementService } from '../services/user-management.service';
import { IRole, IEntity, IEntityOperation, IPermission, IOperation, IChild, IPermissionResponse, IUsersResponse } from '../models/user-management.model';
import { MatOptionSelectionChange, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent implements OnInit {
  test: Object = new Object();
  // operations: string[] = ['Operation 1', 'Operation 2'];
  lists: string[] = ['List 1', 'List 2'];
  submitted = false;
  model: any = {};
  currentEntity;
  role;
  roles: IRole[] = [];
  entities: IEntity[] = [];
  operations : IOperation[] = [];
  childs : IChild[] = [];
  entityOperations: IEntityOperation[] = [];
  permissions: IPermission[] = [];
  selected = [];
  selectedEntityName: string;
  
 
  entity: IEntity = {} as IEntity;
  operationData: IOperation = {} as IOperation;

  private gridApi;
  private gridColumnApi;
  public rowSelection: any;

  public rowData: any;

  addPermissionForm:any;
  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;

  private defaultColDef;
  private getRowHeight;

  //public columnDefs: any;
  columnDefs = [
    { headerName: 'PermissionId Id', field: 'permissionId', pinned: 1, filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true, maxWidth: 110},
    { headerName: 'Entity Name', field: 'entityName', pinned: 2, filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true,maxWidth: 150},
    { headerName: 'Operation Name', field: 'operationName', cellStyle: { 'white-space': 'normal' }, pinned: 3, resizable: true, filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true,minWidth: 400 },
    { headerName: 'Child Name', field: 'childName', cellStyle: { 'white-space': 'normal' }, pinned: 4, resizable: true, filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true,minWidth: 615 }
    ];


  constructor(public userManagementService: UserManagementService,private formBuilder: FormBuilder,) {
    this.rowSelection = "single";
    this.overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>'
    this.overlayNoRowsTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>';

    this.getRowHeight = function(params) {
      if(params.data.childName.length <= 100) return 35 
      else  return params.data.childName.length / 3
    };

    this.addPermissionForm =this.formBuilder.group({
      role:new FormControl('',Validators.required),
      entityType:new FormControl('',Validators.required),
      selected_operation:new FormControl('',Validators.required),
      selected_child:new FormControl('',Validators.required),
   
   });

  }

  ngOnInit() {
    this.loadRoles();
    this.loadEntity();
  }

  get fval() {
    return this.addPermissionForm.controls;
  }

  loadRoles() {
    this.userManagementService.getActiveRoleMyOrg().subscribe(
      response => {
        this.roles = response;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadEntity() {
    this.userManagementService.getEntityMyOrg().subscribe(
      response => {
        this.entities = response;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadPermissionByRole(value: any) {
    // this.model.entityType=[];
    // this.selected_operation=[];
    // this.selected_child=[];
    this.role = value;
    this.userManagementService.getPermissionsByRole(this.role).subscribe(
      (permissionResponse: IPermissionResponse[]) => {
        this.rowData = permissionResponse;
      },
      error => {
        console.log(error);
      }
    );
  }

  entityPermission(value) {
    this.selected_operation=[];
 this.selected_child=[];
    this.currentEntity = value;
    for (let j = 0; this.entities.length; j++) {
      this.entity = this.entities[j];
      if (this.entity.id == value) {
        this.selectedEntityName = this.entity.name;
        this.operations = this.entity.operations;
      }
    }
  }

  loadEntityOperation() {
    
 this.selected_child=[];
    this.userManagementService.getEntityOperationMyOrg().subscribe(
      response => {
        this.entityOperations = response;
      },
      error => {
        console.log(error);
      }
    );
  }

  exam_title;
  selected_operation;
  educationLevelChangeAction(education) {
    this.exam_title = "";
    this.childs = [];
    if(education.length==0){
      return;
    }
    this.exam_title = education;

    for (let j = 0; this.entities.length; j++) {
      this.entity = this.entities[j];
      if (this.entity.id == this.currentEntity) {
        this.operations = this.entity.operations;
        for (let k = 0; this.operations.length; k++) {
          this.operationData = this.operations[k];
          for (let data of this.exam_title) {
            if(this.operationData.id == data){
              var local = this.operationData.childs;
              this.childs.push(...local);
            }
          }        
        }
      }
    }
  }
  exam_title_child;
  selected_child;
  educationLevelChangeAction1(education) {
    this.exam_title_child = "";
    this.exam_title_child = education;
  }


  onItemSelect(item: any) {
    this.selected = [];
    this.selected.push(item);
  }

  optionSelected(event: any) {
  }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key,
            field: key,
            sortable: true,
            filter: true
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }

  

  onGridReady(params: { api: { sizeColumnsToFit: () => void; }; columnApi: any; }) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
  }
 
  addPermission() {
    if(this.currentEntity !=541 ){
      if(this.currentEntity!=543){
          this.submitted = true;
          if (this.addPermissionForm.invalid) {
            return;
           }
        }
    }
   
    // this.operations = [];
    // this.childs = [];
    this.submitted=false;
    // console.log("Role --> ", this.role);
    // console.log("Entity --> ", this.currentEntity);
    // console.log("Operations --> ", this.exam_title);
    // console.log("Childs --> ", this.exam_title_child);

    let flag: boolean = false;
    for(let entity of this.rowData){
      if(entity.entityName == this.selectedEntityName) {
        flag = true;
        break;
      }
    }

    if(flag) {
      alert("Entity Type permission already present");
    }
    else {
      let map = new Map();

      for (let j = 0; j < this.entities.length; j++) {
        if (this.entities[j] !== undefined) {
          if (this.entities[j].id == this.currentEntity) {
            this.operations = this.entities[j].operations;
            for (let k = 0; k < this.operations.length; k++) {
              for (let data_opeartion_id of this.exam_title) {
                if (this.operations[k] !== undefined) {
                  if (data_opeartion_id == this.operations[k].id) {
                    this.childs = this.operations[k].childs;
                    for (let i = 0; i < this.childs.length; i++) {
                      for (let data_child_id of this.exam_title_child) {
                        if (data_child_id == this.childs[i].id) {
                          if (map.has(data_opeartion_id)) {
                            var val = map.get(data_opeartion_id);
                            val = val + "," + data_child_id;
                            map.set(data_opeartion_id, val);
                            //console.log("Operation Id & Child Id --> ", data_opeartion_id, val);
                          } else {
                            map.set(data_opeartion_id, data_child_id);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } 
      }

      var operationObjects = [];
      for (let [key, value] of map) {
        var childObjects = [];
        var str;
        try {
          str = value.split(",");
          for(let i = 0 ; i < str.length; i++) {
            var child = {
              "id": str[i]
            } 
            childObjects.push(child);
          }
        } catch (error) {
          str = value;
          var child = {
            "id": str
          } 
          childObjects.push(child);
        }
        
        

        var parent = {
            "id": key,
            "childs": childObjects
        }
        operationObjects.push(parent);
      }

      var requestData = {
        "roleIds": [
          this.role
        ],
        "entites": [
          {
            "id": this.currentEntity,
            "operations": operationObjects
          },
        ],
        "organisationId": "myorg"
      }

      //console.log("Final Request Data --> ", requestData);

      this.userManagementService.savePermission(requestData).subscribe(
        screenResponse => {
          alert("Permission Added Successfully")
          this.loadPermissionByRole(this.role);
          this.model.entityType=[];
          this.selected_operation=[];
          this.selected_child=[];;
        },
        error => {
          console.log(error);
        }

      );

    }
  }

  deletePermission() {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData == "") {
      alert("please select a record to delete");
    }
    else if(this.role !== undefined){
      if (confirm("Are you sure you want to do this?")) {
      this.userManagementService.deletePermission(this.gridApi.getSelectedRows()[0].permissionId,this.role).subscribe(
        (response: IUsersResponse) => {
          alert(response.message);
          this.loadPermissionByRole(this.role);
        },
        error => {
          alert("Something Wrong..");
          console.log(error);
        }
      );
    } 
  }
  }
  onPageSizeChanged(newPageSize) {
    const element = document.getElementById('page-size') as HTMLInputElement
    const value = element.value
    this.gridApi.paginationSetPageSize(Number(value));
  }
}