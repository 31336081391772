
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DialogData } from 'src/app/modules/parent/recon/models/recon.model';
import { RuleService } from '../../../services/rule.service';


@Component({
    selector: 'edit-dialog',
    templateUrl: 'edit.html'
  })
  export class EditdialogaddRecon implements OnInit {
    local_data: any;
    TableNames: any[] = [];
    editReconconf:FormGroup;
    submitted = false;
    constructor(
        public dialogRef: MatDialogRef<EditdialogaddRecon> ,public ruleService: RuleService,
          @Inject(MAT_DIALOG_DATA) public data: DialogData,private formBuilder: FormBuilder)
         {
          this.local_data = data[0];
          
          this.ruleService.getTablesBasedonModule(this.local_data.moduleId).subscribe(
            data => {
              for (const [key, value] of Object.entries(data)) {
                this.TableNames.push({ name: key, value: value });
              }
            }
              );
    console.log("Edit Constructor --> ", data);
   }
  ngOnInit() {
    this.editReconconf = this.formBuilder.group({
      id: new FormControl('', Validators.required),
      moduleId: new FormControl('', Validators.required),
      moduleName: new FormControl('', Validators.required),
      sourceName: new FormControl('', Validators.required),
      isCentral: new FormControl('', Validators.required),
      reconDescription:new FormControl('', Validators.required),
   });


  this.editReconconf.patchValue({

  })
  }
   get fval() {
    return this.editReconconf.controls;
  }
  
   saveReconConfig(){
    this.submitted = true;
    if (this.editReconconf.invalid) {
      return;
    }
     var saveData=this.data;
     console.log("savedata",saveData);
     this.ruleService.updateReconConfig(saveData[0]).subscribe((data:any)=>{
      alert(data.message);
      this.dialogRef.close(this.local_data);
     })
   }

   loadTableNames(moduleId){
 
     console.log("modulId",moduleId);
    this.ruleService.getTablesBasedonModule(moduleId).subscribe(
      data =>{
            for (const [key, value] of Object.entries(data)) {
              this.TableNames.push({name:key,value:value});
              }
  })
}
chageSource(data)
{
  for( var i=0;i<this.TableNames.length;i++){
    if (this.TableNames[i].value == data){
     this.local_data.sourceId=this.TableNames[i].name;
    }
  }
}
}