import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterTableService } from '../../services/master-setup.service';

@Component({
  selector: 'app-edit-master-data',
  templateUrl: './edit-master-data.component.html',
  styleUrls: ['./edit-master-data.component.scss']
})
export class EditMasterDataComponent implements OnInit {

  editMasterDataForm: FormGroup;
  submitted = false;
  columnNamesDemo:any=[];
  tableName:any;
  columnNames=[];
  selectedData:any = [];
  SID:any;
  constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<EditMasterDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,public masterTableService: MasterTableService) {
      this.tableName=data.tableName;
      this.columnNamesDemo=data.columnNames;
      this.selectedData=data.selectedData;
      this.SID=this.selectedData.SID
      for(var i=0;i<this.columnNamesDemo.length;i++){
        this.columnNames.push(this.columnNamesDemo[i]);
      }
      this.editMasterDataForm =this.createForm(this.columnNames);
      }

  ngOnInit() {
    //   for(var i=0;i<this.columnNamesDemo.length;i++){
  //   this.editMasterDataForm = this.formBuilder.group({
  //     [this.columnNames[i]]:['', Validators.required]
  //    // firstField: ['', Validators.required]
  //   });
  // }
  for (let [key, value] of Object.entries(this.selectedData))
  {
  this.editMasterDataForm.patchValue({
   [key]:value
  })
}
}
  createForm(data:string[]){
    let  group ={};
    data.forEach(x=>{
      group[x]= new FormControl();
    })
    return new FormGroup(group);
     }
  get f() { return this.editMasterDataForm.controls; }

  onSubmit() {
    this.submitted = true;
    console.log("Submited :",this.editMasterDataForm.value);
    if (this.editMasterDataForm.invalid) {
      return;
    }
    let   columnData={"tableName": this.tableName,"data":this.editMasterDataForm.value,"SID":this.SID};

    this.masterTableService.updateMasterDatass(columnData).subscribe(
      screenResponse => {
        alert(screenResponse.STATUS);
       this.dialogRef.close("true");

      },
      error => {
        console.log(error);
      }

    );
   
  }
}