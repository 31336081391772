import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../../login.service';
import { Router } from '@angular/router';
import { IServerResponse } from '../../login.model';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'forget-password-page',
  templateUrl: './forget-password-page.component.html',
  styleUrls: ['./forget-password-page.component.scss']
})
export class ForgetPasswordPageComponent implements OnInit {

  forgetPasswordForm: FormGroup;

  errorFlag: boolean = false;
  errorMessage: string;

  submitted = false;
  hide = true;

  emailId:string;

  constructor(
    public dialogRef: MatDialogRef<ForgetPasswordPageComponent>,
    private formBuilder: FormBuilder,
    public loginService: LoginService,
    public router: Router
  ) { }

  get f() { return this.forgetPasswordForm.controls; }

  ngOnInit() {
    console.log("Indise Forget Password Page Component");
    this.emailId = localStorage.getItem('emailID');

    this.forgetPasswordForm = this.formBuilder.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      otp: ['', Validators.required]
    });
  }

  submitPassword(){
    this.errorFlag = false;
    this.submitted = true;
    if (this.forgetPasswordForm.invalid) {
      return;
    }
    console.log("Data --> ", this.forgetPasswordForm.value.newPassword);
    console.log("Data --> ", this.forgetPasswordForm.value.confirmPassword);
    console.log("Data --> ", this.forgetPasswordForm.value.otp);
    console.log("Data --> ", this.emailId);

    this.loginService.forgetPasswordCreate(this.emailId, this.forgetPasswordForm.value.newPassword, this.forgetPasswordForm.value.confirmPassword, this.forgetPasswordForm.value.otp).subscribe(
      (response: IServerResponse) => {
       if(response.status == true){
          console.log("Response --> ", response.message);
          alert(response.message);
          localStorage.clear();
          //this.router.navigate(['']);
          this.dialogRef.close();
       } else {
          console.log("Response --> ", response.message);
          alert(response.message);
       }
      },
      error => {
        console.log(error);
      }
    );


  }

}
