import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ISourceConfig } from '../../../models/recon.model';
import { ReconService } from '../../../services/recon.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-download-table-file',
  templateUrl: './download-table-file.component.html',
  styleUrls: ['./download-table-file.component.scss']
})
export class DownloadTableFileComponent implements OnInit {

  sourceList: ISourceConfig[] = [];
  fileType: string;
  typeOfData: string;

  constructor(public reconService: ReconService,
    public dialogRef: MatDialogRef<DownloadTableFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.sourceList = data.sourceList;
    this.fileType = data.fileType;
    this.typeOfData = data.typeOfData;
  }

  ngOnInit() {
  }

  download(source: ISourceConfig, fileType: string) {
    source.gridOptions = undefined;
    if (this.typeOfData == "UnReconciled") {
      this.exportUnReconciled(source, fileType);
    }
    else if (this.typeOfData == "Suppress") {
      this.exportSuppress(source, fileType);
    }
  }

  exportUnReconciled(source: ISourceConfig, fileType: string) {
    if (fileType == "Excel") {
      this.reconService.exportUnReconcileExcel(source).subscribe(
        response => {
          this.exportExcel(response, source.tableName);
        },
        error => {
          console.log(error);
        }
      );
    }
    else if (fileType == "CSV") {
      this.reconService.exportUnReconcileeCSV(source).subscribe(
        response => {
          this.exportCSV(response, source.tableName);
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  exportSuppress(source: ISourceConfig, fileType: string) {
    if (fileType == "Excel") {
      this.reconService.exportSuppressExcel(source).subscribe(
        response => {
          this.exportExcel(response, source.tableName);
        },
        error => {
          console.log(error);
        }
      );
    }
    else if (fileType == "CSV") {
      this.reconService.exportSuppressCSV(source).subscribe(
        response => {
          this.exportCSV(response, source.tableName);
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  exportExcel(response: any, fileName: string) {
    const blob = new Blob([response.body], { type: 'application/vnd.ms.excel' });
    FileSaver.saveAs(blob, fileName + '.xlsx');
  }

  exportCSV(response: any, fileName: string) {
    const blob = new Blob([response.body], { type: 'text/csv' });
    FileSaver.saveAs(blob, fileName + '.csv');
  }

}
