import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/modules/material-module';
// import { FileExtractionComponent, dialog } from './modules/etl/file-extraction/file-extraction.component';
import { AgGridModule } from 'ag-grid-angular';
import { FrameworkComponent } from './modules/parent/framework-landing-page/framework.component';
import { NavMenuComponent } from './core/components/nav-menu/nav-menu.component';
import { ParentRoutingModule } from './modules/parent/parent-routing.module';
import { ParentComponent } from './modules/parent/parent.component';
import { ParentModule } from './modules/parent/parent.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './modules/login/login.component';
import { ReplacePipe } from 'src/replace.pipe';
import { ForgetPasswordPageComponent } from './modules/login/forget-password/forget-password-page/forget-password-page.component';
import { MatLoaderComponent } from './shared/loader/component/mat-loader/mat-loader.component';
import { LoaderService } from './shared/loader/loader.service';
import { LoaderInterceptor } from './shared/loader/interceptors/loader-interceptor.service';
import { tokenInterceptorProviders } from './shared/loader/interceptors/token.interceptor';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment';
import { MinuteSecondsPipe } from './core/components/header/minute-seconds.pipe';
import { ChangePasswordComponent } from './core/components/header/change-password/change-password.component';
import { SessionTimeoutComponent } from './core/components/header/session-timeout/session-timeout.component'; // optional, provides moment-style pipes for date formatting

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    // FileExtractionComponent,
    // dialog,
    FrameworkComponent,
    NavMenuComponent,
    ParentComponent,
    LoginComponent,
    ReplacePipe,
    ForgetPasswordPageComponent,
    MatLoaderComponent,
    SessionTimeoutComponent,
    MinuteSecondsPipe,
    ChangePasswordComponent,
    SessionTimeoutComponent
  ],
  // entryComponents: [ dialog, FileExtractionComponent ],
  imports: [    
    BrowserModule,
    AppRoutingModule,
    ParentModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    NgSelectModule,
    FormsModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule    
  ],
  exports: [
    NavMenuComponent
  ],
  entryComponents:[
    SessionTimeoutComponent
  ],
  providers: [
    tokenInterceptorProviders,
    MinuteSecondsPipe,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
if (!Object.entries)
  Object.entries = function (obj: { [x: string]: any; }) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  }
