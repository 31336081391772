import { Component, OnInit } from '@angular/core';
 
import { MasterTableService } from '../master-setup/services/master-setup.service';
import { ILoginResponse } from '../../login/login.model';

@Component({
  selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  url: string;

  loginResponse:ILoginResponse;
  userName: string;
  moduleId:string;
  moduleName:string;

  constructor(public masterTableService:MasterTableService) { }

  ngOnInit() {
    this.loginResponse = JSON.parse(localStorage.getItem('user'));
    console.log("Login Response UserName--> ", this.loginResponse.userName);
    this.userName = this.loginResponse.userName;

    this.moduleId = localStorage.getItem('reconModuleId');
    this.moduleName= localStorage.getItem('reconModuleName');

    this.loadDashboard();
  }

  loadDashboard(){
    console.log("Current UserName & Module Name --> ", this.userName, this.moduleName);
    this.masterTableService.loadDashboard(this.userName, this.moduleName).subscribe(
      data=>{
        for (const [key, value] of Object.entries(data)) {
          this.url = value;
        }
        console.log(this.url);
    })
}

}






// import { Component, OnInit } from '@angular/core';
// import { trigger, style, animate, transition, state, group } from '@angular/animations';

// @Component({
//   selector: 'app-dashboard',
//   templateUrl: './dashboard.component.html',
//   styleUrls: ['./dashboard.component.scss'],
//   animations: [
//     trigger('flyInOut', [
//       state('in', style({
//         width: 120,
//         transform: 'translateX(0)', opacity: 1
//       })),
//       transition('void => *', [
//         style({ width: 10, transform: 'translateX(50px)', opacity: 0 }),
//         group([
//           animate('0.3s 0.1s ease', style({
//             transform: 'translateX(0)',
//             width: 120
//           })),
//           animate('0.3s ease', style({
//             opacity: 1
//           }))
//         ])
//       ]),
//       transition('* => void', [
//         group([
//           animate('0.3s ease', style({
//             transform: 'translateX(50px)',
//             width: 10
//           })),
//           animate('0.3s 0.2s ease', style({
//             opacity: 0
//           }))
//         ])
//       ])
//     ])
//   ]
// })

// export class DashboardComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
    
//   }

// }
