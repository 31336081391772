import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { AgGridModule } from 'ag-grid-angular';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';


import { ParentRoutingModule } from './parent-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MaterialModule } from 'src/app/shared/modules/material-module';
import { BarChartComponent } from './dashboard/charts/bar-chart/bar-chart.component';
import { LineChartComponent } from './dashboard/charts/line-chart/line-chart.component';
import { DoughnutChartComponent } from './dashboard/charts/doughnut-chart/doughnut-chart.component';


import { FileUploadComponent } from './etl/components/file-upload/file-upload.component';
import { FileExtractionComponent } from './etl/components/file-extraction/file-extraction.component';
import { FileNaming, HeaderAvailable, FooterAvailable,HeaderAvailableclick,FooterAvailableclick } from './etl/components/file-extraction/file-extraction-modal/file-extraction-modal';
import { FieldConfigurationComponent } from './etl/components/field-configuration/field-configuration.component';
import { Adddialog, Editdialog } from './etl/components/field-configuration/field-configuration-modal/field-configuration-modal';
import { FileTransformationComponent } from './etl/components/file-transformation/file-transformation.component';
import { StagingDataComponent } from './staging/components/staging-data/staging-data.component';
import { ExceptionDataComponent } from './staging/components/exception-data/exception-data.component';
import { addValidation } from './etl/components/file-transformation/file-transformation-modal/ft-valid-add';
import { cleaningAdd } from './etl/components/file-transformation/file-transformation-modal/cleaning-modal';
import { formatingAdd } from './etl/components/file-transformation/file-transformation-modal/formating';
import { splitingAdd } from './etl/components/file-transformation/file-transformation-modal/spliting';
import { AddMetaDataAdd } from './etl/components/file-transformation/file-transformation-modal/add-meta-data';
import { editFormating } from './etl/components/file-transformation/file-transformation-modal/ft-format-edit';
import { editMetaData } from './etl/components/file-transformation/file-transformation-modal/add-meta-data-edit';
import { editCleaning } from './etl/components/file-transformation/file-transformation-modal/cleaning-edit';
import { editValidation } from './etl/components/file-transformation/file-transformation-modal/ft-valid-edit';
import { editJavaSplit } from './etl/components/file-transformation/file-transformation-modal/spliting-javaSplit-edit';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProcessStatusComponent } from './etl/components/process-status/process-status.component';
import { AddRuleComponent } from './recon/components/add-rule/add-rule.component';
import { AddRuleDetailsComponent } from './recon/components/add-rule-details/add-rule-details.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UnreconciledComponent } from './recon/components/unreconciled/unreconciled.component';
import { ForceUnMatchModal, ReconciledComponent, ReconciledModal } from './recon/components/reconciled/reconciled.component';
import { AddReconconfigComponent } from './recon/components/add-reconconfig/add-reconconfig.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SafePipe } from 'src/app/shared/safe.pipe';
import { ReportsComponent } from './reports/reports.component';
import { ForceMatch } from './recon/components/unreconciled/force-match/force-match.component';
import { ActivityManagerComponent } from './activity-manager/components/activity-manager/activity-manager.component';
import { ActivityDataComponent } from './activity-manager/components/activity-data/activity-data.component';
import { RunReconComponent } from './recon/components/run-recon/run-recon.component';
import { SuppressComponent } from './recon/components/unreconciled/suppress/suppress.component';
import { EscMatrixComponent } from './escalation-matrix/components/escalation-matrix/esc-matrix.component';
import { EditEscMatrixComponent } from './escalation-matrix/components/escalation-matrix/edit-esc-matrix/edit-esc-matrix.component';
import {EditdialogAddRule} from '../parent/recon/components/add-rule/add-rule-modal/edit';
import {EditdialogaddRecon } from '../parent/recon/components/add-reconconfig/add-reconconfig-modal/edit';
import { AgingComponent } from './aging/aging.component';
import { CaseCreationComponent } from './case-management/components/case-creation/case-creation.component';
import { CreatedCasesComponent } from './case-management/components/created-cases/created-cases.component';
import { ClosedCasesComponent } from './case-management/components/closed-cases/closed-cases.component';
import { CaseDataComponent } from './case-management/components/case-data/case-data.component';
import { ReOpenComponent } from './case-management/components/closed-cases/re-open/re-open.component';
import { CloseComponent } from './case-management/components/created-cases/close/close.component';
import { UpdateCommentsComponent } from './case-management/components/update-comments/update-comments.component';
import { DownloadFileComponent } from './case-management/components/update-comments/download-file.component';
import { MasterSetupModule } from './master-setup/master-setup.module';
import { RollBackComponent } from './etl/components/process-status/roll-back/roll-back.component';
import { SettelementModule } from './settelement/settelement.module';
import { ReconRollBackComponent } from './recon/components/run-recon/recon-roll-back/recon-roll-back.component';
import { SameSideReversalComponent } from './recon/components/same-side-reversal/same-side-reversal.component';
import { UserManagementModule } from './user-management/user-management.module';
import { FrameworkComponent } from './framework-landing-page/framework.component';
import { ViewReconComponent } from './recon/view-recon/view-recon.component';
import { ViewEtlComponent } from './etl/components/view-etl/view-etl.component';
import { AdministrationComponent } from './reports/administration/administration.component';
import { ViewComponent } from './reports/view/view.component';
import { AddPasswordPolicyComponent } from './user-management/password-policy/add-password-policy/add-password-policy.component';
import { ForgetPasswordDialog } from '../login/forget-password/forget-password.componet';
import { PasswordPolicyComponent } from './user-management/password-policy/password-policy.component';
import {EditPasswordPolicyComponent} from './user-management/password-policy/edit-password-policy/edit-password-policy.component'
import { SchedulerModule } from './scheduler/scheduler.module';
import { ChangePasswordComponent } from 'src/app/core/components/header/change-password/change-password.component';
import { DownloadTableFileComponent } from './recon/components/unreconciled/download-table-file/download-table-file.component';
import { ExportComponent } from './recon/components/same-side-reversal/export/export.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { GlBalancingComponent } from './recon/components/gl-balancing/gl-balancing.component';
import { SameSideMatchComponent } from './recon/components/unreconciled/same-side-match/same-side-match.component';
import { SameSideUnmatchComponent } from './recon/components/same-side-reversal/same-side-unmatch/same-side-unmatch.component';

@NgModule({
  declarations: [
    DashboardComponent,
    BarChartComponent,
    LineChartComponent,
    DoughnutChartComponent,
    FileUploadComponent,
    FileExtractionComponent,
    FileNaming,
    HeaderAvailable,
    FooterAvailable,
    HeaderAvailableclick,FooterAvailableclick,
    FieldConfigurationComponent,
    Adddialog,
    Editdialog,
    FileTransformationComponent,
    StagingDataComponent,
    ExceptionDataComponent,

    addValidation,
    cleaningAdd,
    formatingAdd,
    splitingAdd,
    FileTransformationComponent,
    AddMetaDataAdd,

    editFormating,
    editCleaning,
    editValidation,
    editJavaSplit,
    editMetaData,
    ProcessStatusComponent,
    AddRuleComponent,
    AddRuleDetailsComponent,
    AddReconconfigComponent,
    
    ReconciledComponent,
    ReconciledModal,
    ForceUnMatchModal,
    UnreconciledComponent,
    ReportsComponent,
    SafePipe,
    ForceMatch,
    SuppressComponent,
    ActivityManagerComponent,
    ActivityDataComponent,
    RunReconComponent,
    EscMatrixComponent,
    EditEscMatrixComponent,
    EditdialogAddRule,
    EditdialogaddRecon,
    AgingComponent,
    CaseCreationComponent,
    CreatedCasesComponent,
    ClosedCasesComponent,
    CaseDataComponent,
    ReOpenComponent,
    CloseComponent,
    UpdateCommentsComponent,
    DownloadFileComponent,
    RollBackComponent,
    ReconRollBackComponent,
    SameSideReversalComponent,
    ViewReconComponent,
    ViewEtlComponent,
    AdministrationComponent,
    ViewComponent,
    //LoginComponent
    //FrameworkComponent,

    PasswordPolicyComponent,
    AddPasswordPolicyComponent,
    EditPasswordPolicyComponent,
    ForgetPasswordDialog,
    DownloadTableFileComponent,
    ExportComponent,
    AccessDeniedComponent,
    GlBalancingComponent,
    SameSideMatchComponent,
    SameSideUnmatchComponent
  ],
  imports: [
    CommonModule,
    ParentRoutingModule,
    MaterialModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([]),
    AngularMultiSelectModule,
    NgSelectModule,
    NgbModule,
    MasterSetupModule,
    SettelementModule, 
    UserManagementModule,
    SchedulerModule
 
  ],
  entryComponents:[
    HeaderAvailable,
    FooterAvailable,
    HeaderAvailableclick,FooterAvailableclick,
    FileNaming,
    Adddialog,
    Editdialog,
    addValidation,
    cleaningAdd,
    formatingAdd,
    splitingAdd,
    FileTransformationComponent,
    AddMetaDataAdd,
    
    editFormating,
    editCleaning,
    editValidation,
    editJavaSplit,
    editMetaData,

    ReconciledModal,
    ForceUnMatchModal,
    ForceMatch,
    ActivityManagerComponent,
    ActivityDataComponent,
    SuppressComponent,
    EditEscMatrixComponent,
    EditdialogAddRule,
    EditdialogaddRecon,
    CaseCreationComponent,
    CreatedCasesComponent,
    ClosedCasesComponent,
    CaseDataComponent,
    ReOpenComponent,
    CloseComponent,
    UpdateCommentsComponent,
    DownloadFileComponent,
    RollBackComponent,
    ReconRollBackComponent,

    ForgetPasswordDialog,
    AddPasswordPolicyComponent,
    EditPasswordPolicyComponent,
    ChangePasswordComponent,
    DownloadTableFileComponent,
    ExportComponent,
    SameSideMatchComponent,
    SameSideUnmatchComponent
  ],
  providers:[
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ],
  exports: [
    SafePipe
  ],
  bootstrap: [
    
  ]
})
export class ParentModule { }
