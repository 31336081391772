
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog, MatDialogRef  } from '@angular/material';
import { EtlService } from '../../../services/etl.service';
import { FormGroup, Validators, FormControl,FormBuilder } from '@angular/forms';

@Component({
    selector: 'editCleaning',
    templateUrl: 'cleaning-edit.html'  
  })
 
  export class editCleaning implements OnInit {
    config_data:any;  
    local_data : any;
    cleaningEdit:any;
    submitted = false;
    COLUMN_DATA_TYPE:any;
    rowData:any;
    columnName:any;
    ngOnInit(): void {
      this.cleaningEdit.reset();

      this.loadTempColumns();
      this.local_data=[];
      this.local_data=this.data.editSelectedData;
      this.selectOperationValue=this.data.editSelectedData.OPERATION_NAME;
      this.config_data=this.data;
      console.log("config_data",this.config_data);
     }
    templetColumnNames:any=[];
    selectOperationValue:string;

    constructor(@Inject(MAT_DIALOG_DATA) public data:any,public etlService:EtlService,
    public dialogRef:MatDialogRef<editCleaning>,private fb:FormBuilder) {
      this.cleaningEdit = new FormGroup({
        ColumnName:new FormControl('', Validators.required),
        Category:new FormControl('', Validators.required),
        Operation:new FormControl('', Validators.required),
        REPLACE_NULL_BY:new FormControl(),
        MASKING_CHARACTER:new FormControl(),
        MASKING_FROMPOS:new FormControl(),
        MASKING_TOPOS:new FormControl(),
        REPLACE_CHARACTER:new FormControl(),
        REPLACE_CHARACTER_BY:new FormControl(),
        REMOVE_CHARACTERS:new FormControl(),
        dataType:new FormControl(),
        // MASKING_CHARACTER:new FormControl('', Validators.required),
        // MASKING_FROMPOS:new FormControl('', Validators.required),
        // MASKING_TOPOS:new FormControl('', Validators.required),
        // REPLACE_CHARACTER:new FormControl('', Validators.required),
        // REPLACE_CHARACTER_BY:new FormControl('', Validators.required),
        // REMOVE_CHARACTERS:new FormControl('', Validators.required),
        
      });
        
      

    }
    get fval() {
      return this.cleaningEdit.controls;
      }
      signup(){
        this.submitted = true;
        if (this.cleaningEdit.invalid) {
     
          return;
        }
       
       
        }



    changeSelectOperation(value)
    {
        this.selectOperationValue = value;
        this.cleaningEdit.get('REPLACE_NULL_BY').setValidators(null);
        this.cleaningEdit.get('MASKING_CHARACTER').setValidators(null);
        this.cleaningEdit.get('MASKING_FROMPOS').setValidators(null);
        this.cleaningEdit.get('MASKING_TOPOS').setValidators(null);
        this.cleaningEdit.get('REPLACE_CHARACTER').setValidators(null);
        this.cleaningEdit.get('REPLACE_CHARACTER_BY').setValidators(null);
        this.cleaningEdit.get('REMOVE_CHARACTERS').setValidators(null);
        this.local_data.REMOVE_CHARACTERS=undefined;
        this.local_data.REPLACE_CHARACTER_BY=undefined;
        this.local_data.REPLACE_CHARACTER=undefined;
        this.local_data.REPLACE_NULL_BY=undefined;
        this.local_data.MASKING_CHARACTER=undefined;
        this.local_data.MASKING_FROMPOS=undefined;
        this.local_data.MASKING_TOPOS=undefined;
        this.cleaningEdit.patchValue({
          REPLACE_NULL_BY: undefined,
          MASKING_CHARACTER: undefined,
          MASKING_FROMPOS: undefined,
          MASKING_TOPOS: undefined,
          REPLACE_CHARACTER: undefined,
          REPLACE_CHARACTER_BY: undefined,
          REMOVE_CHARACTERS: undefined,
        })
        if(this.selectOperationValue==='ReplaceNullBy')
        {
          const ReplaceNullBy = <FormControl>this.cleaningEdit.get('REPLACE_NULL_BY');
          ReplaceNullBy.setValidators([Validators.required, ])
       }else if(this.selectOperationValue==='Data Masking')
       {
        const MASKING_CHARACTER = <FormControl>this.cleaningEdit.get('MASKING_CHARACTER');
        MASKING_CHARACTER.setValidators([Validators.required, ]);

        const MASKING_FROMPOS = <FormControl>this.cleaningEdit.get('MASKING_FROMPOS');
        MASKING_FROMPOS.setValidators([Validators.required, ]);

        const MASKING_TOPOS = <FormControl>this.cleaningEdit.get('MASKING_TOPOS');
        MASKING_TOPOS.setValidators([Validators.required, ]);
       }else if(this.selectOperationValue==='Replace Character/String')
       {
        const REPLACE_CHARACTER = <FormControl>this.cleaningEdit.get('REPLACE_CHARACTER');
        REPLACE_CHARACTER.setValidators([Validators.required, ]);
        const REPLACE_CHARACTER_BY = <FormControl>this.cleaningEdit.get('REPLACE_CHARACTER_BY');
        REPLACE_CHARACTER_BY.setValidators([Validators.required, ]);
       }else if(this.selectOperationValue==='Remove Character/String')
       {
        const REMOVE_CHARACTERS = <FormControl>this.cleaningEdit.get('REMOVE_CHARACTERS');
        REMOVE_CHARACTERS.setValidators([Validators.required, ])
       }
        
    }
    
    loadTempColumns()
    {
      this.etlService.getTemplateColumnNameData(this.data.editSelectedData.TEMPLET_ID).subscribe(
        data=>{
          this.rowData=data;
          for (const [key, value] of Object.entries(data)) {
            
                this.templetColumnNames.push({name:key,value:value});
                }

                for(const [key, value] of Object.entries(this.rowData)){
                  if (key === this.local_data.COLUMN_NAME){
                   this.COLUMN_DATA_TYPE=value;
                   }
                }
                this.columnName=this.data.editSelectedData.COLUMN_NAME+' '+this.COLUMN_DATA_TYPE;
        }
      )
    }

    saveCleaningConfig()
    {
      
      if (this.cleaningEdit.invalid) {
        return;
      }
     
      this.etlService.getEditCleaningConfig(this.config_data).subscribe( (data:any)=>{
       
        this.dialogRef.close(data);
       }) 

     
    }
   
changeColumnName(value)
{
  this.local_data.REMOVE_CHARACTERS=undefined;
  this.local_data.REPLACE_CHARACTER_BY=undefined;
  this.local_data.REPLACE_CHARACTER=undefined;
  this.local_data.REPLACE_NULL_BY=undefined;
  this.selectOperationValue="";
 var str=value.split(" ");
  this.local_data.COLUMN_NAME=str[0];
  this.COLUMN_DATA_TYPE=str[1];
  this.cleaningEdit.patchValue({
    Operation:"",
    ReplaceNullBy: undefined,
    MaskingCharacter: undefined,
    MaskingfromPos: undefined,
    MaskingToPos: undefined,
    ReplaceCharacters: undefined,
    ReplaceCharactersBy: undefined,
    RemovingCharacters: undefined,
  })
  this.changeSelectOperation(this.cleaningEdit.Operation);
}
  
  }