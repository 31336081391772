
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { EtlService } from '../../../services/etl.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'formatingAdd',
    templateUrl: 'formating-add.html'  
  })
 
  export class formatingAdd implements OnInit {
 
formattingSelectTempletid1:0;
formattingSelectTempletGroupId:"";
formattingSelectColumnName:any;
formattingSelectOperationCategory:any;
formattingSelectOperation:any;
formatingDate:any;
dateTypeSelectOperation:any;
timeFormat:any;
inputFormat:any;
outputFormat:any;
timeDateTypeSelectOperation:any;
FormatingAdd:any;
submitted = false;
dateTypeSelectOperationn:any;
COLUMN_DATA_TYPE:string;
rowData:any;
    ngOnInit(): void {
      this.loadTempColumns();
    }
    get fval() {
      return this.FormatingAdd.controls;
      }
      signup(){
        this.submitted = true;
        if (this.FormatingAdd.invalid) {
       
          return;
        }
      
      
        }

    public templetColumnNames:any=[];
    selectOperationValue:string;
    constructor(@Inject(MAT_DIALOG_DATA) public data:any,public etlService:EtlService,
    public dialogRef:MatDialogRef<formatingAdd>) {
      this.rowData=data.row;
      this.FormatingAdd = new FormGroup ({
        formattingSelectColumnName:new FormControl('', Validators.required),
        formattingSelectOperationCategory:new FormControl('', Validators.required),
        formattingSelectOperation:new FormControl('', Validators.required),
        dateTypeSelectOperationn:new FormControl(''),
        OutputFormat:new FormControl(''),
        InputFormat:new FormControl(''),
        timeFormat:new FormControl(''),
        dateTypeSelectOperation:new FormControl(''),
        DateFormatt:new FormControl(''),
        dataType:new FormControl('')
      });

this.formattingSelectTempletid1=data.tempId;
this.formattingSelectTempletGroupId=data.groupId;
this.formattingSelectOperationCategory="Formatting";


    }

    changeSelectOperation(value)
    {
        this.selectOperationValue = value;
        this.FormatingAdd.get('dateTypeSelectOperation').setValidators(null);
        this.FormatingAdd.get('DateFormatt').setValidators(null);
        this.FormatingAdd.get('timeFormat').setValidators(null);
        this.FormatingAdd.get('InputFormat').setValidators(null);
        this.FormatingAdd.get('OutputFormat').setValidators(null);
        this.FormatingAdd.get('dateTypeSelectOperationn').setValidators(null);
        this.FormatingAdd.patchValue({
          dateTypeSelectOperation: undefined,
          DateFormatt: undefined,
          timeFormat: undefined,
          InputFormat: undefined,
          OutputFormat: undefined,
          dateTypeSelectOperationn: undefined,
        })
        if(this.selectOperationValue==='FormattingDate')
       {
        const DataType = <FormControl>this.FormatingAdd.get('dateTypeSelectOperation');
        DataType.setValidators([Validators.required, ]);

        const DateFormatt = <FormControl>this.FormatingAdd.get('DateFormatt');
        DateFormatt.setValidators([Validators.required, ]);
       }
        else if(this.selectOperationValue==='FormattingTime')
       {
        const timeFormat = <FormControl>this.FormatingAdd.get('timeFormat');
        timeFormat.setValidators([Validators.required, ]);
       }else if(this.selectOperationValue==='FormattingDateTime')
       {
        this.outputFormat="yyyy-MM-dd";
        const InputFormat = <FormControl>this.FormatingAdd.get('InputFormat');
        InputFormat.setValidators([Validators.required, ]);

        const OutputFormat = <FormControl>this.FormatingAdd.get('OutputFormat');
        OutputFormat.setValidators([Validators.required, ]);
        const dateTypeSelect = <FormControl>this.FormatingAdd.get('dateTypeSelectOperationn');
        dateTypeSelect.setValidators([Validators.required, ]);
       }
    }
    
    loadTempColumns()
    {
      console.log("load col   :",this.data)
      this.etlService.getTemplateColumnNameData(this.data.tempId).subscribe(
        data=>{
            for (const [key, value] of Object.entries(data)) {
            
                this.templetColumnNames.push({name:key,value:value});
                }
          this.FormatingAdd.patchValue({
            formattingSelectColumnName:"",
            formattingSelectOperation:""
          })
        }
      )
    }
   
saveFormattingConfig()
{ 
  if (this.FormatingAdd.invalid) {
       
    return;
  }
 
  if(this.selectOperationValue==='FormattingDateTime' && this.inputFormat.includes("mm")){
    alert("Input date Field should be proper format eg. dd-MM-yyyy or dd-MMM-yyyy or MM-dd-yyyy or MMM-dd-yyyy");
  }
  let flag: boolean = false;


  for (let data of this.rowData) {
     if (this.formattingSelectOperation == "ToUpperCase") {
        if (data.OPERATION_NAME == this.formattingSelectOperation && data.COLUMN_NAME==this.formattingSelectColumnName ) {
          flag = true;
          break;
        }
      }
        if (this.formattingSelectOperation == "ToLowerCase") {
          if (data.OPERATION_NAME == this.formattingSelectOperation && data.COLUMN_NAME==this.formattingSelectColumnName) {

            flag = true;
            break;

          }
        }
        if (this.formattingSelectOperation == "CapatilizeFirst") {
          if (data.OPERATION_NAME == this.formattingSelectOperation && data.COLUMN_NAME==this.formattingSelectColumnName ) {

            flag = true;
            break;

          }
        }
        if (this.formattingSelectOperation == "FormattingDateTime") {
          if (data.OPERATION_NAME == this.formattingSelectOperation && data.COLUMN_NAME==this.formattingSelectColumnName && data.INPUT_FORMAT==this.inputFormat && data.OUTPUT_FORMAT==this.outputFormat && data.DATETIME_TYPE == this.timeDateTypeSelectOperation) {

            flag = true;
            break;

          }
        }
  }

  if (flag) {
    alert(" Record already exists");
  }
  else{
  this.dialogRef.close(
  this.etlService.saveFormattingConfig({cleaningSelectOperationCategory:"formatting",
    formattingSelectTempletid1:this.formattingSelectTempletid1,
    formattingSelectTempletGroupId:this.formattingSelectTempletGroupId,
    formattingSelectColumnName:this.formattingSelectColumnName,
    formattingSelectOperationCategory:this.formattingSelectOperationCategory,
    formattingSelectOperation:this.formattingSelectOperation,
    formatingDate:this.formatingDate,
    dateTypeSelectOperation:this.dateTypeSelectOperation,
    timeFormat:this.timeFormat,
    inputFormat:this.inputFormat,
    outputFormat:this.outputFormat,
    timeDateTypeSelectOperation: this.timeDateTypeSelectOperation
     }).subscribe((data:any)=>{
      if(data.message =="Record sucessfully inserted "){
        alert("Operation Added Successfully");
      }
      else
      alert(data.message);

  }))
  }
}

changeColumnName(value)
{
  var str=value.split(" ");
    this.formattingSelectColumnName=str[0];
    this.COLUMN_DATA_TYPE=str[1];
    this.FormatingAdd.patchValue({
      formattingSelectOperation:""
    })
    this.changeSelectOperation(this.FormatingAdd.formattingSelectOperation);
  }
}
  
  