import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AddGroupsComponent } from './add-groups/add-groups.component';
import { EditGroupsComponent } from './edit-groups/edit-groups.component';
import { AddGroupUsersComponent } from './add-group-users/add-group-users.component';
import { EditGroupUsersComponent } from './edit-group-users/edit-group-users.component';
import { GroupService } from './service/group.service';
import { NgLocaleLocalization } from '@angular/common';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  public rowData1: any;
  public rowData: any;
  myTempRowData: any;
  gridColumnApi: any;
  public columnDefs: any;
  private gridApi: any;
  private gridApi1: any;
  public rowSelection: any;
  roles:any;


  constructor(public dialog: MatDialog,public groupService : GroupService) { 
    this.rowSelection = "single";
  }

  ngOnInit() {
    this.loadGroupData();
    this.loadAsignGroupTOUserData();
  }

  addGroups(){
    const dialogRef = this.dialog.open(AddGroupsComponent, {
      width: '500px',
     panelClass: 'mat-modal'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadGroupData();
    });
  }
  
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  editGroups():void {
    var editSelectedData = this.gridApi.getSelectedRows();
    console.log("Edit Selected Row --> ", editSelectedData);
    if (editSelectedData == "") {
      alert("please select a record");
      }
     else{
    const dialogRef = this.dialog.open(EditGroupsComponent, {
      data: editSelectedData[0],
      panelClass : 'mat-modal',
      width : '500px'
    });
   
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog edit was closed', result);
      if (result !== undefined) {
        this.gridApi.updateRowData({ update: [result] });
       
      }
    });
  }
  }

  onSelectionChanged(){
    console.log('hi');
  }

  deleteGroups(){
    var selectedData = this.gridApi.getSelectedRows();
    console.log("selected data", selectedData);
    if (selectedData == "") {
      alert("please select a record to delete");
    } else if (selectedData !== "") {
      if (confirm("Do you want to delete this record")) {
        var groupId = selectedData[0].id;
        this.groupService.deleteGroup(groupId).subscribe(
          (groupResponse: any) => {
            alert(groupResponse.message);
            this.gridApi.updateRowData({ remove: selectedData });
          },
          error => {
            alert(error.error.details[0]);
            console.log(error);
          });
      }
    }
  }
  onPageSizeChanged(newPageSize) {
    const element = document.getElementById('page-size') as HTMLInputElement
    const value = element.value
    this.gridApi.paginationSetPageSize(Number(value));
  }

  addGroupUsers(){
    const dialogRef = this.dialog.open(AddGroupUsersComponent, {
      width: '500px',
     panelClass: 'mat-modal'
    });

    dialogRef.afterClosed().subscribe(result => {
     
      this.loadAsignGroupTOUserData();
    });
  }

  // editGroupUsers(){
  //   const dialogRef = this.dialog.open(EditGroupUsersComponent, {
  //     width: '500px',
  //    panelClass: 'mat-modal'
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
     
      
  //   });
  // }

 
  //getting users records 
  loadGroupData(){
    this.groupService.getGroupsData().subscribe(
      (escalationList) => {
        this.rowData = escalationList;
        this.columnDefs = this.columnDefesc;


      },
      error => {
        console.log(error);
      }
    );
  }
  columnDefesc = [
    {headerName: 'Group Name', field: 'name', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: false, resizable: true, suppressSizeToFit: false,width:300},
  //  {headerName: 'roles', field: 'roles', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
   
];


onGridReady1(params) {
  this.gridApi1 = params.api;
}

loadAsignGroupTOUserData(){
  this.groupService.getAssignGroupToUserData().subscribe(
    (dataList) => {
      this.rowData1 = dataList;
      this.columnDefesc1 = this.columnDefesc1;

    },
    error => {
      console.log(error);
    }
  );
}

deleteGroupUsers(){
  var selectedData = this.gridApi1.getSelectedRows();
    console.log("selected data", selectedData);
    if (selectedData == "") {
      alert("please select a record to delete");
    } else if (selectedData !== "") {
      if (confirm("Do you want to delete this record")) {
        var userId = selectedData[0].userId;
        this.groupService.deleteAssignGroupsToUser(userId).subscribe(
          (groupResponse: any) => {
            alert(groupResponse.message);
            this.gridApi1.updateRowData({ remove: selectedData });
          },
          error => {
            console.log(error);
          });
      }
    }
}

columnDefesc1 = [
  {headerName: 'User Name', field: 'userName', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true},
  {headerName: 'Group Name', field: 'groups', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true, suppressSizeToFit: true,width:300},
  
 
]

onPageSizeChangedd(newPageSize) {
  const element = document.getElementById('page-size') as HTMLInputElement
  const value = element.value
  this.gridApi1.paginationSetPageSize(Number(value));
}
}
