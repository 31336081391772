import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AgGridColumnsService {

  constructor() { }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            suppressSizeToFit: false,
            floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: false,
            suppressMenu: true,
            suppressColumnVirtualisation: true,
            resizable: true,
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }


  generateReconColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            filter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: false,
            resizable: true,
            cellStyle: function (params) {
              if (params.data.WORKFLOW_STATUS == 'Y') {
                return { backgroundColor: 'orange' };
              }
            }
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }

  generateColumnsForSingleRecord(data: any[]) {
    let columnDefinitions = [];
    Object
      .keys(data)
      .map(key => {
        let mappedColumn = {
          headerName: key.toUpperCase(),
          field: key,
          sortable: true, suppressSizeToFit: false,
          floatingFilterComponentParams: { suppressFilterButton: true },
          floatingFilter: false,
          suppressMenu: true,
          suppressColumnVirtualisation: true,
          resizable: true,
          cellStyle: function (params) {
            if (params.data.WORKFLOW_STATUS == 'Y') {
              return { backgroundColor: 'orange' };
            }
          }
        }
        columnDefinitions.push(mappedColumn);
      })


    return columnDefinitions;
  }

  generateUnReconcileColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object.keys(object).map(key => {
        let mappedColumn = {};
        if (key == "SID") {
          mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            filter: true,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            pinned: 'left',
            lockPinned: true,
            cellClass: 'lock-pinned',
            headerCheckboxSelectionFilteredOnly: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: false,
            resizable: true,
            cellStyle: function (params) {
              if (params.data.WORKFLOW_STATUS == 'Y') {
                return { backgroundColor: 'orange' };
              }
            }
          }
        }
        else {
          mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            filter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: false,
            resizable: true,
            cellStyle: function (params) {
              if (params.data.WORKFLOW_STATUS == 'Y') {
                return { backgroundColor: 'orange' };
              }
            }
          }
        }
        columnDefinitions.push(mappedColumn);
      })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }

  generateActivityColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {};
          if (key == "activityObjectData") {
            mappedColumn = {
              headerName: key.toUpperCase(),
              hide: true,
              suppressSizeToFit: false,
              floatingFilterComponentParams: { suppressFilterButton: true },
              floatingFilter: false,
              suppressMenu: true,
              suppressColumnVirtualisation: true,
              resizable: true,
            }
          }
          else {
            mappedColumn = {
              headerName: key.toUpperCase(),
              field: key,
              sortable: true, suppressSizeToFit: false,
              floatingFilterComponentParams: { suppressFilterButton: true },
              floatingFilter: false,
              suppressMenu: true,
              suppressColumnVirtualisation: true,
              resizable: true,
            }
          }
          columnDefinitions.push(mappedColumn);
        })
    })
    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }

}