import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AddConfigurationComponent } from './add-configuration/add-configuration.component';
import { ConfigurationService } from '../../services/configuration.service';
import { IJobScheduler, ISchedulerResponse } from '../../models/scheduler.model';
import { AgGridColumnsService } from '../../services/ag-grid-columns.service';
import { EditConfigurationComponent } from './edit-configuration/edit-configuration.component';
import { IReconModule } from '../../../recon/models/recon.model';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  reconModuleList: IReconModule[];
  jobSchedulerList: IJobScheduler[];
  columnDefs: any;
  gridApi: any;

  constructor(public dialog: MatDialog,
    private configurationService: ConfigurationService,
    private agGridColumnsService: AgGridColumnsService) { }

  ngOnInit() {
    this.loadReconModules();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  loadReconModules() {

    this.configurationService.loadReconModules().subscribe(
      (reconModuleList: IReconModule[]) => {
        this.reconModuleList = reconModuleList;

        this.loadAllJobSchedulers();
      },
      error => {
        console.log(error);
      }
    );
  }
  loadAllJobSchedulers() {
    this.configurationService.loadAllJobSchedulers().subscribe(
      (jobSchedulerList: any[]) => {
        let data = jobSchedulerList;
        this.columnDefs = this.agGridColumnsService.generateColumns(jobSchedulerList);
        //this.jobSchedulerList = jobSchedulerList;
        for (let i = 0; i < data.length; i++) {
          let startDate=new Date(jobSchedulerList[i].startingDate).toLocaleString();
          let endDate=new Date(jobSchedulerList[i].endDate).toLocaleString();
          jobSchedulerList[i].startingDate=startDate;
          jobSchedulerList[i].endDate=endDate;
          for (let j = 0; j < this.reconModuleList.length; j++) {
            if (jobSchedulerList[i].reconName == this.reconModuleList[j].id) {
              jobSchedulerList[i].reconName = this.reconModuleList[j].moduleName
            }
          }
        }

        this.jobSchedulerList = jobSchedulerList;
      },
      error => {
        console.log(error);
      }
    );
  }


  addConfiguration() {
    const dialogRef = this.dialog.open(AddConfigurationComponent, {
      width: '705px',
      panelClass: 'mat-modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadAllJobSchedulers();
      }
    });
  }

  editConfiguration() {
    if (this.gridApi.getSelectedRows().length == 0) {
      alert("Please Select Record");
    }
    else {
      const dialogRef = this.dialog.open(EditConfigurationComponent, {
        data: {
          jobScheduler: this.gridApi.getSelectedRows()[0],
          id:this.gridApi.getSelectedRows()[0].id
        },
        width: '705px',
        panelClass: 'mat-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loadAllJobSchedulers();
        }
      });
    }
  }

  deleteConfiguration() {
    if (this.gridApi.getSelectedRows().length == 0) {
      alert("Please Select Record");
    }
    else {
      if (confirm("Do you want to delete this scheduler")) {
        this.configurationService.deleteSchedulerConfig(this.gridApi.getSelectedRows()[0].id).subscribe(
          (response: ISchedulerResponse) => {
            //alert(response.message);
            this.gridApi.updateRowData({ remove: this.gridApi.getSelectedRows() });
          },
          error => {
            console.log(error);
          }
        );
      }
    }
  }


}
