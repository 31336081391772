import { Component, OnInit } from '@angular/core';
import { RuleService } from '../../services/rule.service';
import { isObject } from 'util';
import { IReconModule, DialogData, IActivityResponse } from '../../models/recon.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { MatDialog } from '@angular/material';
import { EditdialogaddRecon } from './add-reconconfig-modal/edit';
import { UserManagementService } from '../../../user-management/services/user-management.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-add-reconconfig',
  templateUrl: './add-reconconfig.component.html',
  styleUrls: ['./add-reconconfig.component.scss']
})
export class AddReconconfigComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  reconModuleList: IReconModule[] = [];
  reconNames: any[] = [];
  TableNames: any[] = [];
  editSelectedData: DialogData;
  sourceId: any;
  isCentral: any;
  reconDescription: any;
  public rowData: any;
  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;

  moduleId: string;
  moduleName: string;

  //reconConfigObj : any = new Object(); 
  addreconconf: any;
  submitted = false;
  rowSelection: any;
  permissionList: string[] = [];

  constructor(public dialog: MatDialog, public ruleService: RuleService, private formBuilder: FormBuilder,
    private userManagementService: UserManagementService, private router: Router,
    private route: ActivatedRoute) {
    this.rowSelection = "single";

    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">No Rows To Show</span>'
    this.overlayNoRowsTemplate =
      '<span class="ag-overlay-loading-center">No Rows To Show</span>';

    this.addreconconf = this.formBuilder.group({
      sourceId: new FormControl('', Validators.required),
      isCentral: new FormControl('', Validators.required),
      reconDescription: new FormControl('', Validators.required),
    });

  }

  columnDefs = [
    { headerName: 'ID', field: 'ID', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'MODULEID', field: 'MODULEID', sortable: true, filter: true, editable: false, resizable: true, width: 500, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'MODULENAME', field: 'MODULENAME', sortable: true, filter: true, editable: false, resizable: true, width: 500, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'SOURCEID', field: 'SOURCEID', sortable: true, filter: true, editable: false, resizable: true, width: 500, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'ISCENTRAL', field: 'ISCENTRAL', sortable: true, filter: true, editable: false, resizable: true, width: 500, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'RECONDESCRIPTION', field: 'RECONDESCRIPTION', sortable: true, filter: true, editable: false, resizable: true, width: 500, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true }

  ];
  ngOnInit() {
    this.moduleId = localStorage.getItem('reconModuleId');
    this.moduleName= localStorage.getItem('reconModuleName');
    this.loadChildPermissionsByUser();
    this.loadTableNames();
  }

  loadChildPermissionsByUser() {
    this.userManagementService.loadChildPermissionsByUser(this.route.firstChild.routeConfig.path.replace(/-/g,' ')).subscribe(
     (list: string[]) => {
       this.permissionList = list;
       console.log(this.permissionList);
       console.log(this.router.url);
     },
     error => {
       console.log(error);
     }
   );
 }

  get fval() {
    return this.addreconconf.controls;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.columnApi.autoSizeColumns();
    this.gridApi.sizeColumnsToFit();


  }

  loadTableNames() {
    this.rowData = [];
    this.TableNames = []
    this.isCentral = [];
    this.sourceId = [];

    this.ruleService.getTablesBasedonModule(this.moduleId).subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          this.TableNames.push({ name: key, value: value });
        }

        this.ruleService.getReconConfig(this.moduleId).subscribe(data => {
          this.rowData = data
          if (this.rowData) {
            this.columnDefs = this.generateColumns(this.rowData);
          }
        }
        );
      },
      error => {
        console.log(error);
      }
    );
  }
  //Save recon Config

  saveReconConfig() {

    this.submitted = true;
    if (this.addreconconf.invalid) {
      return;
    }

    var str1 = this.sourceId.split(" ");
    var sourceId = str1[0];
    var sourceName = str1[1];
    let reconData = {
      "moduleId": this.moduleId,
      "moduleName": this.moduleName,
      "sourceId": sourceId,
      "sourceName": sourceName,
      "isCentral": this.isCentral,
      "reconDescription": this.reconDescription,
    };


    this.ruleService.saveReconConfig(reconData).subscribe(data => {
      alert(data.message);


      this.ruleService.getReconConfig(this.moduleId).subscribe(data => {
        this.rowData = data
        if (this.rowData) {
          this.columnDefs = this.generateColumns(this.rowData);
          this.gridApi.sizeColumnsToFit();
          this.sourceId = [],
            this.isCentral = [],
            this.reconDescription = []
          this.submitted = false;
        }

      }
      );

    })

  }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            filter: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true,
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }
  
  openEditDialog(): void {
    this.editSelectedData = this.gridApi.getSelectedRows();
    console.log("Edit Selected Row --> ", this.editSelectedData);
    if (this.gridApi.getSelectedRows().length == 0) {
      alert("Please select data ");
      return;
    }
    const dialogRef = this.dialog.open(EditdialogaddRecon, {
      data: this.editSelectedData,
      panelClass: 'mat-modal',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog edit was closed', result);
      if (result !== undefined) {
         this.gridApi.updateRowData({ update: [result] });
         this.ruleService.getReconConfig(this.moduleId).subscribe(data => {
          this.rowData = data
          if (this.rowData) {
            this.columnDefs = this.generateColumns(this.rowData);
            }
  
        });
      }
    });
  }
  deleteconf() {

    var selectedData = this.gridApi.getSelectedRows();
    console.log("selected data", selectedData);
    if (selectedData == "") {
      alert("please select a record to delete");
    } else if (selectedData !== "") {
      if (confirm("Do you want to delete this record")) {
        console.log("selected data", selectedData[0].id)
        var srcId = selectedData[0].id;
        this.ruleService.deleteConfById(srcId)
          .subscribe((data: any) => {

          });

        this.gridApi.updateRowData({ remove: selectedData });
      }




    }
  }


}
