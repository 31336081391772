import { Component, OnInit } from '@angular/core';
import { IReconModule, ISourceConfig, ISelectedActivityTxns, IActivityData } from '../../models/recon.model';
import { ReconService } from '../../services/recon.service';
import { AgGridColumnsService } from '../../services/ag-grid-columns.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Grid, GridOptions, Module, ClientSideRowModelModule } from "@ag-grid-community/all-modules";
import { ForceMatch } from './force-match/force-match.component';
import { SuppressComponent } from './suppress/suppress.component';
import { CaseCreationComponent } from '../../../case-management/components/case-creation/case-creation.component';
import { CaseCreationService } from '../../../case-management/services/case-creation.service';
import { ActivatedRoute } from '@angular/router';
import { UserManagementService } from '../../../user-management/services/user-management.service';
import { DownloadFileComponent } from '../../../case-management/components/update-comments/download-file.component';
import { DownloadTableFileComponent } from './download-table-file/download-table-file.component';
import { SameSideMatchComponent } from './same-side-match/same-side-match.component';


@Component({
  selector: 'app-unreconciled',
  templateUrl: './unreconciled.component.html',
  styleUrls: ['./unreconciled.component.scss']
})
export class UnreconciledComponent implements OnInit {

  reconModuleList: IReconModule[] = [];
  sourceList: ISourceConfig[];
  sourceDataList: ISourceConfig[];
  reconModuleId: number;
  reconModuleName: string;

  showDataRadio: boolean = true;

  gridOptions: GridOptions = <GridOptions>{};
  permissionList: string[] = [];

  constructor(public reconService: ReconService,
    public caseCreationService: CaseCreationService,
    public agGridColumns: AgGridColumnsService,
    public dialog: MatDialog,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.reconModuleId = parseInt(localStorage.getItem('reconModuleId'));
    this.reconModuleName = localStorage.getItem('reconModuleName');
    this.loadReconSourceList();
    this.loadChildPermissionsByUser();
    this.onPageSizeChanged();
  }

  loadChildPermissionsByUser() {
    this.userManagementService.loadChildPermissionsByUser(this.route.firstChild.routeConfig.path.replace(/-/g, ' ')).subscribe(
      (list: string[]) => {
        this.permissionList = list;
      },
      error => {
        console.log(error);
      }
    );
  }

  showData(radioType: boolean) {
    this.sourceDataList = [];
    if (radioType) this.showDataRadio = true;
    else this.showDataRadio = false;
    this.loadReconSourceList();
  }

  loadReconSourceList() {
    this.sourceList = [];
    this.sourceDataList = [];
    let checkSourceDataCount: number = 0;
    document.getElementById("myGrid").innerHTML = "";
    this.reconService.loadSourceListByRecon(this.reconModuleId).subscribe(
      (sourceList: ISourceConfig[]) => {
        this.sourceList = sourceList;
        this.sourceList.forEach((source) => {
          if (this.showDataRadio) {   //UnReconcile
            this.reconService.loadUnReconcileDataBySource(source).subscribe(
              data => {
                this.createTable(data, source);
              },
              error => {
                console.log(error);
                if (error.status == 404) {
                  if (++checkSourceDataCount == this.sourceList.length)
                    alert("No Data Available");
                }
              }
            );
          }
          else {    //Suppress
            this.reconService.loadSuppressDataBySource(source).subscribe(
              data => {
                this.createTable(data, source);
              },
              error => {
                console.log(error);
                if (error.status == 404) {
                  if (++checkSourceDataCount == this.sourceList.length)
                    alert("No Data Available");
                }
              }
            );
          }
        });//for
      },
      error => {
        console.log(error);
        if (error.status == 404) alert("Recon not configured properly");
      }
    );
  }


  createTable(data: any[], source: ISourceConfig) {
    this.gridOptions = {
      columnDefs: this.agGridColumns.generateUnReconcileColumns(data),
      rowData: data,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      enableCellTextSelection: true,
      floatingFilter: true,
      animateRows: true,
      pagination: true,
    };
    this.sourceDataList.push(source);
    let eGridDiv: HTMLElement = <HTMLElement>document.querySelector('#myGrid');
    new Grid(eGridDiv, this.gridOptions, { modules: [ClientSideRowModelModule] });
    this.gridOptions.columnApi.autoSizeAllColumns();
    source.gridOptions = this.gridOptions;
  }


  constructForceMatch() {

    let flag: boolean = true;
    let message: string;
    let amount: number[] = [];
    let isBaseSourceselected: boolean = false;
    let isExternalSourceselected: boolean = false;

    for (let source of this.sourceList) {
      let selectedRecords = source.gridOptions.api.getSelectedNodes().map(node => node.data);
      let sourceAmount: number = 0;
      if (selectedRecords.length != 0) {
        for (let record of selectedRecords) {
          sourceAmount = Math.abs(sourceAmount) + Math.abs(Number(record[source.amountColumn]));
          if (record.WORKFLOW_STATUS == 'Y') {
            flag = false;
            message = "The Selected Records Under Workflow";
            break;
          }
        }
        if (source.flag) {
          isBaseSourceselected = true;
        }
        else {
          isExternalSourceselected = true;
        }
        amount.push(sourceAmount);
      }
    }
    if (!isBaseSourceselected || !isExternalSourceselected) {
      flag = false;
      message = "Please Select The Records";
    }

    if (flag) {
      if (amount.every((val, i, arr) => val === arr[0]) || this.reconModuleName == 'COMPANYAGENCY' || this.reconModuleName == 'POS') {
//    if (amount.every((val, i, arr) => val === arr[0])) {

        let baseSelectedActivityTxns: ISelectedActivityTxns;
        let externalSelectedActivityTxns: ISelectedActivityTxns[] = [];
        let activityData: IActivityData;

        for (let source of this.sourceList) {
          let selectedRecords = source.gridOptions.api.getSelectedNodes().map(node => node.data);
          if (source.flag) {
            baseSelectedActivityTxns = {
              tableName: source.tableName,
              data: selectedRecords
            }
          }
          else {
            externalSelectedActivityTxns.push({
              tableName: source.tableName,
              data: selectedRecords
            })
          }
        }

        activityData = {
          base: baseSelectedActivityTxns,
          external: externalSelectedActivityTxns
        }

        this.reconService.loadSelectedTxnsAllColumns(activityData).subscribe(
          activityDataAllColumns => {
            const dialogRef = this.dialog.open(ForceMatch, {
              panelClass: 'mat-modal',
              width: '1047px',
              data: {
                reconName: this.reconModuleName,
                activityData: activityDataAllColumns
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.loadReconSourceList();
              }
            });

          },
          error => {
            console.log(error);
          }
        );
      }
      else {
        alert("Amounts not matching....!");
      }
    }
    else {
      alert(message);
    }

  }

  constructSameSideMatch() {

    let flag: boolean = true;
    let message: string;
    let selectedRecords: any[] = [];
    let debitCount: number = 0;
    let creditCount: number = 0;
    let debitAmount: number = 0;
    let creditAmount: number = 0;
    let externalSelectedActivityTxns: ISelectedActivityTxns[] = [];

    for (let source of this.sourceList) {
      if (source.tableName.includes('A1PT')) {
        selectedRecords = source.gridOptions.api.getSelectedNodes().map(node => node.data);
        if (selectedRecords.length != 0) {
          for (let record of selectedRecords) {
            if (record.WORKFLOW_STATUS == 'Y') {
              flag = false;
              message = "The Selected Records Under Workflow";
              break;
            }
            if (record.MT_TYPE == 'D') {
              debitCount++;
              debitAmount = Math.abs(debitAmount) + Math.abs(Number(record[source.amountColumn]));
            }
            else {
              creditCount++;
              creditAmount = Math.abs(creditAmount) + Math.abs(Number(record[source.amountColumn]));
            }
          }//record
          if (debitCount != 1 || creditCount == 0) {
            flag = false;
            message = "Only one debit and multiple credits are allowed";
            break;
          }
          else if (debitAmount != creditAmount) {
            flag = false;
            message = "Amounts not matching....!";
            break;
          }
          externalSelectedActivityTxns.push({
            tableName: source.tableName,
            data: selectedRecords
          })
        }//selectedRecords
        else {
          flag = false;
          message = "Please Select A1PT Records";
          break;
        }
      }
    }

    if (flag) {

      let activityData: IActivityData;
      activityData = {
        base: null,
        external: externalSelectedActivityTxns
      }

      this.reconService.loadSelectedTxnsAllColumns(activityData).subscribe(
        activityDataAllColumns => {
          const dialogRef = this.dialog.open(SameSideMatchComponent, {
            panelClass: 'mat-modal',
            width: '998px',
            data: {
              reconName: this.reconModuleName,
              activityData: activityDataAllColumns
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              console.log(result);
              this.loadReconSourceList();
            }
          });

        },
        error => {
          console.log(error);
        }
      );

    }
    else {
      alert(message);
    }

  }

  constructSuppress(action: string) {

    let selectedSourceCount = 0;
    let workFlowFlag: boolean = false;
    for (let source of this.sourceList) {
      if (source.gridOptions != undefined) {
        let selectedRecords = source.gridOptions.api.getSelectedNodes().map(node => node.data);
        if (selectedRecords.length != 0) {
          selectedSourceCount++;
        }
        for (let record of selectedRecords) {
          if (record.WORKFLOW_STATUS == 'Y') {
            workFlowFlag = true;
            break;
          }
        }
      }
    }

    if (selectedSourceCount == 0) {
      alert("Please Select Records");
    }
    else if (workFlowFlag) {
      alert("The Selected Records Under Workflow");
    }
    else {

      let baseSelectedActivityTxns: ISelectedActivityTxns;
      let externalSelectedActivityTxns: ISelectedActivityTxns[] = [];
      // let activityData: IActivityData;
      const activityData = new IActivityData();

      for (let source of this.sourceList) {
        if (source.gridOptions != undefined) {
          let selectedRecords = source.gridOptions.api.getSelectedNodes().map(node => node.data);
          if (source.flag && selectedRecords.length > 0) {
            baseSelectedActivityTxns = {
              tableName: source.tableName,
              data: selectedRecords
            }
            activityData.base = baseSelectedActivityTxns;
          }
          else {
            if (selectedRecords.length > 0) {
              externalSelectedActivityTxns.push({
                tableName: source.tableName,
                data: selectedRecords
              })
            }
          }
        }
      }
      activityData.external = externalSelectedActivityTxns;

      this.reconService.loadSelectedTxnsAllColumns(activityData).subscribe(
        activityDataAllColumns => {
          const dialogRef = this.dialog.open(SuppressComponent, {
            panelClass: 'mat-modal',
            width: '1047px',
            data: {
              reconName: this.reconModuleName,
              activityData: activityDataAllColumns,
              action: action
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.loadReconSourceList();
            }
          });

        },
        error => {
          console.log(error);
        }
      );

    }//else

  }


  constructCreateCase() {

    let selectedSourceCount = 0;
    let workFlowFlag: boolean = false;

    let selectedActivityTxns: ISelectedActivityTxns;
    let selectedActivityTxnsAllColumns: ISelectedActivityTxns;

    let amountColumn: string;

    for (let source of this.sourceList) {
      if (source.gridOptions != undefined) {
        let selectedRecords = source.gridOptions.api.getSelectedNodes().map(node => node.data);
        if (selectedRecords.length != 0) {
          selectedSourceCount = selectedSourceCount + selectedRecords.length;
        }
        for (let record of selectedRecords) {
          if (record.WORKFLOW_STATUS == 'Y') {
            workFlowFlag = true;
            break;
          }
        }
      }
    }

    if (selectedSourceCount == 0) {
      alert("Please Select Record");
    }
    if (selectedSourceCount > 1) {
      alert("Please Select Only One Record");
    }
    else if (workFlowFlag) {
      alert("The Selected Records Under Workflow");
    }
    else {

      for (let source of this.sourceList) {
        if (source.gridOptions != undefined) {
          let selectedRecords = source.gridOptions.api.getSelectedNodes().map(node => node.data);
          if (selectedRecords.length != 0) {
            amountColumn = source.amountColumn;
            selectedActivityTxns = {
              tableName: source.tableName,
              data: selectedRecords
            }
          }
        }
      }

      this.caseCreationService.checkCase(this.reconModuleName, selectedActivityTxns.tableName, selectedActivityTxns.data[0].SID).subscribe(
        caseFlag => {
          if (caseFlag) {
            alert("Case Already Created For This Record");
          }
          else {
            this.reconService.loadSelectedTxnsAllColumnsBySource(selectedActivityTxns).subscribe(
              data => {
                selectedActivityTxnsAllColumns = data;
                const dialogRef = this.dialog.open(CaseCreationComponent, {
                  data: {
                    reconName: this.reconModuleName,
                    selectedActivityTxns: selectedActivityTxnsAllColumns,
                    amountColumn: amountColumn,

                  },
                  panelClass: 'mat-modal',
                  width: '1050px',
                  disableClose: true
                });
                dialogRef.afterClosed().subscribe(result => {
                  if (result) {
                    this.loadReconSourceList();
                  }
                });

              },
              error => {
                console.log(error);
              }
            );
          }//else
        },
        error => {
          console.log(error);
        }
      );



    }

  }
  onPageSizeChangedsuppress(newPageSize) {
    for (let source of this.sourceList) {
      var value = ((document.getElementById("page-size")) as HTMLInputElement).value;
      if (source.gridOptions != undefined)
        source.gridOptions.api.paginationSetPageSize(Number(value));
    }
  }




  onPageSizeChanged() {
    for (let source of this.sourceList) {
      var value = ((document.getElementById("page-size")) as HTMLInputElement).value;
      if (source.gridOptions != undefined)
        source.gridOptions.api.paginationSetPageSize(Number(value));


    }
  }

  export(typeOfData: string, fileType: string) {
    if (this.sourceDataList != undefined && this.sourceDataList.length > 0) {
      this.dialog.open(DownloadTableFileComponent, {
        width: '500px',
        panelClass: 'mat-modal',
        data: {
          sourceList: this.sourceDataList,
          fileType: fileType,
          typeOfData: typeOfData
        }
      });
    }
    else {
      alert("No data to export");
    }
  }

}
