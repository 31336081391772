import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ICaseManagement, ICaseResponse } from '../models/case-management.model';

@Injectable({
  providedIn: 'root'
})
export class ClosedCasesService {

  constructor(private http: HttpClient) { }

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  loadClosedCases(reconModuleName: string): Observable<ICaseManagement[]> {
    return this.http.get<ICaseManagement[]>(environment.caseManagementUrl + '/loadClosedCases.ascent/'+reconModuleName, this.httpOptions);
  }

  reOpen(caseManagement: ICaseManagement, comments: String): Observable<ICaseResponse> {
    return this.http.put<ICaseResponse>(environment.caseManagementUrl + '/reOpen.ascent?comments='+comments, caseManagement, this.httpOptions);
  }

}
