import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DependencyService } from '../../services/dependency.service';
import { IJobScheduler, ISchedulerResponse, IJobDependency } from '../../models/scheduler.model';
import { AgGridColumnsService } from '../../services/ag-grid-columns.service';

@Component({
  selector: 'app-dependency',
  templateUrl: './dependency.component.html',
  styleUrls: ['./dependency.component.scss']
})
export class DependencyComponent implements OnInit {

  jobSchedulerList: IJobScheduler[];
  jobDependencyList: IJobDependency[];
  dependencyForm: FormGroup;
  submitted = false;
  gridApi: any;

  private columnDefs = [
    { headerName: 'JOBTYPE', field: 'jobType', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, width:250 },
    { headerName: 'JOBNAME', field: 'jobName', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, width:270 },
    { headerName: 'DEPENDENCIES', field: 'dependencies', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, width:440 },
  ];

  constructor(private dependencyService: DependencyService,
    private agGridColumnsService: AgGridColumnsService,
    private formBuilder: FormBuilder) { }


  ngOnInit() {
    this.loadAllJobSchedulers();
    this.loadAllJobDependencies();
    this.dependencyForm = this.formBuilder.group({
      jobType: ['', Validators.required],
      jobId: ['', Validators.required],
      dependencies: ['', Validators.required]
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  get f() { return this.dependencyForm.controls; }

  loadAllJobSchedulers() {
    this.dependencyService.loadAllJobSchedulers().subscribe(
      (jobSchedulerList: IJobScheduler[]) => {
        this.jobSchedulerList = jobSchedulerList;
      },
      error => {
        console.log(error);
      }
    );
  }

  onDependencySubmit() {
    this.submitted = true;
    if (this.dependencyForm.invalid) {
      return;
    }
    this.dependencyForm.value.dependencies = this.dependencyForm.value.dependencies.toString();
    this.dependencyService.saveSchedulerDependency(this.dependencyForm.value).subscribe(
      (schedulerResponse: ISchedulerResponse) => {
        alert(schedulerResponse.message);
        this.loadAllJobDependencies();
      },
      error => {
        console.log(error);
      }
    );
  }

  loadAllJobDependencies() {
    this.dependencyService.loadAllJobDependencies().subscribe(
      (jobSchedulerList: IJobDependency[]) => {
        this.jobDependencyList = jobSchedulerList;
      },
      error => {
        console.log(error);
      }
    );
  }

  deleteDependency() {
    if (this.gridApi.getSelectedRows().length == 0) {
      alert("Please Select Record");
    }
    else {
      if (confirm("Do you want to delete this dependency")) {
        this.dependencyService.deleteDependency(this.gridApi.getSelectedRows()[0].id).subscribe(
          (response: ISchedulerResponse) => {
            alert(response.message);
            this.gridApi.updateRowData({ remove: this.gridApi.getSelectedRows() });
          },
          error => {
            console.log(error);
          }
        );
      }
    }
  }

}
