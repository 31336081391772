import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UsersService } from '../services/users.service';
import { IGroupUser, IUsersData } from '../models/users.model';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  userId: any;
  userName: any;
  password: any;
  confirmPassword: any;
  mailId: any;
  phoneNo: any;
  reportingUser: any;
  ldapUser: any;
  userNameList: any=[];
  users:any;
  organization:any;
  userNameList1: any[]=[];
  status:any;
 
  submitted = false;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  constructor(public dialogRef: MatDialogRef<AddUserComponent>, public usersService: UsersService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.users =this.formBuilder.group({
        userId:new FormControl('',Validators.required),
        userName:new FormControl('',Validators.required),
        mailId :new FormControl('',Validators.required),
        phoneNo:new FormControl('',Validators.required),
        reportingUser:new FormControl(),
        ldapUser:new FormControl('',Validators.required),
        status:new FormControl('',Validators.required),
        password:new FormControl('',Validators.required),
        confirmPassword:new FormControl('',Validators.required)
     });
    }

  ngOnInit() {
    this. loadUsers();
  }
  get fval() {
    return this.users.controls;
  }
  saveUsers() {
    this.submitted = true;   
    console.log('Submitted'); 
    //this.closeDialog();
  }
  
  closeDialog(): void {
     this.dialogRef.close();
   }

   loadUsers(){
    this.usersService.getCheckerUser().subscribe(
     
    data =>{
      for (const [key, value] of Object.entries(data)) {
        this.userNameList.push({name:key,value:value});
        }
},
      error => {
        console.log(error);
      }
    );
  }


  addUserDetails() {
    this.submitted = true;
    if (this.users.invalid) {
      return;
    } else {
      if (this.password != this.confirmPassword) {
        alert("Password & Confirm Password does not match.")
      } else {
        let newColumnData = {
          "user_id": this.userId,
          "user_name": this.userName,
          "email_id": this.mailId,
          "phon_number": this.phoneNo,
          "reporting_user_id": this.reportingUser,
          "organizationid": "myorg",
          "is_ldap_user": this.ldapUser,
          "is_active": this.status,
          "password": this.password
        };
        this.usersService.addUserDetails(newColumnData).subscribe(
          userResponse => {
            alert(userResponse.message);
            if (userResponse.message.includes("Password Policy")) {
              console.log("poonam password", userResponse.message.includes("Password Policy"))
            }
            else
              this.dialogRef.close("true");

          },
          error => {
            console.log(error);
            alert(error.error.details);
          }
        );
      }
    }
  }
}