import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ICaseManagement, ICaseResponse, IClose, IReOpen, IUpdateComment } from '../models/case-management.model';

@Injectable({
  providedIn: 'root'
})
export class UpdateCommentService {

  constructor(private http: HttpClient) { }

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  updateComments(caseManagement: ICaseManagement, comments: String): Observable<ICaseResponse> {
    return this.http.post<ICaseResponse>(environment.caseManagementUrl + '/updateComments.ascent?comments='+comments, caseManagement, this.httpOptions);
  }

  loadCloseComments(caseId: Number): Observable<IClose[]> {
    return this.http.get<IClose[]>(environment.caseManagementUrl + '/loadCloseComments.ascent?caseId='+caseId, this.httpOptions);
  }

  downloadFile(sno: Number) {
    return this.http.get(environment.caseManagementUrl + '/downloadFile.ascent?sno=' + sno,
      {
        headers: new HttpHeaders().append('Accept', 'text; charset=utf-8'),
        observe: 'response',
        responseType: 'text'
      });
  }

  loadUpdatedComments(caseId: Number): Observable<IUpdateComment[]> {
    return this.http.get<IUpdateComment[]>(environment.caseManagementUrl + '/loadUpdatedComments.ascent?caseId='+caseId, this.httpOptions);
  }

  loadReOpenComments(caseId: Number): Observable<IReOpen[]> {
    return this.http.get<IReOpen[]>(environment.caseManagementUrl + '/loadReOpenComments.ascent?caseId='+caseId, this.httpOptions);
  }

}
