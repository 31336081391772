import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulerRoutingModule } from './scheduler-routing.module';
import { SchedulerComponent } from './scheduler.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { DependencyComponent } from './components/dependency/dependency.component';
import { MaterialModule } from 'src/app/shared/modules/material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddConfigurationComponent } from './components/configuration/add-configuration/add-configuration.component';
import { AgGridModule } from 'ag-grid-angular/dist/aggrid.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { EditConfigurationComponent } from './components/configuration/edit-configuration/edit-configuration.component';

@NgModule({
  declarations: [SchedulerComponent, ConfigurationComponent, DependencyComponent, AddConfigurationComponent, EditConfigurationComponent],
  imports: [
    CommonModule,
    SchedulerRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,    
    AgGridModule.withComponents([]),
    NgxMaterialTimepickerModule
  ],
  entryComponents:[
    AddConfigurationComponent,
    EditConfigurationComponent
  ]
})
export class SchedulerModule { }
