
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog, MatDialogRef  } from '@angular/material';
import { EtlService } from '../../../services/etl.service';
import { FormGroup, Validators, FormControl,FormBuilder } from '@angular/forms';
@Component({
    selector: 'editValidation',
    templateUrl: 'ft-valid-edit.html'  
  })
 
  export class editValidation implements OnInit {
    config_data:any;  
    local_data : any;
    validationEdit:any;
    submitted = false;
    columnName:any;
    COLUMN_DATA_TYPE:any;
    rowData:any;
    ngOnInit(): void {
      this.loadTempColumns();
      this.local_data=this.data.editSelectedData;
      console.log("data",this.data)
      console.log("local_data",this.local_data);
      console.log("file id",this.data.fileId);
      this.selectOperationValue=this.data.editSelectedData.OPERATION_NAME;
      this.config_data=this.data;
      console.log("config_data",this.config_data);
      
    }
    public templetColumnNames:any=[];
    selectOperationValue:string;
    constructor(@Inject(MAT_DIALOG_DATA) public data:any,public etlService:EtlService,
    public dialogRef:MatDialogRef<editValidation>) {

      this.validationEdit = new FormGroup({
        ColumnName:new FormControl('', Validators.required),
        Category:new FormControl('', Validators.required),
        Operation:new FormControl('', Validators.required),
        STARTS_WITH:new FormControl(),
        ENDS_WITH:new FormControl(),
        CONTAINS_CONSTANTS:new FormControl(),
        PATTERN_CHECK:new FormControl(),
        LENGTH_CHECK:new FormControl(),
        LENGTH_TYPE:new FormControl(),
        dataType:new FormControl()
        // STARTS_WITH:new FormControl('', Validators.required),
        // ENDS_WITH:new FormControl('', Validators.required),
        // CONTAINS_CONSTANTS:new FormControl('', Validators.required),
        // PATTERN_CHECK:new FormControl('', Validators.required),
        // LENGTH_CHECK:new FormControl('', Validators.required),
        // LENGTH_TYPE:new FormControl('', Validators.required),

       
        
      });
        
    } 

    get fval() {
      return this.validationEdit.controls;
      }
      signup(){
        this.submitted = true;
        if (this.validationEdit.invalid) {
     
          return;
        }
       
       
        }

    changeSelectOperation(value)
    {
      
        this.selectOperationValue = value;
        this.validationEdit.get('STARTS_WITH').setValidators(null);
        this.validationEdit.get('ENDS_WITH').setValidators(null);
        this.validationEdit.get('CONTAINS_CONSTANTS').setValidators(null);
        this.validationEdit.get('PATTERN_CHECK').setValidators(null);
        this.validationEdit.get('LENGTH_CHECK').setValidators(null);
        this.validationEdit.get('LENGTH_TYPE').setValidators(null);
        this.validationEdit.patchValue({
          STARTS_WITH: undefined,
          ENDS_WITH: undefined,
          CONTAINS_CONSTANTS: undefined,
          PATTERN_CHECK: undefined,
          LENGTH_CHECK: undefined,
          LENGTH_TYPE: undefined,
        })
        if(this.selectOperationValue==='Starts With')
        {
          const STARTS_WITH = <FormControl>this.validationEdit.get('STARTS_WITH');
          STARTS_WITH.setValidators([Validators.required, ])
       }else if(this.selectOperationValue==='Ends With')
       {
        const ENDS_WITH = <FormControl>this.validationEdit.get('ENDS_WITH');
        ENDS_WITH.setValidators([Validators.required, ]);
       }else if(this.selectOperationValue==='Contains Data')
       {
        const CONTAINS_CONSTANTS = <FormControl>this.validationEdit.get('CONTAINS_CONSTANTS');
        CONTAINS_CONSTANTS.setValidators([Validators.required, ]);
       }else if(this.selectOperationValue==='Pattern Check')
       {
        const PATTERN_CHECK = <FormControl>this.validationEdit.get('PATTERN_CHECK');
        PATTERN_CHECK.setValidators([Validators.required, ]);
       }else if(this.selectOperationValue==='Valid Length')
       {
        const LENGTH_CHECK1 = <FormControl>this.validationEdit.get('LENGTH_CHECK');
        LENGTH_CHECK1.setValidators([Validators.required, ]);

        const LENGTH_TYPE1 = <FormControl>this.validationEdit.get('LENGTH_TYPE');
        LENGTH_TYPE1.setValidators([Validators.required, ]);
       }
        
    }
    
    loadTempColumns()
    {
      console.log("select data   :",this.data.editSelectedData.TEMPLET_ID)
      this.etlService.getTemplateColumnNameData(this.data.editSelectedData.TEMPLET_ID).subscribe(
        data=>{
          this.rowData=data;
            for (const [key, value] of Object.entries(data)) {
            
                this.templetColumnNames.push({name:key,value:value});
                }

                for(const [key, value] of Object.entries(this.rowData)){
                  if (key === this.local_data.COLUMN_NAME){
                      this.COLUMN_DATA_TYPE=value;
                   }
                }
                this.columnName=this.data.editSelectedData.COLUMN_NAME+' '+this.COLUMN_DATA_TYPE;
          
        }
      )
    }

    saveValidationConfig()
    {
      console.log("config data  :",this.config_data)
      if (this.validationEdit.invalid) {
        return;
      }
     
      
     this.etlService.getEditValidationConfig(this.config_data).subscribe((data:any)=>{
       
      this.dialogRef.close(data);
     }) 


    }
    changeColumnName(value)
    {
      this.selectOperationValue="";
      var str=value.split(" ");
      this.local_data.COLUMN_NAME=str[0];
      this.COLUMN_DATA_TYPE=str[1];
      this.validationEdit.patchValue({
       Operation:"",
       STARTS_WITH:undefined,
        ENDS_WITH:undefined,
        CONTAINS_CONSTANTS:undefined,
        PATTERN_CHECK:undefined,
        LENGTH_CHECK:undefined,
        LENGTH_TYPE:undefined,
        })
this.changeSelectOperation(this.validationEdit.Operation);
    }

    omit_special_char(e) {
      var k;
      document.all ? k = e.keyCode : k = e.which;
      return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  }