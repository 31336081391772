import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { stringify } from 'querystring';
import { IUsersResponse } from 'src/app/modules/parent/user-management/models/user-management.model';
import { ILoginResponse } from 'src/app/modules/login/login.model';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private userLoggedIn = new Subject<boolean>();

  httpOptions = { headers: new HttpHeaders({
                 'Access-Control-Allow-Origin': '*',
                 'Access-Control-Allow-Credentials': 'true',
                 'Access-Control-Allow-Headers': 'Content-Type',
                 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                 'Content-Type': 'application/json'
    }) };

  constructor(private http: HttpClient) {
    this.userLoggedIn.next(false);
   }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  logout(user) {
    return this.http.post(environment.userManagementUrl+'/login/logout/'+user,this.httpOptions);
  }

  changePassword(changePasswordForm: any): Observable<IUsersResponse> {
    return this.http.post<IUsersResponse>(environment.userManagementUrl+'/changePassword.ascent', changePasswordForm, this.httpOptions);
  }

  reset(user) {
    return this.http.post(environment.userManagementUrl+'/login/reset/'+user,this.httpOptions);
  }

  stayLoggedIn(): Observable<ILoginResponse> {
    return this.http.get<ILoginResponse>(environment.userManagementUrl+'/login/stayLoggedIn',this.httpOptions);
  }

}