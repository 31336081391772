import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { EditEscMatrixComponent } from './edit-esc-matrix/edit-esc-matrix.component';
import { IReconDepartMent } from '../../models/escalationmatrix.model';
import { EscalationMatrixService } from '../../services/escalation-matrix.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-esc-matrix',
  templateUrl: './esc-matrix.component.html',
  styleUrls: ['./esc-matrix.component.scss']
})
export class EscMatrixComponent implements OnInit {
  private gridApi: any;
  private gridColumnApi: any;
  public rowSelection: any;

  reconDepartMentList: IReconDepartMent[] = [];
  departmentName: any;
  escalatedPerson: any;
  mailId: any;
  status: any;
  public rowData: any;
  public columnDefs: any;
  editSelectedData: any;
  submitted = false;
  Escalation:any;
  constructor(public dialog: MatDialog, public escalationServic: EscalationMatrixService,private formBuilder: FormBuilder) {
    this.rowSelection = "single";
    this.Escalation = this.formBuilder.group({
      departmentName: new FormControl('', Validators.required),
      escalatedPerson: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
       mailId:new FormControl('', Validators.required),
    });
  }
  columnDefesc = [
    {headerName: 'ID', field: 'id', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true, suppressSizeToFit: true},
   {headerName: 'DEPARTMENT', field: 'departMent', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
   {headerName: 'ESCALATED PERSON', field: 'escalatedPerson', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
   {headerName: 'MAIL ID', field: 'mailid', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
   {headerName: 'STSTUS', field: 'status', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
   {headerName: 'ACTIVITY INDEX', field: 'activeIndex', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
  {headerName: 'VERSION', field: 'version', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
 
];
  ngOnInit() {
    this.loadReconDepartMent();
    this.loadescalationForUser();
  }
  get fval() {
    return this.Escalation.controls;
  }
  //load departMent for escalation
  loadReconDepartMent() {
    this.escalationServic.loadReconDepartMent().subscribe(
      (reconDepartMentList: IReconDepartMent[]) => {
        this.reconDepartMentList = reconDepartMentList;
      },
      error => {
        console.log(error);
      }
    );
  }
  //save Escalation Records 
  saveEscalationMatrix() {
 
      this.submitted = true;
    
      if (this.Escalation.invalid) {
        return;
       }
      //alert("departMentName:"+this.departmentName)
      console.log("departMantName --> ", this.departmentName, this.escalatedPerson, this.mailId, this.status)
      let objData = {
        "departMent": this.departmentName,
        "escalatedPerson": this.escalatedPerson,
        "mailid": this.mailId,
        "status": this.status,
      };
      console.log("MyObject:" + objData)
      this.escalationServic.saveEscalation(objData).subscribe(
        data => {
          alert(data.message);
         this.loadescalationForUser();
         this.departmentName =[],
         this.escalatedPerson = [],
         this.mailId = [],
         this.status = []
         this.submitted = false;
       
       
        },

        error => {
          console.log(error);
        }
      );
    


  }
  //getting escalation records 
  loadescalationForUser() {

    this.escalationServic.getEscalationData().subscribe(
      (escalationList) => {
        this.rowData = escalationList;
        this.columnDefs = this.columnDefesc;


      },
      error => {
        console.log(error);
      }
    );
  }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            filter: true
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  //for Update Escalation
  openEditDialog(): void {
    var editSelectedData = this.gridApi.getSelectedRows();
    console.log("Edit Selected Row --> ", editSelectedData);
    if (editSelectedData == "") {
      alert("please select a record");
    }
    else {


      const dialogRef = this.dialog.open(EditEscMatrixComponent, {
        data: editSelectedData,
        panelClass: 'mat-modal',
        width: '500px'
      });


      dialogRef.afterClosed().subscribe(result => {
        this. loadescalationForUser() ;
        console.log('The dialog edit was closed', result);
        if (result !== undefined) {
          this.gridApi.updateRowData({ update: [result] });

        }
      });
    }
  }
  //delete Escalation
  deleteRow() {
    var selectedData = this.gridApi.getSelectedRows();
    console.log("selected data", selectedData);
    if (selectedData == "") {
      alert("please select a record to delete");
    } else if (selectedData !== "") {
      if (confirm("Do you want to delete this record")) {
        console.log("selected data", selectedData[0].id)
        this.escalationServic.deleteEscalation(selectedData[0])
          .subscribe((activityResponse: any) => {

            alert(activityResponse.message);
          },
            error => {
              console.log(error);
            });
        this.gridApi.updateRowData({ remove: selectedData });
      }

    }

  }
  runEscalation() {
    this.escalationServic.runEscalationData().subscribe(
      () => {

      },
      error => {
        console.log(error);
      }
    );


  }
}
