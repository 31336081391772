import { Component, OnInit } from '@angular/core';
import { ICaseManagement } from '../../models/case-management.model';
import { MatDialogRef, MatDialog } from '@angular/material';
import { CaseDataComponent } from '../case-data/case-data.component';
import { ClosedCasesService } from '../../services/closed-cases.service';
import { ReOpenComponent } from './re-open/re-open.component';
import { AgGridColumnsService } from '../../services/ag-grid-columns.service';
import { UpdateCommentsComponent } from '../update-comments/update-comments.component';
import { UserManagementService } from '../../../user-management/services/user-management.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-closed-cases',
  templateUrl: './closed-cases.component.html',
  styleUrls: ['./closed-cases.component.scss']
})
export class ClosedCasesComponent implements OnInit {

  caseManagementList: ICaseManagement[] = [];
  columnDefs: any;
  rowData: any = [];
  gridApi: any;
  permissionList: string[] = [];

  constructor(public dialogRef: MatDialogRef<ClosedCasesComponent>,
    public closedCasesService: ClosedCasesService,
    public agGridColumns: AgGridColumnsService,
    public dialog: MatDialog,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.loadChildPermissionsByUser();
    this.loadClosedCases();
  }

  loadChildPermissionsByUser() {
    this.userManagementService.loadChildPermissionsByUser(this.route.firstChild.routeConfig.path.replace(/-/g,' ')).subscribe(
     (list: string[]) => {
       this.permissionList = list;
     },
     error => {
       console.log(error);
     }
   );
 }

  loadClosedCases() {
    this.closedCasesService.loadClosedCases(localStorage.getItem('reconModuleName')).subscribe(
      (caseManagementList: ICaseManagement[]) => {
        this.caseManagementList = caseManagementList;
        this.columnDefs = this.agGridColumns.generateCaseColumns(caseManagementList);
        this.rowData = caseManagementList;
      },
      error => {
        console.log(error);
      }
    );
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  onRowClicked(event: any) {
    this.dialog.open(CaseDataComponent,
      {
        data: {
          caseData: event.data.caseObjectData
        },
        panelClass: 'mat-modal',
        width: '998px'
      }
    );
  }

  constructReOpen() {
    if (this.gridApi.getSelectedRows().length == 0) {
      alert("Please Select Record");
    }
    else {
      let caseManagement: ICaseManagement;
      caseManagement = this.gridApi.getSelectedRows()[0];
      const dialogRef = this.dialog.open(ReOpenComponent,
        {
          data: {
            selectedCase: caseManagement
          },
          panelClass: 'mat-modal',
          width: '500px',         
          disableClose: true
        }
      );
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.gridApi.updateRowData({ remove: this.gridApi.getSelectedRows() });
        }
      });
    }
  }

  constructUpdateComments() {
    if (this.gridApi.getSelectedRows().length == 0) {
      alert("Please Select Record");
    }
    else {
      const dialogRef = this.dialog.open(UpdateCommentsComponent,
        {
          data: {
            selectedCase: this.gridApi.getSelectedRows()[0],
            caseType: 'Closed'
          },
          panelClass: 'mat-modal',
          width: '998px',         
          disableClose: true
        }
      );
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.gridApi.updateRowData({ remove: this.gridApi.getSelectedRows() });
        }
      });
    }
  }

  onPageSizeChanged() {
    var value =((document.getElementById("page-size"))as HTMLInputElement).value;
    this.gridApi.paginationSetPageSize(Number(value));
  }
  
}