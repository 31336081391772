import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment'; 
import { Observable } from 'rxjs';
import { IReconModule, IActivityResponse, IReconProcessStatus, RollBackResponse } from '../models/recon.model';
import { IServerResponse } from '../../settelement/modules/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class RuleService {
    constructor(private http: HttpClient) { }

    httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    getStagingModel(){
      return this.http.get(environment.apiUrl + '/getStagingModel.ascent', this.httpOptions);
    }

    getFileNames(){
      return this.http.get(environment.apiUrl + '/getFileNames.ascent', this.httpOptions);
    }

    getFileNames2(){
      return this.http.get(environment.apiUrl + '/getFileNames.ascent', this.httpOptions);
    }
    getTablesFields(tableName:string){
      console.log("Table name 11 :",tableName)
      console.log("Table name 11 :",environment.reconUrl)
      return this.http.get(environment.reconUrl+'/getTableFields.ascent?tableName='+tableName, this.httpOptions);
    }
    getTablesFields2(tableName:string){
      console.log("Table name 22 :",tableName)
      console.log("Table name 22 :",environment.reconUrl)
      return this.http.get(environment.reconUrl+'/getTableFields.ascent?tableName='+tableName, this.httpOptions);
    }
    loadModuleNames(){
      return this.http.get(environment.reconUrl + '/loadReconModules.ascent', this.httpOptions);
    }
    loadReconNames(){
      return this.http.get(environment.reconUrl + '/loadReconModules.ascent', this.httpOptions);
    }
    getTablesBasedonModule(moduleId:string){
      return this.http.get(environment.reconUrl + '/getTablesBasedonModule.ascent?moduleId=' + moduleId, this.httpOptions);
    }
    getTablesBasedonModuleAndReconConfig(moduleId:string){
      return this.http.get(environment.reconUrl + '/getTablesBasedonModuleAndReconConfig.ascent?moduleId=' + moduleId, this.httpOptions);
    }
    
    getReconConfig(moduleId:any)

    {
      return this.http.get(environment.reconUrl + '/getReconConfigTableData.ascent?moduleId='+moduleId, this.httpOptions);
    }
    saveReconConfig(reconConfigData: any):Observable<any>
    {
      return this.http.post<IActivityResponse>(environment.reconUrl + '/saveReconConfig.ascent',JSON.stringify(reconConfigData), this.httpOptions);
    }
    runRecon(reconId:any, userName:any)
    {
     return this.http.get(environment.reconUrl+'/runReconNew/'+reconId+'/'+userName, this.httpOptions);
    }

    loadReconModules(){
      return this.http.get<IReconModule []>(environment.reconUrl + '/loadReconModules.ascent', this.httpOptions);
    }
     //saveReconAddRule
     saveAddrule(objData: any):Observable<any>{
      return this.http.post<any>(environment.reconUrl+'/saveRule',JSON.stringify(objData) , this.httpOptions);
    }
    getRuleTableData(moduleId:any)
    {
     return this.http.get(environment.reconUrl+'/getRuleTableData?moduleId='+moduleId, this.httpOptions);
    }
    saveSources(objData : any[]){
      return this.http.post<IActivityResponse>(environment.reconUrl+'/saveSources',JSON.stringify(objData) , this.httpOptions);
    }     
    getRulesRelatedRecon(reconId:any)
    {
     return this.http.get(environment.reconUrl+'/getRulesRelatedRecon?reconId='+reconId, this.httpOptions);
    }

    
    getRulesDetails(data:any)
    {
      console.log("IDs",data)
     return this.http.get(environment.reconUrl+'/getData?moduleId='+data.moduleId+"&ruleId="+data.ruleId, this.httpOptions);
    }
    
    deleteRuleDetails(data:any) {
     return this.http.get(environment.reconUrl+'/deleteSources?sids='+data,this.httpOptions);
    }
    deleteRuleById(data: any):Observable<any>{
      return this.http.get(environment.reconUrl+'/deleteRuleById?ruleId='+data,this.httpOptions);
    }
    updateReconRule(updateReconRule: any):Observable<any>{
      return this.http.put<any>(environment.reconUrl + '/updateReconRule.ascent',JSON.stringify(updateReconRule), this.httpOptions);
    }
    deleteConfById(data: any):Observable<any>{
      return this.http.get(environment.reconUrl+'/deleteConfById?sids='+data,this.httpOptions);
    }
    updateReconConfig(reconConfigData: any):Observable<any>{
      return this.http.put<any>(environment.reconUrl + '/updateReconConfig.ascent',JSON.stringify(reconConfigData), this.httpOptions);
    }

    loadReconProcessList(moduleId:any): Observable<IReconProcessStatus[]>
    {
      return this.http.get<IReconProcessStatus[]>(environment.reconUrl + '/loadReconProcessList.ascent?moduleId='+moduleId, this.httpOptions);
    }
    reconRollBack(reconProcessStatus:IReconProcessStatus): Observable<RollBackResponse>
    {
      return this.http.put<RollBackResponse>(environment.reconUrl + '/reconRollBack.ascent', reconProcessStatus, this.httpOptions);
    }

    loadReconMactingData(){
      return this.http.get<IServerResponse>(environment.reconUrl + '/matchingElements', this.httpOptions);
    }

    loadReconView(moduleName:any){
      return this.http.get<IServerResponse>(environment.reconUrl + '/reconview/' + moduleName, this.httpOptions);
    }
}