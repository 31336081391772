import { Component, OnInit, Inject } from '@angular/core';
import { ICaseManagement, IClose, IReOpen, IUpdateComment } from '../../models/case-management.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UpdateCommentService } from '../../services/update-comment.service';
import { AgGridColumnsService } from '../../services/ag-grid-columns.service';
import { DownloadFileComponent } from './download-file.component';

@Component({
  selector: 'app-update-comments',
  templateUrl: './update-comments.component.html',
  styleUrls: ['./update-comments.component.scss']
})
export class UpdateCommentsComponent implements OnInit {

  caseManagement: ICaseManagement;
  caseType: string;
  updateCommentsForm: FormGroup;
  submitted: boolean = false;
  loading = false;
  gridApi: any;
  frameworkComponents: any;

  closeColumnDefs: any;
  closeRowData: any = [];

  commentsColumnDefs: any;
  commentsRowData: any = [];

  reOpenColumnDefs: any;
  reOpenRowData: any = [];

  constructor(public caseManagementService: UpdateCommentService,
    public dialogRef: MatDialogRef<ICaseManagement>,
    private formBuilder: FormBuilder,
    public agGridColumns: AgGridColumnsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.caseManagement = data.selectedCase;
    this.caseType = data.caseType;
    this.frameworkComponents = {
      downloadFileComponent: DownloadFileComponent
    };

  }

  ngOnInit() {
    this.loadCaseUpdatedComments();
    this.loadCaseCloseComments();
    this.loadCaseReOpenComments();
    this.updateCommentsForm = this.formBuilder.group({
      comments: ['', Validators.required]
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  loadCaseUpdatedComments() {
    this.caseManagementService.loadUpdatedComments(this.caseManagement.caseId).subscribe(
      (updateCommentList: IUpdateComment[]) => {
        this.commentsColumnDefs = this.agGridColumns.generateCommentsColumns(updateCommentList);
        this.commentsRowData = updateCommentList;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadCaseCloseComments() {
    this.caseManagementService.loadCloseComments(this.caseManagement.caseId).subscribe(
      (closeCaseList: IClose[]) => {
        this.closeColumnDefs = this.agGridColumns.generateCommentsColumns(closeCaseList);
        this.closeRowData = closeCaseList;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadCaseReOpenComments() {
    this.caseManagementService.loadReOpenComments(this.caseManagement.caseId).subscribe(
      (reOpenCommentsList: IReOpen[]) => {
        this.reOpenColumnDefs = this.agGridColumns.generateCommentsColumns(reOpenCommentsList);
        this.reOpenRowData = reOpenCommentsList;
      },
      error => {
        console.log(error);
      }
    );
  }

  get f() { return this.updateCommentsForm.controls; }

  updateComments() {

    this.submitted = true;
    if (this.updateCommentsForm.invalid) {
      return;
    }
    this.loading = true;

    this.caseManagementService.updateComments(this.caseManagement, this.updateCommentsForm.value.comments).subscribe(
      caseResponse => {
        alert(caseResponse.message);
        this.loading = false;
        this.dialogRef.close("false");
      },
      error => {
        console.log(error);
      }
    );

  }

}
