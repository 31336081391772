import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { IServerResponse, IReconReportInfo } from '../modules/server-response.model';
import { ServerResponse } from 'http';

@Injectable({
  providedIn: 'root'
})
export class SettelementService {
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  getReconReportInfoName() : Observable<IServerResponse>{
    return this.http.get<IServerResponse>(environment.reconUrl+'/getReconReportInfoName', this.httpOptions);
  }

  getReconNames(){
    return this.http.get(environment.reconUrl + '/loadReconModules.ascent', this.httpOptions);
  }
  saveReconReportInfo(data:any): Observable<any>{
    return this.http.post<IServerResponse>(environment.reconUrl+'/saveReconReportInfo', data, this.httpOptions); 
  }

  loadReportList(reconName:string): Observable<any[]>{
      console.log("reconName",reconName);
    return this.http.get<any[]>(environment.reconUrl + '/loadReportList.ascent?reconName=' + reconName, this.httpOptions);
  }

  getReportInfo(requestData:any){
    return this.http.post<any[]>(environment.reconUrl + '/loadReportData.ascent?map='+encodeURI(JSON.stringify(requestData)), this.httpOptions);
  
     
  }

  ttumStatusUpdate(requestData:any){
    return this.http.post<any[]>(environment.reconUrl + '/updateTtumReconStatus.ascent?map='+encodeURI(JSON.stringify(requestData)), this.httpOptions);

     
  }
  getReconReportInfo(reconName:any){
    return this.http.get<IReconReportInfo[]>(environment.reconUrl+'/readReportInfo.ascent/'+reconName, this.httpOptions);
  }
  deleteReconRecportInfo(data:any):Observable<any>{
    return this.http.get<ServerResponse>(environment.reconUrl+'/deleteReportInfoByreportId.ascent?reportId='+data,this.httpOptions);
  }

  insertGLBalancingRecords(requestData:any){
    return this.http.post<any[]>(environment.reconUrl + '/insertGLBalancingRecords.ascent?map='+encodeURI(JSON.stringify(requestData)), this.httpOptions);
  
     
  }
}