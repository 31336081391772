export class CaseDepartment
{
  public departmentId : Number;
  public departmentName : String;
}

export class CaseReason
{
  public reasonId : Number;
  public reason : String;
}

export class ICaseManagement
{
  public caseId : Number;
  public sid : Number;
  public department : String;
  public reconModule : String;
  public source : String;
  public createdBy : String;
  public assignedTo : String;
  public reason : String;
  public narration : String;
  public amount : Number;
  public status : String;
  public allowedApprovers : String;
  public caseObjectData : any;
  public version : Number;
  public createdOn : String;
  public updatedOn : String;
}

export class ICaseResponse
{
  public status:boolean;
  public message: string;
}

export class IClose
{
  public caseId: Number;
  public fileName: String;
  public fileData: String;
  public comments: String;
  public createdOn: String;
  public createdBy: String;
  public caseManagement: ICaseManagement;
}

export class IReOpen
{
  public caseId: Number;
  public fileName: String;
  public fileData: String;
  public comments: String;
  public createdOn: String;
  public createdBy: String;
}

export class IUpdateComment
{
  public caseId: Number;
  public fileName: String;
  public fileData: String;
  public comments: String;
  public createdOn: String;
  public createdBy: String;
}