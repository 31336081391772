import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {MasterTable, MasterTableResponse } from '../../master-setup/models/master-setup.model';
import { encode } from 'punycode';

@Injectable({
  providedIn: 'root'
})
export class MasterTableService {

  constructor(private http: HttpClient) { }

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
 

  loadAllMasterTableToSetup(): Observable<MasterTable[]> {
    return this.http.get<MasterTable[]>(environment.reconUrl + '/loadAllMasterTable/screenSetup', this.httpOptions);
  }

  loadAllMasterTable(): Observable<MasterTable[]> {
    return this.http.get<MasterTable[]>(environment.reconUrl + '/loadAllMasterTable/masterData', this.httpOptions);
  }
  saveScreenSetup(screenData: any):Observable<any>
    {
      return this.http.post<any>(environment.reconUrl + '/saveScreenSetup.ascent',JSON.stringify(screenData), this.httpOptions);
    }

    addNewColumnData(newColumnData: any):Observable<any>
    {
      console.log("In Master service :",newColumnData);
      return this.http.post<any>(environment.reconUrl + '/addNewColumnData.ascent',JSON.stringify(newColumnData), this.httpOptions);
    }

    loadMasterTableNames(tableName:string): Observable<any[]>{
      
      return this.http.get<any[]>(environment.reconUrl + '/loadMasterTableNames.ascent?tableName=' + tableName, this.httpOptions);
    }
     //Vaiji

     launchReport(userName:any, moduleName:any){
      return this.http.get(environment.reconUrl+'/launchReport.ascent?userName='+userName+'&moduleName='+moduleName, this.httpOptions);
    }
    
    loadDashboard(userName:any, moduleName:any){
      return this.http.get(environment.reconUrl+'/loadDashboard.ascent?userName='+userName+'&moduleName='+moduleName, this.httpOptions);
    }
    deleteMasterData(data:any):Observable<any>{
      return this.http.get<MasterTableResponse>(environment.reconUrl+'/deleteMasterDataByID?id='+data,this.httpOptions);
    }
     updateMasterScreeen(updateMasterScreeen:any):Observable<any>{
      return this.http.put<MasterTableResponse>(environment.reconUrl +'/updateMasterScreen.ascent',JSON.stringify(updateMasterScreeen), this.httpOptions);
    }

    
    addMasterDatass(columnData: any):Observable<any>
    {
      console.log("In Master service master data :",columnData);
      return this.http.post<any>(environment.reconUrl + '/addMasterDataintoDB.ascent?map='+encodeURI(JSON.stringify(columnData)), this.httpOptions);
    }

    updateMasterDatass(columnData: any):Observable<any>
    {
      console.log("In Master service master data :",columnData);
      return this.http.post<any>(environment.reconUrl + '/updateMasterDataintoDB.ascent?map='+encodeURI(JSON.stringify(columnData)), this.httpOptions);
    }

    deleteMasterDatass(columnData: any):Observable<any>
    {
      console.log("In Master service master data :",columnData);
      return this.http.post<any>(environment.reconUrl + '/deleteMasterDataintoDB.ascent?map='+encodeURI(JSON.stringify(columnData)), this.httpOptions);
    }
    createMasterTable(tableName:string):Observable<any>
    {
      return this.http.post<any>(environment.reconUrl + '/createMasterTable.ascent?tableName=' + tableName, this.httpOptions);
    }

    getTemplateColumnNameData(selectTempletid1:string)
    { 
      return this.http.post(environment.reconUrl+'/masterDataColumnNames.ascent?id='+selectTempletid1, this.httpOptions);
    }

    saveImportData(data:any)
    {
      return this.http.post(environment.reconUrl+'/saveImportData.ascent?data='+encodeURI(JSON.stringify(data)), this.httpOptions);
    }
}