import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISelectedActivityTxns } from '../../../recon/models/recon.model';
import { CaseDepartment, CaseReason, ICaseManagement } from '../../models/case-management.model';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { AgGridColumnsService } from '../../services/ag-grid-columns.service';
import { CaseCreationService } from '../../services/case-creation.service';

@Component({
  selector: 'app-case-creation',
  templateUrl: './case-creation.component.html',
  styleUrls: ['./case-creation.component.scss']
})
export class CaseCreationComponent implements OnInit {

  reconName: string;
  amountColumn: string;
  columnDefs: any;
  selectedActivityTxns: ISelectedActivityTxns;

  //caseId: Number;
  caseDepartmentList: CaseDepartment[] = [];
  caseReasonList: CaseReason[] = [];

  caseForm: FormGroup;
  submitted = false;
  //emailPattern = /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.(com)+$)/;
  emailPattern = /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-z])/;
  emailFlag: boolean = false;
  loading = false;

  constructor(public dialogRef: MatDialogRef<CaseCreationComponent>,
    public caseCreationService: CaseCreationService,
    public agGridColumns: AgGridColumnsService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.reconName = data.reconName;
    this.amountColumn = data.amountColumn;
    this.selectedActivityTxns = data.selectedActivityTxns;
    this.columnDefs = this.agGridColumns.generateColumns(this.selectedActivityTxns.data);
  }

  ngOnInit() {
    //this.getCaseID();
    this.loadAllCaseDepartments();
    this.loadAllCaseReasons();

    this.caseForm = this.formBuilder.group({
      department: ['', Validators.required],
      reason: ['', Validators.required],
      narration: ['', Validators.required],
      assignedTo: ['',
        Validators.compose([
          Validators.required, this.validateEmail
        ])
      ]
    });

  }

  validateEmail = (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value != "") {
      this.emailFlag = false;
      var emails = control.value.split(';');
      emails.forEach((email: string) => {
        if (!this.emailPattern.test(email)) 
          this.emailFlag = true;
      });
      return this.emailFlag ? { 'assignedTo': { value: control.value.trim() } } : null;
    }
  };

  get f() { return this.caseForm.controls; }

  // getCaseID() {
  //   this.caseManagementService.getCaseID().subscribe(
  //     (caseId: Number) => {
  //       this.caseId = caseId;
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }

  loadAllCaseDepartments() {
    this.caseCreationService.loadAllCaseDepartments().subscribe(
      (caseDepartmentList: CaseDepartment[]) => {
        this.caseDepartmentList = caseDepartmentList;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadAllCaseReasons() {
    this.caseCreationService.loadAllCaseReasons().subscribe(
      (caseReasonList: CaseReason[]) => {
        this.caseReasonList = caseReasonList;
      },
      error => {
        console.log(error);
      }
    );
  }

  saveCase() {


    this.submitted = true;
    if (this.caseForm.invalid) {
      return;
    }

    this.loading = true;
    const caseManagement = new ICaseManagement();
    caseManagement.caseId = this.caseForm.value.caseId;
    caseManagement.sid = this.selectedActivityTxns.data[0].SID;
    caseManagement.department = this.caseForm.value.department;
    caseManagement.reconModule = this.reconName;
    caseManagement.source = this.selectedActivityTxns.tableName;
    caseManagement.assignedTo = this.caseForm.value.assignedTo;
    caseManagement.reason = this.caseForm.value.reason;
    caseManagement.narration = this.caseForm.value.narration;
    caseManagement.amount = this.selectedActivityTxns.data[0][this.amountColumn];
    caseManagement.caseObjectData = this.selectedActivityTxns;

    this.caseCreationService.saveCase(caseManagement).subscribe(
      caseResponse => {
        alert(caseResponse.message);
        this.loading = false;
        this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }
    );

  }

}
