import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { Alert } from 'selenium-webdriver';
import { IJobScheduler, IReconModule, ISchedulerResponse, ISource } from '../../../models/scheduler.model';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-edit-configuration',
  templateUrl: './edit-configuration.component.html',
  styleUrls: ['./edit-configuration.component.scss']
})
export class EditConfigurationComponent implements OnInit {

  editConfigurationForm: FormGroup;
  submitted = false;
  selected: any;
  showDataRadio: boolean = true;
  jobScheduler: IJobScheduler;
  date = new FormControl(new Date());
  showWeekly: boolean = false;

  reconModuleName: string;

  reconModuleList: IReconModule[];
  sourceList: ISource[];
  id: any;

  totalWeekDays = [
    { name: 'Monday' },
    { name: 'Tuesday' },
    { name: 'Wednesday' },
    { name: 'Thursday' },
    { name: 'Friday' },
    { name: 'Saturday' },
    { name: 'Sunday' }
  ];

  constructor(private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditConfigurationComponent>,) {
    this.jobScheduler = data.jobScheduler;
    let responseData: any;
    let startTime: String;
    let endTime: String;
    responseData = data.jobScheduler;
    startTime = new Date(responseData.startingDate).toLocaleTimeString();
    endTime = new Date(responseData.endDate).toLocaleTimeString();
    let startTimeArr: any[];
    let endTimeArr: any[];
    startTimeArr = startTime.split(" ");
    endTimeArr = endTime.split(" ");
    this.jobScheduler.startTime = moment(startTimeArr[0], startTimeArr[1]);
    this.jobScheduler.endTime = moment(endTimeArr[0], endTimeArr[1]);
    let newDate = new Date(this.jobScheduler.startingDate);
    let newDate1 = new Date(this.jobScheduler.endDate);
    this.jobScheduler.startingDate = newDate;
    this.jobScheduler.endDate = newDate1

    if (data.jobScheduler.triggerType == "WEEKLEY") this.showWeekly = true;
    this.editConfigurationForm = this.formBuilder.group({
      jobName: ['', Validators.required],
      jobType: ['', Validators.required],
      reconName: [Number, Validators.required],
      sourceId: [Number],
      retryCount: [''],
      interval: [''],
      occurance: [''],
      startingDate: [Date, Validators.required],
      startTime: ['', Validators.required],
      endDate: [Date, Validators.required],
      EndTime: ['', Validators.required],
      weekDaysList: this.createWeekDays(this.totalWeekDays),
      onSuccess: ['', Validators.required],
      onFailure: ['', Validators.required],
      status: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.loadReconModules();
    this.id = this.data.id;
  }

  createWeekDays(totalWeekDays) {
    const arr = totalWeekDays.map(day => {
      return new FormControl(day.selected || false);
    });
    return new FormArray(arr);
  }

  get f() { return this.editConfigurationForm.controls; }

  showBlockOneTime() {
    this.showWeekly = false;
  }

  showBlockDaily() {
    this.showWeekly = false;
  }

  showBlockWeekly() {
    this.showWeekly = true;
  }

  loadReconModules() {
    this.configurationService.loadReconModules().subscribe(
      (reconModuleList: IReconModule[]) => {
        this.reconModuleList = reconModuleList;
        for (let reconModule of this.reconModuleList) {
          if (reconModule.id == this.jobScheduler.id) {
            this.reconModuleName = reconModule.moduleName;
            break;
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  // loadsourcesByModule(reconModuleId: number) {
  //   this.sourceList = [];
  //   this.configurationService.loadSourcesByReconModules(reconModuleId).subscribe(
  //     data => {
  //       for (const [key, value] of Object.entries(data)) {
  //         this.sourceList.push({ id: key, sourceName: value });
  //       }
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }

  onReconNameChange(data: any) {
    this.id = null;
    for (let i = 0; i < this.reconModuleList.length; i++) {
      if (data == this.reconModuleList[i].moduleName) {
        this.id = this.reconModuleList[i].id;
      }
    }

  }



  canbeChecked(day: string): boolean {
    if (this.jobScheduler.weekDays.includes(day)) return true;
    else return false;
  }


  onJobTypeChange(data){
    console.log("data :",data);
    
  }



  saveSchedulerConfig() {
    this.submitted = true;
    if (this.editConfigurationForm.invalid) {
      return;
    }
    this.jobScheduler = this.editConfigurationForm.value;
    this.jobScheduler.id = this.data.jobScheduler.id
    this.jobScheduler.reconName = this.id;
  
    if (this.editConfigurationForm.value.occurance == "WEEKLEY") {
      let selectedWeekDays: string[] = [];
      this.editConfigurationForm.value.weekDaysList.forEach((day, i) => {
        if (day) selectedWeekDays.push(this.totalWeekDays[i].name);
      });
      this.jobScheduler.weekDays = selectedWeekDays.join(',');
    }


    let startDateTime: any;
    let startDate: string;
    let startTime: string;
    let endDateTime: any;
    let endDate: string;
    let endTime: string;
    startDate = this.editConfigurationForm.get('startingDate').value
    startTime = this.editConfigurationForm.get('startTime').value
    endDate = this.editConfigurationForm.get('endDate').value
    endTime = this.editConfigurationForm.get('EndTime').value
    startDateTime = startDate.toString().substring(0, 15) + ' ' + startTime;
    endDateTime = endDate.toString().substring(0, 15) + ' ' + endTime;
    let newDate = new Date(startDateTime);
    let newDate1 = new Date(endDateTime);
    this.jobScheduler.startingDate = newDate;
    this.jobScheduler.endDate = newDate1;

    this.configurationService.updateSchedulerConfig(this.jobScheduler, this.data.jobScheduler.id).subscribe(
      (schedulerResponse: ISchedulerResponse) => {
        //alert(schedulerResponse.message);
        this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }
    );
  }

}
function moment(arg0: string, arg1: string) {
  let time: string;
  if (arg1.trim() == "PM") {
    let pmTime = arg0.split(":");
    let intVal = parseInt(pmTime[0])
    let calTime: any;
    if (intVal == 12) {
      calTime = 12;
    } else {
      calTime = intVal + 12;
    }
    if (pmTime[1] == "00") {
      time = calTime.toString() + ':00';
    } else {
      time = calTime.toString() + ':' + pmTime[1];
    }

  } else {
    let amTime = arg0.split(":");
    time = amTime[0] + ':' + amTime[1];
  }
  return time;
}

