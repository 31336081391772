import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddRolesComponent } from './add-roles/add-roles.component';
import { EditRolesComponent } from './edit-roles/edit-roles.component';
import { UserManagementService } from '../services/user-management.service';
import { GridOptions } from 'ag-grid-community/dist/lib/main';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  public rowData: any;
  public columnDefs: any;

  gridOptions: GridOptions;

  myTempRowData: any[] = [];

  private gridApi: any;
  private gridColumnApi: any;
  public rowSelection: any;

  constructor(public dialog: MatDialog, public userManagementService: UserManagementService) {
    this.rowSelection = "multiple";

  }

  private columnDefsRoledata = [
    {width:'365', headerName: 'Name', field: 'name', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { width:'365',headerName: 'Description', field: 'description', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    {width:'365', headerName: 'Responsiblity', field: 'responsiblity', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    // { headerName: 'Organisation', field: 'organisationId', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { width:'365',headerName: 'Status', field: 'status', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
  ];

  ngOnInit() {
    this.gridOptions = <GridOptions>{
      columnDefsRoledata : this.columnDefsRoledata

    }

    this.getRolesData();
  }

  // private columnDefsRoledata = [
  //   { headerName: 'Name', field: 'name',sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:320},
  //   { headerName: 'Description', field: 'description', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:320 },
  //   { headerName: 'Responsiblity', field: 'responsiblity', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:320 },
  //   // { headerName: 'Organisation', field: 'organisationId', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
  //   { headerName: 'Status', field: 'status', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:330},
  //  // {headerName: 'UPDATION TIME', field: 'updateTime', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500}
  // ];

  public get columnDefsRolesdata() {
    return this.columnDefsRoledata;
  }
  public set columnDefsRolesdata(value) {
    this.columnDefsRoledata = value;
  }

  addRoles() {
    const dialogRef = this.dialog.open(AddRolesComponent, {
      data:this.rowData,
      width: '500px',
      panelClass: 'mat-modal'
    });

    dialogRef.afterClosed().subscribe(result => {


      this.userManagementService.addNewRoles(result).subscribe(
        screenResponse => {
          alert("Record save sucessfully");
          
          this.getRolesData();
        },
        error => {
          console.log(error);
        }
  
      );
    
      }  );
    
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  editRoles() {

    var editSelectedData = this.gridApi.getSelectedRows();
    console.log("Edit Selected Row --> ", editSelectedData);
    if (editSelectedData == "") {
      alert("please select a record");
    }
    else{
    const dialogRef = this.dialog.open(EditRolesComponent, {
      data: editSelectedData,
      width: '500px',
      panelClass: 'mat-modal'
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result !== undefined) {
        this.gridApi.updateRowData({ update: [result] });

      }
    });
  }
  }
  getRolesData() {
    this.rowData = [];
    this.myTempRowData = [];
    this.userManagementService.getRolesData().subscribe(
      data => {
        var dummyData: any = [];
        dummyData = data;
        let tempObj = {};
        dummyData.forEach(element => {

          let map = new Map(Object.entries(element));

          for (let [key, value] of map) {
            if (value === undefined || value === "undefined") {
              tempObj[key] = "NA";
            } else {
              tempObj[key] = value;
            }
          }
          this.myTempRowData.push(tempObj);
          tempObj = {};
        });
        this.rowData = [...this.myTempRowData];

        if (this.rowData) {
          this.columnDefs = this.columnDefsRolesdata;
        };
        //        this.columnDefs = this.generateColumns(this.rowData);
      }
    );
  }


  deleteRoles() {
    var selectedData = this.gridApi.getSelectedRows();
    console.log("selected data", selectedData);
    if (selectedData == "") {
      alert("please select a record to delete");
    } else if (selectedData !== "") {
      if (confirm("Do you want to delete this record")) {
        var roleId = selectedData[0].id;
        this.userManagementService.deleteRole(roleId).subscribe(
          (roleResponse: any) => {
            alert(roleResponse.message);
            this.gridApi.updateRowData({ remove: selectedData });
          },
          error => {
            alert(error.error.details[0]);
            console.log(error);
          });
      }

    }

  }
  onPageSizeChanged(newPageSize) {
    const element = document.getElementById('page-size') as HTMLInputElement
    const value = element.value
    this.gridApi.paginationSetPageSize(Number(value));
  }

}
