import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HeaderService } from '../header.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  submitted = false;
  currentPassword: any;

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private headerService: HeaderService) { }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
    });
  }

  get f() { return this.changePasswordForm.controls; }

  changePassword() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
if(this.changePasswordForm.value.currentPassword == this.changePasswordForm.value.newPassword){
  alert("New Password should not be same as Current Password");
}
else{
    this.headerService.changePassword(this.changePasswordForm.value).subscribe(
      usersResponse => {
        alert(usersResponse.message);
        if (usersResponse.message.includes("SUCCESS")) this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }
    );
    }
  }

}
