import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ISchedulerResponse, IJobScheduler, IJobDependency } from '../models/scheduler.model';
import { Observable } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class DependencyService {
 
  constructor(private http: HttpClient) { }
 
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
 
  loadAllJobSchedulers(): Observable<IJobScheduler[]>  {
    return this.http.get<IJobScheduler[]>(environment.scheduler + '/loadAllJobSchedulers.ascent', this.httpOptions);
  }

  saveSchedulerDependency(jobDependency: IJobDependency): Observable<ISchedulerResponse>  {
    return this.http.post<ISchedulerResponse>(environment.scheduler + '/saveSchedulerDependency.ascent', jobDependency, this.httpOptions);
  }

  loadAllJobDependencies(): Observable<IJobDependency[]>  {
    return this.http.get<IJobDependency[]>(environment.scheduler + '/loadAllJobDependencies.ascent', this.httpOptions);
  }

  deleteDependency(dependencyJobId: number): Observable<ISchedulerResponse> {
    return this.http.delete<ISchedulerResponse>(environment.scheduler + '/deleteDependency.ascent/'+dependencyJobId, this.httpOptions);
  }

}