import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SelectCycleComponent } from './select-cycle/select-cycle.component';
import { SettelementService } from '../services/settelement.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-settelement-list',
  templateUrl: './settelement-list.component.html',
  styleUrls: ['./settelement-list.component.scss']
})
export class SettelementListComponent implements OnInit {
  private gridApi: any;
  private gridColumnApi: any;
  public rowSelection: any;
  public rowData: any;

  model: any = {};
  submitted = false;
  fromDate: any;
  toDate: any;
  
  public columnDefs: any;

  reconNames: any[] = [];
  ReportNames: any[] = [];
  reconName: any;
  reportName: any;
  reconModuleName: string;
  records:any;

  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;

  constructor(public dialog: MatDialog, public settelementService: SettelementService) { 
    this.overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>'
    this.overlayNoRowsTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>';
  }

  ngOnInit() {
    this.reconModuleName = localStorage.getItem('reconModuleName');
    this.loadReportList();
  }

  // onSubmit() {
  //   this.submitted = true;    
  //   const dialogRef = this.dialog.open(SelectCycleComponent, {
  //     width: '500px',
  //     panelClass: 'mat-modal'

  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');

  //   });
  // }


  // getReconNames() {
  //   this.settelementService.getReconNames().subscribe(
  //     data => {
  //       console.log("Result Data --> ", data);
  //       for (const [key, value] of Object.entries(data)) {
  //         console.log(value.id, "  ===  ", value.moduleName)
  //         this.reconNames.push({ name: value.id, value: value.moduleName });
  //       }
  //       console.log("Recon Names --> ", this.reconNames);
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }

  loadReportList() {
    this.ReportNames = [];

    this.settelementService.loadReportList(this.reconModuleName).subscribe(data => {
      console.log("Load settlement :", data)

      for (const [key, value] of Object.entries(data)) {
        this.ReportNames.push({ name: value.reportId, value: value.reportName });
      }

       

    }
    );
  }

  getReportInfo(reportName) {


    let params = { "params": "cycle", "paramValue": "1C','2C','3C','4C" }



    let requestData = {
      "reconName": this.reconModuleName, "tableName": "UPI_NPCI_STG", "columnList": "UPI_NPCI_STG.*", "direction": "0", "startIndex": 1, "limit": 100000, "whereClause": "", "IdColumn": "sid", "orderBy": "sid",
      "reportName": reportName, "fromDate": (this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day),
      "toDate": (this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day), "params": params


    };

    console.log("Send data :", requestData)

    this.settelementService.getReportInfo(requestData).subscribe(
      data => {
        var settlementData :any;
        settlementData=data;

        this.rowData = settlementData.records;
        if (this.rowData) {
          this.columnDefs = this.generateColumns(this.rowData);
        }

        this.settelementService.insertGLBalancingRecords(requestData).subscribe(
          data => {
            console.log(data);
            if(data[0]=="Data Already Exist")
            {
              alert("Data Already Exist");
            }
          }
        )
      });

  }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true, resizable: true,
            filter: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true,
            hide:key==="ROW1"
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))    )
    return columnDefinitions;
  }


  onGridReady(params: { api: any; columnApi: any; }) {
    this.gridApi = params.api;
    // this.gridApi.sizeColumnsToFit();
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.sizeColumnsToFit;
    this.gridApi.sizeColumnsToFit();
    //params.api.sizeColumnsToFit();
  }


  ttumStatusUpdate(reportName) {
// alert("TTUM Report name :"+reportName)
   let params = { "params": "cycle", "paramValue": "1C','2C','3C','4C" }
    let requestData = {
      "reconName": this.reconModuleName, "tableName": "UPI_NPCI_STG", "columnList": "UPI_NPCI_STG.*", "direction": "0", "startIndex": 1, "limit": 100000, "whereClause": "", "IdColumn": "sid", "orderBy": "sid",
      "reportName": reportName, "fromDate": (this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day),
      "toDate": (this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day), "params": params
    };

    console.log("Send data :", requestData)

    this.settelementService.ttumStatusUpdate(requestData).subscribe(
      data => {

        // alert("TTUM Status updated Successfully")

      });

 }

  fileNameExcel: string = 'SettelmentFile.xlsx';
  excelExport(): void {
  var exportData = [];
  var tempColumns = [];
  var rowColArray = [];
  let rowIndex = 0;
  for (let index = 0; index < this.columnDefs.length; index++) {
    tempColumns[index] = this.columnDefs[index].field;
    rowColArray[index] = this.columnDefs[index].field;
  }
  exportData[rowIndex] = rowColArray;
  rowIndex = rowIndex + 1;
  this.rowData.forEach((k) => {
    let colIndex = 0;
    var rowArray = [];
    for (let key in k) {
      if (tempColumns.indexOf(key) > -1) {
        rowArray[colIndex] = k[key];
        colIndex = colIndex + 1;
      }
    }
    exportData[rowIndex] = rowArray;
    rowIndex = rowIndex + 1;
  })

  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(exportData);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
  XLSX.writeFile(wb, this.fileNameExcel);
}

 //Export Csv
 csvExport() {
  var params = {     
    fileName: 'SettelmentFile.csv',      
  };
  this.gridApi.exportDataAsCsv(params);
//new Angular2Csv(this.baseSourceRowData, this.formula, { headers: Object.keys(this.baseSourceRowData[0]) });
  }


}
