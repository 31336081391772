import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddEditMasterDataComponent } from './add-edit-master-data/add-edit-master-data.component';
import { MasterTable } from '../models/master-setup.model';
import { FormBuilder } from '@angular/forms';
import { MasterTableService } from '../services/master-setup.service';
import { StagingService } from '../../staging/services/staging.service';
import { EtlService } from '../../etl/services/etl.service';
import { EditMasterDataComponent } from './edit-master-data/edit-master-data.component';
import * as XLSX from 'xlsx';
type AOA = any[][];
@Component({
  selector: 'app-master-data',
  templateUrl: './master-data.component.html',
  styleUrls: ['./master-data.component.scss']
})
export class MasterDataComponent implements OnInit {

  selected = 'choose';
  model: any = {};
  submitted = false;
  tablename:any;
  public rowData: any;
  public columnDefs: any;
  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;
  gridApi: any;
  masterTableList:any;
  columnNames:any=[];
  tableName:any;
  public rowSelection: any;
  editSelectedData:any;
  dummy:any=[];
  constructor(public dialog: MatDialog ,
    public masterTableService: MasterTableService,
    public stagingService: StagingService,
    public etlservice: EtlService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">No Rows To Show</span>'
    this.overlayNoRowsTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>';
    this.rowSelection = "single";

    }

  ngOnInit() {
this.loadAllMasterTable();

  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
 


  onSubmit() {
    this.submitted = true;
  }

  addMasterData(tableName) {
      const dialogRef = this.dialog.open(AddEditMasterDataComponent, {  
        width: '600px',
        panelClass: 'mat-modal',
        data:{columnNames:this.dummy,tableName:this.tableName}
      });

      dialogRef.afterClosed().subscribe(result => {
        this.loadMasterTableNames(tableName);

      });

       
    }

    editMasterData(){
      this.editSelectedData = this.gridApi.getSelectedRows();
      console.log("editSelectedData",this.editSelectedData.length)
      if(this.editSelectedData.length==0){
        alert("Please select Record");
        return;
      }
      const dialogRef = this.dialog.open(EditMasterDataComponent, {
        width: '500px',
        panelClass: 'mat-modal', 
        data:{columnNames:this.dummy,tableName:this.tableName,selectedData:this.editSelectedData[0]}
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result=="true"){
          this.stagingService.getTablesData(this.tableName).subscribe(data => {
            this.rowData = data
            
          }
          
          );
        }
      });
    }

    deleteMasterData(){
      this.editSelectedData=[];
      this.editSelectedData=this.gridApi.getSelectedRows();
      if(this.editSelectedData.length==0){
        alert("Please select Record");
        return;
      }
      if(confirm("Do you want to delete record ? ")){
     let   columnData={"tableName": this.tableName,"SID":this.editSelectedData[0].SID};
     console.log("columnData :",columnData);
     this.masterTableService.deleteMasterDatass(columnData).subscribe(
      screenResponse => {
        alert(screenResponse.STATUS);
       },
      error => {
        console.log(error);
      }

    );
    this.gridApi.updateRowData({ remove: this.editSelectedData });
    }
  }

    loadMasterTableNames(value)
    { 
          this.tableName="";
          this.columnDefs="";
          this.rowData="";
          this.dummy=[];
          this.columnNames=[];
          var data:any;
          data=value.split(' ');
          var tableName=data[0];
          var id=data[1];
          this.tableName=tableName;
          console.log("tableName :",tableName);
          console.log("id  :",id);
          this.masterTableService.getTemplateColumnNameData(id).subscribe(data =>{
            console.log("data :",data);
            for (const [key, value] of Object.entries(data)) {
 
             this.columnNames.push({colNames: value, id: key});
           }
           console.log("columnNames :",this.columnNames);
           for(var i=0;i<this.columnNames.length;i++){
            if(this.columnNames[i].colNames!=="SID"){
              this.dummy.push(this.columnNames[i].colNames);
             }
           }
           console.log("dummy",this.dummy);
           this.columnDefs = this.generateColumns(this.dummy);
           });
           this.stagingService.getTablesData(tableName).subscribe(data => {
            this.rowData = data
            
          }
          
          );
          
    }
    
  generateColumns(data: any[]) {
    let columnDefinitions = [];
    // data.map(object => {
    //   Object
    //     .keys(object)
    //     .map(key => {
    //       let mappedColumn = {
    //         headerName: key.toUpperCase(),
    //         field: key,
    //         sortable: true,
    //         filter: true,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true
    //       }
    //       columnDefinitions.push(mappedColumn);
    //     })
    // })
for(var i=0;i<data.length;i++){
  let mappedColumn = {
            headerName: data[i].toUpperCase(),
            field: data[i],
            sortable: true,
            filter: true,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true,
            hide:data[i]==="SID"
          }
          columnDefinitions.push(mappedColumn);
}
    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }
  loadAllMasterTable() {
    
    this.masterTableService.loadAllMasterTable().subscribe(
      (masterTableList: MasterTable[]) => {
        this.masterTableList = masterTableList;
        console.log("Master data list",this.masterTableList);
      },
      error => {
        console.log(error);
      }
      
    );
    
  }

  //Import Functionality Start
  dataImport: AOA;
  finalData = [];

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    let validExts = new Array(".xlsx", ".xls", ".csv", ".CSV");
    console.log("File Name ", target.files[0].name);
    let fileExt = target.files[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0 && fileExt != "") {
      alert("Invalid file selected, valid files are of " + validExts.toString() + " types.");
    }
    else {
      / wire up file reader /
      //const target: DataTransfer = <DataTransfer>(evt.target);
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        / read workbook /
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        / grab first sheet /
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        / save data /
        this.dataImport = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

        for (let index = 0; index < this.dataImport.length; index++) {
          var objData = {};
          for (let index1 = 0; index1 < this.dataImport[0].length; index1++) {
            objData[this.dataImport[0][index1]] = this.dataImport[index][index1];
          }
          if (index !== 0) {
            this.finalData[index - 1] = objData;
          };
        }
        for (let index = 0; index < this.finalData.length; index++) {
          this.gridApi.updateRowData({ add: [this.finalData[index]] });
          this.rowData.push(this.finalData[index]);
        }
      };
      reader.readAsBinaryString(target.files[0]);
    }
  }

  saveImportData(){
    var requestData = {"data": this.rowData, "tableName": this.tableName};
    console.log("requestData ::>>",requestData);
    
    this.masterTableService.saveImportData(requestData).subscribe(
      data =>{
        alert(data);
      }
    );
  }

  }
