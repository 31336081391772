import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { CaseCreationComponent } from '../case-creation/case-creation.component';
import { ICaseManagement } from '../../models/case-management.model';
import { CreatedCasesService } from '../../services/created-cases.service';
import { CaseDataComponent } from '../case-data/case-data.component';
import { UpdateCommentsComponent } from '../update-comments/update-comments.component';
import { CloseComponent } from './close/close.component';
import { AgGridColumnsService } from '../../services/ag-grid-columns.service';
import { UserManagementService } from '../../../user-management/services/user-management.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-created-cases',
  templateUrl: './created-cases.component.html',
  styleUrls: ['./created-cases.component.scss']
})
export class CreatedCasesComponent implements OnInit {

  caseManagementList: ICaseManagement[] = [];
  columnDefs: any;
  rowData: any = [];
  gridApi: any;
  permissionList: string[] = [];

  constructor(public dialogRef: MatDialogRef<CaseCreationComponent>,
    public createdCasesService: CreatedCasesService,
    public agGridColumns: AgGridColumnsService,
    public dialog: MatDialog,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.loadChildPermissionsByUser();
    this.loadCreatedCases();
  }

  loadChildPermissionsByUser() {
    this.userManagementService.loadChildPermissionsByUser(this.route.firstChild.routeConfig.path.replace(/-/g,' ')).subscribe(
     (list: string[]) => {
       this.permissionList = list;
     },
     error => {
       console.log(error);
     }
   );
 }
 
  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  loadCreatedCases() {
    this.createdCasesService.loadCreatedCases(localStorage.getItem('reconModuleName')).subscribe(
      (caseManagementList: ICaseManagement[]) => {
        this.caseManagementList = caseManagementList;
        this.columnDefs = this.agGridColumns.generateCaseColumns(caseManagementList);
        this.rowData = caseManagementList;
      },
      error => {
        console.log(error);
      }
    );
  }

  onRowClicked(event: any) {

    this.dialog.open(CaseDataComponent,
      {
        data: {
          caseData: event.data.caseObjectData
        },
        panelClass: 'mat-modal',
        width: '998px'
      }
    );
  }

  constructClose() {
    if (this.gridApi.getSelectedRows().length == 0) {
      alert("Please Select Record");
    }
    else {
      let caseManagement: ICaseManagement;
      caseManagement = this.gridApi.getSelectedRows()[0];

      const dialogRef = this.dialog.open(CloseComponent,
        {
          data: {
            selectedCase: caseManagement
          },
          panelClass: 'mat-modal',
          width: '500px',         
          disableClose: true
        }
      );
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.gridApi.updateRowData({ remove: this.gridApi.getSelectedRows() });
        }
      });
    }
  }

  constructUpdateComments() {
    if (this.gridApi.getSelectedRows().length == 0) {
      alert("Please Select Record");
    }
    else {
      const dialogRef = this.dialog.open(UpdateCommentsComponent,
        {
          data: {
            selectedCase: this.gridApi.getSelectedRows()[0],
            caseType: 'Created'
          },
          panelClass: 'mat-modal',
          width: '998px',         
          disableClose: true
        }
      );
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.gridApi.updateRowData({ remove: this.gridApi.getSelectedRows() });
        }
      });
    }
  }

  onPageSizeChanged() {
    var value =((document.getElementById("page-size"))as HTMLInputElement).value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

}