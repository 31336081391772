import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UsersService } from '../services/users.service';
import { UsersDilogData, IUsersData } from '../models/users.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  modelData : any;
  submitted = false;
  model: any = {};
  userNameList: any=[];
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  usersedit:any;
  status:any;
  constructor(public dialogRef: MatDialogRef<EditUserComponent>,public usersServic: UsersService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: UsersDilogData) {
      console.log("Data --> ", data[0]);
    this.modelData = data[0];

    this.modelData.confirmPassword = data[0].password;

    this.usersedit =this.formBuilder.group({
      userId:new FormControl('',Validators.required),
      userName:new FormControl('',Validators.required),
      mailId :new FormControl('',Validators.required),
      phoneNo:new FormControl('',Validators.required),
      reportingUser:new FormControl(),
      ldapUser:new FormControl('',Validators.required),
      status:new FormControl('',Validators.required),
      password:new FormControl('',Validators.required),
      confirmPassword:new FormControl('',Validators.required)
   });
  }
  ngOnInit() {
    this.loadUsers()
  }
  get fval() {
    return this.usersedit.controls;
  }

  onSubmit() {
    this.submitted = true;   
    console.log('Submitted'); 
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  // loadUsers(){
  //   this.usersServic.getUsersData().subscribe(
  //     (userNameList : IUsersData[]) => {
  //       this.userNameList = userNameList;
  //    console.log("userNameList",this.userNameList)
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }
  loadUsers(){
    this.usersServic.getCheckerUser().subscribe(
    //   (userNameList : IUsersData[]) => {
    //     //this.userNameList = userNameList;
    //  console.log("userNameList",this.userNameList)

    //  for (const [key, value] of Object.entries(userNameList)) {
    //   this.userNameList.push({name:key,value:value});
    //   }
    //   },
    data =>{
      for (const [key, value] of Object.entries(data)) {
        this.userNameList.push({name:key,value:value});
        }
},
      error => {
        console.log(error);
       
      }
    );
  }

  saveEditUsersForm(){
    this.submitted = true;
    if (this.usersedit.invalid) {
      return;
     }
     else{
    
    var saveData = this.data;
    console.log("savedata",saveData);

    if(saveData[0].password != saveData[0].confirmPassword){
      alert("Password & Confirm Password does not match.");
    } else {
      this.usersServic.updateUsers(saveData[0]).subscribe(
        (userResponse:any)=>{
          
          alert(userResponse.message);
          if (userResponse.message.includes("Password Policy")) {
            console.log("poonam password", userResponse.message.includes("Password Policy"))
          }
          else
            this.dialogRef.close("true");

        },
        error => {
          console.log(error);
          alert(error.error.details);
        });
    }
    }
  }
}
