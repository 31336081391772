import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment'; 
import { Observable } from 'rxjs';
import { IUsersData } from '../models/users.model';



@Injectable({
  providedIn: 'root'
})
export class UsersService {
    constructor(private http: HttpClient) { }

    httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  
    getUsersData() {
       return this.http.get<IUsersData[]>(environment.userManagementUrl+'/v1/user/read/all/myorg', this.httpOptions);
    }
    updateUsers(updateUsers: any):Observable<any>{
      return this.http.put(environment.userManagementUrl +'/v1/user/update',JSON.stringify(updateUsers), this.httpOptions);
    }
    deleteUser(data:any):Observable<any>{
      console.log("In Users service :",data);
      return this.http.delete(environment.userManagementUrl+'/v1/user/delete/'+data,this.httpOptions);
    }
    addUserDetails(newColumnData: any):Observable<any>
    {
      console.log("In Users service :",newColumnData);
      return this.http.post<any>(environment.userManagementUrl + '/v1/user/create',JSON.stringify(newColumnData), this.httpOptions);
    }

    getCheckerUser() {
      return this.http.get<IUsersData[]>(environment.userManagementUrl+'/v1/user/read/role/Approver/all/myorg', this.httpOptions);
   }
}