import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ILoginResponse, IServerResponse } from './login.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) { }

  authenticateUser(loginRequest: any):Observable<ILoginResponse>{
    return this.http.post<ILoginResponse>(environment.userManagementUrl+'/login/authenticateUser',loginRequest, this.httpOptions);
  }

  forgetPassword(emailId: string):Observable<IServerResponse>{
    return this.http.get<IServerResponse>(environment.userManagementUrl+'/forgetpassword/check/'+emailId, this.httpOptions);
  }

  forgetPasswordCreate(emailId: string, newPassword: string, createdPassword: string, otp: string):Observable<IServerResponse>{
    return this.http.get<IServerResponse>(environment.userManagementUrl+'/forgetpassword/save/'+emailId +'/'+ newPassword +'/'+ createdPassword +'/'+ otp , this.httpOptions);
  }

  authenticateUserLogout(){
    console.log("Logout call to Server");
    return this.http.get(environment.reconUrl+'/logoutIntellicus.ascent', this.httpOptions);
  }
}