import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MasterSetupRoutingModule } from './master-setup-routing.module';
import { MasterSetupComponent } from './master-setup.component';
import { ScreenSetupComponent } from './screen-setup/screen-setup.component';
import { MasterDataComponent } from './master-data/master-data.component';
import { OrgStructureComponent } from './org-structure/org-structure.component';
import { MaterialModule } from 'src/app/shared/modules/material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { AddEditMasterDataComponent } from './master-data/add-edit-master-data/add-edit-master-data.component';
import { AddScreenComponent } from './screen-setup/add-screen/add-screen.component';
import { EditScreenComponent } from './screen-setup/edit-screen/edit-screen.component';
import { EditMasterDataComponent } from './master-data/edit-master-data/edit-master-data.component';


@NgModule({
  declarations: [MasterSetupComponent, ScreenSetupComponent, MasterDataComponent, OrgStructureComponent, AddEditMasterDataComponent, AddScreenComponent, EditScreenComponent, EditMasterDataComponent],
  imports: [
    CommonModule,
    MasterSetupRoutingModule,
    MaterialModule,
    FormsModule,
    AgGridModule.withComponents([]),
    ReactiveFormsModule
  ],
  entryComponents:[
    AddScreenComponent,
    AddEditMasterDataComponent,
    EditMasterDataComponent,
    EditScreenComponent
  ]
})
export class MasterSetupModule { }
