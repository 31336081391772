
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog, MatDialogRef  } from '@angular/material';
import { EtlService } from '../../../services/etl.service';
import { FormGroup, Validators, FormControl,FormBuilder } from '@angular/forms';

@Component({
    selector: 'editFormating',
    templateUrl: 'ft-format-edit.html'  
  })
 
  export class editFormating implements OnInit {
    config_data:any;  
    local_data : any;
    formatEdit:any;
    submitted = false;
    ColumnName:any;
    COLUMN_DATA_TYPE:any;
    rowData:any;
    OUTPUT_FORMAT:any;
    INPUT_FORMAT:any
    ngOnInit(): void {
      this.loadTempColumns();
      this.local_data=this.data.editSelectedData;
      this.selectOperationValue=this.data.editSelectedData.OPERATION_NAME;
      this.config_data=this.data;
     
    }
    public templetColumnNames:any=[];
    selectOperationValue:string;
    constructor(@Inject(MAT_DIALOG_DATA) public data:any,public etlService:EtlService,
    public dialogRef:MatDialogRef<editFormating>) {

      this.formatEdit = new FormGroup({
        ColumnName:new FormControl('', Validators.required),
        Category:new FormControl('', Validators.required),
        Operation:new FormControl('', Validators.required),
        DATE_FORMAT:new FormControl(),
        DATE_TYPE:new FormControl(),
        TIME_FORMAT:new FormControl(),
        INPUT_FORMAT:new FormControl(),
        OUTPUT_FORMAT:new FormControl(),
        DATETIME_TYPE:new FormControl(),
        dataType:new FormControl()
        
      });
      console.log("this.config_data.INPUT_FORMAT",this.INPUT_FORMAT);
        
    }

    get fval() {
      return this.formatEdit.controls;
      }
      signup(){
        this.submitted = true;
        if (this.formatEdit.invalid) {
     
          return;
        }
       
       
        }

    changeSelectOperation(value)
    {
        this.selectOperationValue = value;
        this.formatEdit.get('DATE_FORMAT').setValidators(null);
        this.formatEdit.get('DATE_TYPE').setValidators(null);
        this.formatEdit.get('TIME_FORMAT').setValidators(null);
        this.formatEdit.get('INPUT_FORMAT').setValidators(null);
        this.formatEdit.get('OUTPUT_FORMAT').setValidators(null);
        this.formatEdit.get('DATETIME_TYPE').setValidators(null);
        this.formatEdit.patchValue({
          DATE_FORMAT: undefined,
          DATE_TYPE: undefined,
          TIME_FORMAT: undefined,
          INPUT_FORMAT: undefined,
          OUTPUT_FORMAT: undefined,
          DATETIME_TYPE: undefined,
        })
        if(this.selectOperationValue==='FormattingDate')
        {
          const DATE_FORMAT = <FormControl>this.formatEdit.get('DATE_FORMAT');
          DATE_FORMAT.setValidators([Validators.required, ]);

          const DATE_TYPE = <FormControl>this.formatEdit.get('DATE_TYPE');
          DATE_TYPE.setValidators([Validators.required, ]);
       }else if(this.selectOperationValue==='FormattingTime')
       {
        const TIME_FORMAT = <FormControl>this.formatEdit.get('TIME_FORMAT');
        TIME_FORMAT.setValidators([Validators.required, ]);
       }else if(this.selectOperationValue==='FormattingDateTime')
       {
         this.OUTPUT_FORMAT="yyyy-MM-dd"
        const INPUT_FORMAT = <FormControl>this.formatEdit.get('INPUT_FORMAT');
        INPUT_FORMAT.setValidators([Validators.required, ]);

        const OUTPUT_FORMAT = <FormControl>this.formatEdit.get('OUTPUT_FORMAT');
        OUTPUT_FORMAT.setValidators([Validators.required, ]);

        const DATETIME_TYPE = <FormControl>this.formatEdit.get('DATETIME_TYPE');
        DATETIME_TYPE.setValidators([Validators.required, ]);
       }

    }
    
    loadTempColumns()
    {
      console.log("select data   :",this.data.editSelectedData.TEMPLET_ID)
      this.etlService.getTemplateColumnNameData(this.data.editSelectedData.TEMPLET_ID).subscribe(
        data=>{
          this.rowData=data;
            for (const [key, value] of Object.entries(data)) {
            
                this.templetColumnNames.push({name:key,value:value});
                }

                for(const [key, value] of Object.entries(this.rowData)){
                  if (key === this.local_data.COLUMN_NAME){
                      this.COLUMN_DATA_TYPE=value;
                       
      if(this.COLUMN_DATA_TYPE == "VARCHAR2" || this.COLUMN_DATA_TYPE == "VARCHAR" ){
        if(this.local_data.OPERATION_NAME=="FormattingDateTime"){
          this.changeSelectOperation("");
        this.formatEdit.patchValue({
          OUTPUT_FORMAT:"NA",
          INPUT_FORMAT:"NA",
          DATETIME_TYPE:"NA"
          })
        }
      }
                   }
                }
                this.ColumnName=this.data.editSelectedData.COLUMN_NAME+' '+this.COLUMN_DATA_TYPE;
          
        }
      )
    }

    saveFormatEdit()
    {
      if (this.formatEdit.invalid) {
        return;
      }
      console.log("this.config_data",this.config_data);
      if(this.selectOperationValue==='FormattingDateTime' && this.config_data.editSelectedData.INPUT_FORMAT.includes("mm")){
        alert("Input date Field should be proper format eg. dd-MM-yyyy or dd-MMM-yyyy or MM-dd-yyyy or MMM-dd-yyyy");
      }
      else{
     
      this.etlService.getEditFormattingConfig(this.config_data).subscribe((data:any)=>{
       
        this.dialogRef.close(data);
       }) 
      }
    }
   
    changeColumnName(value)
    {
      this.selectOperationValue="";
      var str=value.split(" ");
      this.local_data.COLUMN_NAME=str[0];
      this.COLUMN_DATA_TYPE=str[1];
      this.formatEdit.patchValue({
       Operation:"",
       })
  this.changeSelectOperation(this.formatEdit.Operation);
  }
}