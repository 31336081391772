import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IActivity } from '../../models/activity-manager.model';
import { IExternalSource } from '../../../recon/models/recon.model';
import { AgGridColumnsService } from '../../../recon/services/ag-grid-columns.service';
import { ActivityManagerService } from '../../services/activity-manager.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-activity-data',
  templateUrl: './activity-data.component.html',
  styleUrls: ['./activity-data.component.scss']
})
export class ActivityDataComponent implements OnInit {

  makerComments: string;
  activity: IActivity;

  baseTable: string;
  baseColumnDefs: any;
  baseRowData: any = [];

  checkerComments: string;
  submitted: boolean = false;
  public externalData: IExternalSource[] = [];
  ActivityManager: any;
  permissionList: string[] = [];
  private domLayout;

  constructor(public agGridColumns: AgGridColumnsService,
    public activityService: ActivityManagerService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ActivityDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.domLayout = 'autoHeight';
    this.activity = data.activity;
    this.makerComments = data.activity.comments;
    this.permissionList = data.permissionList;

    if (this.activity.activityObjectData.base != null) {
      this.baseTable = this.activity.activityObjectData.base.tableName;
      this.baseColumnDefs = this.agGridColumns.generateColumns(this.activity.activityObjectData.base.data);
      this.baseRowData = this.activity.activityObjectData.base.data;
    }

    if (this.activity.activityObjectData.external != null) {
      this.activity.activityObjectData.external.forEach((source) => {
        this.externalData.push({
          externalSourceName: source.tableName,
          externalSourceColumnDefs: this.agGridColumns.generateColumns(source.data),
          externalSourceRowData: source.data
        });
      });
    }

    this.ActivityManager = this.formBuilder.group({
      checkerComments: new FormControl('', Validators.required),
      makerComments: new FormControl()
    });

  }

  ngOnInit() {
    
  }

  get fval() {
    return this.ActivityManager.controls;
  }
  submitActivity(submitType: string) {
    this.submitted = true
    if (this.ActivityManager.invalid) {
      return;
    }
    else {


      this.activityService.persistActivity(this.activity, submitType, this.checkerComments).subscribe(
        activityResponse => {
          alert(activityResponse.message);
          this.dialogRef.close("true");
        },
        error => {
          console.log(error);
        }
      );
    }
  }
}
