import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { EtlService } from '../../../services/etl.service';
import { RollBackResponse } from '../../../models/etl.model';

@Component({
  selector: 'app-roll-back',
  templateUrl: './roll-back.component.html',
  styleUrls: ['./roll-back.component.scss']
})
export class RollBackComponent implements OnInit {

  etlProcessId: number;
  templateName: string;
  fileName: string;
  loading = false;

  constructor(public etlService: EtlService,
    public dialogRef: MatDialogRef<RollBackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.etlProcessId = data.etlProcessId;
    this.templateName = data.templateName;
    this.fileName = data.fileName;
  }

  ngOnInit() {
  }

  submitRollBack(){
    this.loading = true;
    this.etlService.rollBackByFile(this.etlProcessId,this.templateName,this.fileName).subscribe(
      (response : RollBackResponse) => {
        alert(response.message);
        this.loading = false;
        this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }
    );
  }

}
