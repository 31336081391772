import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRole } from 'src/app/modules/parent/user-management/models/user-management.model';
import { environment } from 'src/environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class NavMenuService {
 
  constructor(private http: HttpClient) { }
 
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
 
  loadUserPermissions(): Observable<IRole[]> {
    return this.http.get<IRole[]>(environment.userManagementUrl + '/v1/user/roles/operations/read/'+JSON.parse(localStorage.getItem('user')).id, this.httpOptions);
  }

}