import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IReconModule, IReconProcessStatus } from '../../models/recon.model';
import { RuleService } from '../../services/rule.service';
import { AgGridColumnsService } from '../../services/ag-grid-columns.service';
import { MatDialog } from '@angular/material';
import { ReconRollBackComponent } from './recon-roll-back/recon-roll-back.component';
import { UserManagementService } from '../../../user-management/services/user-management.service';
import { ActivatedRoute } from '@angular/router';
import { ILoginResponse } from 'src/app/modules/login/login.model';

@Component({
  selector: 'app-run-recon',
  templateUrl: './run-recon.component.html',
  styleUrls: ['./run-recon.component.scss']
})
export class RunReconComponent implements OnInit {
  reconModuleList: IReconModule[] = [];
  submitted = false;
  public rowData: any;
  public columnDefs: any;
  gridApi: any;
  reconModuleId: number;
  loginResponse:ILoginResponse;
  userName: string;
  permissionList: string[] = [];

  constructor(private fb: FormBuilder,
    public dialog: MatDialog,
    public ruleService: RuleService,
    public agGridColumns: AgGridColumnsService,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.reconModuleId = parseInt(localStorage.getItem('reconModuleId'));
    this.loginResponse = JSON.parse(localStorage.getItem('user'));
    console.log("Login Response UserName--> ", this.loginResponse.userName);
    this.userName = this.loginResponse.userName;
    this.loadChildPermissionsByUser();
    this.loadReconProcessList();
  }

  loadChildPermissionsByUser() {
    this.userManagementService.loadChildPermissionsByUser(this.route.firstChild.routeConfig.path.replace(/-/g, ' ')).subscribe(
      (list: string[]) => {
        this.permissionList = list;
      },
      error => {
        console.log(error);
      }
    );
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  loadReconProcessList() {
    this.ruleService.loadReconProcessList(this.reconModuleId).subscribe(
      (reconProcessStatusList: IReconProcessStatus[]) => {
        this.rowData = reconProcessStatusList
        if (this.rowData) {
          this.columnDefs = this.agGridColumns.generateColumns(this.rowData);
        }
      }
    );
  }

  runRecon() {
    this.submitted = true;
    // stop here if form is invalid
    this.ruleService.runRecon(this.reconModuleId, this.userName).subscribe((data: any) => {
      alert(data.message);
      this.loadReconProcessList();

    })
   
  }

  constructRollBack() {
    let selectedRecord = this.gridApi.getSelectedRows();
    let reconStatus = selectedRecord[0].reconStatus;
    if (selectedRecord.length == 0) {
      alert("Please Select Record");
    }
    else if (reconStatus == "RUNNING" || reconStatus == "FAILED") {
      alert("Recon status is "+reconStatus+"... Can't Roll-Back");
    }
    else if( reconStatus == "ROLL-BACKED"){
      alert("Recon already Roll-Backed");
    }
    else if (selectedRecord[0].rollbackStatus == "ROLL-BACKED") {
      alert("Recon already Roll-Backed");
    }
    else {
      const dialogRef = this.dialog.open(ReconRollBackComponent,
        {
          data: {
            reconProcessStatus: this.gridApi.getSelectedRows()[0]
          },
          panelClass: 'mat-modal',
          width: '500px',
          disableClose: true
        }
      )
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loadReconProcessList();
        }
      });
    }
  }



}
