import { Component, OnInit } from '@angular/core';
import { EtlService } from '../../services/etl.service';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { TemplateID, ITemplateName, FileType, TemplateName, FileName, IfileExtract } from '../../models/etl.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FileNaming, HeaderAvailable, FooterAvailable } from './file-extraction-modal/file-extraction-modal';
import { StagingService } from '../../../staging/services/staging.service';

@Component({
	selector: 'app-file-extraction',
	templateUrl: './file-extraction.component.html',
	styleUrls: ['./file-extraction.component.scss']
})

export class FileExtractionComponent implements OnInit {
	selectVal2: string;
	selectVal: any;
	selectVal1: any;
	public templateId: TemplateID[];
	fileConfiguration: any;
	fileConvention: string;
	filenamingconvtab: boolean = true;
	headerconvtab: boolean = true;
	footertab: boolean = true;
	dataformationtab: boolean = true;
	ext: any;
	disabled = true;
	submitted = false;
	isSaveShow: boolean = true;
	isEditshow: boolean = true;
	isNextShow: boolean = true;
	isClearshow: boolean = true;
	FiletempObjs: ITemplateName[] = [];
	fileType: FileType[] = [];
	templateName: TemplateName[];
	fileName: FileName[];
	FilenameObjs: IfileExtract[] = [];
	fileId: any[];
	fileExt: string;
	filePath: any;
	footerCountLine: any;
	delimeter: string;
	numberOfColumn: any;
	headerKeyType: any;
	fileTypes: any[] = [];
	moduleNames: any[] = [];
	headerFormatsDetails: any;
	footerFormatsDetails: any;
	reconModuleId: number;

	constructor(public etlService: EtlService, private formBuilder: FormBuilder, public router: Router, public dialog: MatDialog, public stagingService: StagingService) {
		this.fileConfiguration = this.formBuilder.group({
			fileExtension: new FormControl('', Validators.required),
			fileName: new FormControl(),
			templateName: new FormControl(),
			filePath: new FormControl('', Validators.required),
			delimeter: new FormControl(),
			fileBeginWithCheck: new FormControl(),
			fileConventionAvailable: new FormControl(),
			fileBeginWith: new FormControl(),
			fileNameConventionCheckApplicable: new FormControl(),
			fileconvention: new FormControl(),
			duplicateCheck: new FormControl(),
			fileNameLength: new FormControl(),
			headeravailable: new FormControl(),
			headerFormatsDetails: new FormControl(),
			duplicateCheckHeader: new FormControl(),
			multilineHeader: new FormControl(),
			headerKeyType: new FormControl,
			multipleHeaderDataCount: new FormControl(),
			lineBetweenHeaderData: new FormControl(),
			headerSequenceCheck: new FormControl(),
			headerSequenceFormat: new FormControl(),
			datarecordBetweenHeader: new FormControl(),
			headerOccureTime: new FormControl(),
			footerAvailable: new FormControl(),
			footer: new FormControl(),
			filefooterWithConst: new FormControl(),
			footerConst: new FormControl(),
			footerFormatsDetails: new FormControl,
			multilineFooter: new FormControl(),
			footerCountLine: new FormControl(),
			lineBetweenFooterData: new FormControl(),
			footerOccureMultiple: new FormControl(),
			dataValidationAvailable: new FormControl(),
			databeginConst: new FormControl(),
			datarecordConst: new FormControl(),
			linebetweenDataRecord: new FormControl(),
			multiLineRecordCheck: new FormControl(),
			numberOfLine: new FormControl(),
			recordCountPerLine: new FormControl(),
			fileId: new FormControl('', Validators.required),
			templateId: new FormControl('', Validators.required),
			tableName: new FormControl()
		});
		this.fileConfiguration.controls['fileConventionAvailable'].disable();
		this.fileConfiguration.controls['headeravailable'].disable();
		this.fileConfiguration.controls['footerAvailable'].disable();

		this.fileConfiguration.get('fileBeginWithCheck').disable()
		this.fileConfiguration.get('fileNameConventionCheckApplicable').disable()
		this.fileConfiguration.get('duplicateCheck').disable()
		//this.fileConfiguration.get('btnOpenFileNaming').disable()

		this.fileConfiguration.get('duplicateCheckHeader').disable()
		this.fileConfiguration.get('multilineHeader').disable()
		this.fileConfiguration.get('headerKeyType').disable()
		this.fileConfiguration.get('headerSequenceCheck').disable()
		this.fileConfiguration.get('headerOccureTime').disable()

		this.fileConfiguration.get('footer').disable()
		this.fileConfiguration.get('filefooterWithConst').disable()
		this.fileConfiguration.get('multilineFooter').disable()
		this.fileConfiguration.get('footerOccureMultiple').disable()

		this.fileConfiguration.get('databeginConst').disable()
		this.fileConfiguration.get('multiLineRecordCheck').disable()
	}
	get fval() {
		return this.fileConfiguration.controls;
	}
	signup() {
		this.submitted = true;
		if (this.fileConfiguration.invalid) {

			return;
		}
	}
	OpenFileNaming() {
		const dialogRef = this.dialog.open(FileNaming);
		this.fileConvention = "";
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed', result);
			if (result != undefined) {
				result.forEach(element => {
					this.fileConvention = this.fileConvention + `:${element.chooseConstant}:${element.constantValue}`

				});


			}
		});
	}

	OpenHeaderAvailable() {
		console.log(this.fileConfiguration.value.multipleHeaderDataCount)
		if (this.fileConfiguration.value.multipleHeaderDataCount == null) {
			alert("please enter header count");
		}
		else if (this.fileConfiguration.value.multipleHeaderDataCount > 10) {
			alert('Header Count Should not be more than 10');

		}
		else if (this.fileConfiguration.value.multipleHeaderDataCount == 0) {
			alert('Header Count Should not be 0');
		}
		else if (this.fileConfiguration.value.multipleHeaderDataCount < 0) {
			alert('Header Count Should not be negative');
		}
		else {
			console.log("in dialog OpenHeaderAvailable :", this.OpenHeaderAvailable)
			const dialogRef = this.dialog.open(HeaderAvailable, { data: { dataCount: this.fileConfiguration.value.multipleHeaderDataCount, headerFormatsDetails: this.headerFormatsDetails } });

			dialogRef.afterClosed().subscribe(result => {
				console.log('The dialog was closed', result);
				if (result) {
					this.fileConfiguration.patchValue({
						headerFormatsDetails: result
					})
				}

			});
		}
	}
	OpenFooterAvailable() {
		if (this.fileConfiguration.value.footerCountLine == null) {
			alert("please enter Footer count");
		}
		else if (this.fileConfiguration.value.footerCountLine > 10) {
			alert('Footer Count Should not be more than 10');

		}
		else if (this.fileConfiguration.value.footerCountLine == 0) {
			alert('Footer Count Should not be 0');
		} else if (this.fileConfiguration.value.footerCountLine < 0) {
			alert('Footer Count Should not be negative');
		}
		else {
			//const dialogRef = this.dialog.open(FooterAvailable,{data:this.fileConfiguration.value.multipleFooterDataCount
			const dialogRef = this.dialog.open(FooterAvailable, {
				data: { dataCount: this.fileConfiguration.value.footerCountLine, footerFormatsDetails: this.footerFormatsDetails }
			});
			dialogRef.afterClosed().subscribe(result => {
				console.log('The dialog was closed', result);
				if (result) {
					this.fileConfiguration.patchValue({
						footerFormatsDetails: result
					})
				}
			});
		}
	}
	ngOnInit() {
		this.reconModuleId = parseInt(localStorage.getItem('reconModuleId'));
		this.loadFileType();
		// this.fileType = [
		// 	{ name: "Delimiter", value: "TEXT" },
		// 	{ name: "Excel", value: "excel" },
		// 	{ name: "Fixed Length", value: "FixLength" },
		// 	{ name: "Swift MT103", value: "MT103" },
		// 	{ name: "Swift MT202", value: "MT202" },
		// 	{ name: "Swift MT940", value: "MT940" },
		// 	{ name: "Swift MT950", value: "MT950" },
		// 	{ name: "Visa Acquire", value: "VisaAcquire" },
		// 	{ name: "Rupay 01", value: "Rupay01" },
		// 	{ name: "NTSL", value: "NTSL" },
		// 	{ name: "TLF", value: "TLF" },
		// 	{ name: "MASTERCARD", value: "MASTERCARD" }

		// ];
		this.fileName = [];
		//this.loadModuleNames();
		//this.fileConfiguration.controls['moduleId'].setValue('choose');
		console.log("selectVal :", this.selectVal)
	}

	loadFileType(){
		var currentPage = "File Extraction";
		this.etlService.getFileType(currentPage).subscribe(
		  data => {
			for (const [key, value] of Object.entries(data)) {
			  this.fileType.push({ name: key, value: value });
			}
		  },
		  error => {
			console.log(error);
		  }
		);
	  }

	// loadModuleNames(){

	//   this.moduleNames=[]
	//   this.stagingService.getStagingModel().subscribe(
	//     data=>{
	//       for (const [key, value] of Object.entries(data)) {
	//         this.moduleNames.push({name:key,value:value});
	//       }
	//     },
	//     error=>{
	//       console.log(error);
	//     }
	//   );
	// }

	// onSelectModuleName(){
	//   this.isClearshow=false;
	// }

	loadTableNames(moduleId) {
		this.fileTypes = [];
		this.FilenameObjs = [];

		this.stagingService.getStagingModelStagingTables(moduleId).subscribe(
			data => {
				for (const [key, value] of Object.entries(data)) {
					this.fileTypes.push({ name: key, value: value + '_EX' });
				}
			},
			error => {
				console.log(error);
			}
		);
	}
	loadMyExt(ext) {
		console.log("this.selectvalpoonam", this.selectVal);
		if (ext === "choose...") {
			this.fileConfiguration.controls['fileConventionAvailable'].disable();
			this.fileConfiguration.controls['headeravailable'].disable();
			this.fileConfiguration.controls['footerAvailable'].disable();
			//this.fileConfiguration.controls['DataFormation'].disable();
			this.isSaveShow = true;
			this.isNextShow = true;
			this.isEditshow = true;
			this.onReset();
		}
		this.FiletempObjs = [];
		this.filePath = [];
		this.numberOfColumn = [];
		this.delimeter = null;
		this.selectVal = "";
		this.selectVal1 = "choose";
		this.selectVal2 = "";
		this.fileConfiguration.patchValue({
			fileId: "",
			templateId: "choose",
			filePath: null,
			delimeter: null,
		})
		// this.isSaveShow=true;
		// this.isNextShow=true;
		// this.isEditshow=true;
		this.isClearshow = false;
		this.isSaveShow = true;
		this.isEditshow = true;
		this.fileExt = ext;
		this.FilenameObjs = [];
		var str = ext.split(" ");
		var id = str[0];

		const newVal = id;
		this.selectVal2 = newVal

		const delimeter = <FormControl>this.fileConfiguration.get('delimeter');
		if (this.fileExt === "TEXT") {

			delimeter.setValidators([Validators.required,]);

		}

		else if (this.fileExt !== "TEXT") {

			delimeter.setValidators(null);
		}




		this.etlService.getFileNameData(ext, this.reconModuleId).subscribe(
			data => {

				for (const [key, value] of Object.entries(data)) {
					this.FilenameObjs.push({ name: key, value: value });
				}

			},
			error => {
				console.log(error);
			}
		);

	}


	loadTemplatedByFile(id1) {
		this.FiletempObjs = [];
		this.filePath = [];
		this.numberOfColumn = [];
		this.delimeter = null;
		this.isClearshow = false;
		const fileName = <FormControl>this.fileConfiguration.get('fileName');
		const templateName = <FormControl>this.fileConfiguration.get('templateName');


		console.log("id1 :", id1)
		if (id1 === "choose") {



			this.fileConfiguration.controls['fileConventionAvailable'].disable();
			this.fileConfiguration.controls['headeravailable'].disable();
			this.fileConfiguration.controls['footerAvailable'].disable();
			//this.selectVal=id1;
			//this.fileConfiguration.controls['DataFormation'].disable();


		}


		const newVal = id1;
		this.isSaveShow = true;
		this.isNextShow = true;
		this.isEditshow = true;


		this.selectVal = newVal
		console.log("select value :", this.selectVal)
		if (id1 === "New") {
			this.isSaveShow = false;
			this.fileConfiguration.patchValue({
				fileName: "",


			});
			fileName.setValidators([Validators.required,]);
			templateName.setValidators([Validators.required,]);
			return;
		} else {
			fileName.setValidators(null);
			templateName.setValidators(null);
		}

		this.etlService.getTemplateData(id1).subscribe(
			data => {
				for (const [key, value] of Object.entries(data)) {
					this.FiletempObjs.push({ name: key, value: value });
				}
			},
			error => {
				console.log(error);
			}
		);

	}



	onTemp(value) {
		this.fileConfiguration.patchValue({

			filePath: null,
			delimeter: null,
			fileBeginWithCheck: null,
			fileconvention: null,
			fileConventionAvailable: null,
			fileNameLength: null,
			fileBeginWith: null,
			fileNameConventionCheckApplicable: null,
			duplicateCheck: null,
			headeravailable: null,
			multilineHeader: null,
			multipleHeaderDataCount: null,
			duplicateCheckHeader: null,
			headerKeyType: null,
			headerSequenceCheck: null,
			headerSequenceFormat: null,
			footerAvailable: null,
			footer: null,
			filefooterWithConst: null,
			footerConst: null,
			multilineFooter: null,
			footerCountLine: null,
			footerOccureMultiple: null,
			headerFormatsDetails: null,
			footerFormatsDetails: null,
		});

		if (value === "choose") {
			this.fileConfiguration.controls['fileConventionAvailable'].disable();
			this.fileConfiguration.controls['headeravailable'].disable();
			this.fileConfiguration.controls['footerAvailable'].disable();
			// this.fileConfiguration.controls['DataFormation'].disable();
		}
		//this.isShow =false;
		this.filePath = [];
		this.numberOfColumn = [];
		this.delimeter = null;
		this.isNextShow = true;
		this.isEditshow = true;
		this.selectVal1 = value;
		if (value === "New") {
			this.fileConfiguration.patchValue({
				templateName: "",

			})
		}

		if (value == "New" && value !== "Choose") {
			this.fileConfiguration.controls['fileConventionAvailable'].enable();
			this.fileConfiguration.controls['headeravailable'].enable();
			this.fileConfiguration.controls['footerAvailable'].enable();
			// this.fileConfiguration.controls['DataFormation'].enable();
		}
		if (value !== "New" && value !== "choose") {

			this.isEditshow = false;
			this.isNextShow = false;
			this.fileConfiguration.controls['fileConventionAvailable'].enable();
			this.fileConfiguration.controls['headeravailable'].enable();
			this.fileConfiguration.controls['footerAvailable'].enable();
			//this.fileConfiguration.controls['DataFormation'].enable();

			this.etlService.loadTemplateEdit(value).subscribe((data: any) => {
				console.log("configure data", data);
				this.headerFormatsDetails = data.headerFormatsDetails;
				this.footerFormatsDetails = data.footerFormatsDetails;
				this.fileConfiguration.patchValue({
					fileName: data.fileName,
					templateName: data.templateName,
					filePath: data.filePath,
					delimeter: data.delimeter,
					fileBeginWithCheck: data.fileBeginWithCheck,
					fileconvention: data.fileconvention,
					fileNameLength: data.fileNameLength,
					fileBeginWith: data.fileBeginWith,
					fileNameConventionCheckApplicable: data.fileNameConventionCheckApplicable,
					duplicateCheck: data.duplicateCheck,
					headerFormatsDetails: data.headerFormatsDetails,
					multilineHeader: data.multilineHeader,
					multipleHeaderDataCount: data.multipleHeaderDataCount,
					duplicateCheckHeader: data.duplicateCheckHeader,
					headerKeyType: data.headerKeyType,
					headerSequenceCheck: data.headerSequenceCheck,
					headerSequenceFormat: data.headerSequenceFormat,
					footer: data.footer,
					filefooterWithConst: data.filefooterWithConst,
					footerConst: data.footerConst,
					footerFormatsDetails: data.footerFormatsDetails,
					multilineFooter: data.multilineFooter,
					footerCountLine: data.footerCountLine,
					footerOccureMultiple: data.footerOccureMultiple
				});
				if (data.fileConventionAvailable === "true") {
					this.fileConfiguration.patchValue({
						fileConventionAvailable: data.fileConventionAvailable,
					});
					this.filenamingconvtab = false;
					this.disabled = !this.disabled;
					this.fileConfiguration.get('fileBeginWithCheck').enable();
					this.fileConfiguration.get('fileNameConventionCheckApplicable').enable()
					this.fileConfiguration.get('duplicateCheck').enable()
				}
				if (data.headeravailable === "true") {
					this.fileConfiguration.patchValue({
						headeravailable: data.headeravailable,
					});
					this.disabled = !this.disabled;
					this.headerconvtab = false;
					this.fileConfiguration.get('duplicateCheckHeader').enable()
					this.fileConfiguration.get('multilineHeader').enable()
					this.fileConfiguration.get('headerKeyType').enable()
					this.fileConfiguration.get('headerSequenceCheck').enable()
					this.fileConfiguration.get('headerOccureTime').enable()
				}
				if (data.footerAvailable === "true") {
					this.fileConfiguration.patchValue({
						footerAvailable: data.footerAvailable,
					});
					this.disabled = !this.disabled;
					this.footertab = false;
					this.fileConfiguration.get('footer').enable()
					this.fileConfiguration.get('filefooterWithConst').enable()
					this.fileConfiguration.get('multilineFooter').enable()
					this.fileConfiguration.get('footerOccureMultiple').enable()
				}

			})
		}


	}
	chkFileValidation(inpu_chk: any) {
		console.log(inpu_chk.currentTarget.checked);
		const obj = inpu_chk.currentTarget.checked
		if (obj == true) {
			this.filenamingconvtab = false;
			this.disabled = !this.disabled;
			this.fileConfiguration.get('fileBeginWithCheck').enable();
			this.fileConfiguration.get('fileNameConventionCheckApplicable').enable()
			this.fileConfiguration.get('duplicateCheck').enable()
			this.fileConfiguration.get('btnOpenFileNaming').enable()
		}
		else {
			this.filenamingconvtab = true;
			this.fileConfiguration.get('fileBeginWithCheck').disable();
			this.fileConfiguration.get('fileNameConventionCheckApplicable').disable()
			this.fileConfiguration.get('duplicateCheck').disable()
			this.fileConfiguration.get('btnOpenFileNaming').disable()
		}
	}
	chkHeaderValidation(inpu_chk: any) {
		console.log(inpu_chk.currentTarget.checked);
		const obj = inpu_chk.currentTarget.checked
		if (obj == true) {

			this.disabled = !this.disabled;
			this.headerconvtab = false;
			this.fileConfiguration.get('duplicateCheckHeader').enable()
			this.fileConfiguration.get('multilineHeader').enable()
			this.fileConfiguration.get('headerKeyType').enable()
			this.fileConfiguration.get('headerSequenceCheck').enable()
			this.fileConfiguration.get('headerOccureTime').enable()
		}

		else {
			this.headerconvtab = true;
			this.fileConfiguration.get('duplicateCheckHeader').disable()
			this.fileConfiguration.get('multilineHeader').disable()
			this.fileConfiguration.get('headerKeyType').disable()
			this.fileConfiguration.get('headerSequenceCheck').disable()
			this.fileConfiguration.get('headerOccureTime').disable()
		}
	}
	changeheader(id) {

		this.headerKeyType = []
		console.log(id);
		if (id == "YES") {

			this.headerKeyType = "Multiple";
		}
		else {
			this.headerKeyType = "Single";
		}
	}
	chkFooterValidation(inpu_chk: any) {
		console.log(inpu_chk.currentTarget.checked);
		const obj = inpu_chk.currentTarget.checked
		if (obj == true) {

			this.disabled = !this.disabled;
			this.footertab = false;
			this.fileConfiguration.get('footer').enable()
			this.fileConfiguration.get('filefooterWithConst').enable()
			this.fileConfiguration.get('multilineFooter').enable()
			this.fileConfiguration.get('footerOccureMultiple').enable()
		}
		else {
			this.footertab = true;
			this.fileConfiguration.get('footer').disable()
			this.fileConfiguration.get('filefooterWithConst').disable()
			this.fileConfiguration.get('multilineFooter').disable()
			this.fileConfiguration.get('footerOccureMultiple').disable()
		}
	}




	chkDataFormation(inpu_chk: any) {
		console.log(inpu_chk.currentTarget.checked);
		const obj = inpu_chk.currentTarget.checked
		if (obj == true) {

			this.disabled = !this.disabled;
			this.dataformationtab = false;
			this.fileConfiguration.get('databeginConst').enable()
			this.fileConfiguration.get('multiLineRecordCheck').enable()
		}
		else {
			this.dataformationtab = true;
			this.fileConfiguration.get('databeginConst').disable()
			this.fileConfiguration.get('multiLineRecordCheck').disable()
		}
	}

	onClickNext(fileExtension, fileId, fileName, templateName, templateId) {


		if (confirm("Do you want to go on field configuration :")) {
			this.router.navigate(['sample-recon/etl/field-configuration', fileExtension, fileId, fileName, templateName, templateId]);
		}
	}

	saveConfigurationDetails() {
		this.fileConfiguration.get('fileId').setValidators(null);
		this.fileConfiguration.get('templateId').setValidators(null);

		// var moduleId=this.fileConfiguration.value.moduleId;
		// if(moduleId==='choose')
		// {
		//   this.fileConfiguration.patchValue({
		//     moduleId:"",

		//   });
		// }
		if (this.fileConfiguration.invalid) {

			return;
		}
		this.fileConfiguration.patchValue({
			fileId: null,
			templateId: null
		});
		this.fileConfiguration.value.moduleId = this.reconModuleId;
		console.log("fileConfiguration data :", this.fileConfiguration.value)
		this.etlService.saveConfigurationDetails({
			fileConfiguration: this.fileConfiguration.value, action: "action"
		}).subscribe((data: any) => {
			if (data.includes("Store")) {
				alert("Configuration Saved Successfully")
			}
			else
				alert(data);

			this.onReset();
			this.fileConfiguration.reset();
			this.submitted = false;
			this.isSaveShow = true;
			//this.loadMyExt(this.ext);
			this.isClearshow = true;




		})


	}

	editConfigurationDetails() {


		// var value=this.fileConfiguration.value.moduleId;
		// if(value==='choose')
		// {
		//   this.fileConfiguration.patchValue({
		//     moduleId:""
		//   })
		// }


		if (this.fileConfiguration.invalid) {

			return;
		}



		console.log("this.fileConfiguration.value", this.fileConfiguration.value)


		var str1 = this.fileConfiguration.value.templateName;

		var tableName = str1 + '_TEMP';


		this.fileConfiguration.patchValue({

			tableName: tableName,
			moduleId: this.reconModuleId,
			fileBeginWithCheck: this.fileConfiguration.value.fileBeginWithCheck,
			fileconvention: this.fileConfiguration.value.fileconvention,
			fileConventionAvailable: this.fileConfiguration.value.fileConventionAvailable,
			fileNameLength: this.fileConfiguration.value.fileNameLength,
			fileBeginWith: this.fileConfiguration.value.fileBeginWith,
			fileNameConventionCheckApplicable: this.fileConfiguration.value.fileNameConventionCheckApplicable,
			duplicateCheck: this.fileConfiguration.value.duplicateCheck,
			headerFormatsDetails: this.fileConfiguration.value.headerFormatsDetails,
			headeravailable: this.fileConfiguration.value.headeravailable,
			multilineHeader: this.fileConfiguration.value.multilineHeader,
			multipleHeaderDataCount: this.fileConfiguration.value.multipleHeaderDataCount,
			duplicateCheckHeader: this.fileConfiguration.value.duplicateCheckHeader,
			headerKeyType: this.fileConfiguration.value.headerKeyType,
			headerSequenceCheck: this.fileConfiguration.value.headerSequenceCheck,
			headerSequenceFormat: this.fileConfiguration.value.headerSequenceFormat,
			footerAvailable: this.fileConfiguration.value.footerAvailable,
			footer: this.fileConfiguration.value.footer,
			filefooterWithConst: this.fileConfiguration.value.filefooterWithConst,
			footerConst: this.fileConfiguration.value.footerConst,
			multilineFooter: this.fileConfiguration.value.multilineFooter,
			footerCountLine: this.fileConfiguration.value.footerCountLine,
			footerOccureMultiple: this.fileConfiguration.value.footerOccureMultiple





		});


		this.fileConfiguration.value.moduleId = this.reconModuleId;
		console.log("new file config", this.fileConfiguration.value);
		this.etlService.editConfigurationDetails({
			fileConfiguration: this.fileConfiguration.value, action: "edit"
		}).subscribe((data: any) => {
			console.log("poonam",data)
			if (data == "Success fully Configuration Update") {
				alert("File configuration updated")
			}
			else
				alert(data);

		})


	}

	onReset() {
		this.fileExt = null;
		this.FiletempObjs = [];
		this.FilenameObjs = [];
		this.selectVal = [];
		this.selectVal1 = [];
		this.selectVal2 = "";
		this.submitted = false;
		this.isSaveShow = true;
		this.isEditshow = true;
		this.isNextShow = true;
		this.isClearshow = true;
		this.fileConfiguration.reset();
		this.fileConfiguration.patchValue({
			//moduleId:"choose",
			fileExtension: "choose...",
			fileId: "",
			templateId: "choose",
			filePath: null,
			delimeter: null,
		})
		this.fileConfiguration.controls['fileConventionAvailable'].disable();
		this.fileConfiguration.controls['headeravailable'].disable();
		this.fileConfiguration.controls['footerAvailable'].disable();
		this.fileConfiguration.get('fileBeginWithCheck').disable()
		this.fileConfiguration.get('fileNameConventionCheckApplicable').disable()
		this.fileConfiguration.get('duplicateCheck').disable()
		//this.fileConfiguration.get('btnOpenFileNaming').disable()

		this.fileConfiguration.get('duplicateCheckHeader').disable()
		this.fileConfiguration.get('multilineHeader').disable()
		this.fileConfiguration.get('headerKeyType').disable()
		this.fileConfiguration.get('headerSequenceCheck').disable()
		this.fileConfiguration.get('headerOccureTime').disable()

		this.fileConfiguration.get('footer').disable()
		this.fileConfiguration.get('filefooterWithConst').disable()
		this.fileConfiguration.get('multilineFooter').disable()
		this.fileConfiguration.get('footerOccureMultiple').disable()

		this.fileConfiguration.get('databeginConst').disable()
		this.fileConfiguration.get('multiLineRecordCheck').disable()
		this.footertab = true;
		this.headerconvtab = true;
		this.filenamingconvtab = true;
	}

}

