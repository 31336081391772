import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IActivityData, IActivityInput } from '../../../models/recon.model';
import { AgGridColumnsService } from '../../../services/ag-grid-columns.service';
import { ReconService } from '../../../services/recon.service';
import { ReconciledModal } from '../../reconciled/reconciled.component';

@Component({
  selector: 'app-same-side-unmatch',
  templateUrl: './same-side-unmatch.component.html',
  styleUrls: ['./same-side-unmatch.component.scss']
})
export class SameSideUnmatchComponent implements OnInit {

  reconName: string;
  activityData: IActivityData;
  sourceName: string;
  columnDefs: any;
  rowData: any = [];
  comments: string;
  submitted: boolean = false;
  sameSideForm: any;

  constructor(public reconService: ReconService,
    public agGridColumns: AgGridColumnsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SameSideUnmatchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.reconName = data.reconName;
    this.activityData = data.activityData;

    this.activityData.external.forEach((source) => {
      this.sourceName = source.tableName;
      this.columnDefs = this.agGridColumns.generateColumns(source.data);
      this.rowData = source.data;
    });

    this.sameSideForm = this.formBuilder.group({
      comments: new FormControl('', Validators.required),
    });
  }


  ngOnInit() {
  }

  get fval() { return this.sameSideForm.controls; }
  
  sameSideUnMatch() {
    this.submitted = true;
    if (this.sameSideForm.invalid) {
      return;
    }
    let activityInput: IActivityInput;
    activityInput = {
      reconName: this.reconName,
      comments: this.comments,
      activityType: "SAME_SIDE_UNMATCH",
      activityData: this.activityData
    }
    this.reconService.processActivity(activityInput).subscribe(
      activityResponse => {
        alert(activityResponse.message);
        this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }
    );
  }

}
