import { MatDialogRef, MatDialog } from '@angular/material';
import { Component } from '@angular/core';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';
import { IServerResponse } from '../login.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ForgetPasswordPageComponent } from './forget-password-page/forget-password-page.component';

@Component({
  selector: 'forget-password',
  templateUrl: 'forget-password.html',
})
export class ForgetPasswordDialog {

  forgetForm: any;
  submitted = false;

  constructor(
    public dialogRef: MatDialogRef<ForgetPasswordDialog>,
    private formBuilder: FormBuilder,
    public loginService: LoginService,
    public router: Router,
    public dialog: MatDialog
  ) {
    this.forgetForm = this.formBuilder.group({
      emailId: new FormControl('', Validators.required)
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get f() { return this.forgetForm.controls; }

  sendMail() {

    this.submitted = true;
    if (this.forgetForm.invalid) {
      return;
    }

    this.loginService.forgetPassword(this.forgetForm.value.emailId).subscribe(
      (response: IServerResponse) => {
        if (response.status == true) {
          localStorage.setItem('emailID', response.object);
          alert(response.message);
          //this.router.navigate(['forget-password-page']);
          this.dialogRef.close();
          const dialogRef = this.dialog.open(ForgetPasswordPageComponent, {

          });
        } else {
          console.log("Response --> ", response.message);
          alert(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

}