import { Component, OnInit } from '@angular/core';
import { ActivityManagerService } from '../../services/activity-manager.service';
import { AgGridColumnsService } from '../../../recon/services/ag-grid-columns.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivityDataComponent } from '../activity-data/activity-data.component';
import { UserManagementService } from '../../../user-management/services/user-management.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activity-manager',
  templateUrl: './activity-manager.component.html',
  styleUrls: ['./activity-manager.component.scss']
})
export class ActivityManagerComponent implements OnInit {

  activityColumnDefs: any;
  activityRowData: any;
  permissionList: string[] = [];

  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;
  private gridApi: any;
  moduleName:string;

  constructor(public activityManagerService: ActivityManagerService,
    public agGridColumns: AgGridColumnsService,
    public dialog: MatDialog,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute, ) { 
      this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">No Rows To Show</span>'
      this.overlayNoRowsTemplate =
      '<span class="ag-overlay-loading-center">No Rows To Show</span>';
    }
  columnDefsActivity = [
    { headerName: 'SNO', field: 'sno', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'ACTIVITY ID', field: 'activityId', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'RECON', field: 'recon', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'ACTIVITY TYPE', field: 'activityType', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'STATUS', field: 'status', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'ACTIVITY OWNER', field: 'activityOwner', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'ALLOWED APPROVERS', field: 'allowedApprovers', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'RECENT ACTOR', field: 'recentActor', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'ACTIVITY LEVEL', field: 'activityLevel', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    // { headerName: 'activityByteData', field: 'activityByteData', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'ACTIVITY INDEX', field: 'activityIndex', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'CREATED ON', field: 'createdOn', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'RECENTLY UPDATEDON', field: 'recentlyUpdatedOn', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'VERSION', field: 'version', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    { headerName: 'COMMENTS', field: 'comments', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true },
    // { headerName: 'businessArea', field: 'businessArea', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true }

  ];

  ngOnInit() {
    this.moduleName= localStorage.getItem('reconModuleName');
    this.loadChildPermissionsByUser();
    this.loadActivityForUser();
    this.activityColumnDefs = this.columnDefsActivity;
    this.onPageSizeChanged();
  }

  loadChildPermissionsByUser() {
    this.userManagementService.loadChildPermissionsByUser(this.route.firstChild.routeConfig.path.replace(/-/g, ' ')).subscribe(
      (list: string[]) => {
        this.permissionList = list;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadActivityForUser() {
    this.activityColumnDefs = this.columnDefsActivity;
    this.activityManagerService.loadActivityForUser(this.moduleName).subscribe(
      (activityList) => {
        this.activityRowData = activityList;
        this.activityColumnDefs = this.columnDefsActivity;
      },
      error => {
        console.log(error);
      }
    );
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  onRowClicked(event: any) {
    const dialogRef = this.dialog.open(ActivityDataComponent, {
      width: '998px',
      panelClass: 'mat-modal',
      data: {
        activity: event.data,
        permissionList: this.permissionList
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.gridApi.updateRowData({ remove: this.gridApi.getSelectedRows() });
        //this.loadActivityForUser();
      }
    });

  }
  onPageSizeChanged() {
   
    var value =((document.getElementById("page-size"))as HTMLInputElement).value;
    this.gridApi.paginationSetPageSize(Number(value));
  
  }
}
