


import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { EtlService } from '../../../services/etl.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'cleaningAdd',
  templateUrl: 'cleaning-add.html'
})

export class cleaningAdd implements OnInit {

  selectTempletid1: 0;
  selectTempletGroupId: "";
  cleaningSelectColumnName: any;
  cleaningSelectColumnName1: any;
  cleaningSelectOperationCategory: any;
  selectOperation: any;
  replaceNullBy: any;
  maskingCharacter: any;
  maskingFromPos: any;
  maskingToPos: any;
  replaceCharacters: any;
  replaceCharactersBy: any;
  removingCharacters: any;
  CleaningAdd: any;
  submitted = false;
  rowData: any;
  COLUMN_NAME: any;
  COLUMN_DATA_TYPE:string;
  ngOnInit(): void {
    this.loadTempColumns();
  }
  public templetColumnNames: any = [];
  selectOperationValue: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public etlService: EtlService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<cleaningAdd>) {

    this.CleaningAdd = new FormGroup({
      ColumnName: new FormControl('', Validators.required),
      Category: new FormControl('', Validators.required),
      Operation: new FormControl('', Validators.required),
      ReplaceNullBy: new FormControl(''),
      MaskingCharacter: new FormControl(''),
      MaskingfromPos: new FormControl(''),
      MaskingToPos: new FormControl(''),
      ReplaceCharacters: new FormControl(''),
      ReplaceCharactersBy: new FormControl(''),
      RemovingCharacters: new FormControl(''),
      dataType: new FormControl('')
    });

    console.log("VV cleaning modal :", data);
    console.log("data.row", data.row.COLUMN_NAME);

    this.rowData = data.row;
    this.selectTempletid1 = data.tempId;
    this.selectTempletGroupId = data.groupId;
    this.cleaningSelectOperationCategory = "Cleaning";

  }
  get fval() {
    return this.CleaningAdd.controls;
  }
  signup() {
    this.submitted = true;
    if (this.CleaningAdd.invalid) {

      return;
    }


  }

  changeSelectOperation(value) {
    this.selectOperationValue = value;
    this.CleaningAdd.get('ReplaceNullBy').setValidators(null);
      this.CleaningAdd.get('MaskingCharacter').setValidators(null);
      this.CleaningAdd.get('MaskingToPos').setValidators(null);
      this.CleaningAdd.get('MaskingfromPos').setValidators(null);
      this.CleaningAdd.get('ReplaceCharacters').setValidators(null);
      this.CleaningAdd.get('ReplaceCharactersBy').setValidators(null);
      this.CleaningAdd.get('RemovingCharacters').setValidators(null);
    this.removingCharacters=undefined;
    this.replaceCharactersBy=undefined;
    this.replaceCharacters=undefined;
    this.replaceNullBy=undefined;
    this.maskingCharacter=undefined,
    this.maskingFromPos=undefined,
    this.maskingToPos=undefined
    this.CleaningAdd.patchValue({
      ReplaceNullBy: undefined,
      MaskingCharacter: undefined,
      MaskingfromPos: undefined,
      MaskingToPos: undefined,
      ReplaceCharacters: undefined,
      ReplaceCharactersBy: undefined,
      RemovingCharacters: undefined,
    })

    if (this.selectOperationValue === 'ReplaceNullBy') {
      const ReplaceNullBy = <FormControl>this.CleaningAdd.get('ReplaceNullBy');
      ReplaceNullBy.setValidators([Validators.required,])
    } else if (this.selectOperationValue === 'Data Masking') {
      const MASKING_CHARACTER = <FormControl>this.CleaningAdd.get('MaskingCharacter');
      MASKING_CHARACTER.setValidators([Validators.required,]);

      const MASKING_FROMPOS = <FormControl>this.CleaningAdd.get('MaskingfromPos');
      MASKING_FROMPOS.setValidators([Validators.required,]);

      const MASKING_TOPOS = <FormControl>this.CleaningAdd.get('MaskingToPos');
      MASKING_TOPOS.setValidators([Validators.required,]);
    } else if (this.selectOperationValue === 'rplaceCharString') {
      const REPLACE_CHARACTER = <FormControl>this.CleaningAdd.get('ReplaceCharacters');
      REPLACE_CHARACTER.setValidators([Validators.required,]);
      const REPLACE_CHARACTER_BY = <FormControl>this.CleaningAdd.get('ReplaceCharactersBy');
      REPLACE_CHARACTER_BY.setValidators([Validators.required,]);
    }else  if (this.selectOperationValue === 'rmvCharString') {
      const REMOVE_CHARACTERS = <FormControl>this.CleaningAdd.get('RemovingCharacters');
      REMOVE_CHARACTERS.setValidators([Validators.required,])
    }



  }


  loadTempColumns() {

    this.etlService.getTemplateColumnNameData(this.data.tempId).subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {

          this.templetColumnNames.push({ name: key, value: value });
        }
        this.CleaningAdd.patchValue({
          ColumnName:"",
          Operation:""
       
        })
      }
    )
  }


  saveCleaningConfig() {
    if (this.CleaningAdd.invalid) {

      return;
    }
    //console.log("rowData",.ColumnName);
    console.log("noooooo", this.cleaningSelectColumnName);

    let flag: boolean = false;


    for (let data of this.rowData) {
      console.log("COLUMN_NAME", data.COLUMN_NAME);
      console.log("cleaningSelectColumnName", this.cleaningSelectColumnName);
      console.log("OPERATION_NAME", data.OPERATION_NAME);
      console.log("selectOperation", this.selectOperation);
      console.log("REPLACE_NULL_BY", data.REPLACE_NULL_BY);
      console.log("replaceNullBy", this.replaceNullBy);
      // console.log("rowData",  data.COLUMN_NAME);
      // console.log("rowData",  data.COLUMN_NAME);
      // console.log("rowData",  data.COLUMN_NAME);
      // console.log("rowData",  data.COLUMN_NAME);
      
        if (this.selectOperation == "ReplaceNullBy") {
          if (data.COLUMN_NAME == this.cleaningSelectColumnName && data.REPLACE_NULL_BY == this.replaceNullBy) {
            flag = true;
            break;
          }
        }
          if (this.selectOperation == "Data Masking") {
            if (data.COLUMN_NAME == this.cleaningSelectColumnName) {

              flag = true;
              break;

            }
          }
          if (this.selectOperation == "rplaceCharString") {
            if (data.COLUMN_NAME == this.cleaningSelectColumnName && data.REPLACE_CHARACTER == this.replaceCharacters && data.REPLACE_CHARACTER_BY == this.replaceCharactersBy) {

              flag = true;
              break;

            }
          }
          if (this.selectOperation == "rmvCharString") {
            if (data.COLUMN_NAME == this.cleaningSelectColumnName && data.REMOVE_CHARACTERS == this.removingCharacters) {

              flag = true;
              break;

            }
          }
          if (this.selectOperation == "Reject Null"){
        if (data.OPERATION_NAME == "Reject Null" && data.COLUMN_NAME == this.cleaningSelectColumnName) {
        flag = true;
        break;
      }
    }
    if (this.selectOperation == "Trim Data"){
      if ( data.OPERATION_NAME == "Trim Data" && data.COLUMN_NAME == this.cleaningSelectColumnName) {
      flag = true;
      break;
    }
  }
    }

    if (flag) {
      alert(" Record already exists");
    }
    else {
      this.dialogRef.close(
        this.etlService.saveCleaningConfig({
          cleaningSelectOperationCategory: "Cleaning",
          selectTempletGroupId: this.selectTempletGroupId,
          selectTempletid1: this.selectTempletid1,
          cleaningSelectColumnName: this.cleaningSelectColumnName,
          selectOperation: this.selectOperation,
          replaceNullBy: this.replaceNullBy,
          maskingCharacter: this.maskingCharacter,
          maskingFromPos: this.maskingFromPos,
          maskingToPos: this.maskingToPos,
          replaceCharacters: this.replaceCharacters,
          replaceCharactersBy: this.replaceCharactersBy,
          removingCharacters: this.removingCharacters

        }).subscribe((data: any) => {
          if(data.message =="Record sucessfully inserted "){
            alert("Operation Added Successfully");
          }
          else
          alert(data.message);
          // alert("Cleaning configuration successfully saved");


        }))

    }
  }

  changeColumnName(value){
    var str=value.split(" ");
    this.cleaningSelectColumnName=str[0];
    this.COLUMN_DATA_TYPE=str[1];
    this.CleaningAdd.patchValue({
      Operation:""
    })
    this.changeSelectOperation(this.CleaningAdd.Operation);
  }
}