import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gl-balancing',
  templateUrl: './gl-balancing.component.html',
  styleUrls: ['./gl-balancing.component.scss']
})
export class GlBalancingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
