import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from './header.service';
import { Idle } from '@ng-idle/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	reconModuleName: string;
	user: string;
	idleState: any;
	timedOut = false;
	lastPing?: Date = null;
	timed: boolean = false;
	countMinutes: any;
	countSeconds: any;
	actualCountdown: any;

	constructor(private router: Router, private idle: Idle, public headerService: HeaderService, public dialog: MatDialog) {

		idle.setIdle(1);
		idle.setTimeout(this.getTimer());
		idle.onTimeout.subscribe(() => {
			this.timedOut = true;
			this.idle.stop();
			localStorage.clear();
			this.dialog.closeAll();
			alert("Your session has expired. Please login again.");
			this.router.navigate(['/']);
		});
		idle.onTimeoutWarning.subscribe((countdown: any) => {
			localStorage.setItem("timer", countdown);
			this.countMinutes = (Math.floor(countdown / 60));
			this.countSeconds = countdown % 60;
			this.actualCountdown = this.countMinutes + ':' + this.countSeconds;
			this.idleState = 'Session Expires in ' + this.actualCountdown;
			if (countdown == 120) {
				this.timed = true;
				const dialogRef = this.dialog.open(SessionTimeoutComponent, {
					data: {
						sessionTime: this.actualCountdown
					},
					width: '500px',
					panelClass: 'mat-modal',
					disableClose: true
				});
				dialogRef.afterClosed().subscribe(result => {
					if (result) {
						idle.setTimeout(1800);
						this.dialog.closeAll();
					}
				});
			}
		});

		this.headerService.getUserLoggedIn().subscribe(userLoggedIn => {
			if (userLoggedIn) {
				idle.watch()
				this.timedOut = false;
			} else {
				idle.stop();
			}
		});
		this.reset();
	}

	getTimer(): number {
		if (localStorage.getItem("timer")) {
			return parseInt(localStorage.getItem("timer"));
		} else {
			return 1800;
		}
	}

	reverseNumber(countdown: number) {
		return (1800 - (countdown - 1));
	}

	reset() {
		this.idle.watch();
		this.timedOut = false;
	}

	ngOnInit() {
		this.reconModuleName = localStorage.getItem('reconModuleName');
		this.user = JSON.parse(localStorage.getItem('user')).userId;
	}

	@HostListener('window:unload', ['$event'])
	unloadHandler() {
		this.logout();
	}

	logout() {
		this.headerService.logout(this.user).subscribe(
			data => {

			},
			error => {
				console.log(error);
			}
		);
		localStorage.setItem('existingUserName', this.user);
		localStorage.setItem('existingModuleName', this.reconModuleName);
		this.idle.stop();
		this.router.navigate(['']);
	}

	changePassword() {
		const dialogRef = this.dialog.open(ChangePasswordComponent,
			{
				panelClass: 'mat-modal',
				width: '500px',
				disableClose: true
			}

		);
		dialogRef.afterClosed().subscribe(result => {
			console.log("result",result);
			if(result){
				this.logout();
			}
			


		});
	}
}