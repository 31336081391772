import { Component, OnInit } from '@angular/core';
import { StagingService } from '../../services/staging.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-staging-data',
  templateUrl: './staging-data.component.html',
  styleUrls: ['./staging-data.component.scss']
})
export class StagingDataComponent implements OnInit {
  moduleNames: any[] = [];
  fileTypes: any[] = [];
  columnDefs: any;
  private gridApi: any;
  gridColumnApi: any;
  rowData: any;
  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;
  myTempRowData: any[] = [];

  Tablename:any

  pageSize:any;

  currentPage:any;

  totalRecords:any;

  pgno:boolean=false

  stagingData

  constructor(public stagingService: StagingService) {
    this.overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>'
  this.overlayNoRowsTemplate =
  '<span class="ag-overlay-loading-center">Please wait while your data is loading</span>';


  }

  ngOnInit() {
    this.loadTableNames();
    this.currentPage=0;
    this.totalRecords=0;
  }

  onFirstDataRendered(params) {
    params.api.autoSizeAllColumns();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  loadTableNames() {
    this.fileTypes = [];
    this.stagingService.getStagingModelStagingTables(localStorage.getItem('reconModuleId')).subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          this.fileTypes.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  loadTableData(tableName) {

    this.Tablename=tableName

    this.pageSize=10;

    this.loadData()
    // this.stagingService.getTablesData(tableName).subscribe(data => {
    //   if(data.length > 0) {
    //     this.rowData = data
    //     if (this.rowData) {
    //       this.columnDefs = this.generateColumns(this.rowData);
    //     }
    //   } else {
    //     this.rowData = [];
    //     this.overlayLoadingTemplate =
    //     '<span class="ag-overlay-loading-center">No Row Data into Staging Table</span>'
    //     this.overlayNoRowsTemplate =
    //     '<span class="ag-overlay-loading-center">No Row Data into Staging Table</span>';
    
    //   } 
    // }
    // );
  }

  //iqbal bhai 
  onPageSizeChanged(newPageSize) {

    const element = document.getElementById('page-size') as HTMLInputElement

    const value = element.value

    this.currentPage=0;

    this.gridApi.paginationSetPageSize(Number(value));

    this.pageSize=value;

    this.loadData()

  }

  onPaginationChanged(event){



    if(this.Tablename )

    {

        this.setText('#lbCurrentPage', this.currentPage+1);

        this.setText('#lbTotalPages',  Math.ceil(this.totalRecords/this.pageSize));

        this.setText('#startRecordCount', ((this.currentPage)*this.pageSize)+1);

         if(this.totalRecords<((this.currentPage+1)*this.pageSize))

         {

           this.setText('#endRecordCount',   this.totalRecords);

         }

         else

          this.setText('#endRecordCount',   (this.currentPage+1)*this.pageSize);



       this.setText('#totalRecordsCount', this.totalRecords);



    }

  }

  onBtFirst() {

    this.gridApi.paginationGoToFirstPage();

  }



  onBtLast() {

    this.gridApi.paginationGoToLastPage();

  }




  onBtNext() {

    if(this.currentPage==0){

      this.pgno=!this.pgno



    }

  if(Math.ceil(this.totalRecords/this.pageSize)>this.currentPage+1)

  {

     this.currentPage=this.currentPage+1;

    //this.setText('#lbCurrentPage', this.currentPage);

    this.loadData()

  // }

  }

}
onBtPrevious() {

  console.log("page no :",this.currentPage);



  if(this.currentPage==1){

    this.pgno=false;

  }

   if(this.currentPage>=0)

   {

this.currentPage=this.currentPage-1;

//   this.setText('#lbCurrentPage', this.currentPage);

this.loadData()

   }

}
onBtPageFive() {

  this.gridApi.paginationGoToPage(4);

}

onBtPageFifty() {

  this.gridApi.paginationGoToPage(49);

}



  setText(selector, text) {

    document.querySelector(selector).innerHTML = text;

  }
  loadData(){
    this.stagingService.getTablesDataByPagination(this.Tablename,this.currentPage,this.pageSize).subscribe(
    
      data =>{
        console.log("Pagination data",data);

        this.stagingData=data;

       console.log('this.stagingData.totalElements',this.stagingData.totalElements);

       let tempObj = {};

          this.myTempRowData=[];

          this.totalRecords=this.stagingData.totalElements;

          this.stagingData.content.forEach(element => {

            let map = new Map(Object.entries(element));     
          
         for (let [key, value] of map) {
             if (value === null || value === "null") {
               tempObj[key] = "NA";
               }else {
                 tempObj[key] = value;
                  }
                 }
                  this.myTempRowData.push(tempObj);
                  tempObj = {};
              })
              this.rowData = [...this.myTempRowData]

              if (this.rowData) {
    
                this.columnDefs = this.generateColumns(this.rowData);
              }
              this.setText('#lbCurrentPage', this.currentPage);

              this.setText('#lbTotalPages',  this.totalRecords/this.gridApi.paginationGetPageSize());

      })

  }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            filter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: false,
            resizable: true
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }
  fileNameExcel: string = 'StagingData.xlsx';
  excelExport(): void {
    var exportData = [];
    var tempColumns = [];
    var rowColArray = [];
    let rowIndex = 0;
    for (let index = 0; index < this.columnDefs.length; index++) {
      tempColumns[index] = this.columnDefs[index].field;
      rowColArray[index] = this.columnDefs[index].field;
    }
    exportData[rowIndex] = rowColArray;
    rowIndex = rowIndex + 1;
    this.rowData.forEach((k, v) => {
      let colIndex = 0;
      var rowArray = [];
      for (let key in k) {
        if (tempColumns.indexOf(key) > -1) {
          rowArray[colIndex] = k[key];
          colIndex = colIndex + 1;
        }
      }
      exportData[rowIndex] = rowArray;
      rowIndex = rowIndex + 1;
    })

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, this.fileNameExcel);
  }
  formula: string = "Formula 1";
  //Export Csv
  csvExport() {
    var params = {     
      fileName: 'Staging Data.csv',      
    };
    this.gridApi.exportDataAsCsv(params);
  
  }
  // onPageSizeChanged(newPageSize) {
  //   const element = document.getElementById('page-size') as HTMLInputElement
  //   const value = element.value
  //   this.gridApi.paginationSetPageSize(Number(value));
  // }

  
}
