import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { MultiDataSet, Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {
 
  // Doughnut
  public doughnutChartLabels: Label[] = ['AM', 'AU', 'MM', 'MU'];
  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    }
  };
  public pieChartLegend = true;

  public doughnutChartData: MultiDataSet = [
    [350, 300, 150, 250],
  ];
  public doughnutChartType: ChartType = 'doughnut';
  
  public doughnutChartLegend = true;
  public doughnutChartPlugins = [];

  public doughnutChartColors: Color[] = [
    {
      backgroundColor: ['#2684e6', '#d02224', '#078f45', '#fcb623'],
    },
  ];

  constructor() { }

  ngOnInit() {
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  changeLegendPosition() {
    this.doughnutChartOptions.legend.position = this.doughnutChartOptions.legend.position === 'bottom' ? 'bottom' : 'left';
  }

}