import { Component, OnInit } from '@angular/core';
import { RuleService } from '../services/rule.service';

@Component({
  selector: 'app-view-recon',
  templateUrl: './view-recon.component.html',
  styleUrls: ['./view-recon.component.scss']
})
export class ViewReconComponent implements OnInit {
  private gridApi;
  private gridColumnApi;

  moduleId:any;
  moduleName:any;

  public rowData: any;

  constructor(public ruleService: RuleService) { }

  ngOnInit() {
    this.moduleId = localStorage.getItem('reconModuleId');
    this.moduleName= localStorage.getItem('reconModuleName');
    this.loadReconView();
  }


  columnDefs = [
    { headerName: 'Module Id', field: 'Module ID', filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true},
    { headerName: 'Module Name', field: 'Module Name', filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true },
    { headerName: 'Rule Name', field: 'Rule Name', filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true },
    { headerName: 'Rule Type', field: 'Rule Type', filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true },
    { headerName: 'Rule Status', field: 'Rule Status', filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true },
    { headerName: 'Rule Priority', field: 'Rule Priority', filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true },
    { headerName: 'BaseSource', field: 'BaseSource', filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true },
    { headerName: 'OtherSourceName', field: 'OtherSourceName', filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true}
    // { headerName: 'ConditionValue', field: 'ConditionValue', filter: true, editable: false,floatingFilterComponentParams: {suppressFilterButton:true} ,floatingFilter: false,suppressMenu: true }
  ];

  loadReconView(){
    console.log("Module Name --> ", this.moduleName);
 
    this.ruleService.loadReconView(this.moduleName).subscribe(
      (data) => {
        console.log("Data --> ", data.object);
        this.rowData = data.object;
      },
      error => {
        console.log(error);
      }
    );
  }

  onGridReady(params){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit(); 
  }

  csvExport() {
    var params = {     
      fileName: 'ReconViewData.csv',      
    };
    this.gridApi.exportDataAsCsv(params);
  //new Angular2Csv(this.rowData, this.formula, { headers: Object.keys(this.rowData[0]) });
  }

}
