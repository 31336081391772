import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Idle } from '@ng-idle/core';
import { HeaderService } from '../header.service';
import { ILoginResponse } from 'src/app/modules/login/login.model';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {

  reconModuleName: string;
	user: string;
	idleState = '';
	timedOut = false;
  lastPing?: Date = null;
  countdown: number;
  countMinutes: number;
	countSeconds: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialogRef: MatDialogRef<SessionTimeoutComponent>,
  private headerService: HeaderService,
  idle: Idle) { 
    idle.onTimeoutWarning.subscribe((countdown: any) => {
      localStorage.setItem("timer", countdown);
      this.countdown = parseInt(localStorage.getItem("timer"));
      this.countMinutes = (Math.floor(this.countdown / 60));
      this.countSeconds = this.countdown % 60;
    });
  }

  ngOnInit() {
  }

	stayLoggedIn() {
    this.headerService.stayLoggedIn().subscribe(
			(loginResponse: ILoginResponse) => {
        if (loginResponse.status) {
          localStorage.setItem('user',JSON.stringify(loginResponse));
          this.headerService.setUserLoggedIn(true);
          this.dialogRef.close("true");
        }
			},
			error => {
        console.log(error);
        this.dialogRef.close("false");
			}
    );
	}

}
