import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { UsersService } from './services/users.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  public rowData: any;
  public columnDefs: any;
  myTempRowData: any[] = [];

  private gridApi: any;
  private gridColumnApi: any;
  public rowSelection: any;

  constructor(public dialog: MatDialog, public usersService: UsersService) {
    this.rowSelection = "single";

  }

  ngOnInit() {
    this.loadUsersData();
  }

  private columnDefsUserdata = [
    { headerName: 'User Id', field: 'user_id', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'User Name', field: 'user_name', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'E-Mail', field: 'email_id', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Phone No', field: 'phon_number', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Reporting User', field: 'reporting_user_id', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    // { headerName: 'OrganizationId', field: 'organizationid', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Ldap User', field: 'is_ldap_user', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Status', field: 'is_active', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'Created Date', field: 'createdDate', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'password change time', field: 'password_change_time', sortable: true, filter: true, editable: false, resizable: true, suppressSizeToFit: true, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true }
  ];





  //getting users records 
  loadUsersData() {
    this.usersService.getUsersData().subscribe(
      (escalationList) => {
        this.rowData = escalationList;
        this.columnDefs = this.columnDefsUserdata;


      },
      error => {
        console.log(error);
      }
    );
  }

  addUserDetails() {
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '500px',
      panelClass: 'mat-modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadUsersData();
    });
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  editUserDetails(): void {
    var editSelectedData = this.gridApi.getSelectedRows();
    console.log("Edit Selected Row --> ", editSelectedData);
    if (editSelectedData == "") {
      alert("please select a record");
    }
    else {
      const dialogRef = this.dialog.open(EditUserComponent, {
        data: editSelectedData,
        panelClass: 'mat-modal',
        width: '500px'
      });


      dialogRef.afterClosed().subscribe(result => {
        this.loadUsersData();
        console.log('The dialog edit was closed', result);
        if (result !== undefined) {
          this.gridApi.updateRowData({ update: [result] });

        }
      });
    }
  }

  deleteUserDetails() {
    var selectedData = this.gridApi.getSelectedRows();
    console.log("selected data", selectedData);
    if (selectedData == "") {
      alert("please select a record to delete");
    } else if (selectedData !== "") {
      if (confirm("Do you want to delete this record")) {
        console.log("selected data", selectedData[0].id)
        var user_id = selectedData[0].id;
        this.usersService.deleteUser(user_id)
          .subscribe((userResponse: any) => {
            alert(userResponse.message);
            this.gridApi.updateRowData({ remove: selectedData });
          },
            error => {
              alert(error.error.details[0]);
              console.log(error);
            });
      }

    }

  }

  onPageSizeChanged(newPageSize) {
    const element = document.getElementById('page-size') as HTMLInputElement
    const value = element.value
    this.gridApi.paginationSetPageSize(Number(value));
  }

}
