import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment'; 
import { IReconDepartMent, IEscalationMatrixData } from './../models/escalationmatrix.model';
import { Observable } from 'rxjs';
import { IActivityResponse } from '../../recon/models/recon.model';


@Injectable({
  providedIn: 'root'
})
export class EscalationMatrixService {
    constructor(private http: HttpClient) { }

    httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    loadReconDepartMent(){
      return this.http.get<IReconDepartMent []>(environment.reconUrl + '/loadReconDepartment.ascent', this.httpOptions);
    }
     //saveReconAddRule
     saveEscalation(objData: any):Observable<any>{
      return this.http.post<IActivityResponse>(environment.reconUrl+'/saveEscalation',JSON.stringify(objData) , this.httpOptions);
    }
    getEscalationData() : Observable<IEscalationMatrixData[]>{
      return this.http.get<IEscalationMatrixData[]>(environment.reconUrl+'/getEscalationData.ascent', this.httpOptions);
    }
    updateEscalation(updateEscalation: any):Observable<any>{
      return this.http.put<IActivityResponse>(environment.reconUrl +'/updateEscalation.ascent',JSON.stringify(updateEscalation), this.httpOptions);
    }
    deleteEscalation(data: any):Observable<any>{
      return this.http.put<IActivityResponse>(environment.reconUrl+'/deleteEscalationById.ascent',JSON.stringify(data),this.httpOptions);
    }
    runEscalationData(){
    return this.http.get(environment.reconUrl + '/runEscalation.ascent', this.httpOptions);
    }
}