import { Component, OnInit } from '@angular/core';
import { IRole, IPermission } from 'src/app/modules/parent/user-management/models/user-management.model';
import { NavMenuService } from './nav-menu.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  permissionListWithSubTabs: IPermission[];
  permissionListWithoutSubTabs: IPermission[];

  constructor(private navMenuService: NavMenuService) { }

  ngOnInit() {
    this.loadUserPermissions();
  }

  loadUserPermissions() {
    this.permissionListWithSubTabs = [];
    this.permissionListWithoutSubTabs = [];
    this.navMenuService.loadUserPermissions().subscribe(
      (roleList: IRole[]) => {
        roleList[0].permissions.forEach(permission => {
          permission.entities.forEach((entity: { operations: string | any[]; }) => {
            if (entity.operations.length != 0)
              this.permissionListWithSubTabs.push(permission);
            else
              this.permissionListWithoutSubTabs.push(permission);
          });
        });
      },
      error => {
        console.log(error);
      }
    );
  }

}
