import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
//for Cleaning
@Component({
    selector: 'splitingAdd',
    templateUrl: 'spliting-add.html'  
  })
 
    export class splitingAdd {
      selectOperationValue:string
    constructor() { }
    changeSelectOperation(event)
    {
        this.selectOperationValue = event.target.value;
        
    }
  
  }