import { Component, OnInit } from '@angular/core';
import { IFileName1, ITemplateName } from '../etl/models/etl.model';
import { EtlService } from '../etl/services/etl.service';
import { RuleService } from '../recon/services/rule.service';
import { IReconModule } from '../recon/models/recon.model';


@Component({
  selector: 'app-aging',
  templateUrl: './aging.component.html',
  styleUrls: ['./aging.component.scss']
})
export class AgingComponent implements OnInit {
  public FilenameObjs: IFileName1[] = [];
  columnDefs: any;
  rowData: any;
  moduleNames: any[] = [];
  public FiletempObjs: ITemplateName[] = [];
  reconModuleList: IReconModule[] = [];
  TableNames: any[] = [];
  constructor(public etlservice: EtlService,public ruleService: RuleService) { }

  ngOnInit() {
    this.loadReconModules();
  }

  loadReconModules() {
    this.ruleService.loadReconModules().subscribe(
      (reconModuleList : IReconModule []) => {
        this.reconModuleList = reconModuleList;
      },
      error => {
        console.log(error);
      }
    );
  }
 

  loadTemplateName(id) {
    console.log("Logger ID --> ", id)
    this.TableNames=[]
   
  this.ruleService.getTablesBasedonModule(id).subscribe(
    data =>{
          for (const [key, value] of Object.entries(data)) {
            this.TableNames.push({name:key,value:value});
            }
          }
      );
}
  loadAgingData(tempName){
    this.etlservice.checkAging(tempName).subscribe(data =>{
      this.rowData = data
      if (this.rowData) {
        this.columnDefs = this.generateColumns(this.rowData);
      }
    });
  }
  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            filter: true,
            cellStyle: function(params) {
              if (params.data.AGGING==441) {
                  return {backgroundColor: 'red'};
              }else if(params.data.AGGING==439){
                  return {backgroundColor: 'orange'};
              }
            }
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }

}
