export class MasterTable
{
  public id : Number;
  public screenName : String;
}
export class DilogOpenData
{
  public id:number;
  public fieldName:string; 
  public shortName:string;
  public colType:string;
  public colFormat:string;
  public mandatory:number;
  public tableName:number;
}
export class MasterTableResponse
{
  public status:boolean;
  public message: string;
}