import {Grid, GridOptions, Module, AllCommunityModules} from "@ag-grid-community/all-modules";
import { IRole } from '../../models/user-management.model';

export interface IGroupName
{
   id:number;
   name:string; 
   createdDate:string;
   lastModifiedDate:string;
   updatedBy:string;
   description:string;
   organisationId:string;
   status:string;
   roles:IRole[];
  
}
export interface IGroupUser
{
}

export interface IUsersData
{
   id:number;
   user_id:string; 
   user_name:string;
   email_id:string;
   phon_number:string;
   reporting_user_id:number;
   organizationid:string;
   is_ldap_user:string;
   groups:any[];
}
export class UsersDilogData
{
   user_id:string; 
   user_name:string;
   email_id:string;
   phon_number:string;
   //reporting_user_id:number;
   organizationid:string;
   is_ldap_user:string;
   password:string
}
