import { Component, OnInit, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EtlService } from '../../services/etl.service';
import { Adddialog, Editdialog } from '../field-configuration/field-configuration-modal/field-configuration-modal';
import { DialogData, IFileName1, ITemplateName, FileType } from '../../models/etl.model';
import { AllCommunityModules, Module } from "@ag-grid-community/all-modules";
import * as XLSX from 'xlsx';
import { Location } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';

type AOA = any[][];


@Component({
  selector: 'app-field-configuration',
  templateUrl: './field-configuration.component.html',
  styleUrls: ['./field-configuration.component.scss']
})
export class FieldConfigurationComponent implements OnInit {
  @ViewChild('fileUploader', { static: true }) fileUploader: ElementRef;
  @ViewChild('dataTable', { static: true }) table: ElementRef;
  public FilenameObjs: IFileName1[] = [];
  public FiletempObjs: ITemplateName[] = [];
  fileType: FileType[] = [];
  FIELD_NAME: string;
  editSelectedData: DialogData;
  $: any;
  private gridApi;
  private gridColumnApi;
  public modules: Module[] = AllCommunityModules;
  fieldConfiguration: any;
  dataTable: any;
  activeMenu: boolean = true;
  fieldConfig: any;
  public fileId: any;
  public fileExtension: any;
  public fileName1: any;
  public TemplateName: any;
  public TemplateId: any;
  public rowData: any;
  public colData: any;
  public rowSelection: any;
  public requestData: any;
  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;
  fieldvalidationb: boolean = true;
  reset: boolean = true;
  reconModuleId: number;
  chooseBtn: any;

  columnDefs = [
    { headerName: 'FIELD_NAME', field: 'FIELD_NAME', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'FIELD_SHORT_NAME', field: 'FIELD_SHORT_NAME', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'FIELD_TYPE', field: 'FIELD_TYPE', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'FIELD_FORMAT', field: 'FIELD_FORMAT', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'FIELD_LENGTH', field: 'FIELD_LENGTH', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'FIELD_FROM_POS', field: 'FIELD_FROM_POS', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'FIELD_TO_POS', field: 'FIELD_TO_POS', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'COL_POS', field: 'COL_POS', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'MANDATORY', field: 'MANDATORY', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true }
  ];

  columnDefscsv = [
    { headerName: 'FIELD_NAME', field: 'FIELD_NAME', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'FIELD_SHORT_NAME', field: 'FIELD_SHORT_NAME', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'FIELD_TYPE', field: 'FIELD_TYPE', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'FIELD_FORMAT', field: 'FIELD_FORMAT', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'FIELD_LENGTH', field: 'FIELD_LENGTH', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    // { headerName: 'FIELD_FROM_POS', field: 'FIELD_FROM_POS', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    //{ headerName: 'FIELD_TO_POS', field: 'FIELD_TO_POS', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'COL_POS', field: 'COL_POS', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true },
    { headerName: 'MANDATORY', field: 'MANDATORY', filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true }
  ];

  constructor(public dialog: MatDialog, public fileconfigService: EtlService, private activatedRoute: ActivatedRoute, private http: HttpClient,
    private location: Location) {
    this.rowSelection = "multiple";
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">No Rows To Show</span>'
    this.overlayNoRowsTemplate =
      '<span class="ag-overlay-loading-center">No Rows To Show</span>';

    this.fieldConfiguration = new FormGroup({
      chooseBtn: new FormControl()
    });

  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(Adddialog, {
      data: { FIELD_NAME: this.FIELD_NAME, FILE_TYPE: this.fileExtension, RowData: this.rowData },
      width: '500px',
      panelClass: 'mat-modal'
    });

    console.log('dialogRef --> ', dialogRef);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed --> ', result);
      let flag: boolean = false;
      for (let entity of this.rowData) {
        if (entity.COL_POS == result.COL_POS) {
          flag = true;
          break;
        }
      }

      if (flag) {
        alert("COL_POS already present");
        dialogRef.disableClose;
      }
      else {
        if (result !== undefined) {
          alert("Record Saved Successfully");

          this.gridApi.updateRowData({ add: [result] });
          this.rowData.push(result);
          // this.loadConfig(this.TemplateId);
          console.log('Row Data --> ', this.rowData);
          if (this.fileExtension == "TEXT" || this.fileExtension == "excel" || this.fileExtension == "MT103" || this.fileExtension == "NTSL" || this.fileExtension == "TLF" ||
            this.fileExtension == "MT202" || this.fileExtension == "MT940" || this.fileExtension == "MT950" || this.fileExtension == "VisaAcquire" || this.fileExtension == "Rupay01") {
            this.columnDefs = this.columnDefscsv;
          }
          else {
            this.columnDefs = this.generateColumns(this.rowData);
          }
        }
      }
    });
  }

  openEditDialog(): void {
    this.editSelectedData = this.gridApi.getSelectedRows();
    if (this.gridApi.getSelectedRows().length == 0) {
      alert("Please select data ");
      return;
    }
    console.log("Edit Selected Row --> ", this.editSelectedData);
    const dialogRef = this.dialog.open(Editdialog, {
      data: { selectedData: this.editSelectedData, FILE_TYPE: this.fileExtension },
      width: '500px',
      panelClass: 'mat-modal'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog edit was closed', result);
      if (result !== undefined) {
        alert("Record Updated Successfully");
        this.gridApi.updateRowData({ update: [result] });
        console.log('Row Data --> ', this.rowData)
      }
    });
  }

  ngOnInit() {
    this.reconModuleId = parseInt(localStorage.getItem('reconModuleId'));
    this.loadFileType();
    // this.fileType = [
    //   { name: "Delimiter", value: "TEXT" },
    //   { name: "Excel", value: "excel" },
    //   { name: "Fixed Length", value: "FixLength" },
    //   { name: "Swift MT103", value: "MT103" },
    //   { name: "Swift MT202", value: "MT202" },
    //   { name: "Swift MT940", value: "MT940" },
    //   { name: "Swift MT950", value: "MT950" },
    //   { name: "Visa Acquire", value: "VisaAcquire" },
    //   { name: "Rupay 01", value: "Rupay01" },
    //   { name: "NTSL", value: "NTSL" },
    //   { name: "TLF", value: "TLF" }

    // ];
    this.fieldConfiguration.controls['chooseBtn'].disable();

  }

  loadFileType(){
    var currentPage = "Field Configuration";
    this.fileconfigService.getFileType(currentPage).subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          this.fileType.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  loadMyExt(ext) {
    this.fileExtension = [];
    this.fileExtension = ext;
    this.FilenameObjs = [];
    this.FiletempObjs = [];
    this.rowData = [];
    if (ext == "choose...") {
      this.fieldvalidationb = true;
    }
    this.fieldvalidationb = true;
    this.reset = true
    this.fieldConfiguration.controls['chooseBtn'].disable();
    this.fileconfigService.getFileNameData(ext, this.reconModuleId).subscribe(
      data => {

        for (const [key, value] of Object.entries(data)) {
          this.FilenameObjs.push({ name: key, value: value });
        }

      },
      error => {
        console.log(error);
      }
    );

  }

  getFileNameData() {

    this.FilenameObjs = [];
    this.FiletempObjs = [];
    this.fieldvalidationb = true;
    this.reset = true;

    this.fileconfigService.getFileNameData1().subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          this.FilenameObjs.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );

  }

  loadTemplateName(id) {
    this.fieldvalidationb = true;
    this.reset = true;
    this.fileId = [];
    this.fileId = id;
    this.FiletempObjs = [];
    this.rowData = [];

    if (id == "Choose...") {
      this.fieldvalidationb = true;
    }
    this.fileconfigService.getTemplateName(id).subscribe(
      data => {

        for (const [key, value] of Object.entries(data)) {
          this.FiletempObjs.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );

  }

  loadConfig(id) {
    this.TemplateId = [];
    this.TemplateId = id;
    this.rowData = [];
    this.fieldConfiguration.controls['chooseBtn'].enable();

    if (id == "Choose...") {
      this.fieldvalidationb = true;
      this.reset = true
      this.fieldConfiguration.controls['chooseBtn'].disable();
    }
    this.fileconfigService.getFieldConfig(parseInt(id)).subscribe((data: any) => {

      if (data) {

        this.rowData = data
        var arrLength = this.rowData.length;

        if (arrLength === 0) {
          this.reset = false;
        } else {
          this.reset = true
        }

        console.log("rowData :", this.rowData);

      }
      if (this.fileExtension == "TEXT" || this.fileExtension == "excel" || this.fileExtension == "MT103" || this.fileExtension == "NTSL" || this.fileExtension == "TLF" ||
        this.fileExtension == "MT202" || this.fileExtension == "MT940" || this.fileExtension == "MT950" || this.fileExtension == "VisaAcquire" || this.fileExtension == "Rupay01") {
        this.columnDefs = this.columnDefscsv;
      }
      else {
        this.columnDefs = this.generateColumns(this.rowData);
      }

      this.fieldvalidationb = false;
    }
    );
  }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key,
            field: key,
            sortable: true,
            filter: true,
            suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }

  onSaveFeilds() {
    var arrLength = this.rowData.length;
    var stgTableName: any;
    var check: boolean;
    if (arrLength === 0) {
      alert("Data table has no value.Please configure");
    } else {

      this.fileconfigService.checkStagingTable(this.TemplateId).subscribe(data => {
        console.log("data :", data);
        stgTableName = data;
        console.log("stgTableName >>>>", stgTableName);
        if (stgTableName !== null && stgTableName !== undefined) {
          if (confirm("Table is already exist !!! Do you want to create new Table ? ")) {
            check = true;
          }
        }
        else {
          if (confirm("Do you want to create new Table ? ")) {
            check = true;
          }
        }

        if (check) {
          this.fieldConfig = { "fileId": this.fileId, "file_temp_id": this.TemplateId, "FILE_TYPE": this.fileExtension };
          this.requestData = { "configuredTabletmpData": this.rowData, "fieldConfig": this.fieldConfig };
          this.fileconfigService.saveFeildsConfig(this.requestData).subscribe((data: any) => {
            console.log({ data });
            if (data == "successfully saved") {
              alert("Table Created successfully");
            }
            else alert(data);

            this.reset = true;
            this.fileUploader.nativeElement.value = null;

          });

        }

      })

      // if (confirm("Do you want to create new Table ? ")) {
      //   this.fieldConfig = { "fileId": this.fileId, "file_temp_id": this.TemplateId, "FILE_TYPE": this.fileExtension };
      //   this.requestData = { "configuredTabletmpData": this.rowData, "fieldConfig": this.fieldConfig };
      //   //var data =  {"data" : this.requestData };
      //   this.fileconfigService.saveFeildsConfig(this.requestData).subscribe((data: any) => {
      //     console.log({ data });
      //     alert(data);



      //   });
      // }

      this.fileUploader.nativeElement.value = null;
    }
  }


  onResetFeilds(id) {
    if (confirm("Do you want to reset Table ? ")) {
      this.rowData = [];
      this.fieldConfiguration.patchValue({
        chooseBtn: ""
      });
    }
  }


  onBackClick() {
    this.location.back();
  }

  onRemoveSelected() {
    var selectedData = this.gridApi.getSelectedRows();
    if (selectedData == "") {
      alert("please select a record to delete");
    }
    else if (selectedData !== "") {
      if (confirm("Are you sure you want to do this?")) {
        console.log("Remove Data --> ", selectedData);
        var res = this.gridApi.updateRowData({ remove: selectedData });
        for (let index = 0; index < this.rowData.length; index++) {
          const element = this.rowData[index];
          if (this.rowData[index].FIELD_NAME == selectedData[0].FIELD_NAME) {
            alert("Record Deleted Successfully");
            this.rowData.splice(index, 1)
            console.log(this.rowData);
           
          }
        }
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }


  //Import Functionality Start
  dataImport: AOA;
  finalData = [];

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    let validExts = new Array(".xlsx", ".xls", ".csv", ".CSV");
    console.log("File Name ", target.files[0].name);
    let fileExt = target.files[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0 && fileExt != "") {
      alert("Invalid file selected, valid files are of " + validExts.toString() + " types.");
    }
    else {
      / wire up file reader /
      //const target: DataTransfer = <DataTransfer>(evt.target);
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        / read workbook /
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        / grab first sheet /
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        / save data /
        this.dataImport = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

        for (let index = 0; index < this.dataImport.length; index++) {
          var objData = {};
          for (let index1 = 0; index1 < this.dataImport[0].length; index1++) {
            objData[this.dataImport[0][index1]] = this.dataImport[index][index1];
          }
          if (index !== 0) {
            this.finalData[index - 1] = objData;
          };
        }
        for (let index = 0; index < this.finalData.length; index++) {
          this.gridApi.updateRowData({ add: [this.finalData[index]] });
          this.rowData.push(this.finalData[index]);
          if (this.fileExtension == "TEXT" || this.fileExtension == "excel" || this.fileExtension == "MT103" || this.fileExtension == "NTSL" || this.fileExtension == "TLF" ||
            this.fileExtension == "MT202" || this.fileExtension == "MT940" || this.fileExtension == "MT950" || this.fileExtension == "VisaAcquire" || this.fileExtension == "Rupay01") {
            this.columnDefs = this.columnDefscsv;
          }
          else {
            this.columnDefs = this.generateColumns(this.rowData);
          }
        }
      };
      reader.readAsBinaryString(target.files[0]);
    }
  }

  //Export Functionality Start
  //fileNameExcel: string = 'Export.xlsx';

  // export(): void {
  //   var exportData = [];
  //   var tempColumns = [];
  //   var rowColArray = [];
  //   let rowIndex = 0;
  //   for (let index = 0; index < this.columnDefs.length; index++) {
  //     tempColumns[index] = this.columnDefs[index].field;
  //     rowColArray[index] = this.columnDefs[index].field;
  //   }
  //   exportData[rowIndex] = rowColArray;
  //   rowIndex = rowIndex + 1;
  //   this.rowData.forEach((k, v) => {
  //     let colIndex = 0;
  //     var rowArray = [];
  //     for (let key in k) {
  //       if (tempColumns.indexOf(key) > -1) {
  //         rowArray[colIndex] = k[key];
  //         colIndex = colIndex + 1;
  //       }
  //     }
  //     exportData[rowIndex] = rowArray;
  //     rowIndex = rowIndex + 1;
  //   })

  //   const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(exportData);


  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //   XLSX.writeFile(wb, this.fileNameExcel);
  // }

  CustomLoadingCellRenderer() {
    this.CustomLoadingCellRenderer.prototype.init = function (params) {
      this.eGui = document.createElement('div');
      this.eGui.innerHTML =
        '<div class="ag-custom-loading-cell" style="padding-left: 10px; line-height: 25px;">' +
        '    <span>' + params.loadingMessage + ' </span>' +
        '</div>';

      this.CustomLoadingCellRenderer.prototype.getGui = function () {
        return this.eGui;
      };
    };

  }
  formula: string = "Formula 1";
  export(params: any, formula: string) {
    console.log("In component")
    this.gridApi.exportDataAsCsv(params, this.formula);
  }

  //Export Functionality End

}



