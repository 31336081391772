import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(): boolean {
        if (localStorage.getItem('user') == null) {
            this.router.navigate(['']);
            return false;
        }
        return true;
    }
}