import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { IActivity, IActivityResponse, IReconModule, ISourceConfig, IActivityData, IActivityInput, ISelectedActivityTxns } from '../models/recon.model';
import { Observable } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class ReconService {
 
  constructor(private http: HttpClient) { }
 
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
 
  loadReconModules() {
    return this.http.get<IReconModule[]>(environment.reconUrl + '/loadReconModules.ascent', this.httpOptions);
  }

  checkReconciledPermission() {
    return this.http.get(environment.reconUrl + '/checkPermission', this.httpOptions);
  }
 
  loadReconciledBaseSourceData(reconId: Number, fromDate: string, toDate: string) {
    console.log("http://localhost:8082/auto-recon/loadReconciledBaseSourceDataByPagination.ascent");
    return this.http.get(environment.reconUrl + '/loadReconciledBaseSourceData.ascent?reconId=' + reconId + "&fromDate=" + fromDate + "&toDate=" + toDate, this.httpOptions);
  }


  loadReconciledDataByPagination(reconId:Number,fromDate:string,toDate:string,pageNo:string,pageSize:string):Observable<any[]>{
    return this.http.post<any[]>(environment.reconUrl + '/loadReconciledBaseSourcePaginationData.ascent',JSON.stringify({'reconId':reconId,'fromDate':fromDate,'toDate':toDate,'pageNo':pageNo,'pageSize':pageSize}),  this.httpOptions);
 }

  loadReconciledExternalSourceData(reconId: Number, sourceReconId: string) {
    return this.http.get(environment.reconUrl + '/loadReconciledExternalSourceData.ascent?reconId=' + reconId + '&sourceReconId=' + sourceReconId, this.httpOptions);
  }
 
  forceUnMatch(forceUnMatchData: IActivity) {
    return this.http.post<IActivityResponse>(environment.reconUrl + '/forceUnMatchActivity.ascent', forceUnMatchData, this.httpOptions);
  }
 
  loadSources(reconId: number) {
    return this.http.get(environment.reconUrl + '/getTablesBasedonModule.ascent?moduleId=' + reconId, this.httpOptions);
  }
 
  loadUnReconciledDataBySource(reconId: number, sourceId: number) {
    return this.http.get(environment.reconUrl + '/loadUnReconciledDataBySource.ascent/' + reconId + '/' + sourceId, this.httpOptions);
  }
 
  loadUnReconciledDataBySource_test2(reconId: number, sourceId: number) {
    return this.http.get(environment.reconUrl + '/loadUnReconciledDataBySource.ascent/' + reconId + '/' + sourceId, this.httpOptions);
  }
 
  loadUnReconciledDataBySource_test(reconId: number, sourceId: number) {
    return this.http.get(environment.reconUrl + '/loadUnReconciledDataBySource.ascent/' + reconId + '/' + sourceId, this.httpOptions);
  }
 
  //un-reconcile start
  loadSourceListByRecon(reconId: number): Observable<ISourceConfig[]> {
    return this.http.get<ISourceConfig[]>(environment.reconUrl + '/unReconcile/loadSourceListByRecon/' + reconId, this.httpOptions);
  }
 
  loadUnReconcileDataBySource(reconSource: ISourceConfig): Observable<any[]> {
    console.log("recsrc-->",reconSource);
    return this.http.post<any[]>(environment.reconUrl + '/unReconcile/loadDataBySource', reconSource, this.httpOptions);
  }
 
  exportUnReconcileExcel(reconSource: ISourceConfig) {
    return this.http.post(environment.reconUrl + '/unReconcile/exportExcel', reconSource, 
    {
        headers: new HttpHeaders().append('Accept', 'text; charset=utf-8'),
        observe: 'response',
        responseType: 'blob' as 'blob'
      });
  }

  exportUnReconcileeCSV(reconSource: ISourceConfig) {
    return this.http.post(environment.reconUrl + '/unReconcile/exportCSV', reconSource, 
    {
        headers: new HttpHeaders().append('Accept', 'text; charset=utf-8'),
        observe: 'response',
        responseType: 'text'
      });
  }
  
  loadSelectedTxnsAllColumns(activityData: IActivityData): Observable<IActivityData> {
    return this.http.post<IActivityData>(environment.reconUrl + '/unReconcile/loadSelectedTxnsAllColumns', activityData, this.httpOptions);
  }

  loadSelectedTxnsAllColumnsBySource(selectedActivityTxns: ISelectedActivityTxns): Observable<ISelectedActivityTxns> {
    return this.http.post<ISelectedActivityTxns>(environment.reconUrl + '/unReconcile/loadSelectedTxnsAllColumnsBySource', selectedActivityTxns, this.httpOptions);
  }

  loadSuppressDataBySource(reconSource: ISourceConfig): Observable<any[]> {
    return this.http.post<any[]>(environment.reconUrl + '/unReconcile/loadSuppressDataBySource', reconSource, this.httpOptions);
  }

  exportSuppressExcel(reconSource: ISourceConfig) {
    return this.http.post(environment.reconUrl + '/unReconcile/exportSuppressExcel', reconSource, 
    {
        headers: new HttpHeaders().append('Accept', 'text; charset=utf-8'),
        observe: 'response',
        responseType: 'blob' as 'blob'
      });
  }

  exportSuppressCSV(reconSource: ISourceConfig) {
    return this.http.post(environment.reconUrl + '/unReconcile/exportSuppressCSV', reconSource, 
    {
        headers: new HttpHeaders().append('Accept', 'text; charset=utf-8'),
        observe: 'response',
        responseType: 'text'
      });
  }
  
  processActivity(activityInput: IActivityInput): Observable<IActivityResponse> {
    return this.http.post<IActivityResponse>(environment.reconUrl + '/unReconcile/processActivity', activityInput, this.httpOptions);
  }
  //un-reconcile end


  //same side start
  checkSameSidePermission() {
    return this.http.get(environment.reconUrl + '/sameSideReversal/checkPermission', this.httpOptions);
  }

  loadSameSideReversalDataBySource(reconSource: ISourceConfig, fromDate: string, toDate: string): Observable<any[]> {
    return this.http.post<any[]>(environment.reconUrl + '/sameSideReversal/loadDataBySource/'+fromDate+'/'+toDate, reconSource, this.httpOptions);
  }

  exportSameSideDataBySourceToExcel(reconSource: ISourceConfig, fromDate: string, toDate: string) {
    return this.http.post(environment.reconUrl + '/sameSideReversal/exportExcel/'+fromDate+'/'+toDate, reconSource, 
    {
        headers: new HttpHeaders().append('Accept', 'text; charset=utf-8'),
        observe: 'response',
        responseType: 'blob' as 'blob'
      });
  }

  exportSameSideDataBySourceToCSV(reconSource: ISourceConfig, fromDate: string, toDate: string) {
    return this.http.post(environment.reconUrl + '/sameSideReversal/exportCSV/'+fromDate+'/'+toDate, reconSource, 
    {
        headers: new HttpHeaders().append('Accept', 'text; charset=utf-8'),
        observe: 'response',
        responseType: 'text'
      });
  }

  loadReconciledBaseSourceDataByPagination(reconId: Number, fromDate: string, toDate: string,page:any,pageSize:any,filterCriteria:any) {

    return this.http.post(environment.reconUrl + '/loadReconciledBaseSourceDataByPagination.ascent',
  
   JSON.stringify({'reconId' :reconId , 'fromDate': fromDate , 'toDate': toDate ,'page': page , 'pageSize': pageSize,'filterCriteria': JSON.stringify(filterCriteria)}), this.httpOptions);
  
  }


}