import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog, MatDialogRef  } from '@angular/material';
//import { FormGroup, FormArray, FormBuilder,FormControl } from '@angular/forms';
import { FormControl,FormBuilder, FormGroup, FormControlName, Validators } from '@angular/forms';
import { MasterTableService } from '../../services/master-setup.service';

@Component({
  selector: 'app-add-edit-master-data',
  templateUrl: './add-edit-master-data.component.html',
  styleUrls: ['./add-edit-master-data.component.scss']
})
export class AddEditMasterDataComponent implements OnInit {

  model: any = {};
  columnNames=[];
  submitted = false;
  form: FormGroup;
  columnNamesDemo:any=[];
  tableName:any;
  formDemo:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data:any, public masterTableService: MasterTableService,public dialogRef:MatDialogRef<AddEditMasterDataComponent>,
  private fb: FormBuilder ) 
  {
    console.log("data",data);
    this.tableName=data.tableName;
    console.log("tableName",this.tableName);
    this.columnNamesDemo=data.columnNames;
    console.log("columnNamesDemo",this.columnNamesDemo);
    for(var i=0;i<this.columnNamesDemo.length;i++){
      this.columnNames.push(this.columnNamesDemo[i]);
    }


    this.form =this.createForm(this.columnNames);
    console.log("this.form",this.form.value);
      
  }
   

  
  ngOnInit() {
  

  }

  createForm(data:string[]){
 let  group ={};
 data.forEach(x=>{
   group[x]= new FormControl();
 })
 return new FormGroup(group);
  }
  onSubmit() {
    this.submitted = true;   
    console.log("Submited :",this.form.value);

    let   columnData={"tableName": this.tableName,"data":this.form.value};

    this.masterTableService.addMasterDatass(columnData).subscribe(
      screenResponse => {
        alert(screenResponse.STATUS);
        //this.screenName=[];
        this.dialogRef.close("true");
        

      },
      error => {
        console.log(error);
      }

    );






  }
}
