import { Component, OnInit } from '@angular/core';
import { RuleService } from '../../services/rule.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { IReconModule } from '../../models/recon.model';
import { EditdialogAddRule } from './add-rule-modal/edit';
import { MatDialog } from '@angular/material';
import { UserManagementService } from '../../../user-management/services/user-management.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-rule',
  templateUrl: './add-rule.component.html',
  styleUrls: ['./add-rule.component.scss']
})

export class AddRuleComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  reconModuleList: IReconModule[] = [];
  ruleName: any;
  ruleDescription:any;
  ruleStatus: any;
  moduleName: any;
  rulePriority: number;
  ruleServiceResp : any;
  ruleType : any;
  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;
 // selectedReconId:number;
  moduleId:string;
  submitted = false;
  ruleId:any;
  public rowData:any;
  columnDefs:any[];
  addrule:any;
  rowSelection :any;
  permissionList: string[] = [];
 
  constructor(public dialog: MatDialog,public ruleService: RuleService,private formBuilder: FormBuilder,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute) {
    this.rowSelection = "single";
    this.overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>'
  this.overlayNoRowsTemplate =
  '<span class="ag-overlay-loading-center">No Rows To Show</span>';
    this.addrule =this.formBuilder.group({
       'ruleName':new FormControl('',Validators.required),
       'ruleStatus':new FormControl('',Validators.required),
       'rulePriority' :new FormControl('',[Validators.required,Validators.minLength(1),Validators.maxLength(20),Validators.pattern("^[0-9]*$"),]),
       'ruleType':new FormControl('',Validators.required),
       'ruleDescription':new FormControl('',Validators.required)
    });
 
   }
   columnDefsrule = [
    {headerName: 'RULEID', field: 'ruleId', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true, suppressSizeToFit: true},
   {headerName: 'MODULEID', field: 'moduleId', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
   {headerName: 'RULENAME', field: 'ruleName', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
   {headerName: 'RULEDESCRIPTION', field: 'ruleDescription', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
   {headerName: 'RULEPRIORITY', field: 'rulePriority', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
   {headerName: 'RULESTATUS', field: 'ruleStatus', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
  {headerName: 'RULETYPE', field: 'ruleType', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
  {headerName: 'CREATION TIME', field: 'creationTime', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500},
  {headerName: 'UPDATION TIME', field: 'updateTime', sortable: true, filter: true, editable: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true, resizable: true,suppressSizeToFit: true,width:500}
  
];

  ngOnInit() {
    this.moduleId = localStorage.getItem('reconModuleId');
    this.loadChildPermissionsByUser();
    this.getRules();
  }

  loadChildPermissionsByUser() {
    this.userManagementService.loadChildPermissionsByUser(this.route.firstChild.routeConfig.path.replace(/-/g,' ')).subscribe(
     (list: string[]) => {
       this.permissionList = list;
     },
     error => {
       console.log(error);
     }
   );
 }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		return !(charCode > 31 && (charCode < 48 || charCode > 57));
	}


  get fval() {
    return this.addrule.controls;
    }
  
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  formatLabel(value: number) {
    if (value <= 100) {
      return Math.round(value);
    }

    return value;
  }

  addRuleMethod(){

   this.rowData=[];
   
    this.submitted = true;
    if (this.addrule.invalid) {
    
    return;
    }
  
    else if(this.rulePriority>20 || this.rulePriority <=0 ){
      alert("rule priority should be between 1 to 20");
    }
    else{
    console.log("RuleName --> ", this.ruleId ,this.ruleName,this.ruleDescription, this.ruleStatus, this.moduleId, this.rulePriority,this.ruleType)
 
    let objData = {
     "moduleId":this.moduleId,
     //"moduleName" : this.moduleName,
      "ruleName":this.ruleName,
      "ruleDescription":this.ruleDescription,
      "ruleStatus": this.ruleStatus,
      "rulePriority":this.rulePriority,
      "ruleType":this.ruleType,
    };
console.log(objData);
let flag: boolean = false;
let flag1 :boolean= false;
for(let data of this.rowData){

  if(data.ruleName == objData.ruleName &&
     data.ruleDescription == objData.ruleDescription && 
     data.ruleStatus == objData.ruleStatus &&
      data.rulePriority == objData.rulePriority && 
      data.ruleType == objData.ruleType  ) {
    flag = true;
    break;
  }
  if( data.rulePriority == objData.rulePriority && 
    data.ruleType == objData.ruleType){
      flag = true;
      break;
    }
    if( data.rulePriority == objData.rulePriority){
        flag1 = true;
        break;
      }
}

if(flag) {
  alert(" Record already exists");
}
// if(flag1) {
//   alert("Rule Priority is already Exists");
// }
else{
      this.ruleService.saveAddrule(objData).subscribe(
         data => {
          this.ruleService.getRuleTableData(this.moduleId).subscribe(data=>{
console.log("rowdata",data);
            this.rowData=data
       
            if(this.rowData){
              this.columnDefs=this.columnDefsrule;
             console.log("ruleId",this.ruleId);
              this.ruleName=[];
              this.ruleStatus=[];
              this.rulePriority=null;
               this.ruleType=[];
               this.ruleDescription=[];
            this.submitted=false;
            }
         
          })
     alert(data.message);
      }  );
      
  
    }
  }
  }

  getRules()
  {
    this.rowData = [];
   
       this.ruleName=[];
       this.ruleStatus=[];
       this.rulePriority=null;
       this.ruleType=[];
       this.submitted=false;
 this.ruleService.getRuleTableData(this.moduleId).subscribe(data=>{

      this.rowData=data

      if(this.rowData){
        this.columnDefs=this.columnDefsrule;
        //this.columnDefs=this.generateColumns(this.rowData);
      }
    })

  }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            filter: true,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true
          }
          columnDefinitions.push(mappedColumn);
        })
    })
  
    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }
  deleteRule(){
    var selectedData = this.gridApi.getSelectedRows();
console.log("selected data",selectedData);
if (selectedData == "") {
  alert("please select a record to delete");
 }else if(selectedData !== "")
 {
  if (confirm("Do you want to delete this record")) {
    console.log("selected data",selectedData[0].ruleId)
    var srcId = selectedData[0].ruleId;
    this.ruleService.deleteRuleById(srcId)
    .subscribe((data:any)=>{
 
    });
    this.gridApi.updateRowData({ remove: selectedData });
  }
  }
  }

  openEditDialog(): void {
    var editSelectedData = this.gridApi.getSelectedRows();
    if(this.gridApi.getSelectedRows().length==0)
    {
      alert("Please select data ");
      return;
    }
    console.log("Edit Selected Row --> ", editSelectedData);
    const dialogRef = this.dialog.open(EditdialogAddRule, {
      data: editSelectedData,
      panelClass: 'mat-modal',
      width: '500px',
    });
   
    dialogRef.afterClosed().subscribe(result => {
      this.getRules();
      console.log('The dialog edit was closed', result);
      if (result !== undefined) {
        this.gridApi.updateRowData({ update: [result] });
       
      }
   
    });
  }
}
