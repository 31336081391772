import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RuleService } from '../../../recon/services/rule.service';
import { cleaningAdd } from '../../../etl/components/file-transformation/file-transformation-modal/cleaning-modal';
import { AllCommunityModules, Module } from "@ag-grid-community/all-modules";
import { IReconModule, IReconDEModule } from '../../models/recon.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserManagementService } from '../../../user-management/services/user-management.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-add-rule-details',
  templateUrl: './add-rule-details.component.html',
  styleUrls: ['./add-rule-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddRuleDetailsComponent implements OnInit {
  reconModuleList: IReconModule[] = [];
  reconDEModuleList: IReconDEModule[] = [];
  reconMatchingList: any[] = [];
  private overlayLoadingTemplate: string;
  private overlayNoRowsTemplate: string;

  private gridApi: any;
  private gridColumnApi: any;
  public rowSelection: any;
  public rowData: any;
  public modules: Module[] = AllCommunityModules;
 submitted3 = false;
 submitted1=false;
 submitted2=false;
 submitted=false;
  count: any;
  moduleNames: any[] = [];
  ruleNames: any[] = [];
  TableNames: any[] = [];
  tableNa: any;
  source2Id: any;
  addruledetails:any;

  fileNames: any[] = [];
  fileNames2: any[] = [];
  templetColumnNames: any[] = [];
  templetColumnNames2: any[] = [];
  permissionList: string[] = [];
  constructor(public ruleService: RuleService,
    private formBuilder: FormBuilder,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute) {
    this.rowSelection = "multiple";
    this.overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">No Rows To Show</span>'
  this.overlayNoRowsTemplate =
  '<span class="ag-overlay-loading-center">No Rows To Show</span>';
    this.addruledetails =this.formBuilder.group({
        ruleId:new FormControl('',Validators.required),
        tableName:new FormControl('',Validators.required),
       columnName:new FormControl('',Validators.required),
       operator:new FormControl('',Validators.required),
      conditionValue:new FormControl('',Validators.required),
     tableName2:new FormControl('',Validators.required),
     dataMatchId:new FormControl('',Validators.required),
     toMany:new FormControl('',Validators.required),
     source2Id:new FormControl('',Validators.required),
     //count:new FormControl('',Validators.required),
     columnName2:new FormControl('',Validators.required),
     matcher:new FormControl('',Validators.required),
     conditionValue2:new FormControl('',Validators.required),
     operator2:new FormControl('',Validators.required)
   
  
    });
    this.toMany=0;
  }

  moduleId: any;
  ruleId: any;
  ruleId2: any;
  sourceName:any;
  tableName: any;
  columnName: any;
  operator: any;
  conditionValue: any;

  matcher: any;
  sourceName2:any;
  tableName2: any;
  toMany: any;
  columnName2: any;
  operator2: any;
  conditionValue2: any;

  dataMatchId: any;


  ngOnInit() {
    this.moduleId = localStorage.getItem('reconModuleId');
    this.loadChildPermissionsByUser();
    this.loadFileNames();
    this.loadFileNames2();
    // this.cleaningAddvice.loadTempColumns();
    this.count = 0;
    this.loadReconMactingData();
    this.getRulesRelatedRecon();
  }

  loadChildPermissionsByUser() {
    this.userManagementService.loadChildPermissionsByUser(this.route.firstChild.routeConfig.path.replace(/-/g,' ')).subscribe(
     (list: string[]) => {
       this.permissionList = list;
     },
     error => {
       console.log(error);
     }
   );
 }
 
  get fval() {
    return this.addruledetails.controls;
    }
    onSubmit(){
    if(this.addruledetails.invalid)  {
      return;
    }

    }

  loadReconMactingData() {
    this.ruleService.loadReconMactingData().subscribe(
      (data) => {
        var object = data.object;
        for (const [key, value] of Object.entries(object)) {
          this.reconMatchingList.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  loadMasterDeForRecon() {
    this.ruleService.loadReconModules().subscribe(
      (reconModuleList: IReconModule[]) => {
        this.reconModuleList = reconModuleList;
      },
      error => {
        console.log(error);
      }
    );
  }

  loadFileNames() {

    this.ruleService.getFileNames().subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          this.fileNames.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );

  }

  loadFileNames2() {

    this.ruleService.getFileNames2().subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          this.fileNames2.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );

  }

  loadTableFields(sourceName: string) {
    this.columnName="undefined";
    this.operator="undefined";
    this.conditionValue=undefined;
    var str = sourceName.split(" ");
    var id = str[0];
    this.tableNa = str[0];
    this.tableName = str[1];


    console.log("Source name :", this.tableName)
    console.log("sourceKey:", this.TableNames)
    this.templetColumnNames = [];
    // this.tableName = sourceKey;


    this.ruleService.getTablesFields(this.tableName).subscribe(
      data => {

        for (const [key, value] of Object.entries(data)) {
          this.templetColumnNames.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  changeFieldName(){
    this.operator="undefined";
    this.conditionValue=undefined;
  }
  onOperatorChange(){
    this.conditionValue=undefined;
  }
  loadTableFields2(Source2: string) {
    this.columnName2="undefined";
    this.operator2="undefined"
    this.conditionValue2=undefined;
    var str = Source2.split(" ");
    var id = str[0];
    this.source2Id = str[0];
    this.tableName2 = str[1];


    this.templetColumnNames2 = [];


    this.ruleService.getTablesFields2(this.tableName2).subscribe(
      data => {
        // console.log("Data", data)
        for (const [key, value] of Object.entries(data)) {
          this.templetColumnNames2.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  changeFieldName2(){
    this.operator2="undefined";
    this.conditionValue2=undefined;
  }
  onOperatorChange2(){
    this.conditionValue2=undefined;
  }

  appendCondition() {
    this.submitted1=true;
    this.submitted2=true;
  if(this.sourceName==undefined || this.sourceName=="undefined" ||
    this.columnName==undefined || this.columnName=="undefined" ||
    this.operator==undefined || this.operator=="undefined")
   {
    if(this.sourceName=="undefined"){
      this.addruledetails.patchValue({
        tableName:undefined
      })
    }if(this.columnName=="undefined"){
      this.addruledetails.patchValue({
        columnName:undefined
      })
    }if(this.operator=="undefined"){
      this.addruledetails.patchValue({
        operator:undefined
      })
    }
     return;
   }
  
    if (this.operator == '=' || this.operator == '!=' || this.operator == '>' || this.operator == '<' || this.operator == '>=' || this.operator == '<=') {
      this.conditionValue = this.tableName + "." + this.columnName + " " + this.operator + " '  ' ";
    } else if (this.operator == 'LIKE') {
      this.conditionValue = this.tableName + "." + this.columnName + " " + this.operator + " '%  %' ";
    }

    else if (this.operator == 'OR' || this.operator == 'AND') {
      this.conditionValue = "((" + this.tableName + "." + this.columnName + " = ' ')" + this.operator + " (" + this.tableName + "." + this.columnName + "= ' '))";
    }
    else if (this.operator == 'SUBSTR') {
      // this.conditionValue = this.operator + "(" + this.tableName + "." + this.columnName + " , ,) ";
      this.conditionValue = this.operator + "(" + this.columnName + " , ,) ";
    } 
    else if (this.operator == 'ABS') {
      this.conditionValue = this.operator + "(" + this.columnName + " ) ";
    }else if (this.operator == 'IN') {
      this.conditionValue = this.tableName + "." + this.columnName + " " + this.operator + "('  ')";
    }
    else {
      this.conditionValue = this.tableName + "." + this.columnName + " " + this.operator;
    }

  }



  //Source 2


  appendCondition2() {
    this.submitted=true;
    this.submitted2=true;
    if(this.sourceName2==undefined || this.sourceName2=="undefined" ||
    this.columnName2==undefined || this.columnName2=="undefined" ||
    this.operator2==undefined || this.operator2=="undefined")
   {
    if(this.sourceName2=="undefined"){
      this.addruledetails.patchValue({
        tableName2:undefined
      })
    }if(this.columnName2=="undefined"){
      this.addruledetails.patchValue({
        columnName2:undefined
      })
    }if(this.operator2=="undefined"){
      this.addruledetails.patchValue({
        operator2:undefined
      })
    }
     return;
   }
  if (this.operator2 == '=' || this.operator2 == '!=' || this.operator2 == '>' || this.operator2 == '<' || this.operator2 == '>=' || this.operator2 == '<=') {
      this.conditionValue2 = this.tableName2 + "." + this.columnName2 + " " + this.operator2 + " '  ' ";
    } else if (this.operator2 == 'LIKE') {
      this.conditionValue2 = this.tableName2 + "." + this.columnName2 + " " + this.operator2 + " '%  %' ";
    }

    else if (this.operator2 == 'OR' || this.operator2 == 'AND') {
      this.conditionValue2 = "((" + this.tableName2 + "." + this.columnName2 + " = ' ')" + this.operator2 + " (" + this.tableName2 + "." + this.columnName2 + "= ' '))";
    }
    else if (this.operator2 == 'SUBSTR') {
      // this.conditionValue2 = this.operator2 + "(" + this.tableName2 + "." + this.columnName2 + " , ,) ";
      this.conditionValue2 = this.operator2 + "(" + this.columnName2 + " , ,) ";
    } 
    else if (this.operator2 == 'ABS') {
      this.conditionValue2 = this.operator2 + "(" + this.columnName2 + " ) ";
    }else if (this.operator2 == 'IN') {
      this.conditionValue2 = this.tableName2 + "." + this.columnName2 + " " + this.operator2 + "('  ')";
    }
    else {
      this.conditionValue2 = this.tableName2 + "." + this.columnName2 + " " + this.operator2;
    }

  }

  columnDefs = [
    { 
      headerName: 'Serial No',
      field: 'SourceId',
      sort: 'asc',
      sortable: true,
      filter: true,
      editable: false,
      resizable: false,
      suppressSizeToFit: true,
      floatingFilterComponentParams: {suppressFilterButton:true} ,
      floatingFilter: false,
      suppressMenu: true ,
      lockPosition: true,
      cellClass: "locked-col",
      width: 150
    },
    { 
      headerName: 'FLAG_FOR_MANY',
      field: 'FLAG_FOR_MANY',
      sortable: true,
      filter: true,
      editable: false,
      resizable: false,
      suppressSizeToFit: true,
      floatingFilterComponentParams: {suppressFilterButton:true} ,
      floatingFilter: false,
      suppressMenu: true ,
      lockPosition: true,
      cellClass: "locked-col",
      width: 200
    },
    
    { 
      headerName: 'Condition Value',
      field: 'CONDITION_VALUE',
      sortable: true,
      filter: true,
      editable: false,
      resizable: true, 
      floatingFilterComponentParams: { suppressFilterButton: true }, 
      floatingFilter: false, 
      suppressMenu: true, 
      suppressColumnVirtualisation: true,
      width: 400
    }

  ];

  appendToDatatable() {
   console.log("matcher model:",this.matcher+"form",this.addruledetails);
// this.submitted=true;
// this.submitted1=true;

//  this.submitted3=true;
if(this.ruleId==undefined || this.ruleId==null){
  this.submitted2=true;
  return;
}
if(this.tableNa == undefined && this.columnName == null && 
  this.source2Id==undefined && this.columnName2==null){
    alert("fill mandetory fields");
    return;
  }

let flag:boolean=false;
 if(this.tableNa != undefined && this.columnName != null && 
  this.source2Id!=undefined && this.columnName2!=null)
  {
 if(this.matcher==undefined || this.matcher =="undefined")
 {
  flag=true;
 
 }
 }
   
 
if(flag){
  alert("matcher is mandetory");
}
 else{
  

  if(this.operator==undefined || this.operator=="undefined"|| this.operator2=="undefined" ||this.operator2==undefined){
    if(this.operator=="undefined" || this.operator==undefined  ){
    this.operator=(null) 
    console.log("operator",this.operator);}
      if(this.operator2=="undefined" || this.operator2==undefined)
      {
        this.operator2=(null) 
        console.log("operator2",this.operator2); }
  }

    console.log('Filed Type -->', this.dataMatchId)
    this.count++;

    var finalData = this.conditionValue + " " + this.matcher + " " + this.conditionValue2;
    console.log("Final --> ")

    var rowData1 = { SERIAL_NO: this.count, CONDITION_VALUE: finalData };
    // console.log('rowData1', rowData1)
    // this.gridApi.updateRowData({ add: [rowData1] });
    // this.rowData.push(rowData1);
    console.log('Row Data --> ', this.rowData);
    console.log("Rule Name List ", this.ruleId);

    let obj1 = {
      "moduleId": this.moduleId,
      "ruleId": this.ruleId,
      "templateId": this.tableNa,
      "matchBothId": this.count,
      "fieldName": this.columnName,
      "operator": this.operator,
      "conditionValue": this.conditionValue,
      "flagForLeft": 1,
      "flagForMany": this.toMany,
      "masterColId": this.dataMatchId
    }
    let obj2 = {
      "moduleId": this.moduleId,
      "ruleId": this.ruleId,
      "templateId": this.source2Id,
      "matchBothId": this.count,
      "fieldName": this.columnName2,
      "matcher": this.matcher,
      "conditionValue": this.conditionValue2,
      "operator": this.operator2,
      "flagForLeft": 0,
      "flagForMany": this.toMany,
      "masterColId": this.dataMatchId
    };

    console.log("Object 1 :", obj1);
    console.log("Object 2 :", obj2);

    let objdata: any[];
    if(obj1.fieldName != undefined && obj1.templateId != undefined && obj2.fieldName != null && obj2.templateId != undefined)
{
  objdata = [obj1,obj2];
  console.log("Bothe obj", objdata);
   
    }
    else if(obj1.fieldName != undefined && obj1.templateId != undefined && obj1.operator != null && obj1.conditionValue != undefined ){
      objdata = [obj1];
      console.log(" obj", objdata);
   }
    else if(obj2.fieldName != undefined && obj2.templateId != undefined && obj2.operator != null && obj2.conditionValue != undefined){
      objdata = [obj2];
    }
    
    console.log("Bothe obj", objdata);

    this.ruleService.saveSources(objdata).subscribe(
      data => {
        alert(data.message);
        this.getRuleID(this.ruleId);

      },
      error => {
        //alert(error.error.message);
        console.log("errorrrrrrrrrrrr",error.error.message);
      }
    );
    // this.moduleId=[],
    // this.ruleId=[],
    this.tableNa=[],
    this.sourceName=undefined,
    // this.count=[],
    this.columnName=undefined,
    this.templetColumnNames=[],
    this.operator=undefined,
    this.conditionValue=[],
    this.sourceName2=undefined,
   // this.moduleId=[],
    // this.ruleId=[],
    this.source2Id=[],
    // this.count=[],
    this.columnName2=undefined,
    this.templetColumnNames2=[],
    this.matcher=undefined,
    this.conditionValue2=[],
    this.operator2=undefined,
    this.toMany=0,
    this.dataMatchId=undefined,
this.submitted=false;
this.submitted1=false;
this.submitted2=false;
this.submitted3=false;

    }
  
  }

  onGridReady(params: { api: any; columnApi: any; }) {
    this.gridApi = params.api;
    // this.gridApi.sizeColumnsToFit();
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.sizeColumnsToFit;
    this.gridApi.sizeColumnsToFit();
    params.api.sizeColumnsToFit();
  }

  sizeToFit() {
    
  }

  getRulesRelatedRecon() {
    this.ruleNames = [];
    this.ruleService.getRulesRelatedRecon(this.moduleId).subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          this.ruleNames.push({ name: key, value: value });
        }
        this.loadTableNames(this.moduleId)



      },
      error => {
        console.log(error);
      }
    );

  }

  loadTableNames(moduleId: string) {
    this.fileNames = [];
    this.fileNames2 = [];
    this.moduleId = moduleId;
    this.ruleService.getTablesBasedonModuleAndReconConfig(moduleId).subscribe(
      data => {
        for (const [key, value] of Object.entries(data)) {
          this.fileNames.push({ name: key, value: value });
          this.fileNames2.push({ name: key, value: value });
        }
      },
      error => {
        console.log(error);
      }
    );
    console.log("Table names :", this.TableNames)
  }

  getRuleID(ruleId: any) {
    if(ruleId == undefined || ruleId == "undefined" ){
      this.rowData=[];
    }
    else{
    this.count = 0;
    this.ruleId = ruleId;
    this.ruleService.getRulesDetails({ moduleId: this.moduleId, ruleId: this.ruleId }).subscribe(data => {
      this.rowData = data
      console.log("Row Data --> ", this.rowData);
      // let index = 0;
      for (let index in this.rowData) {
        var countTest = this.rowData[index].matchId;
        this.count = countTest;
      }

      // if (this.rowData) {
      // this.columnDefs = this.generateColumns(this.rowData);
      //}
    }
    );

  }
  }

  generateColumns(data: any[]) {
    let columnDefinitions = [];
    data.map(object => {
      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key.toUpperCase(),
            field: key,
            sortable: true,
            filter: true,suppressSizeToFit: false, floatingFilterComponentParams: { suppressFilterButton: true }, floatingFilter: false, suppressMenu: true, suppressColumnVirtualisation: true
          }
          columnDefinitions.push(mappedColumn);
        })
    })

    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }

  deleteRuleDetails() {
    var SIDs;
    var selectedData = this.gridApi.getSelectedRows();
    console.log("moduleId -->", this.moduleId);
    console.log("ruleId -->", this.ruleId);
    console.log("selectedData -->", selectedData);
    if (selectedData == "") {
      alert("please select a record to delete");
    } else if (selectedData !== "") {
      if (confirm("Do you want to delete this record")) {
        var sourceId=selectedData[0].SourceId
        for( var i=0;i<this.rowData.length;i++){
          if (this.rowData[i].SourceId == sourceId){
           SIDs=this.rowData[i].SIDs
          }
        }


       
        console.log("SIDs :",SIDs )
        this.ruleService.deleteRuleDetails(SIDs)
          .subscribe((data: any) => {
            alert(data.message);
            this.getRuleID(this.ruleId);
          });

        this.gridApi.updateRowData({ remove: selectedData });
      }
    }
  }

  clearBaseSource() {
    this.tableName = [];
    this.templetColumnNames = [];
    this.operator = [];
    this.conditionValue = "";
    this.matcher = [];
    this.toMany = 0;
  }
  clearSource2() {
    this.fileNames2 = [];
    this.templetColumnNames2 = [];
    this.operator2 = [];
    this.conditionValue2 = "";
    this.matcher = [];
    this.toMany = 0;
  }


}
