
import { Component, OnInit,Inject, Input } from '@angular/core';
import {  FormGroup, FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatDialog,  MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'File-Naming',
    templateUrl: 'file-naming.html'  
  })
  
  export class FileNaming implements OnInit{
  
    constantValue:any[];
    policies= [ ];
    router: any;
    chooseConstant: any;
    form: FormGroup;
    submitted = false;
    FileNaming = new FormGroup({
      chooseConstant: new FormControl(),
      constantValue :new FormControl('', Validators.required)
    });
  
  
  
      constructor(
         public dialogRef:MatDialogRef<FileNaming>,
         @Inject(MAT_DIALOG_DATA) public data:any) {
          
          console.log(data);
         
        }
     
      ngOnInit(){
  
      }
      
   
      addValue(chooseConstant,constantValue)
      {
     

        console.log("chooseConstant   :",chooseConstant,  "constantValue   :",constantValue)
        var obj={
          "chooseConstant":chooseConstant,
          "constantValue":constantValue
        }
        if (this.FileNaming.invalid) {
         
        return;
        }
        this.policies.push(obj);
       
      }
  
     
      deleteRowAdressForm(rowNumber: number){
        this.policies.pop();
      
    }
    get fval() {
      return this.FileNaming.controls;
      }
      signup(){
        this.submitted = true;
        if (this.FileNaming.invalid) {
        return;
        }
        }
        
    save(chooseConstant,constantValue){
      var obj={
        "chooseConstant":chooseConstant,
        "constantValue":constantValue
      }
      
     
      if (this.FileNaming.invalid) {
       
        return;
        }
        this.policies.push(obj);
      this.dialogRef.close(this.policies);
  
    }
    
  }
  @Component({
    selector: 'header-available',
    templateUrl: 'header-available.html'  
  })
  
  export class HeaderAvailable {
  
    public count:any;
    form: FormGroup;
    fileConvention:string;
	headerFormatsDetails:any;
	num1:number;
	num2:number;
    
 constructor(public dialog: MatDialog,private fb: FormBuilder, public dialogRef:MatDialogRef<HeaderAvailable>,
     @Inject(MAT_DIALOG_DATA) public data:any) {
       console.log("data  :",data);
       console.log("headerFormatsDetails :",data.headerFormatsDetails);
  this.count= data.dataCount;
  this.headerFormatsDetails=data.headerFormatsDetails;

  this.form = this.fb.group({

    credentials: this.fb.array([]),
     });
     if(data.headerFormatsDetails===undefined)
     {
      for (let index = 0; index < this.count; index++) {

        const creds=   this.form.controls.credentials as FormArray;
            creds.push(this.fb.group({
             fromPosition:'',
             toPosition: '',
             lineNumber:'',
             format: '',
             headerCheck:'',
             occurance :'',
           }));
         }
         return;
     }
	 if(data.headerFormatsDetails.length>0)
	 {
	   this.num1=data.headerFormatsDetails.length;
	   this.num2=this.count;
	   if(this.num1>this.num2)
	  {
		for (let index = 0; index < this.count; index++) {
			const creds=   this.form.controls.credentials as FormArray;
			   creds.push(this.fb.group({
				fromPosition:'',
				toPosition: '',
				lineNumber:'',
				format: '',
				headerCheck:'',
				occurance :'',
			  }));
			}
	}
	if(this.num1<this.num2)
	{	
		var diff=(this.num2)-(this.num1);
      data.headerFormatsDetails.forEach(element => {
        const creds=   this.form.controls.credentials as FormArray;
        creds.push(this.fb.group({
          fromPosition:element.fromPosition,
          toPosition: element.toPosition,
          lineNumber:element.lineNumber,
          format: element.format,
          headerCheck:element.headerCheck,
          occurance :element.occurance,

        }));
	   });
	   for (let index = 0; index < diff; index++) {

		const creds=   this.form.controls.credentials as FormArray;
			creds.push(this.fb.group({
			 fromPosition:'',
			 toPosition: '',
			 lineNumber:'',
			 format: '',
			 headerCheck:'',
			 occurance :'',
		   }));
		 }
		}if(this.num1==this.num2)
		{
			data.headerFormatsDetails.forEach(element => {
				const creds=   this.form.controls.credentials as FormArray;
				creds.push(this.fb.group({
				  fromPosition:element.fromPosition,
				  toPosition: element.toPosition,
				  lineNumber:element.lineNumber,
				  format: element.format,
				  headerCheck:element.headerCheck,
				  occurance :element.occurance,
		
				}));
			   });
		}
     }else{
        for (let index = 0; index < this.count; index++) {

       const creds=   this.form.controls.credentials as FormArray;
           creds.push(this.fb.group({
            fromPosition:'',
            toPosition: '',
            lineNumber:'',
            format: '',
            headerCheck:'',
            occurance :'',
          }));
        }
      }

   
     }

  save(){
    let data= this.form.value;
    console.log("header array  :",data.credentials);
    this.dialogRef.close(this.form.value.credentials);
  }
    OpenheaderAvailableclick(i) {
      const dialogRef = this.dialog.open(HeaderAvailableclick,{data:i});
      dialogRef.afterClosed().subscribe(result => {
        if(result!=undefined){
         console.log({result})
         let constantValue=""
            result.formate.forEach(element => {
              console.log(element);
              constantValue=`:${element.constantValue}`
              
            });
         
     
          let x=  (<FormArray>this.form.controls['credentials']).at(result.index);
          x.patchValue({
            format:constantValue
          })
          }
      });
    }
    
    ngOnInit(){

    }
  
  }
  @Component({
    selector: 'header-available-click',
    templateUrl: 'header-available-click.html'  
  })
  
  export class HeaderAvailableclick implements OnInit{
    constantValue:any[];
    policies= [
     
    ];
    submitted = false;
    FileNaming = new FormGroup({
      chooseConstant: new FormControl(),
      constantValue :new FormControl('', Validators.required)
    });

    index:any;

    
 

    constructor( public dialogRef:MatDialogRef<HeaderAvailableclick>, @Inject(MAT_DIALOG_DATA) public data:any) {
       console.log({data})
       this.index=data;
     
    }


    ngOnInit(){

    }
    // addValue(chooseConstant,constantValue)
    // {
    //   console.log("chooseConstant   :",chooseConstant,  "constantValue   :",constantValue)
    //   var obj={
    //     "chooseConstant":chooseConstant,
    //     "constantValue":constantValue
    //   }
    //    if (this.FileNaming.invalid) {
    //     alert('UNsuccessfully!');
    //   return;
    //   }
    //   alert('successfully!');


    //   this.policies.push(obj);
    
    // }
    deleteRowAdressForm(rowNumber: number){
      this.policies.pop();
    
  }
  get fval() {
    return this.FileNaming.controls;
    }
    signup(){
      this.submitted = true;
      if (this.FileNaming.invalid) {
      return;
      }
      }
  save(chooseConstant,constantValue){
    
      var obj={
        "chooseConstant":chooseConstant,
        "constantValue":constantValue
      }
      
     
      if (this.FileNaming.invalid) {
       
        return;
        }
        this.policies.push(obj);
      this.dialogRef.close({formate: this.policies,index:this.index});
    
    }

  
  }
  @Component({
    selector: 'footer-available',
    templateUrl: 'footer-available.html'  
  })
  
  export class FooterAvailable {
  
    public count:any;
    form: FormGroup;
	num1:number;
	num2:number;
    footerFormatsDetails:any;
    
    
    constructor(public dialog: MatDialog,private fb: FormBuilder,public dialogRef:MatDialogRef<FooterAvailable>,
     @Inject(MAT_DIALOG_DATA) public data:any) {
      this.form = new FormGroup ({
        fromPosition: new FormControl('', Validators.required),
        toPosition: new FormControl('', Validators.required),
        lineNumber: new FormControl('', Validators.required),
        });
     
  console.log("data   :",data)
  this.count= data.dataCount;
  this.footerFormatsDetails=data.footerFormatsDetails;
  console.log("footerFormatsDetails",this.footerFormatsDetails)
  this.form = this.fb.group({

    credentials: this.fb.array([]),
     });
     if(data.footerFormatsDetails===undefined)
     {
      for (let index = 0; index < this.count; index++) {

        const creds=   this.form.controls.credentials as FormArray;
            creds.push(this.fb.group({
             fromPosition:'',
             toPosition: '',
             lineNumber:'',
             format: '',
             headerCheck:'',
             occurance :'',
           }));
         }
         return;
     }
     if(data.footerFormatsDetails.length>0){
		this.num1=data.footerFormatsDetails.length;
		this.num2=this.count;
		
	if(this.num1>this.num2)
	{
		for (let index = 0; index < this.count; index++) {
			const creds=   this.form.controls.credentials as FormArray;
			   creds.push(this.fb.group({
				fromPosition:'',
				toPosition: '',
				lineNumber:'',
				format: '',
				headerCheck:'',
				occurance :'',
			  }));
			}
	}if(this.num1<this.num2){	
		var diff=(this.num2)-(this.num1);
      data.footerFormatsDetails.forEach(element => {
        const creds=   this.form.controls.credentials as FormArray;
        creds.push(this.fb.group({
          fromPosition:element.fromPosition,
          toPosition: element.toPosition,
          lineNumber:element.lineNumber,
          format: element.format,
          headerCheck:element.headerCheck,
          occurance :element.occurance,

        }));
	   });
	   for (let index = 0; index < diff; index++) {

		const creds=   this.form.controls.credentials as FormArray;
			creds.push(this.fb.group({
			 fromPosition:'',
			 toPosition: '',
			 lineNumber:'',
			 format: '',
			 headerCheck:'',
			 occurance :'',
		   }));
		 }
		}if(this.num1==this.num2)
		{
			data.footerFormatsDetails.forEach(element => {
				const creds=   this.form.controls.credentials as FormArray;
				creds.push(this.fb.group({
				  fromPosition:element.fromPosition,
				  toPosition: element.toPosition,
				  lineNumber:element.lineNumber,
				  format: element.format,
				  headerCheck:element.headerCheck,
				  occurance :element.occurance,
		
				}));
			   });
		}
     }else{
        for (let index = 0; index < this.count; index++) {
		const creds=   this.form.controls.credentials as FormArray;
           creds.push(this.fb.group({
            fromPosition:'',
            toPosition: '',
            lineNumber:'',
            format: '',
            headerCheck:'',
            occurance :'',
          }));
        }
      }
  }

  save(){
    let data= this.form.value;
    this.dialogRef.close(this.form.value.credentials);
  }
  OpenFooterAvailableclick(i) {
      const dialogRef = this.dialog.open(FooterAvailableclick,{data:i});
      dialogRef.afterClosed().subscribe(result => {
        if(result!=undefined){
         console.log({result})
         let constantValue=""
            result.formate.forEach(element => {
              console.log(element);
              constantValue=`:${element.constantValue}`
              
            });
          let x=  (<FormArray>this.form.controls['credentials']).at(result.index);
          x.patchValue({
            format:constantValue
          })
          }
      });
    }
    ngOnInit(){

    }
  }
  

  @Component({
    selector: 'footer-available-click',
    templateUrl: 'footer-available-click.html'  
  })
  
  export class FooterAvailableclick implements OnInit{
    constantValue:any[];
    policies= [
     
    ];
    index: any;
    submitted = false;
    FileNaming = new FormGroup({
      chooseConstant: new FormControl(),
      constantValue :new FormControl('', Validators.required)
    });


    constructor( public dialogRef:MatDialogRef<FooterAvailableclick>, @Inject(MAT_DIALOG_DATA) public data:any) {
      console.log({data})
      this.index=data;

    
     
     
    }


    ngOnInit(){

    }
    // addValue(chooseConstant,constantValue)
    // {
    //   console.log("chooseConstant   :",chooseConstant,  "constantValue   :",constantValue)
    //   var obj={
    //     "chooseConstant":chooseConstant,
    //     "constantValue":constantValue
    //   }
    //   if (this.FileNaming.invalid) {
        
    //   return;
    //   }
    //   alert('successfully!');
    //   this.policies.push(obj);
    //   // console.log("this.",this.constantValue)
    // }
    deleteRowAdressForm(rowNumber: number){
      this.policies.pop();
    
  }
  get fval() {
    return this.FileNaming.controls;
    }
    signup(){
      this.submitted = true;
      if (this.FileNaming.invalid) {
      return;
      }
      }
  save(chooseConstant,constantValue){
    var obj={
      "chooseConstant":chooseConstant,
      "constantValue":constantValue
    }
    if (this.FileNaming.invalid) {
      
    return;
    }
   
    this.policies.push(obj);
    this.dialogRef.close({formate: this.policies,index:this.index});
    
    }
    
    
  }