import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { EtlService } from '../../../services/etl.service';

@Component({
    selector: 'editJavaSplit',
    templateUrl: 'spliting-javaSplit-edit.html'  
  })
 
  export class editJavaSplit implements OnInit {
    config_data:any;  
    local_data : any;
    templateColumnNames1: any = [];
    ngOnInit(): void {
      this.loadTempColumns();
      this.local_data=this.data.editSelectedData;
      console.log("data",this.data)
      console.log("local_data",this.local_data);
      console.log("file id",this.data.fileId);
      this.selectOperationValue=this.data.editSelectedData.OPERATION_NAME;
      this.config_data=this.data;
      console.log("config_data",this.config_data);
      
    }
    public templetColumnNames:any=[];
    selectOperationValue:string;
    constructor(@Inject(MAT_DIALOG_DATA) public data:any,public etlService:EtlService) {
        
    } 

    changeSelectOperation(event)
    {
        this.selectOperationValue = event.target.value;
        
    }
    
    loadTempColumns()
    {
      console.log("select data   :",this.data.editSelectedData.TEMPLET_ID)
      this.etlService.getTemplateColumnNameData(this.data.editSelectedData.TEMPLET_ID).subscribe(
        data=>{
            for (const [key, value] of Object.entries(data)) {
            
                this.templetColumnNames.push({name:key,value:value});
                }

                // for (const [key, value] of Object.entries(data)) {

                //     this.templateColumnNames1.push({itemName:value,id:key});
                //   }
          
        }
      )
    }

    saveJavaSplitConfig()
    {
      
     //this.etlService.getEditJavaSplitConfig(this.config_data).subscribe(); 
    }
   

  
  }