import { Component,OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UserManagementService } from '../../services/user-management.service';
import { RolesDilogData} from "../../models/user-management.model";
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-roles',
  templateUrl: './edit-roles.component.html',
  styleUrls: ['./edit-roles.component.scss']
})
export class EditRolesComponent implements OnInit {

  model: any = {};
  modelData : any;
  RolesEdit:any;
  submitted = false;
  constructor(public dialogRef: MatDialogRef<EditRolesComponent>,public userManagementService: UserManagementService,
     private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)public data: RolesDilogData) {

      this.modelData = data[0];

      this.RolesEdit =this.formBuilder.group({
        roleName:new FormControl('',Validators.required),
        status:new FormControl('',Validators.required),
        description :new FormControl('',Validators.required),
        responsibility:new FormControl('',Validators.required),
   
     });
     }

  ngOnInit() {
  }
  get fval() {
    return this.RolesEdit.controls;
  }
  onSubmit() {
    this.submitted = true;   
    console.log('Submitted'); 
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveEditRolesForm(){
    this.submitted = true;
    if (this.RolesEdit.invalid) {
      return;
     }
     else{

    var saveData = this.data;
    console.log("savedata",saveData);
    this.userManagementService.updateRoles(saveData[0]).subscribe(
      (roleResponse:any)=>{
        alert("Edited Successfully");
        this.dialogRef.close("true");
      },
      error => {
        console.log(error);
      }  )   
   
  }
}

}
